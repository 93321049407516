import {DocumentMagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {RouteTab} from "../../../../components/RouterTabs/index.tsx";
import {Badge, HStack, Text} from "@chakra-ui/react";
import {useParams} from "react-router-dom";
import {ThirdPartyId} from "../../../../Types.ts";
import {useQueryData} from "../../../../state/index.ts";
import AssessmentsPage from "./AssessmentsPage";

export const Title = () => {
  const thirdPartyId = useParams().thirdPartyId! as ThirdPartyId;
  const count = useQueryData({
    queryKey: ["tprm", "thirdPartyAssessments", thirdPartyId],
  }).length;
  return (
    <HStack>
      <Text>Assessments</Text>
      {count > 0 && <Badge colorScheme="blue">{count}</Badge>}
    </HStack>
  );
};

export default {
  title: <Title />,
  path: "assessments",
  icon: DocumentMagnifyingGlassIcon,
  element: <AssessmentsPage />,
} satisfies RouteTab;
