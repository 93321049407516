import {Avatar, Box, Button, Circle, Flex, FlexProps, HStack, Text} from "@chakra-ui/react";
import {usePusher, useSubscription} from "../../hooks/pusher/index.tsx";
import {
  ActivityStream,
  Activity,
  CommentPayload,
  UserMin,
  ApiDateTime,
  UserId,
  ActivityPayload,
  Mention,
} from "../../Types";
import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {usePromiseState} from "../../hooks/promiseState";
import api from "../../api";
import {useTime} from "../../hooks/time";
import {SECOND} from "../../utils/time";
import {CommentBox} from "./CommentBox";
import {RichTextView} from "./RichText";
import {useQueryData} from "../../state";
import {withKey} from "../../state/withKey";
import RelativeTimeText from "../RelativeTimeText.tsx";
import QuestionCreatedView from "./Payloads/QuestionCreated";
import QuestionUpdatedView from "./Payloads/QuestionUpdated";
import {unreachableCase} from "../../utils/typescript";
import DocumentUpdatedView from "./Payloads/DocumentUpdated";

type ActivityStreamProps = {
  activityStream: ActivityStream;
  watchSelector?: ReactNode;
};

type TypingIndicatorData = {
  who: {
    user_id: UserId;
    name: string;
  };
  when: number;
};

const ActivityTitle = ({
  children,
  user,
  occurred_at,
  ...props
}: FlexProps & {user?: UserMin; occurred_at: ApiDateTime}) => {
  return (
    <Flex fontSize="sm" justifyContent="space-between" color="gray.500" {...props}>
      <Text as="span">
        <Text as="span" fontWeight="600">
          {user ? user.name : "Platformed"}
        </Text>{" "}
        {children}
      </Text>
      <RelativeTimeText dateTime={occurred_at} />
    </Flex>
  );
};

export type ActivityPayloadViewProps<T extends ActivityPayload["type"]> = {
  payload: (ActivityPayload & {type: T})["content"];
  mentions: Mention[];
};

const ActivityView = ({activity: {user, payload, occurred_at, mentions}}: {activity: Activity}) => {
  const {internal_mode} = useQueryData({queryKey: ["whoAmI"]});
  // Suppress activity from Platformed users
  const showUser = user && (internal_mode || !user.primary_email.toLowerCase().endsWith("@platformed.com"));
  const effectiveUser = showUser ? user : undefined;
  let titleContent: ReactNode;
  let bodyContent = null;
  switch (payload.type) {
    case "Comment":
      titleContent = "commented";
      bodyContent = (
        <Text fontSize="md" color="gray.700">
          <RichTextView message={payload.content.message} mentions={mentions} />
        </Text>
      );
      break;
    case "QuestionCreated":
      titleContent = <QuestionCreatedView payload={payload.content} mentions={mentions} />;
      break;
    case "QuestionUpdated":
      titleContent = <QuestionUpdatedView payload={payload.content} mentions={mentions} />;
      break;
    case "DocumentUpdated":
      titleContent = <DocumentUpdatedView payload={payload.content} mentions={mentions} />;
      break;
    default:
      unreachableCase(payload);
  }
  const content = bodyContent ? (
    <Box border="1px solid" borderColor="gray.200" borderRadius="lg" p={3}>
      <ActivityTitle user={effectiveUser} occurred_at={occurred_at} mb={2}>
        {titleContent}
      </ActivityTitle>
      {bodyContent}
    </Box>
  ) : (
    <ActivityTitle user={effectiveUser} occurred_at={occurred_at}>
      {titleContent}
    </ActivityTitle>
  );
  return (
    <>
      <Flex
        backgroundColor="white"
        position="relative"
        alignSelf="start"
        justifyContent="center"
        py={2}
        sx={{":nth-last-of-type(2)": {alignSelf: "stretch"}}}
      >
        {effectiveUser ? (
          <Avatar name={effectiveUser.name} size="xs" />
        ) : (
          <Circle size="8px" border="1px solid" borderColor="gray.200" backgroundColor="gray.100" />
        )}
      </Flex>
      <Box display="grid" alignItems="center">
        {content}
      </Box>
    </>
  );
};

const ActivityItems = React.memo(
  ({
    activities,
    hasEarlier,
    isFetchingEarlier,
    fetchEarlier,
  }: {
    activities: Activity[];
    hasEarlier: boolean;
    isFetchingEarlier: boolean;
    fetchEarlier: () => void;
  }) => {
    return (
      <Box rowGap={4} columnGap={4} my={4} display="grid" position="relative" gridTemplateColumns="23px auto">
        <Box position="absolute" top={2} bottom={2} left="11px" w="1px" backgroundColor="gray.200" />
        {activities.map(activity => (
          <ActivityView key={activity.activity_ordinal} activity={activity} />
        ))}
        {hasEarlier && (
          <>
            <Flex
              backgroundColor="white"
              position="relative"
              alignSelf="stretch"
              py={2}
              mt="calc(50% - 4px)"
              justifyContent="center"
            >
              <Circle size="8px" border="1px solid" borderColor="gray.200" backgroundColor="gray.100" />
            </Flex>
            <Box>
              <Button
                isLoading={isFetchingEarlier}
                isDisabled={isFetchingEarlier}
                onClick={fetchEarlier}
                variant="outline"
              >
                Load earlier activity...
              </Button>
            </Box>
          </>
        )}
      </Box>
    );
  },
);

const RecentTypers = ({recentTypers}: {recentTypers: TypingIndicatorData[]}) => {
  if (recentTypers.length === 0) {
    return null;
  }

  const message = recentTypers.length === 1 ? " is typing..." : " are typing...";

  return (
    <HStack spacing={4}>
      {recentTypers.map(x => (
        <Avatar key={x.who.user_id} name={x.who.name} size="xs" />
      ))}
      <Text fontSize="sm" color="gray.500">
        <Text as="span" fontWeight="600">
          {recentTypers.map(x => x.who.name).join(", ")}
        </Text>
        {message}
      </Text>
    </HStack>
  );
};

const MAX_PER_PAGE = 25;
const TYPING_INTERVAL = 3 * SECOND;

export const ActivityStreamView = withKey(
  ({activityStream: {activity_stream_id, channel_name}, watchSelector}: ActivityStreamProps) => {
    const pusher = usePusher();
    const whoami = useQueryData({queryKey: ["whoAmI"]});
    const [activities, setActivities] = useState<Activity[]>([]);
    const [pushedOrdinal, setPushedOrdinal] = useState(0);
    const [readyForInitialFetch, setReadyForInitialFetch] = useState(false);
    const [earlierToLoad, setEarlierToLoad] = useState(false);
    const [whosTyping, setWhosTyping] = useState(new Map<UserId, TypingIndicatorData>());
    const latestOrdinal = activities.length > 0 ? activities[0].activity_ordinal : 0;
    const earliestOrdinal = activities.length > 0 ? activities[activities.length - 1].activity_ordinal : Infinity;

    const [fetching, fetch] = usePromiseState(async () => {
      setReadyForInitialFetch(false);
      const newActivities = await api.activities.list(activity_stream_id, {since: latestOrdinal});
      if (newActivities.length >= MAX_PER_PAGE && latestOrdinal === 0) {
        setEarlierToLoad(true);
      }
      setActivities(activities => [...newActivities, ...activities]);

      // Clear the typing state for users who we just received messages from
      const userIdsWhoSentMessages = new Set(
        newActivities
          .filter(
            (activity): activity is Activity & {payload: CommentPayload; user: UserMin} =>
              activity.payload.type === "Comment" && activity.user != null,
          )
          .map(activity => activity.user.user_id),
      );
      setWhosTyping(m => {
        const newMap = new Map(m);
        for (const userId of userIdsWhoSentMessages) {
          newMap.delete(userId);
        }
        return newMap;
      });
    }, [activity_stream_id, latestOrdinal, setReadyForInitialFetch]);

    const [fetchingEarlier, fetchEarlier] = usePromiseState(async () => {
      const newActivities = await api.activities.list(activity_stream_id, {before: earliestOrdinal});
      if (newActivities.length < MAX_PER_PAGE) {
        setEarlierToLoad(false);
      }
      setActivities(activities => [...activities, ...newActivities]);
    }, [activity_stream_id, earliestOrdinal]);

    useSubscription(channel_name, null, (eventName, data) => {
      switch (eventName) {
        case "pusher:subscription_succeeded":
          setReadyForInitialFetch(true);
          break;
        case "activity":
          setPushedOrdinal(data);
          break;
        case "client-typing":
          setWhosTyping(m => {
            const newMap = new Map(m);
            newMap.set(data.who.user_id, data);
            return newMap;
          });
          break;
      }
    });

    const recentTypers = useTime(
      ts => {
        const current = Array.from(whosTyping.values())
          .filter(v => v.when > ts - TYPING_INTERVAL)
          .sort((a, b) => a.who.name.localeCompare(b.who.name));
        return {
          current,
          expires: Math.min(...current.map(v => v.when)) + TYPING_INTERVAL,
        };
      },
      [whosTyping],
    );

    const wantFetch = pushedOrdinal > latestOrdinal || readyForInitialFetch;
    useEffect(() => {
      if (wantFetch && !fetching.inProgress) {
        fetch();
      }
    }, [fetching.inProgress, wantFetch, fetch]);

    const typing = useCallback(() => {
      const data: TypingIndicatorData = {
        who: {name: whoami.internal_mode ? "Platformed" : whoami.user.name, user_id: whoami.user.user_id},
        when: Date.now(),
      };
      pusher.trigger(channel_name, "client-typing", data);
    }, [whoami.user.name, whoami.user.user_id, whoami.internal_mode, channel_name, pusher]);

    return (
      <Box>
        <Flex justifyContent="space-between" alignItems="center" pb={2}>
          <Text fontWeight="600" fontSize="lg" color="gray.600">
            Activity
          </Text>
          {watchSelector}
        </Flex>

        <Box rowGap={4} columnGap={4} my={2} display="grid" position="relative" gridTemplateColumns="23px auto">
          <Avatar name={whoami.user.name} size="xs" />
          <CommentBox activityStreamId={activity_stream_id} onTyping={typing} />
        </Box>
        <RecentTypers recentTypers={recentTypers} />
        <ActivityItems
          activities={activities}
          hasEarlier={earlierToLoad}
          isFetchingEarlier={fetchingEarlier.inProgress}
          fetchEarlier={fetchEarlier}
        />
      </Box>
    );
  },
  p => p.activityStream.activity_stream_id,
);
