import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import {useResetting} from "../../../hooks/resetting";
import {useValidatedPromiseState, useValidation} from "../../../hooks/validationState";
import api from "../../../api";
import {Owner, ThirdPartyTierMin} from "../../../Types";
import TierSelector from "../components/TierSelector";
import {useRef, useState} from "react";
import OwnerSelector from "../../../components/OwnerSelector";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function CreateThirdPartyModal({isOpen, onClose}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);

  const [name, setName] = useResetting(useState(""), isOpen);
  const [url, setUrl] = useResetting(useState(""), isOpen);
  const [tier, setTier] = useResetting(useState<ThirdPartyTierMin | null>(null), isOpen);
  const [businessOwner, setBusinessOwner] = useResetting(useState<Owner | undefined>(undefined), isOpen);
  const [riskOwner, setRiskOwner] = useResetting(useState<Owner | undefined>(undefined), isOpen);

  const [validationErrors, setValidationErrors] = useValidation(useState({}), {name});
  const buttonDisabled = !name || !url || !tier;

  const [creating, create] = useValidatedPromiseState(
    async () => {
      await api.tprm.thirdParties.create({
        name,
        url,
        tier_id: tier!.tier_id,
        business_owner_id: businessOwner?.owner_id,
        risk_owner_id: riskOwner?.owner_id,
      });

      onClose();
    },
    [name, url, tier, businessOwner, riskOwner, onClose],
    setValidationErrors,
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={create} ref={containerRef}>
        <ModalHeader>Create third party</ModalHeader>
        <ModalBody>
          <Stack spacing={6}>
            <FormControl isRequired isInvalid={validationErrors.name !== undefined}>
              <FormLabel>Name</FormLabel>
              <Input value={name} onChange={e => setName(e.target.value)} />
              {validationErrors.name && validationErrors.name[0] && (
                <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isRequired>
              <FormLabel>URL</FormLabel>
              <Input value={url} onChange={e => setUrl(e.target.value)} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Tier</FormLabel>
              <TierSelector tier={tier} onChange={setTier} portalContainerRef={containerRef} />
              <FormHelperText>How critical is the third party to your organisation?</FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel>Business owner</FormLabel>
              <OwnerSelector owner={businessOwner} onReassign={setBusinessOwner} portalContainerRef={containerRef} />
            </FormControl>
            <FormControl>
              <FormLabel>Risk owner</FormLabel>
              <OwnerSelector owner={riskOwner} onReassign={setRiskOwner} portalContainerRef={containerRef} />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={3}>
            <Button colorScheme="gray" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="green"
              type="submit"
              isDisabled={buttonDisabled || creating.inProgress}
              isLoading={creating.inProgress}
            >
              Create third party
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CreateThirdPartyModal;
