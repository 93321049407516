import {DocumentCategory as DocumentCategoryT} from "../../../../Types";

import {Menu, MenuItemOption, MenuOptionGroup, TagLabel} from "@chakra-ui/react";
import {usePromiseState} from "../../../../hooks/promiseState";
import {DOCUMENT_CATEGORY_MAP} from ".";
import TagMenuButton from "../../../../components/TagMenuButton";
import PortalMenuList from "../../../../components/PortalMenuList";

const DocumentCategoryComp = ({
  category,
  onChangeCategory,
  size,
}: {
  category: DocumentCategoryT;
  onChangeCategory?: (category: DocumentCategoryT) => Promise<void>;
  size?: "sm" | "lg";
}) => {
  const {color, text} = DOCUMENT_CATEGORY_MAP[category];

  const [changing, change] = usePromiseState(
    async (val: string | string[]) => {
      if (onChangeCategory) {
        await onChangeCategory(val as DocumentCategoryT);
      }
    },
    [onChangeCategory],
  );

  return (
    <Menu isLazy>
      <TagMenuButton colorScheme={color} isLoading={changing.inProgress} isDisabled={!onChangeCategory} size={size}>
        <TagLabel>{text}</TagLabel>
      </TagMenuButton>
      <PortalMenuList fontSize="md" onClick={e => e.stopPropagation()}>
        <MenuOptionGroup type="radio" onChange={change} value={category}>
          {Object.keys(DocumentCategoryT)
            .filter(c => c !== DocumentCategoryT.Report)
            .map(c => (
              <MenuItemOption key={c} value={c}>
                {DOCUMENT_CATEGORY_MAP[c].text}
              </MenuItemOption>
            ))}
        </MenuOptionGroup>
      </PortalMenuList>
    </Menu>
  );
};

export default DocumentCategoryComp;
