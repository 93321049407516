import {
  Badge,
  LinkBox,
  Tr,
  Td,
  HStack,
  Avatar,
  LinkOverlay,
  Icon,
  Text,
  Box,
  Spinner,
  Table,
  TableContainer,
  Tbody,
} from "@chakra-ui/react";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import DueDate from "../../../../components/DueDate";
import {Question, QuestionStatus, LayerType, Task} from "../../../../Types";
import {getResponseLayer, useLayerType} from "../../../../hooks/layerType";
import PlatformedLogo from "../../../../../assets/logo/logo.svg";
import {withSuspense} from "../../../../state/withSuspense";
import {useQueryData} from "../../../../state";
import {Link as RouterLink} from "react-router-dom";
import {useMemo} from "react";

function taskKey(task: Task): string {
  return taskQuestion(task).question_id;
}

function taskQuestion(task: Task): Question {
  switch (task.type) {
    case "QuestionnaireQuestion":
      return task.content.question;
    case "AdHocQuestion":
      return task.content.adhoc_question.question;
  }
}

function taskLink(task: Task): string {
  switch (task.type) {
    case "QuestionnaireQuestion":
      return `/vendor-toolkit/questionnaires/${task.content.questionnaire.questionnaire_id}/questions/${task.content.question.question_id}`;
    case "AdHocQuestion":
      return `/vendor-toolkit/questions/${task.content.adhoc_question.question.question_id}`;
  }
}

function TaskAvatar({task}: {task: Task}) {
  const src = useMemo(() => {
    switch (task.type) {
      case "QuestionnaireQuestion":
        return `https://logo.clearbit.com/${task.content.questionnaire.counterparty.url}`;
      case "AdHocQuestion":
        if (task.content.adhoc_question.question_source != null) {
          return task.content.adhoc_question.question_source.connector.logo_url;
        }

        return PlatformedLogo;
    }
  }, [task]);

  const name = useMemo(() => {
    switch (task.type) {
      case "QuestionnaireQuestion":
        return task.content.questionnaire.counterparty.name;
      case "AdHocQuestion":
        // This should never display since the `src` for the Platformed logo is valid.
        return "Platformed";
    }
  }, [task]);

  return <Avatar size="xs" src={src} name={name} />;
}

function taskContextName(task: Task): string {
  switch (task.type) {
    case "QuestionnaireQuestion":
      return task.content.questionnaire.name;
    case "AdHocQuestion": {
      if (task.content.adhoc_question.asker?.name != null) {
        return `Asked by ${task.content.adhoc_question.asker.name}`;
      }

      return "Question";
    }
  }
}

function questionStatusColor(status: QuestionStatus): string {
  switch (status) {
    case QuestionStatus.Review:
      return "orange";
    case QuestionStatus.Respond:
      return "red";
    case QuestionStatus.Automating:
      return "gray";
    case QuestionStatus.Complete:
      return "green";
  }
}

function TaskStatusBadge({status}: {status: QuestionStatus}) {
  return (
    <Badge colorScheme={questionStatusColor(status)} fontSize="sm">
      {status}
    </Badge>
  );
}

function Row({task}: {task: Task}) {
  const CELL_STYLES = {
    border: "none",
  };

  const question = taskQuestion(task);
  const {due_date, status} = getResponseLayer(question.response_layers, LayerType.External);

  return (
    <LinkBox as={Tr} _hover={{bg: "gray.50"}} _notLast={{borderBottom: "1px solid", borderColor: "gray.200"}}>
      <Td {...CELL_STYLES}>
        <HStack spacing="6">
          <TaskAvatar task={task} />
          <Text fontWeight="600">{taskContextName(task)}</Text>
        </HStack>
      </Td>
      <Td {...CELL_STYLES} whiteSpace="wrap" w="100%">
        <LinkOverlay as={RouterLink} to={taskLink(task)}>
          <Text noOfLines={2}>{question.text}</Text>
        </LinkOverlay>
      </Td>
      <Td {...CELL_STYLES}>
        <DueDate dueDate={due_date} complete={status === QuestionStatus.Complete} />
      </Td>
      <Td {...CELL_STYLES}>
        <TaskStatusBadge status={status} />
      </Td>
      <Td {...CELL_STYLES}>
        <Icon display="block" as={ChevronRightIcon} />
      </Td>
    </LinkBox>
  );
}

export const QuestionsTable = withSuspense(
  ({offset, limit}: {offset: number; limit: number}) => {
    const [layerType] = useLayerType();
    const tasks = useQueryData({queryKey: ["vendorToolkit", "tasks", layerType, {offset, limit}]});

    return (
      <TableContainer>
        <Table variant="simple" fontSize="md">
          <Tbody>
            {tasks.items.map(task => (
              <Row key={taskKey(task)} task={task} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    );
  },
  <Box p={8} display="grid" placeItems="center">
    <Spinner size="sm" />
  </Box>,
);
