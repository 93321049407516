import {Heading, HeadingProps, Stack, StackProps} from "@chakra-ui/react";

export function CardHeading({hasBorder = false, ...props}: {hasBorder?: boolean} & HeadingProps) {
  return (
    <Heading
      size="sm"
      p="4"
      pb={hasBorder ? 4 : 0}
      color="gray.600"
      fontWeight="600"
      {...(hasBorder ? {borderBottom: "1px solid", borderColor: "gray.200"} : {})}
      {...props}
    />
  );
}

export function Card({children, ...props}: {children: React.ReactNode} & StackProps) {
  return (
    <Stack
      border="1px solid"
      borderColor="gray.200"
      rounded="md"
      spacing="0"
      overflow="hidden"
      flex="1 1 auto"
      {...props}
    >
      {children}
    </Stack>
  );
}
