import {Badge, Box, Button, Divider, Highlight, HStack, Icon, Stack, Text, useDisclosure} from "@chakra-ui/react";
import ContentWrapper from "./components/ContentWrapper";
import {ArrowDownTrayIcon, LockClosedIcon} from "@heroicons/react/20/solid";
import {DocumentExternal} from "../Types";
import externalApi from "../api/external";
import {usePromiseState} from "../hooks/promiseState";
import {useTime} from "../hooks/time";
import {relativeDateView} from "../utils/time";
import {RequestDocumentAccessModal} from "./components/RequestDocumentAccessModal";
import {useClientAccount} from "./hooks/clientAccount";
import Search from "../components/Search";
import {useSearchParam} from "../hooks/searchParams";
import {useTrustCenterDocuments} from "./config";
import {useTrustCenterPassword} from "./hooks/accessPassword.ts";

export function DocumentDownloadButton({document}: {document: DocumentExternal}) {
  const clientAccount = useClientAccount();
  const modal = useDisclosure();
  const password = useTrustCenterPassword(clientAccount.account_id) ?? "";
  const [downloading, download] = usePromiseState(async () => {
    await externalApi.trustCenters.downloadDocument(clientAccount.account_id, document.document_id, password);
  }, [clientAccount.account_id, document.document_id, password]);

  return !document.is_readable ? (
    <>
      <Button
        leftIcon={<Icon as={LockClosedIcon} />}
        size="sm"
        fontSize="sm"
        onClick={modal.onOpen}
        variant="secondary"
      >
        Request access
      </Button>
      <RequestDocumentAccessModal isOpen={modal.isOpen} onClose={modal.onClose} document={document} />
    </>
  ) : (
    <Button
      leftIcon={<Icon as={ArrowDownTrayIcon} />}
      size="sm"
      onClick={download}
      isLoading={downloading.inProgress}
      isDisabled={downloading.inProgress}
      variant="primary"
    >
      Download
    </Button>
  );
}

const Document = ({document, query}: {document: DocumentExternal; query?: string}) => {
  const lastUpdateText = useTime(relativeDateView(document.last_update), [document.last_update]);
  return (
    <Box px={4} py={4}>
      <HStack>
        <Stack flex={2} align="flex-start">
          <Text fontSize="md" fontWeight="semibold" color="gray.700">
            {query !== undefined ? (
              <Highlight query={query} styles={{bg: "yellow.200"}}>
                {document.name}
              </Highlight>
            ) : (
              document.name
            )}
          </Text>
          <Badge>Updated {lastUpdateText}</Badge>
        </Stack>
        <DocumentDownloadButton document={document} />
      </HStack>
    </Box>
  );
};

const Documents = () => {
  const [searchQuery, setSearchQuery] = useSearchParam("q");

  const documents = useTrustCenterDocuments().filter(d =>
    searchQuery ? d.name.toLowerCase().includes(searchQuery.toLowerCase()) : true,
  );

  const notFoundText = searchQuery ? "No documents were found for this query." : "There are no documents.";

  return (
    <ContentWrapper py={12}>
      <Stack spacing={4}>
        <Search query={searchQuery ?? ""} onChange={setSearchQuery} placeholder="Search documents..." />
        {documents.length !== 0 ? (
          <Stack border="1px" borderColor="gray.200" rounded="md" divider={<Divider />} spacing={0}>
            {documents.map((document: DocumentExternal) => (
              <Document
                key={document.document_id}
                document={document}
                query={searchQuery !== null ? searchQuery : undefined}
              />
            ))}
          </Stack>
        ) : (
          <Box border="1px" borderColor="gray.200" rounded="md" p={4}>
            <Text fontSize="md" color="gray.500">
              {notFoundText}
            </Text>
          </Box>
        )}
      </Stack>
    </ContentWrapper>
  );
};

export default Documents;
