import {
  HStack,
  Icon,
  LinkBox,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Menu,
  TagLabel,
  Tag,
  TagCloseButton,
} from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom";
import {CheckCircleIcon, ChevronRightIcon, XCircleIcon} from "@heroicons/react/20/solid";
import {ThirdPartyMin, ThirdPartyStatusMin, ThirdPartyTierMin} from "../../../../Types";
import TagMenuButton from "../../../../components/TagMenuButton";
import ThirdPartyName from "../../components/ThirdPartyName";
import DueDate from "../../../../components/DueDate";
import LinkOverlay from "../../../../components/LinkOverlay";
import TierSelector from "../../components/TierSelector";
import api from "../../../../api";
import {useCallback} from "react";

const STATUS_COLORS: {[status: string]: string} = {
  Approved: "green",
  Contracting: "yellow",
};

const ThirdPartyStatus = ({status}: {status: ThirdPartyStatusMin}) => {
  const color = STATUS_COLORS[status.title] || "gray";
  return (
    <Menu isLazy>
      <TagMenuButton colorScheme={color}>
        <TagLabel>{status.title}</TagLabel>
      </TagMenuButton>
    </Menu>
  );
};

const RiskGrade = ({score}: {score: number}) => {
  let color = "red";
  let letter = "F";

  if (score >= 0.8) {
    color = "blue";
    letter = "A";
  } else if (score >= 0.6) {
    color = "green";
    letter = "B";
  } else if (score >= 0.4) {
    color = "yellow";
    letter = "C";
  } else if (score >= 0.2) {
    color = "orange";
    letter = "D";
  } else if (score > 0) {
    color = "red";
    letter = "E";
  } else {
    color = "red";
    letter = "F";
  }

  return (
    <Tag colorScheme={color} size="lg" rounded="full" fontWeight="bold">
      {letter}
    </Tag>
  );
};

const ThirdPartyRow = ({thirdParty, queries}: {thirdParty: ThirdPartyMin; queries: string[]}) => {
  const updateTier = useCallback(
    async (tier: ThirdPartyTierMin) => {
      await api.tprm.thirdParties.updateTier(thirdParty.third_party_id, tier.tier_id);
    },
    [thirdParty.third_party_id],
  );

  return (
    <LinkBox as={Tr} transform="scale(1)" _hover={{bg: "gray.50", cursor: "pointer"}}>
      <Td fontSize="md">
        <LinkOverlay as={RouterLink} to={thirdParty.third_party_id} />
        <Stack gap={4}>
          <ThirdPartyName thirdParty={thirdParty} queries={queries} fontWeight="semibold" />
          {thirdParty.tags.length > 0 && (
            <HStack>
              {thirdParty.tags.map((t, i) => (
                <Tag colorScheme={t.color} key={i}>
                  {t.title}
                  <TagCloseButton />
                </Tag>
              ))}
            </HStack>
          )}
        </Stack>
      </Td>
      <Td>
        <TierSelector tier={thirdParty.tier} onChange={updateTier} />
      </Td>
      <Td fontSize="md">
        <Stack>
          <HStack gap={1}>
            <Icon as={XCircleIcon} color="red.500" />
            <Text>12 Unmitigated</Text>
          </HStack>
          <HStack gap={1}>
            <Icon as={CheckCircleIcon} color="green.500" />
            <Text>18 Mitigated</Text>
          </HStack>
        </Stack>
      </Td>
      <Td fontSize="md">
        <RiskGrade score={thirdParty.score} />
      </Td>
      <Td fontSize="md">
        <ThirdPartyStatus status={thirdParty.status} />
      </Td>
      <Td fontSize="md">
        <DueDate dueDate={thirdParty.due_date} complete={false} />
      </Td>
      <Td>
        <Icon as={ChevronRightIcon} fontSize="24" color="gray.500" />
      </Td>
    </LinkBox>
  );
};

const ThirdPartiesTable = ({thirdParties, queries = []}: {thirdParties: ThirdPartyMin[]; queries?: string[]}) => (
  <Table style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg" layout="fixed">
    <Thead bg="white" borderBottom="10px solid" borderBottomColor="red.100">
      <Tr>
        <Th width="20%" color="gray.500">
          Client
        </Th>
        <Th width="13%" color="gray.500">
          Tier
        </Th>
        <Th width="20%" color="gray.500">
          Risks Identified
        </Th>
        <Th width="12%" color="gray.500">
          Risk Grade
        </Th>
        <Th color="gray.500" width="15%">
          Status
        </Th>
        <Th color="gray.500" width="14%">
          Next Review
        </Th>
        <Th width="6%" />
      </Tr>
    </Thead>
    <Tbody>
      {thirdParties.map(thirdParty => (
        <ThirdPartyRow key={thirdParty.third_party_id} thirdParty={thirdParty} queries={queries} />
      ))}
    </Tbody>
  </Table>
);

export default ThirdPartiesTable;
