import {FormControl, FormLabel, GridItem, SimpleGrid, Switch} from "@chakra-ui/react";
import {withSuspense} from "../../../state/withSuspense";
import {AccountFeature} from "../../../Types";
import {useQueryData} from "../../../state";
import {usePromiseState} from "../../../hooks/promiseState";
import api from "../../../api";
import {Fragment} from "react";
import {TooltipWithIcon} from "../../../components/TooltipWithIcon.tsx";

const planConfigLabels = [
  {feature: AccountFeature.VendorToolkit, label: "Vendor toolkit"},
  {feature: AccountFeature.TrustCenter, label: "Trust center", parent: AccountFeature.VendorToolkit},
  {feature: AccountFeature.Scopes, label: "Scopes", parent: AccountFeature.VendorToolkit},
  {feature: AccountFeature.ExpressCredits, label: "Express credits", parent: AccountFeature.VendorToolkit},
  {
    feature: AccountFeature.InstantAnswering,
    label: "Questionnaire instant option",
    parent: AccountFeature.VendorToolkit,
    tooltip: "Allow users to choose between Instant or Considered questionnaire answering",
  },
  {feature: AccountFeature.CsvImport, label: "Manual CSV import", parent: AccountFeature.VendorToolkit},
  {
    feature: AccountFeature.ResponseWizard,
    label: "Interactive answering & Response modifiers",
    parent: AccountFeature.VendorToolkit,
    tooltip: "Show users the purple 'Answer with AI' and Response Modifiers buttons",
  },
  {feature: AccountFeature.TPRM, label: "Third Party Risk Management"},
];

const activeLabels = [
  ["in_plan", "In plan"],
  ["is_enabled", "Is enabled"],
];

const FeaturesPage = withSuspense(() => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const planFeatures = whoami.account!.plan_features;
  const enabledFeatures = whoami.account!.enabled_features;

  const [togglingEnabled, toggleEnabled] = usePromiseState(
    async (feature: AccountFeature) => {
      await api.config.updateEnabledFeature(feature, !enabledFeatures.includes(feature));
    },
    [enabledFeatures],
  );

  const [togglingPlan, togglePlan] = usePromiseState(
    async (feature: AccountFeature) => {
      await api.config.updatePlanFeature(feature, !planFeatures.includes(feature));
    },
    [planFeatures],
  );

  const getPlanActiveEnabled = (feature: AccountFeature, type: string) => {
    if (type === "in_plan") {
      return planFeatures.includes(feature);
    } else if (type === "is_enabled") {
      return enabledFeatures.includes(feature);
    }
  };
  return (
    <SimpleGrid templateColumns="1fr" columns={3} columnGap="12" rowGap="3" my={4} mx={4} maxW="500px">
      {activeLabels.map(([key, label], col) => (
        <GridItem key={key} as={FormControl} gridColumn={col + 2}>
          <FormLabel display="contents" fontWeight="semibold" color="gray.600">
            {label}
          </FormLabel>
        </GridItem>
      ))}
      {planConfigLabels.map(({feature, label, parent, tooltip}) => (
        <Fragment key={feature}>
          <GridItem as={FormControl} pl={parent && 8}>
            <FormLabel fontWeight={parent ? "normal" : "semibold"} color={parent ? undefined : "gray.600"}>
              {tooltip ? (
                <TooltipWithIcon label={tooltip} placement="right">
                  {label}
                </TooltipWithIcon>
              ) : (
                label
              )}
            </FormLabel>
          </GridItem>
          {activeLabels.map(([key]) => (
            <GridItem key={key} as={FormControl} textAlign="right">
              <Switch
                isChecked={getPlanActiveEnabled(feature, key)}
                onChange={() => (key === "in_plan" ? togglePlan(feature) : toggleEnabled(feature))}
                isDisabled={
                  (parent && !getPlanActiveEnabled(parent, key)) ||
                  (key === "in_plan"
                    ? togglingPlan.inProgress
                    : togglingEnabled.inProgress || !planFeatures.includes(feature))
                }
              />
            </GridItem>
          ))}
        </Fragment>
      ))}
    </SimpleGrid>
  );
});

export default FeaturesPage;
