import {
  ArrowDownTrayIcon,
  ChevronDownIcon,
  CloudArrowDownIcon,
  DocumentIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  UnorderedList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {LayerType, Question, Questionnaire, SharingClassification} from "../../../../../Types.ts";
import {ActionBar} from "../../../../../components/ActionBar.tsx";
import api from "../../../../../api/index.ts";
import {useQueriesData, useQueryData} from "../../../../../state/index.ts";
import {withSuspense} from "../../../../../state/withSuspense.tsx";
import QuestionNumber from "../../components/QuestionNumber.tsx";
import {getResponseLayer, useLayerType} from "../../../../../hooks/layerType.ts";
import {useOutletContext} from "react-router-dom";
import {TabOutletContext} from "../index.tsx";
import ExportPortalModal from "../../../components/Export/ExportPortalModal.tsx";
import {ExportFileModal} from "../../../components/Export/ExportFileModal.tsx";
import PortalMenuList from "../../../../../components/PortalMenuList.tsx";
import questionKeys from "../../../../../utils/questionKeys";
import LanguageSelector from "../../../components/LanguageSelector";
import {useCallback} from "react";

const StyledMenuItem = ({
  icon,
  children,
  description,
  tooltip,
  ...props
}: {icon: React.FC; description: string; tooltip?: string} & Omit<MenuItemProps, "icon">) => {
  const [layerType] = useLayerType();

  return (
    <MenuItem {...props}>
      <Tooltip label={tooltip} placement="right">
        <Flex py="1" w="full" align="flex-start">
          <Icon as={icon} h="6" mr="2" color="gray.400" />
          <Box flex={1}>
            <Box fontWeight={500}>{children}</Box>
            <Box fontWeight={400} fontSize="sm" color="gray.500" maxW={220}>
              {description}
            </Box>

            {layerType !== LayerType.External && (
              <Badge colorScheme="red" display="inline-flex" alignItems="center" mt={2}>
                <Icon as={ExclamationTriangleIcon} mr="1" color="red.800" />
                {layerType} Layer
              </Badge>
            )}
          </Box>
        </Flex>
      </Tooltip>
    </MenuItem>
  );
};

const ExportMenu = ({questionnaire}: {questionnaire: Questionnaire}) => {
  const [layerType] = useLayerType();
  const toast = useToast();
  const {isOpen: fileSupportIsOpen, onOpen: fileSupportOnOpen, onClose: fileSupportOnClose} = useDisclosure();
  const {isOpen: portalSupportIsOpen, onOpen: portalSupportOnOpen, onClose: portalSupportOnClose} = useDisclosure();
  const documentation = useQueryData({
    queryKey: ["vendorToolkit", "questionnaireDocumentation", questionnaire.questionnaire_id, layerType],
  });
  const hasDocumentation = documentation.length > 0;
  const downloadDocumentation = async () => {
    await api.vendorToolkit.questionnaires.downloadSupportingDocumentation(questionnaire.questionnaire_id);
  };
  const downloadCsv = async () => {
    await api.vendorToolkit.questionnaires.downloadCsv(questionnaire.questionnaire_id, layerType);
    toast({description: "Download started. Translations may take some time.", status: "success"});
  };

  const default_message = "Hi Platformed,\nPlease export this questionnaire for me.";
  const default_description =
    "Unless otherwise specified, we'll attempt to export the questionnaire into the same format that was used during import. You can write any other instructions below.";
  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<Icon as={ChevronDownIcon} />}
          colorScheme="green"
          leftIcon={<Icon as={ArrowDownTrayIcon} />}
        >
          Export
        </MenuButton>
        <PortalMenuList fontSize="md">
          <StyledMenuItem
            icon={DocumentIcon}
            description="Export your questionnaire to a file"
            onClick={fileSupportOnOpen}
          >
            To file
          </StyledMenuItem>
          <StyledMenuItem
            icon={CloudArrowDownIcon}
            description="Export your questionnaire to a web portal"
            onClick={portalSupportOnOpen}
          >
            To portal
          </StyledMenuItem>
          <StyledMenuItem
            icon={ArrowDownTrayIcon}
            description="Download a zip of all supporting documentation"
            onClick={downloadDocumentation}
            data-testid="download-supporting-documentation"
            isDisabled={!hasDocumentation}
            tooltip={!hasDocumentation ? "You haven't attached any supporting documentation" : undefined}
          >
            Supporting documentation
          </StyledMenuItem>
          <StyledMenuItem
            icon={ArrowDownTrayIcon}
            description="Download a csv of all questions and responses"
            data-testid="download-questions"
            onClick={downloadCsv}
          >
            Questions CSV
          </StyledMenuItem>
        </PortalMenuList>
      </Menu>

      <ExportFileModal
        isOpen={fileSupportIsOpen}
        onClose={fileSupportOnClose}
        title="Export to file"
        action="export-file"
        subject="Export questionnaire request (file)"
        initialBody={default_message}
        instructions={default_description}
        appendToBody={`\n\n---\n\nReference: ${questionnaire.questionnaire_id}`}
        questionnaireId={questionnaire.questionnaire_id}
      />
      <ExportPortalModal
        isOpen={portalSupportIsOpen}
        onClose={portalSupportOnClose}
        initialBody={default_message}
        instructions={default_description}
        questionnaireId={questionnaire.questionnaire_id}
      />
    </>
  );
};

export function QuestionResponse({question, layerType}: {question: Question; layerType: LayerType}): React.ReactNode {
  const responseLayer = getResponseLayer(question.response_layers, layerType);
  let parts = [];
  if (question.parts.yes_no.enabled.content && responseLayer.response_yes_no != null) {
    parts.push(responseLayer.response_yes_no ? "Yes" : "No");
  }
  if (question.parts.select.enabled.content && responseLayer.response_select != null) {
    const selectParts: [number, string][] = [];
    for (const {value, other_text} of responseLayer.response_select) {
      const optIndex = question.parts.select.config.options.findIndex(opt => opt.value === value);
      const opt = optIndex === -1 ? null : question.parts.select.config.options[optIndex];
      const valueText = opt?.label ?? value;
      const hasOtherText = opt ? opt.allow_other_text && other_text : !!other_text;
      if (hasOtherText) {
        selectParts.push([optIndex, `${valueText}: ${other_text}`]);
      } else {
        selectParts.push([optIndex, valueText]);
      }
    }
    // Try to show the parts in order of their option indices
    selectParts.sort((a, b) => a[0] - b[0]);
    parts.push(...selectParts.map(x => x[1]));
  }
  if (question.parts.text.enabled.content && responseLayer.response_text != "") {
    parts.push(responseLayer.response_text);
  }

  parts = parts.filter(part => part !== undefined && part.trim().length >= 0);

  return parts.length === 1 ? (
    <Text whiteSpace="break-spaces">{parts[0]}</Text>
  ) : (
    <UnorderedList>
      {parts.map((part, i) => (
        <ListItem key={i} whiteSpace="break-spaces">
          {part}
        </ListItem>
      ))}
    </UnorderedList>
  );
}

const ExportPage = withSuspense(() => {
  const {questionnaire} = useOutletContext<TabOutletContext>();
  const [layerType] = useLayerType();
  const documentation = useQueryData({
    queryKey: ["vendorToolkit", "questionnaireDocumentation", questionnaire.questionnaire_id, layerType],
  });
  const questions = useQueriesData({
    queries: questionKeys(questionnaire),
  });

  const internalOnly = documentation.some(
    d => d.attachment.content.sharing_classification == SharingClassification.Internal,
  );
  const requiresNda = documentation.some(d => d.attachment.content.sharing_classification == SharingClassification.NDA);

  const onSetExternalLanguageCode = useCallback(
    async (languageCode: string) => {
      await api.vendorToolkit.questionnaires.updateExternalLanguageCode(questionnaire.questionnaire_id, languageCode);
    },
    [questionnaire.questionnaire_id],
  );

  const alert = internalOnly ? (
    <Alert status="error" fontSize="md" color="red.700" py={4} px={8}>
      <AlertIcon />
      The supporting documents that are attached to this questionnaire are marked{" "}
      <Text display="inline" fontWeight="bold" ml={1}>
        internal only
      </Text>
      .
    </Alert>
  ) : requiresNda ? (
    <Alert status="warning" fontSize="md" py={4} px={8}>
      <AlertIcon />
      The supporting documents that are attached to this questionnaire require an{" "}
      <Text display="inline" fontWeight="bold" mx={1}>
        NDA
      </Text>{" "}
      to be in place before sharing.
    </Alert>
  ) : null;

  return (
    <>
      <ActionBar>
        <ExportMenu questionnaire={questionnaire} />
        <LanguageSelector value={questionnaire.external_language_code} onChange={onSetExternalLanguageCode} />
        {questionnaire.external_language_code !== questionnaire.internal_language_code && (
          <Text align="right" fontStyle="italic" fontSize="sm">
            Questionnaire will be translated on export.
          </Text>
        )}
      </ActionBar>
      {alert}
      <Table style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg" layout="fixed">
        <Thead bg="white" borderBottom="10px solid" borderBottomColor="red.100">
          <Tr>
            <Th width="40%" color="gray.500">
              Question
            </Th>
            <Th width="60%" color="gray.500">
              Response
            </Th>
          </Tr>
        </Thead>
        <Tbody fontSize="md">
          {questions.map(question => (
            <Tr key={question.question_id}>
              <Td fontWeight="600">
                <QuestionNumber question={question} />
                {question.text}
              </Td>
              <Td>
                <QuestionResponse question={question} layerType={layerType} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
});

export default ExportPage;
