import {useQueryData} from "../../../../state/index.ts";
import {useState} from "react";
import {UpdateEsignatureProvider} from "../../../../Types.ts";
import {useValidatedPromiseState, useValidation} from "../../../../hooks/validationState.ts";
import api from "../../../../api/index.ts";
import {router} from "../../../../router/index.tsx";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
} from "@chakra-ui/react";
import {AnyEsignatureProvider} from "./AnyEsignatureProvider.tsx";
import DocumentTitle from "../../../../components/DocumentTitle.tsx";
import {DeleteEsignatureProviderModalButton} from "./DeleteEsignatureProviderModalButton.tsx";
import {useTypedParams} from "../../../../hooks/typedParams";
import {Type} from "../../../../typing";

export const EsignatureProviderParams = Type.Object({esignatureProviderId: Type.EsignatureProviderId()});

export const ConfigureEsignatureProvider = () => {
  const {esignatureProviderId} = useTypedParams(EsignatureProviderParams);
  const provider = useQueryData({queryKey: ["vendorToolkit", "esignatureProvider", esignatureProviderId!]});
  const [name, setName] = useState(provider.name);
  const [payload, setPayload] = useState<UpdateEsignatureProvider | null>({
    config: provider.config,
    external_authorization_id: provider.external_authorization?.external_authorization_id,
  });
  const [validationErrors, setValidationErrors] = useValidation(useState({}), {name});

  const [saving, save] = useValidatedPromiseState(
    async () => {
      if (!payload) return;
      await api.vendorToolkit.esignatureProviders.updateName(provider.esignature_provider_id, name);
      await api.vendorToolkit.esignatureProviders.update(provider.esignature_provider_id, payload);
      await router!.navigate("/vendor-toolkit/configuration/integrations/esignature-providers");
    },
    [provider.esignature_provider_id, payload, name],
    setValidationErrors,
  );

  return (
    <>
      <DocumentTitle title="Configure e-signature provider" />
      <Box as="form" onSubmit={save}>
        <Stack p={8} spacing={8} align="flex-start" w="600px">
          <HStack w="full" justifyContent="space-between">
            <Heading size="md" p="0" display="flex" alignItems="center" gap={2}>
              Configure e-signature provider
            </Heading>
            <DeleteEsignatureProviderModalButton esignatureProvider={provider} />
          </HStack>
          <Stack spacing={4} align="flex-start" w="500px">
            <FormControl isRequired isInvalid={validationErrors.name !== undefined}>
              <FormLabel>E-signature provider name</FormLabel>
              <Input
                onChange={e => setName(e.target.value)}
                value={name}
                isDisabled={false}
                placeholder={provider.connector.name}
              />
              {validationErrors.name && validationErrors.name[0] && (
                <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
              )}
            </FormControl>

            <AnyEsignatureProvider
              connectorType={provider.connector.connector_type}
              payload={payload}
              setPayload={setPayload}
            />

            {saving.lastError ? (
              <Alert status="error" mt="12">
                <AlertIcon boxSize="40px" />
                <Box>
                  <AlertTitle fontSize="md">Error updating e-signature provider</AlertTitle>
                  <AlertDescription fontSize="md">{`${saving.lastError}`}</AlertDescription>
                </Box>
              </Alert>
            ) : null}
          </Stack>
          <Button
            colorScheme="green"
            type="submit"
            isLoading={saving.inProgress}
            isDisabled={validationErrors.name !== undefined || !payload}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </>
  );
};
