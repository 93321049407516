import {
  ShowcaseMessageIds,
  ShowcaseUpdateMessage,
  ShowcaseUpdateMessageId,
  UpdateNotificationStatus,
} from "../../../../Types.ts";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  GridItem,
  HStack,
  Icon,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";

import {useQueryData} from "../../../../state";
import {FormQuestion} from "../Config/FormSection.tsx";
import CommitInput from "../../../../components/CommitInput.tsx";
import {useCallback} from "react";
import api from "../../../../api";
import {parseISO} from "../../../../utils/date.ts";
import * as datefns from "date-fns";
import CommitTextarea from "../../../../components/CommitTextarea.tsx";
import {ExclamationCircleIcon, PaperAirplaneIcon} from "@heroicons/react/24/outline";
import {PromiseState, usePromiseState} from "../../../../hooks/promiseState.ts";
import {NotificationDot} from "../../../../components/NotificationDot.tsx";
import {DeleteModalIconButton} from "../../../../components/DeleteModalButton.tsx";

const HOVER_COLOR = "gray.50";
const ACTIVE_BG_COLOR = "gray.100";
const ACTIVE_FT_COLOR = "blue.500";

const PLACEHOLDER_MESSAGE =
  "<You've made changes to your trust center recently. Please fill in recent trust center update content you would like to share with your customers>";

export const SendModal = ({
  isOpen,
  onClose,
  send,
  sending,
  message,
}: {
  isOpen: boolean;
  onClose: () => void;
  send: () => void;
  sending: PromiseState<void, []>;
  message: ShowcaseUpdateMessage;
}) => {
  const emptyTitle = message.title == null || message.title === "";
  const emptyContent = message.content == null || message.content === "";
  const placeholderContent = message.content === PLACEHOLDER_MESSAGE;
  const isError = emptyTitle || emptyContent || placeholderContent;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{isError ? "Email error" : "Send email"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize="md">
          <Stack>
            {isError ? (
              <Alert status="error" borderRadius="md">
                <AlertIcon boxSize="40px" />
                <Box>
                  {(emptyTitle || emptyContent) && <AlertTitle fontSize="md">Missing fields</AlertTitle>}
                  <AlertDescription fontSize="md">
                    <List fontSize={"large"} mt={1}>
                      {emptyTitle && (
                        <ListItem>
                          <HStack gap={0}>
                            <ListIcon as={ExclamationCircleIcon} color={"red"} />
                            <Text fontSize={"md"}>Title</Text>
                          </HStack>
                        </ListItem>
                      )}
                      {emptyContent && (
                        <ListItem>
                          <HStack gap={0}>
                            <ListIcon as={ExclamationCircleIcon} color={"red"} />
                            <Text fontSize={"md"}>Content</Text>
                          </HStack>
                        </ListItem>
                      )}
                    </List>
                  </AlertDescription>
                  {placeholderContent && <AlertTitle fontSize="md">Remove placeholder text</AlertTitle>}
                  <AlertDescription fontSize="md">
                    <List fontSize={"large"} mt={1}>
                      {placeholderContent && (
                        <ListItem>
                          <HStack gap={0}>
                            <ListIcon as={ExclamationCircleIcon} color={"red"} />
                            <Text fontSize={"md"}>Remove placeholder content</Text>
                          </HStack>
                        </ListItem>
                      )}
                    </List>
                  </AlertDescription>
                </Box>
              </Alert>
            ) : (
              <Stack>
                <Text>Please confirm you want to send this email to your mailing list.</Text>
                {sending.lastError ? (
                  <Alert status="error" borderRadius="md">
                    <AlertIcon boxSize="40px" />
                    <Box>
                      <AlertTitle fontSize="md">Error sending email</AlertTitle>
                      <AlertDescription fontSize="md">{sending.lastError.toString()}</AlertDescription>
                    </Box>
                  </Alert>
                ) : null}
              </Stack>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing="3">
            <Button
              variant={isError ? undefined : "ghost"}
              onClick={onClose}
              colorScheme={isError ? "blue" : undefined}
            >
              {isError ? "Ok" : "Cancel"}
            </Button>
            {!isError && (
              <Button
                colorScheme="red"
                isDisabled={sending.inProgress || emptyContent || emptyTitle || placeholderContent}
                onClick={send}
                isLoading={sending.inProgress}
              >
                Send
              </Button>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const DraftMessage = ({
  messageId,
  setSelectedMessage,
}: {
  messageId: ShowcaseUpdateMessageId;
  setSelectedMessage: (message: ShowcaseUpdateMessageId | undefined) => void;
}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const message = useQueryData({queryKey: ["vendorToolkit", "trustCenterShowcaseMessage", messageId]});

  const setContent = useCallback(
    async (newContent: string | undefined) => {
      await api.vendorToolkit.trustCenter.updateShowcaseMessage(messageId, {...message, content: newContent});
    },
    [message, messageId],
  );

  const setTitle = useCallback(
    async (newTitle: string | undefined) => {
      await api.vendorToolkit.trustCenter.updateShowcaseMessage(messageId, {...message, title: newTitle});
    },
    [message, messageId],
  );

  const [sending, send, clearError] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.sendShowcaseMessage(messageId);
    setSelectedMessage(undefined);
  }, [messageId, setSelectedMessage]);

  return (
    <Stack gap={6}>
      <HStack justifyContent={"space-between"}>
        <HStack>
          <Text fontSize={"md"}>Recipient: </Text>
          <Tag>Trust center mailing list</Tag>
        </HStack>
        <DeleteMessageButton messageId={message.update_message_id} setSelectedMessage={setSelectedMessage} />
      </HStack>
      <FormQuestion title="Title">
        <CommitInput value={message.title ?? ""} onCommit={setTitle} />
      </FormQuestion>
      <FormQuestion title="Content">
        <CommitTextarea value={message.content ?? ""} onCommit={setContent} height={"280px"} />
      </FormQuestion>
      <Button
        leftIcon={<Icon as={PaperAirplaneIcon} />}
        colorScheme="green"
        onClick={onOpen}
        isDisabled={sending.inProgress}
      >
        Send
      </Button>
      <SendModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          clearError();
        }}
        send={send}
        sending={sending}
        message={message}
      />
    </Stack>
  );
};

const SentMessage = ({messageId}: {messageId: ShowcaseUpdateMessageId}) => {
  const message = useQueryData({queryKey: ["vendorToolkit", "trustCenterShowcaseMessage", messageId]});

  return (
    <Stack gap={6}>
      <HStack>
        <Text fontSize={"md"}>Recipient: </Text>
        <Tag>Trust center mailing list</Tag>
      </HStack>
      <HStack>
        <Text fontWeight={"semibold"}>Title:</Text>
        <Text>{message.title ?? ""}</Text>
      </HStack>
      <Stack>
        <Text fontWeight={"semibold"}>Content:</Text>
        <Text>{message.content ?? ""}</Text>
      </Stack>
    </Stack>
  );
};

const MessageRow = ({
  messageId,
  isSelected,
  setSelected,
  type_,
  notification,
}: {
  messageId: ShowcaseUpdateMessageId;
  isSelected: boolean;
  setSelected: (message: ShowcaseUpdateMessageId | undefined) => void;
  type_: UpdateNotificationStatus;
  notification?: boolean;
}) => {
  const message = useQueryData({queryKey: ["vendorToolkit", "trustCenterShowcaseMessage", messageId]});

  const [_reading, read] = usePromiseState(
    async (messageId: ShowcaseUpdateMessageId | undefined) => {
      setSelected(messageId);
      if (messageId && notification) {
        await api.vendorToolkit.trustCenter.readShowcaseMessage(messageId);
      }
    },
    [notification, setSelected],
  );

  return (
    <Tr
      key={messageId}
      onClick={() => read(messageId)}
      padding={2}
      fontSize="md"
      _hover={{
        background: HOVER_COLOR,
        cursor: "pointer",
      }}
      bgColor={isSelected ? ACTIVE_BG_COLOR : "white"}
    >
      <Td
        color={message.title ? "black" : "gray.500"}
        fontWeight={"semibold"}
        borderLeft={isSelected ? "4px" : ""}
        borderLeftColor={ACTIVE_FT_COLOR}
        whiteSpace={"wrap"}
      >
        <Text noOfLines={1}>{message.title ? message.title : "No title"}</Text>
      </Td>
      <Td color={message.content ? "gray.600" : "gray.500"} whiteSpace={"wrap"}>
        <Text noOfLines={1}>{message.content ? message.content : "No content"}</Text>
      </Td>
      <Td color="gray.400">
        <Flex gap={2}>
          {datefns.format(
            parseISO(type_ === UpdateNotificationStatus.Draft ? message.updated_at : message.sent_at!)!,
            "HH:mm dd/MM/yyyy",
          )}
          {notification && <NotificationDot />}
        </Flex>
      </Td>
    </Tr>
  );
};

const ShowcaseMessages = ({
  messages,
  newMessages,
  selectedMessage,
  setSelectedMessage,
  type_,
}: {
  messages: ShowcaseMessageIds;
  newMessages?: ShowcaseMessageIds;
  selectedMessage: ShowcaseUpdateMessageId | undefined;
  setSelectedMessage: (message: ShowcaseUpdateMessageId | undefined) => void;
  type_: UpdateNotificationStatus;
}) => {
  //TODO: paginate/scroll
  return (
    <SimpleGrid columns={2} flex={1}>
      <GridItem>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th w={"33%"}>Title</Th>
                <Th w={"30%"}>Content</Th>
                <Th w={"37%"}>Updated</Th>
              </Tr>
            </Thead>
            <Tbody>
              {newMessages &&
                newMessages.message_ids.map(id => (
                  <MessageRow
                    key={id}
                    messageId={id}
                    isSelected={id === selectedMessage}
                    setSelected={setSelectedMessage}
                    type_={type_}
                    notification={true}
                  />
                ))}
              {messages.message_ids.map(id => (
                <MessageRow
                  key={id}
                  messageId={id}
                  isSelected={id === selectedMessage}
                  setSelected={setSelectedMessage}
                  type_={type_}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </GridItem>
      <GridItem borderLeft="1px solid" borderColor="gray.100" padding={6}>
        {type_ === UpdateNotificationStatus.Draft
          ? selectedMessage && <DraftMessage messageId={selectedMessage} setSelectedMessage={setSelectedMessage} />
          : selectedMessage && <SentMessage messageId={selectedMessage} />}
      </GridItem>
    </SimpleGrid>
  );
};

export const DeleteMessageButton = ({
  messageId,
  setSelectedMessage,
}: {
  messageId: ShowcaseUpdateMessageId;
  setSelectedMessage: (message: ShowcaseUpdateMessageId | undefined) => void;
}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [deletingMessage, deleteMessage] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.deleteShowcaseMessage(messageId);
    setSelectedMessage(undefined);
  }, [messageId, setSelectedMessage]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteMessage}
      deleting={deletingMessage.inProgress}
      modalHeader="Delete message"
      modalBody={`Are you sure you want to delete this update message?`}
    />
  );
};

export default ShowcaseMessages;
