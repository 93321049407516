import {SetStateAction, useState} from "react";
import api from "../../../../../../../api/index.ts";
import type * as XLSX from "xlsx";
import AnnotationManager from "../Preview/AnnotationManager.tsx";
import {ImportState} from "../importState";
import {SizedStack} from "../../../../../../../hooks/stack";
import {CommonBody, CommonFooter} from "../Common";
import {usePromiseState} from "../../../../../../../hooks/promiseState";
import {CreateSection, IdentifiedSheetSection} from "../../../../../../../Types";
import {convertRange} from "../utils";
import {Alert, AlertDescription, AlertIcon, AlertTitle, Button, Flex} from "@chakra-ui/react";

async function extractSectionQuestions(
  xlsx: typeof XLSX,
  section: IdentifiedSheetSection,
  sheet: XLSX.Sheet,
  totalSections: number,
  setProgress: React.Dispatch<SetStateAction<number | null>>,
): Promise<CreateSection> {
  const questionsRange = convertRange(section.questions);
  const columnHeadersRange = section.column_headers && convertRange(section.column_headers);
  const questionsAoa: (string | null)[][] = xlsx.utils.sheet_to_json(sheet, {
    header: 1,
    range: questionsRange,
    defval: null,
  });
  const columnHeadersAoa: (string | null)[][] | undefined =
    columnHeadersRange && xlsx.utils.sheet_to_json(sheet, {header: 1, range: columnHeadersRange});
  const combinedAoa = columnHeadersAoa ? [...columnHeadersAoa, ...questionsAoa] : questionsAoa;
  const questions = await api.vendorToolkit.ai.extractSectionQuestions({
    section_title: section.section_title,
    cells: combinedAoa,
  });
  setProgress(oldProgress => (oldProgress ?? 0) + 1 / totalSections);
  return {title: section.section_title ?? "Untitled", questions, description: ""};
}

const AnnotateStep = ({
  stack: {value, replace, push, pop},
  onClose,
}: {
  stack: SizedStack<ImportState, 3>;
  onClose: () => void;
}) => {
  const [progress, setProgress] = useState<number | null>(null);
  const [advancing, advance] = usePromiseState(async () => {
    const promises: Promise<CreateSection>[] = [];
    setProgress(0);
    const numSections = Object.values(value[2]).flatMap(sheetState => sheetState.sections).length;
    for (const sheetName of value[1].selectedSheetNames) {
      for (const section of value[2][sheetName].sections) {
        promises.push(
          extractSectionQuestions(
            value[0].xlsx,
            section,
            value[1].workbook.Sheets[sheetName],
            numSections,
            setProgress,
          ),
        );
      }
    }
    const results = await Promise.all(promises);
    setProgress(null);
    push(results);
  }, [value, push]);

  return (
    <>
      <CommonBody step={2} progress={progress}>
        <AnnotationManager value={value} replace={replace} />
        {advancing.lastError ? (
          <Alert status="error" variant="subtle" flexDirection="column" gap={2}>
            <Flex alignItems="center">
              <AlertIcon />
              <AlertTitle fontSize="md" color="red.700">
                Failed to extract questions
              </AlertTitle>
            </Flex>
            <AlertDescription fontWeight="thin" fontSize="md" color="red.700" whiteSpace="pre-line" w="full">
              {advancing.lastError.toString()}
            </AlertDescription>
          </Alert>
        ) : null}
      </CommonBody>
      <CommonFooter onClose={onClose}>
        <Button onClick={pop} isDisabled={advancing.inProgress}>
          Back
        </Button>
        <Button
          onClick={advance}
          isDisabled={
            !Object.values(value[2]).some(sheetState => sheetState.sections.length > 0) || advancing.inProgress
          }
          isLoading={advancing.inProgress}
        >
          Next
        </Button>
      </CommonFooter>
    </>
  );
};

export default AnnotateStep;
