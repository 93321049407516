import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  Text,
  HStack,
} from "@chakra-ui/react";
import {memo, useState} from "react";
import {usePromiseState} from "../hooks/promiseState";
import api from "../api";
import {MultiFileUpload} from "./fileUploads/MultiFileUpload";
import {File} from "../Types";
import {FileUpload, useFileUploads} from "../hooks/fileUpload";
import {useAbortable} from "../hooks/abortable";

export type ContactSupportModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  action: string;
  instructions: string;
  subject: string;
  initialBody: string;
  appendToBody?: string;
  onBeforeSend?: (body: string, attachments: File[]) => Promise<void>;
  onSubmit?: () => void;
};

const ContactSupportModalContent = ({
  onClose,
  title,
  action,
  instructions,
  initialBody,
  subject,
  appendToBody,
  onBeforeSend,
  onSubmit,
}: ContactSupportModalProps) => {
  const [body, setBody] = useState(initialBody);
  const [attachmentUploads, setAttachmentUploads] = useAbortable(useState<FileUpload[]>([]));
  const [attachments, isUploading] = useFileUploads(attachmentUploads);

  const [sending, send] = usePromiseState(async () => {
    const fullBody = `${body}${appendToBody ?? ""}`;
    if (onBeforeSend) {
      await onBeforeSend(fullBody, attachments);
    }
    await api.supportEmails.send({
      action,
      subject,
      body: fullBody,
      attachments: attachments.map(a => a.file_id),
      cc_user: true,
    });
    if (onSubmit) {
      onSubmit();
    }
    onClose();
  }, [body, appendToBody, onBeforeSend, action, subject, attachments, onSubmit, onClose]);

  return (
    <ModalContent>
      <ModalHeader>{title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={4}>
          <Text fontSize="md" fontStyle="italic">
            {instructions}
          </Text>
          <FormControl>
            <FormLabel>Message</FormLabel>
            <Textarea value={body} onChange={e => setBody(e.target.value)} rows={6} />
          </FormControl>
          <FormControl>
            <FormLabel>Attachments (optional)</FormLabel>
            <MultiFileUpload Cls={FileUpload} value={attachmentUploads} onChange={setAttachmentUploads} />
          </FormControl>
          {sending.lastError ? (
            <Alert status="error">
              <AlertIcon boxSize="40px" />
              <Box>
                <AlertTitle fontSize="md">Error sending message</AlertTitle>
                <AlertDescription fontSize="md">{`${sending.lastError}`}</AlertDescription>
              </Box>
            </Alert>
          ) : null}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <HStack spacing="3">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" isLoading={sending.inProgress} isDisabled={isUploading} onClick={send}>
            Send
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  );
};

export const ContactSupportModal = memo((props: ContactSupportModalProps) => {
  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose} size="xl">
        <ModalOverlay />
        {props.isOpen && <ContactSupportModalContent {...props} />}
      </Modal>
    </>
  );
});
