import {Text, Box, Select, Stack, CloseButton, Button, Icon} from "@chakra-ui/react";
import {ScopeId, ScopeMatrix, nominate} from "../../../Types";
import {useQueriesData} from "../../../state";
import {withSuspense} from "../../../state/withSuspense";
import * as _ from "lodash-es";
import {Fragment, useCallback, useMemo} from "react";
import {usePromiseState} from "../../../hooks/promiseState";
import {PlusIcon} from "@heroicons/react/20/solid";

type ScopeMatrixEditorProps = {
  value: ScopeMatrix;
  onChange?: (value: ScopeMatrix) => Promise<void>;
};

const ScopeMatrixEditor = withSuspense(({value, onChange}: ScopeMatrixEditorProps) => {
  const [scopes, scopeAxes] = useQueriesData({
    queries: [{queryKey: ["vendorToolkit", "scopes"]}, {queryKey: ["vendorToolkit", "scopeAxes"]}],
  });
  const [scopesById, scopeAxesById] = useMemo(
    () => [
      _.fromPairs(scopes.map(scope => [scope.scope_id, scope])),
      _.fromPairs(scopeAxes.map(axis => [axis.axis_id, axis])),
    ],
    [scopes, scopeAxes],
  );
  // For now, just pin all the axes. In a future where customers have more than 2-3 axes, make this
  // configurable by axis.
  const pinnedAxisIds = scopeAxes.map(axis => axis.axis_id);
  const usedAxisIds = _.uniq(
    pinnedAxisIds.concat(value.rows.flatMap(row => row.scope_ids.map(scopeId => scopesById[scopeId].axis.axis_id))),
  );
  const [changing, change] = usePromiseState(
    async (newValue: ScopeMatrix) => {
      onChange && (await onChange(newValue));
    },
    [onChange],
  );
  const canAddNewAxis = usedAxisIds.length < scopeAxes.length;
  const canAddNewRow = value.rows.every(row => row.scope_ids.length > 0);
  const changeCell = useCallback(
    async (rowIdx: number, scopeIdx: number, scopeId: ScopeId | null) => {
      const newValue = _.cloneDeep(value);
      if (scopeIdx === -1) {
        if (scopeId != null) {
          newValue.rows[rowIdx].scope_ids.push(scopeId);
        }
      } else if (scopeId != null) {
        newValue.rows[rowIdx].scope_ids[scopeIdx] = scopeId;
      } else {
        newValue.rows[rowIdx].scope_ids.splice(scopeIdx, 1);
      }
      await change(newValue);
    },
    [value, change],
  );
  const deleteRow = useCallback(
    async (rowIdx: number) => {
      const newValue = _.cloneDeep(value);
      newValue.rows.splice(rowIdx, 1);
      if (newValue.rows.length === 0) {
        newValue.rows.push({scope_ids: []});
      }
      await change(newValue);
    },
    [value, change],
  );
  const addRow = useCallback(async () => {
    const newValue = _.cloneDeep(value);
    newValue.rows.push({scope_ids: []});
    await change(newValue);
  }, [value, change]);
  const allDisabled = !onChange || changing.inProgress;
  if (scopes.length === 0) {
    return <Text fontStyle="italic">No scopes have been created.</Text>;
  }
  return (
    <Stack gap={2} align="flex-start">
      <Box
        display="grid"
        gridTemplateColumns={`repeat(${usedAxisIds.length + (canAddNewAxis ? 1 : 0)}, 180px) 50px`}
        alignItems="center"
        gap={2}
      >
        {usedAxisIds.map(axisId => (
          <Text key={axisId} fontWeight="600" fontSize="sm" color="gray.600">
            {scopeAxesById[axisId].name}
          </Text>
        ))}
        {canAddNewAxis && <Box />}
        <Box />
        {value.rows.map((row, rowIdx) => (
          <Fragment key={rowIdx}>
            {usedAxisIds.map(axisId => {
              const scopeIdx = row.scope_ids.findIndex(scopeId => scopesById[scopeId].axis.axis_id === axisId);
              return (
                <Select
                  key={axisId}
                  value={scopeIdx === -1 ? "<none>" : row.scope_ids[scopeIdx]}
                  onChange={e =>
                    changeCell(
                      rowIdx,
                      scopeIdx,
                      e.target.value === "<none>" ? null : nominate("scopeId", e.target.value),
                    )
                  }
                  isDisabled={allDisabled}
                  fontStyle={scopeIdx === -1 ? "italic" : "normal"}
                  size="md"
                >
                  <option value="<none>" style={{fontStyle: "italic"}}>
                    Unspecified
                  </option>
                  {scopes
                    .filter(scope => scope.axis.axis_id === axisId)
                    .map(scope => (
                      <option key={scope.scope_id} value={scope.scope_id} style={{fontStyle: "normal"}}>
                        {scope.name}
                      </option>
                    ))}
                </Select>
              );
            })}
            {canAddNewAxis && (
              <Select
                value={"<none>"}
                onChange={e =>
                  changeCell(rowIdx, -1, e.target.value === "<none>" ? null : nominate("scopeId", e.target.value))
                }
                isDisabled={allDisabled}
                fontStyle={"italic"}
                size="md"
              >
                <option value="<none>" style={{fontStyle: "italic"}}>
                  Add scope...
                </option>
                {scopes
                  .filter(scope => !usedAxisIds.includes(scope.axis.axis_id))
                  .map(scope => (
                    <option key={scope.scope_id} value={scope.scope_id} style={{fontStyle: "normal"}}>
                      {scope.axis.name}: {scope.name}
                    </option>
                  ))}
              </Select>
            )}
            <CloseButton
              size="sm"
              variant="ghost"
              color="red.500"
              onClick={() => {
                deleteRow(rowIdx);
              }}
              isDisabled={allDisabled || (value.rows.length === 1 && value.rows[0].scope_ids.length === 0)}
            />
          </Fragment>
        ))}
      </Box>
      {canAddNewRow && (
        <Button
          leftIcon={<Icon as={PlusIcon} />}
          onClick={addRow}
          isDisabled={allDisabled}
          size="sm"
          colorScheme="green"
        >
          Add another context
        </Button>
      )}
    </Stack>
  );
});

export default ScopeMatrixEditor;
