import {useQueryData} from "../../../../../state/index.ts";
import {useState} from "react";
import {UpdateCrmConnection} from "../../../../../Types.ts";
import api from "../../../../../api/index.ts";
import {router} from "../../../../../router/index.tsx";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
} from "@chakra-ui/react";
import {AnyCrmConnection} from "./AnyCrmConnection.tsx";
import {useValidatedPromiseState, useValidation} from "../../../../../hooks/validationState.ts";
import DocumentTitle from "../../../../../components/DocumentTitle.tsx";
import {useTypedParams} from "../../../../../hooks/typedParams";
import {CrmConnectionParams} from "./Crumb";

export const ConfigureCrmConnection = () => {
  const {crmConnectionId} = useTypedParams(CrmConnectionParams);
  const connection = useQueryData({queryKey: ["vendorToolkit", "crmConnection", crmConnectionId!]});
  const [name, setName] = useState(connection.name);
  const [payload, setPayload] = useState<UpdateCrmConnection | null>({
    external_authorization_id: connection.external_authorization?.external_authorization_id,
    config: connection.config,
  });
  const [validationErrors, setValidationErrors] = useValidation(useState({}), {name});

  const [saving, save] = useValidatedPromiseState(
    async () => {
      if (!payload) return;
      await api.vendorToolkit.crmConnections.updateName(connection.crm_connection_id, name);
      await api.vendorToolkit.crmConnections.update(connection.crm_connection_id, payload);

      router!.navigate(`/vendor-toolkit/configuration/integrations/crm-connections`);
    },
    [connection.crm_connection_id, payload, name],
    setValidationErrors,
  );

  return (
    <>
      <DocumentTitle title="Configure CRM connection" />
      <Box as="form" onSubmit={save}>
        <Stack p={8} spacing={8} align="flex-start">
          <Heading size="md" p="0" display="flex" alignItems="center" gap={2}>
            Configure CRM connection - {connection.name}
          </Heading>
          <Stack spacing={4} align="flex-start" w="500px">
            <FormControl isRequired isInvalid={validationErrors.name !== undefined}>
              <FormLabel>Name</FormLabel>
              <Input
                onChange={e => setName(e.target.value)}
                value={name}
                isDisabled={saving.inProgress}
                placeholder={connection.connector.name}
              />
              {validationErrors.name && validationErrors.name[0] && (
                <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
              )}
            </FormControl>
            <AnyCrmConnection
              connectorId={connection.connector.connector_id}
              payload={payload}
              setPayload={setPayload}
            />

            {saving.lastError ? (
              <Alert status="error" mt="12">
                <AlertIcon boxSize="40px" />
                <Box>
                  <AlertTitle fontSize="md">Error updating CRM connection</AlertTitle>
                  <AlertDescription fontSize="md">{`${saving.lastError}`}</AlertDescription>
                </Box>
              </Alert>
            ) : null}
          </Stack>
          <Button
            colorScheme="green"
            type="submit"
            isLoading={saving.inProgress}
            isDisabled={validationErrors.name !== undefined || !payload}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </>
  );
};
