import {Heading, Stack, Text} from "@chakra-ui/react";
import {useQueryData} from "../../../../../state";
import * as _ from "lodash-es";
import ReviewItemRow from "../components/Review/ReviewItemRow";
import NoReviewItemsSnippet from "../components/Review/NoReviewItemsSnippet";
import {PAGINATION_NO_LIMIT} from "../../../../../utils/pagination";
import {ActionBar, ActionBarSearch} from "../../../../../components/ActionBar";
import FilterOwners from "../../../../components/Filters/FilterOwners";
import {useFacetedSearch} from "../../../../../hooks/search";
import {facetedSearchResolution, getResolutionsRelevantOwners, ResolutionFilters} from "../../../../components/Filters";
import {useFilterState} from "../../../../../hooks/filterState";
import FilterBanner from "../../../../components/Filters/FilterBanner";

const DEFAULT_FILTERS = {
  owners: ["ASSIGNED_TO_ME", "ASSIGNED_TO_OTHERS", "UNASSIGNED"],
};

function ReviewItemsPage() {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const resolutions = useQueryData({queryKey: ["vendorToolkit", "resolutions", {...PAGINATION_NO_LIMIT}]}).items;

  const relevantOwners = getResolutionsRelevantOwners(resolutions, whoami.user_owner!);

  const {filters, setFilter, clearFilters, filterCount} = useFilterState<ResolutionFilters>(
    "resolutionFilters",
    DEFAULT_FILTERS,
  );

  const {query, queries, setQuery, filter} = useFacetedSearch(
    r => r.reason,
    facetedSearchResolution(filters, whoami.associated_owners),
    [filters, whoami.associated_owners],
  );

  const {result, counts} = filter(resolutions);

  return (
    <Stack flexGrow={1} gap={0}>
      <Stack spacing={4} p={8} borderBottom="1px solid" borderColor="gray.200">
        <Heading size="md">Review items ({resolutions.length})</Heading>
        <Text fontSize="md" color="gray.500">
          Platformed continuously monitors your fact library for potential conflicts. On this page, you can review and
          resolve these issues by viewing flagged items and disabling any conflicting facts.
        </Text>
      </Stack>
      <ActionBar
        actionButtons={
          <>
            <FilterOwners
              owners={filters.owners}
              setOwners={newOwners => setFilter("owners", newOwners)}
              relevantOwners={relevantOwners}
              counts={counts.owner}
            />
          </>
        }
      >
        <ActionBarSearch value={query} onChange={e => setQuery(e.target.value)} />
      </ActionBar>
      <FilterBanner filterCount={filterCount} clearFilters={clearFilters} />
      {result.length > 0 ? (
        result.map(r => <ReviewItemRow key={r.resolution_id} resolution={r} queries={queries} />)
      ) : (
        <NoReviewItemsSnippet searched={query.trim().length > 0} filtered={filterCount > 0} />
      )}
    </Stack>
  );
}

export default ReviewItemsPage;
