import {useReadLocalStorage} from "usehooks-ts";
import {AccountId, nominate} from "../../Types";

const url = new URL(window.location.href);
if (url.searchParams.has("password")) {
  setTrustCenterPassword(
    nominate("accountId", url.searchParams.get("client_account_id")!),
    url.searchParams.get("password"),
  );
  url.searchParams.delete("password");
  url.searchParams.delete("client_account_id");
  window.history.replaceState(null, "", url.toString());
}

function storageKey(accountId: AccountId): string {
  return `p4daccount-${accountId}-trust-center-password`;
}

export function setTrustCenterPassword(accountId: AccountId, password: string | null) {
  const key = storageKey(accountId);
  if (password) {
    localStorage.setItem(key, JSON.stringify(password));
  } else {
    localStorage.removeItem(key);
  }
  window.dispatchEvent(new StorageEvent("local-storage", {key}));
}

export function useTrustCenterPassword(clientAccountId: AccountId): string | null {
  const key = storageKey(clientAccountId);
  return useReadLocalStorage(key);
}

export function clearTrustCenterPassword(clientAccountId: AccountId) {
  setTrustCenterPassword(clientAccountId, null);
}
