import type * as XLSX from "xlsx";

function unmergeCellsInSheet(sheet: XLSX.Sheet, xlsx: typeof XLSX) {
  for (const merge of sheet["!merges"] ?? []) {
    for (let col = merge.s.c; col <= merge.e.c; ++col) {
      const firstAddr = xlsx.utils.encode_cell({c: col, r: merge.s.r});
      const firstCell = sheet[firstAddr];
      if (firstCell) {
        for (let row = merge.s.r + 1; row <= merge.e.r; ++row) {
          const addr = xlsx.utils.encode_cell({c: col, r: row});
          sheet[addr] = firstCell;
        }
      }
    }
  }
  sheet["!merges"] = [];
}

export function unmergeCellsInWorkbook(workbook: XLSX.WorkBook, xlsx: typeof XLSX) {
  for (const sheetName of workbook.SheetNames) {
    unmergeCellsInSheet(workbook.Sheets[sheetName], xlsx);
  }
}
