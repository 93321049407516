import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import {DocumentPlusIcon} from "@heroicons/react/20/solid";
import {memo, useState} from "react";

import api from "../../../../api";
import {router} from "../../../../router";
import {useValidation, useValidatedPromiseState} from "../../../../hooks/validationState";
import {useResetting} from "../../../../hooks/resetting";

const NewContent = memo(() => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [title, setTitle] = useResetting(useState(""), isOpen);
  const [content, setContent] = useResetting(useState(""), isOpen);
  const [validationErrors, setValidationErrors] = useValidation(useResetting(useState({}), isOpen), {title, content});

  const [saving, save] = useValidatedPromiseState(
    async () => {
      const {content_id} = await api.vendorToolkit.trustCenter.createContent({
        title,
        content,
      });

      router!.navigate(`/vendor-toolkit/trust-center/contents/${content_id}`);
      onClose();
    },
    [title, content, onClose],
    setValidationErrors,
  );

  return (
    <>
      <Button leftIcon={<Icon as={DocumentPlusIcon} />} onClick={onOpen} colorScheme="green">
        New content
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={save}>
          <ModalHeader>Create new trust center content</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="8" mb="4">
              <FormControl isRequired isInvalid={validationErrors.title !== undefined}>
                <FormLabel>Title</FormLabel>
                <Input
                  onChange={e => setTitle(e.target.value)}
                  value={title}
                  isDisabled={saving.inProgress}
                  autoFocus
                />
                {validationErrors.title && validationErrors.title[0] && (
                  <FormErrorMessage>{validationErrors.title[0].message}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isRequired isInvalid={validationErrors.title !== undefined}>
                <FormLabel>Content</FormLabel>
                <Textarea
                  onChange={e => setContent(e.target.value)}
                  value={content}
                  isDisabled={saving.inProgress}
                  rows={10}
                />
                {validationErrors.content && validationErrors.content[0] && (
                  <FormErrorMessage>{validationErrors.content[0].message}</FormErrorMessage>
                )}
              </FormControl>

              {saving.lastError ? (
                <Alert status="error" mt="12">
                  <AlertIcon boxSize="40px" />
                  <Box>
                    <AlertTitle fontSize="md">Error creating content</AlertTitle>
                    <AlertDescription fontSize="md">{`${saving.lastError}`}</AlertDescription>
                  </Box>
                </Alert>
              ) : null}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing="3">
              <Button variant="ghost" onClick={onClose} isDisabled={saving.inProgress}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={saving.inProgress}
                isDisabled={validationErrors.title !== undefined || validationErrors.content !== undefined}
              >
                Create
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

export default NewContent;
