import {Route} from "react-router-dom";
import QuestionViewer from "./QuestionViewer";
import QuestionList from "./QuestionList";
import Crumb from "./Crumb";
import {QuestionStatus} from "../../../Types";

export const DEFAULT_FILTERS = {
  owners: ["ASSIGNED_TO_ME", "ASSIGNED_TO_OTHERS", "UNASSIGNED"],
  statuses: Object.keys(QuestionStatus),
  askers: ["ALL"],
};

export default (
  <Route path="questions" handle={{crumb: "Questions"}}>
    <Route id="Questions" index element={<QuestionList />} />
    <Route id="Question" path=":questionId" handle={{Crumb}} element={<QuestionViewer />} />
  </Route>
);
