import {useEffect, useMemo, useState} from "react";
import {TIMER_WHEEL, TimeView, ceilTime} from "../utils/time";

export function useTime<T>(view: TimeView<T>, inputs?: React.DependencyList): T {
  const minInterval = view.minInterval ?? 1000;
  const [timestamp, setTimestamp] = useState(() => ceilTime(minInterval));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const {current, expires} = useMemo(() => view(timestamp), [timestamp, ...(inputs ?? [])]);

  useEffect(
    () =>
      TIMER_WHEEL.add(minInterval, expires, ts => {
        setTimestamp(ts);
      }),
    [minInterval, expires],
  );

  return current;
}
