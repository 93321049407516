import {useQueryData} from "../../../../state/index.ts";
import {useState} from "react";
import {UpdateNotificationChannel} from "../../../../Types.ts";
import {useValidatedPromiseState, useValidation} from "../../../../hooks/validationState.ts";
import api from "../../../../api/index.ts";
import {router} from "../../../../router/index.tsx";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
} from "@chakra-ui/react";
import {AnyNotificationChannel} from "./AnyNotificationChannel.tsx";
import DocumentTitle from "../../../../components/DocumentTitle.tsx";
import {DeleteNotificationChannelModalButton} from "./DeleteNotificationChannelModalButton.tsx";
import {Type} from "../../../../typing";
import {useTypedParams} from "../../../../hooks/typedParams";

export const NotificationChannelParams = Type.Object({notificationChannelId: Type.NotificationChannelId()});

export const ConfigureNotificationChannel = () => {
  const {notificationChannelId} = useTypedParams(NotificationChannelParams);
  const channel = useQueryData({queryKey: ["notificationChannel", notificationChannelId]});
  const [name, setName] = useState(channel.name);
  const [payload, setPayload] = useState<UpdateNotificationChannel | null>({
    config: channel.config,
    external_authorization_id: channel.external_authorization?.external_authorization_id,
  });
  const [validationErrors, setValidationErrors] = useValidation(useState({}), {name});

  const [saving, save] = useValidatedPromiseState(
    async () => {
      if (!payload) return;
      await api.notificationChannels.updateName(channel.notification_channel_id, name);
      await api.notificationChannels.update(channel.notification_channel_id, payload);

      await router!.navigate("/vendor-toolkit/configuration/integrations/notification-channels");
    },
    [channel.notification_channel_id, payload, name],
    setValidationErrors,
  );

  return (
    <>
      <DocumentTitle title="Configure notification channel" />
      <Box as="form" onSubmit={save}>
        <Stack p={8} spacing={8} align="flex-start" w="600px">
          <HStack w="full" justifyContent="space-between">
            <Heading size="md" p="0" display="flex" alignItems="center" gap={2}>
              Configure notification channel
            </Heading>
            <DeleteNotificationChannelModalButton notificationChannel={channel} />
          </HStack>
          <Stack spacing={4} align="flex-start" w="500px">
            <FormControl isRequired isInvalid={validationErrors.name !== undefined}>
              <FormLabel>Notification channel name</FormLabel>
              <Input
                onChange={e => setName(e.target.value)}
                value={name}
                isDisabled={false}
                placeholder={channel.connector.name}
              />
              {validationErrors.name && validationErrors.name[0] && (
                <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
              )}
            </FormControl>

            <AnyNotificationChannel
              connectorType={channel.connector.connector_type}
              payload={payload}
              setPayload={setPayload}
            />

            {saving.lastError ? (
              <Alert status="error" mt="12">
                <AlertIcon boxSize="40px" />
                <Box>
                  <AlertTitle fontSize="md">Error updating notification channel</AlertTitle>
                  <AlertDescription fontSize="md">{`${saving.lastError}`}</AlertDescription>
                </Box>
              </Alert>
            ) : null}
          </Stack>
          <Button
            colorScheme="green"
            type="submit"
            isLoading={saving.inProgress}
            isDisabled={validationErrors.name !== undefined || !payload}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </>
  );
};
