import {
  Box,
  Button,
  Checkbox,
  Divider,
  HStack,
  Icon,
  IconButton,
  Link,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  Stack,
  Table,
  Tag,
  TagLeftIcon,
  TagRightIcon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {useOutletContext, Link as RouterLink} from "react-router-dom";
import {SourceConfidence, SourceMin, SourceProvider, ThirdParty} from "../../../../Types";
import {ActionBar, ActionBarSearch} from "../../../../components/ActionBar";
import {ArrowPathIcon, ChevronRightIcon} from "@heroicons/react/20/solid";
import {useQueryData} from "../../../../state";
import {unreachableCase} from "../../../../utils/typescript";
import CompanyLogo from "../../components/CompanyLogo";
import UpdatedAt from "../../components/UpdatedAt";
import {CheckCircleIcon, ChevronDownIcon, DocumentIcon, TableCellsIcon} from "@heroicons/react/24/solid";
import TagMenuButton from "../../../../components/TagMenuButton";
import PortalMenuList from "../../../../components/PortalMenuList";
import {PlusIcon} from "@heroicons/react/24/outline";

const MonitoredIcon = () => (
  <HStack gap="2px" bg="green.50" color="green.500" px={1}>
    <Icon as={ArrowPathIcon} fontSize="lg" p="2px" rounded="full" />
    <Text fontSize="xs" fontWeight="semibold">
      MONITORED
    </Text>
  </HStack>
);

const Provider = ({provider}: {provider: SourceProvider}) => {
  switch (provider.type) {
    case "File":
      return (
        <HStack color="gray.600" gap={1} divider={<Text color="gray.300">|</Text>}>
          <Text>Uploaded File</Text>
          <Text>{provider.file_type}</Text>
        </HStack>
      );
    case "Website":
      return (
        <HStack color="gray.600" gap={1} divider={<Text color="gray.300">|</Text>}>
          <Text>Website</Text>
          <Link color="blue.500" href={provider.url} target="_blank">
            {provider.url}
          </Link>
        </HStack>
      );
    case "Google News":
      return (
        <HStack color="gray.600">
          <CompanyLogo name="Google News" domain="news.google.com" size="2xs" />
          <Text>Google News</Text>
        </HStack>
      );
    case "Audit":
      return (
        <Text color="gray.600">
          Audited by{" "}
          {provider.auditor.url ? (
            <Link as={RouterLink} to={provider.auditor.url} target="_blank" color="blue.500">
              {provider.auditor.name}
            </Link>
          ) : (
            provider.auditor.name
          )}
        </Text>
      );
    case "Questionnaire":
      return <Text color="gray.600">Questionnaire</Text>;
    default:
      unreachableCase(provider);
  }
};

const ProviderDetails = ({provider}: {provider: SourceProvider}) => {
  switch (provider.type) {
    case "File":
      return null;
    case "Website":
      return (
        <Text>
          {provider.pages}{" "}
          <Text as="span" fontSize="sm" color="gray.600">
            webpages
          </Text>
        </Text>
      );
    case "Google News":
      return (
        <Text color="red.500">
          {provider.articles}{" "}
          <Text as="span" fontSize="sm">
            articles
          </Text>
        </Text>
      );
    case "Audit":
      return provider.verified ? (
        <Tag as={RouterLink} to={provider.verification_url} target="_blank" colorScheme="green">
          <TagLeftIcon as={CheckCircleIcon} />
          Verified
          <TagRightIcon as={ChevronRightIcon} />
        </Tag>
      ) : (
        <Tag>Unverified</Tag>
      );
    case "Questionnaire":
      return (
        <Stack gap={0}>
          <Text>
            {provider.questions}{" "}
            <Text as="span" fontSize="sm" color="gray.600">
              questions
            </Text>
          </Text>
          {provider.questions > provider.completed_questions && (
            <Text color="red.500" fontSize="sm">
              {provider.questions - provider.completed_questions} unanswered
            </Text>
          )}
        </Stack>
      );
    default:
      unreachableCase(provider);
  }
};

const Confidence = ({confidence}: {confidence: SourceConfidence}) => {
  const color =
    {
      1: "red",
      2: "orange",
      3: "yellow",
      4: "green",
      5: "blue",
    }[confidence.score] ?? "gray";

  return (
    <Menu>
      <TagMenuButton colorScheme={color}>
        <Text>{confidence.name}</Text>
      </TagMenuButton>
      <PortalMenuList>
        <MenuItem as={HStack} fontSize="md">
          <Box bg="blue.500" h={2} w={2} rounded="full" />
          <Text flex={1}>Auditor</Text>
          <Text fontSize="sm" color="gray.500">
            5
          </Text>
        </MenuItem>
        <MenuItem as={HStack} fontSize="md">
          <Box bg="green.500" h={2} w={2} rounded="full" />
          <Text flex={1}>High</Text>
          <Text fontSize="sm" color="gray.500">
            4
          </Text>
        </MenuItem>
        <MenuItem as={HStack} fontSize="md">
          <Box bg="yellow.500" h={2} w={2} rounded="full" />
          <Text flex={1}>Medium</Text>flex 1{" "}
          <Text fontSize="sm" color="gray.500">
            3
          </Text>
        </MenuItem>
        <MenuItem as={HStack} fontSize="md">
          <Box bg="orange.500" h={2} w={2} rounded="full" />
          <Text flex={1}>Low</Text>
          <Text fontSize="sm" color="gray.500">
            2
          </Text>
        </MenuItem>
        <MenuItem as={HStack} fontSize="md">
          <Box bg="red.500" h={2} w={2} rounded="full" />
          <Text flex={1}>No confidence</Text>flex 1{" "}
          <Text fontSize="sm" color="gray.500">
            1
          </Text>
        </MenuItem>
      </PortalMenuList>
    </Menu>
  );
};

const SourceRow = ({source}: {source: SourceMin}) => (
  <LinkBox as={Tr} transform="scale(1)" _hover={{bg: "gray.50", cursor: "pointer"}} fontSize="md" color="gray.700">
    <Td>
      <Checkbox />
    </Td>
    <Td>
      <LinkOverlay as={RouterLink} to={source.source_id} />
      <Stack gap={0}>
        <Text fontWeight="semibold">{source.name}</Text>
        <Provider provider={source.provider} />
      </Stack>
    </Td>
    <Td>
      <ProviderDetails provider={source.provider} />
    </Td>
    <Td>{source.confidence && <Confidence confidence={source.confidence} />}</Td>
    <Td>
      <Stack alignItems="flex-start" gap={1}>
        <UpdatedAt updatedAt={source.updated_at} />
        {source.monitored ? (
          <>
            <MonitoredIcon />
            <Text color="gray.500" fontSize="sm">
              Last synced today
            </Text>
          </>
        ) : (
          <Text color="gray.500" fontSize="sm">
            Uploaded today
          </Text>
        )}
      </Stack>
    </Td>
    <Td align="right">
      <IconButton icon={<Icon as={ChevronRightIcon} fontSize="xl" />} aria-label="View Document" variant="ghost" />
    </Td>
  </LinkBox>
);

const SourcesPage = () => {
  const thirdParty: ThirdParty = useOutletContext();
  const sources: SourceMin[] = useQueryData({
    queryKey: ["tprm", "thirdPartySources", thirdParty.third_party_id],
  });

  return (
    <Stack spacing={0} pb={12} divider={<Divider borderColor="gray.200" />} justify="flex-start">
      <ActionBar
        actionButtons={
          <Menu>
            <MenuButton
              as={Button}
              colorScheme="green"
              rightIcon={<Icon as={ChevronDownIcon} />}
              leftIcon={<Icon as={PlusIcon} />}
            >
              Add
            </MenuButton>
            <PortalMenuList fontSize="md">
              <MenuGroup title="Files">
                <MenuItem icon={<Icon as={TableCellsIcon} />}>Questionnaire</MenuItem>
                <MenuItem icon={<Icon as={DocumentIcon} />}>Document</MenuItem>
              </MenuGroup>
              <MenuGroup title="Integrations">
                <MenuItem icon={<CompanyLogo name="Google News" domain="news.google.com" size="2xs" />} isDisabled>
                  Google News
                </MenuItem>
                <MenuItem icon={<CompanyLogo name="Moody's Orbis" domain="moodys.com" size="2xs" />}>
                  Moody's Orbis
                </MenuItem>
                <MenuItem icon={<CompanyLogo name="ComplyAdvantage" domain="complyadvantage.com" size="2xs" />}>
                  ComplyAdvantage
                </MenuItem>
              </MenuGroup>
            </PortalMenuList>
          </Menu>
        }
      >
        <ActionBarSearch />
      </ActionBar>
      <Table style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg" layout="fixed">
        <Thead bg="white" borderBottom="10px solid" borderBottomColor="red.100">
          <Tr color="red">
            <Th width="5%" color="gray.500" p="0">
              <Checkbox px="8" py="5" />
            </Th>
            <Th width="30%" color="gray.500">
              Name
            </Th>
            <Th width="20%" color="gray.500">
              Detail
            </Th>
            <Th width="15%" color="gray.500">
              Confidence
            </Th>
            <Th width="20%" color="gray.500">
              Freshness
            </Th>
            <Th w="10%" />
          </Tr>
        </Thead>
        <Tbody>
          {sources.map(s => (
            <SourceRow source={s} key={s.source_id} />
          ))}
        </Tbody>
      </Table>
    </Stack>
  );
};

export default SourcesPage;
