import {
  Assessment,
  AssessmentId,
  AssessmentMin,
  AssessmentStatus,
  FrameworkVersion,
  ThirdPartyId,
  ThirdPartyMin,
  nominate,
} from "../../Types";
import {whoAmI} from "../auth";
import api from "..";

export async function generateDemoAssessments(
  thirdParties: ThirdPartyMin[],
  frameworkVersions: FrameworkVersion[],
): Promise<Assessment[]> {
  // Generate controls map for each framework version
  const controls = frameworkVersions.map(fv =>
    fv.control_groups.flatMap(cg => cg.controls).reduce((acc, c) => ({...acc, [c.control_id]: c}), {}),
  );

  return [
    {
      assessment_id: nominate("assessmentId", "Assessment1"),
      third_party: thirdParties[0],
      framework_version: frameworkVersions[2],
      created_at: nominate("datetime", "2024-08-01T00:00:00Z"),
      unmitigated_risks: 0,
      status: AssessmentStatus.Conforms,
      control_count: 122,
      controls: controls[2],
    },
    {
      assessment_id: nominate("assessmentId", "Assessment2"),
      third_party: thirdParties[0],
      framework_version: frameworkVersions[0],
      created_at: nominate("datetime", "2024-08-01T00:00:00Z"),
      control_count: 8,
      unmitigated_risks: 3,
      status: AssessmentStatus.NonConformities,
      controls: controls[0],
    },
    {
      assessment_id: nominate("assessmentId", "Assessment3"),
      third_party: thirdParties[2],
      framework_version: frameworkVersions[1],
      created_at: nominate("datetime", "2024-08-01T00:00:00Z"),
      control_count: 5,
      unmitigated_risks: 3,
      status: AssessmentStatus.NonConformities,
      controls: controls[1],
    },
  ];
}

export async function list(thirdPartyId: ThirdPartyId): Promise<AssessmentMin[]> {
  const whoami = await whoAmI();

  const thirdParties = await api.tprm.thirdParties.list();
  const frameworkVersions = await api.tprm.frameworkVersions.debugList();

  return (await generateDemoAssessments(thirdParties, frameworkVersions))
    .filter(a => a.third_party.third_party_id == thirdPartyId)
    .map(a => ({
      owner: whoami.user_owner,
      ...a,
    }));
}

export async function get(assessmentId: AssessmentId): Promise<Assessment> {
  const whoami = await whoAmI();

  const thirdParties = await api.tprm.thirdParties.list();
  const frameworkVersions = await api.tprm.frameworkVersions.debugList();

  const a = (await generateDemoAssessments(thirdParties, frameworkVersions)).find(
    a => a.assessment_id == assessmentId,
  )!;

  a.owner = whoami.user_owner;
  return a;
}
