import {Navigate, Route} from "react-router-dom";
import Page from "./Page";
import {Fragment} from "react";
import Pages from "./Pages";
import {getFrontendEnvironment} from "../utils/environment";
import LoadTrustCenter from "./LoadTrustCenter.tsx";

const {custom_domain} = getFrontendEnvironment();

const clientAccountRoutes = (
  <Route element={<Page />}>
    {Pages.map(page => (
      <Fragment key={page.path}>
        <Route {...page} />
      </Fragment>
    ))}
    <Route index element={<Navigate to="overview" replace />} />
  </Route>
);

const trustCenterRoutes = custom_domain ? (
  <Route element={<LoadTrustCenter />}>{clientAccountRoutes}</Route>
) : (
  <Route>
    <Route path=":clientAccountSlug" element={<LoadTrustCenter />}>
      {clientAccountRoutes}
    </Route>
    <Route index element={<Navigate to="platformed" replace />} />
  </Route>
);

export default trustCenterRoutes;
