import {Button, ButtonGroup, Heading, HStack, Icon, Stack, Text} from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom";
import {ArrowDownTrayIcon, ChevronRightIcon} from "@heroicons/react/24/solid";

const Report = ({to, name, description}: {to: string; name: string; description: string}) => (
  <HStack border="1px solid" borderColor="gray.200" borderRadius="md" p={4} justify="space-between">
    <Stack>
      <Heading size="sm">{name}</Heading>
      <Text color="gray.600" fontSize="md">
        {description}
      </Text>
    </Stack>
    <ButtonGroup>
      <Button rightIcon={<Icon as={ArrowDownTrayIcon} />} colorScheme="green">
        Download
      </Button>
      <Button as={RouterLink} to={to} rightIcon={<Icon as={ChevronRightIcon} />} colorScheme="blue">
        Open
      </Button>
    </ButtonGroup>
  </HStack>
);

const ReportPage = () => (
  <Stack spacing={6} p={6}>
    <Report
      name="Executive Summary"
      to="executive-summary"
      description="Summary information on the third party, suitable for high level consumption."
    />
    <Report
      name="Detailed Report"
      to="detailed-report"
      description="Detailed information on the third party, suitable for risk managers and other experts."
    />
    <Report
      name="Audit Report"
      to="audit-report"
      description="Record of all work done, suitably for internal and external auditors."
    />
  </Stack>
);

export default ReportPage;
