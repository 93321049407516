import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {ActionBar} from "../../../../components/ActionBar";
import {PlusIcon} from "@heroicons/react/24/outline";
import {DeleteModalIconButton} from "../../../../components/DeleteModalButton";
import {PencilSquareIcon} from "@heroicons/react/20/solid";
import {useState} from "react";
import {useValidatedPromiseState} from "../../../../hooks/validationState";
import {usePromiseState} from "../../../../hooks/promiseState";
import {ColorSchemePicker} from "../../../components/ColorSchemePicker";

export type AttributePayload = {
  title: string;
  color: string;
};

export type Attribute = {
  id: string;
} & AttributePayload;

function ColorSwatch({colorScheme}: {colorScheme: string}) {
  return <Box boxSize={4} bg={`${colorScheme}.500`} rounded="sm" />;
}

function AttributeRow({
  label,
  attribute,
  onUpdate,
  onDelete,
  isDeleteDisabled = false,
}: {
  label: string;
  attribute: Attribute;
  onUpdate: (id: string, attribute: AttributePayload) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  isDeleteDisabled?: boolean;
}) {
  const editModal = useDisclosure();
  const deleteModal = useDisclosure();

  const [deleting, delete_] = usePromiseState(async () => {
    await onDelete(attribute.id);
  }, [attribute.id, onDelete]);

  const deleteTooltipLabel = isDeleteDisabled ? `Cannot delete last ${label}` : `Delete ${label}`;

  return (
    <>
      <HStack
        py={2}
        px={6}
        pr={2}
        spacing={8}
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="gray.200"
        _hover={{bg: "gray.50"}}
        fontSize="md"
      >
        <HStack spacing={6}>
          <ColorSwatch colorScheme={attribute.color} />
          <Text color={`${attribute.color}.700`}>{attribute.title}</Text>
        </HStack>
        <HStack>
          <ButtonGroup>
            <Tooltip label={`Edit ${label}`}>
              <IconButton
                icon={<Icon as={PencilSquareIcon} />}
                aria-label="Edit"
                variant="outline"
                color="gray.500"
                onClick={editModal.onOpen}
              />
            </Tooltip>
            <DeleteModalIconButton
              {...deleteModal}
              modalHeader={`Delete ${label}`}
              modalBody={`Are you sure you want to delete this ${label}?`}
              delete_={delete_}
              deleting={deleting.inProgress}
              tooltip={deleteTooltipLabel}
              buttonProps={{"aria-label": `delete ${label}`, isDisabled: isDeleteDisabled}}
            />
          </ButtonGroup>
        </HStack>
      </HStack>
      {editModal.isOpen && (
        <CreateOrEditAttributeModal {...editModal} label={label} attribute={attribute} onUpdate={onUpdate} />
      )}
    </>
  );
}

function CreateOrEditAttributeModal({
  isOpen,
  onClose,
  label,
  attribute,
  onCreate,
  onUpdate,
}: {
  isOpen: boolean;
  onClose: () => void;
  label: string;
  attribute?: Attribute;
  onCreate?: (attribute: AttributePayload) => Promise<void>;
  onUpdate?: (id: string, attribute: AttributePayload) => Promise<void>;
}) {
  const id = attribute?.id;
  const isCreating = id == null;

  const [title, setTitle] = useState(attribute?.title ?? "");
  const [color, setColor] = useState(attribute?.color ?? "gray");

  const [updating, update] = useValidatedPromiseState(async () => {
    const attribute: AttributePayload = {title, color};
    isCreating ? await onCreate?.(attribute) : await onUpdate?.(id, attribute);
    onClose();
  }, [title, color, isCreating, onCreate, onUpdate, id, onClose]);

  const modalTitle = isCreating ? `Create ${label}` : `Edit ${label}`;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={update}>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>
          <Stack spacing="8" mb="4">
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input onChange={e => setTitle(e.target.value)} value={title} isDisabled={updating.inProgress} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Color</FormLabel>
              <ColorSchemePicker value={color} onChange={setColor} />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing="3">
            <Button variant="ghost" onClick={onClose} isDisabled={updating.inProgress}>
              Cancel
            </Button>
            <Button
              colorScheme={isCreating ? "green" : "blue"}
              isDisabled={updating.inProgress}
              isLoading={updating.inProgress}
              type="submit"
            >
              {isCreating ? `Create ${label}` : "Save changes"}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function AttributesUi({
  attributes,
  label,
  onCreate,
  onUpdate,
  onDelete,
  canDeleteLast = true,
}: {
  attributes: Attribute[];
  label: string;
  onCreate: (attribute: AttributePayload) => Promise<void>;
  onUpdate: (id: string, attribute: AttributePayload) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  canDeleteLast?: boolean;
}) {
  const createModal = useDisclosure();

  return (
    <>
      <ActionBar
        actionButtons={
          <>
            <Button colorScheme="green" leftIcon={<Icon as={PlusIcon} />} onClick={createModal.onOpen}>
              Create {label}
            </Button>
            {createModal.isOpen && <CreateOrEditAttributeModal {...createModal} label={label} onCreate={onCreate} />}
          </>
        }
      />
      <Stack spacing={0}>
        {attributes.map(a => (
          <AttributeRow
            key={a.id}
            label={label}
            attribute={a}
            onUpdate={onUpdate}
            onDelete={onDelete}
            isDeleteDisabled={canDeleteLast ? false : attributes.length === 1}
          />
        ))}
      </Stack>
    </>
  );
}
