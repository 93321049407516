import {DocumentDuplicateIcon} from "@heroicons/react/20/solid";
import {RouteTab} from "../../../../components/RouterTabs/index.tsx";
import DataRoomPage from "./DataRoomPage";

export default {
  title: "Data Room",
  path: "data-room",
  icon: DocumentDuplicateIcon,
  element: <DataRoomPage />,
} satisfies RouteTab;
