import {Dispatch, SetStateAction, useCallback, useMemo} from "react";
import * as _ from "lodash-es";
import WeakValueMap from "../utils/weakValueMap";

function getOrInsert<const K, const V extends WeakKey, const K2 extends K, const V2 extends V>(
  m: WeakValueMap<K, V>,
  k: K2,
  f: (k: K2) => V2,
): V2 {
  let v = m.get(k) as V2 | undefined;
  if (!v) {
    v = f(k);
    m.set(k, v);
  }
  return v;
}

/// A lens gives the ability to view and update part of a larger state
export class Lens<T, U = T> {
  readonly view: (parent: T) => U;
  readonly update: (parent: T, child: U) => T;

  // Cache known lenses
  static #identity: Lens<any> = new Lens(
    parent => parent,
    (_parent, child) => child,
  );
  static #propertyMap = new WeakValueMap<keyof any, Lens<any>>();
  #chainMap = new WeakValueMap<Lens<any>, Lens<any>>();

  static identity<T>(): Lens<T> {
    return Lens.#identity;
  }

  constructor(view: (parent: T) => U, update: (parent: T, child: U) => T) {
    this.view = view;
    this.update = update;
  }
  static identityView<T>(parent: T): T {
    return parent;
  }
  static identityUpdate<T>(_parent: T, child: T): T {
    return child;
  }
  chain<V>(lens: Lens<U, V>): Lens<T, V> {
    return getOrInsert(
      this.#chainMap,
      lens,
      () =>
        new Lens<T, V>(
          parent => lens.view(this.view(parent)),
          (parent, child) => this.update(parent, lens.update(this.view(parent), child)),
        ),
    );
  }
  property<const K extends keyof U>(key: K): Lens<T, U[K]> {
    const prop = getOrInsert(
      Lens.#propertyMap,
      key,
      () =>
        new Lens<U, U[K]>(
          parent => parent[key],
          (parent, child) => {
            const copy = _.clone(parent);
            copy[key] = child;
            return copy;
          },
        ),
    );
    return this.chain(prop);
  }
}

export function lens<T>() {
  return Lens.identity<T>();
}

export function useSubState<T, U>(
  state: T,
  setState: Dispatch<SetStateAction<T>>,
  lensFn: (identityLens: Lens<T>) => Lens<T, U>,
): [U, Dispatch<SetStateAction<U>>] {
  const {view, update} = lensFn(lens<T>());
  const subState = useMemo(() => view(state), [state, view]);
  const setSubState = useCallback(
    (f: SetStateAction<U>) => {
      setState(prevValue => update(prevValue, f instanceof Function ? f(view(prevValue)) : f));
    },
    [setState, view, update],
  );
  return [subState, setSubState];
}
