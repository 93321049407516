import {
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Checkbox,
  Button,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import {usePromiseState} from "../../../../../hooks/promiseState";
import {DocumentSource} from "../../../../../Types";
import {useResetting} from "../../../../../hooks/resetting";
import api from "../../../../../api";
import {router} from "../../../../../router";
import {useState} from "react";

type DeleteDocumentSourceModalProps = {
  isOpen: boolean;
  onClose: () => void;
  documentSource: DocumentSource;
};

export function DeleteDocumentSourceModal({isOpen, onClose, documentSource}: DeleteDocumentSourceModalProps) {
  const [retainDocuments, setRetainDocuments] = useResetting(useState(false), isOpen);
  const [deleting, delete_] = usePromiseState(async () => {
    await api.vendorToolkit.documentSources.delete_(documentSource.document_source_id, {
      retain_documents: retainDocuments,
    });
    onClose();
    await router!.navigate("/vendor-toolkit/configuration/integrations/document-sources");
  }, [documentSource.document_source_id, retainDocuments, onClose]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete document source</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDir="column" gap={4}>
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>This action cannot be undone!</AlertDescription>
            </Alert>
            <Checkbox isChecked={retainDocuments} onChange={e => setRetainDocuments(e.target.checked)}>
              Retain documents synced from this source
            </Checkbox>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <HStack spacing="3">
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              isDisabled={deleting.inProgress}
              isLoading={deleting.inProgress}
              onClick={delete_}
            >
              Delete
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
