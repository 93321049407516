import {useCallback, useEffect, useMemo, useState} from "react";
import {UserMin} from "../Types";
import {useQueryData} from "../state";
import {Avatar, Box, FormControl, HStack, Icon, Input, Stack, Text} from "@chakra-ui/react";
import {withSuspense} from "../state/withSuspense";
import {UserPlusIcon} from "@heroicons/react/20/solid";

export type UserPickerValue =
  | {
      type: "User";
      user: UserMin;
    }
  | {
      type: "Invite";
      nameOrEmail: string;
    };

function valueEq(a?: UserPickerValue | null, b?: UserPickerValue | null): boolean {
  if (a && b) {
    if (a.type === "User" && b.type === "User") {
      return a.user.user_id === b.user.user_id;
    } else if (a.type === "Invite" && b.type === "Invite") {
      return a.nameOrEmail === b.nameOrEmail;
    } else {
      return false;
    }
  } else {
    return !a && !b;
  }
}

export const UserPicker = withSuspense(
  ({
    value,
    onSelect,
    autoFocus,
    onSubmit,
  }: {
    value?: UserPickerValue;
    onSelect: (user: UserPickerValue) => void;
    autoFocus?: boolean;
    onSubmit?: () => void;
  }) => {
    const [name, setName] = useState(
      (value?.type === "User" ? value?.user.name ?? value?.user.primary_email : value?.nameOrEmail) ?? "",
    );
    const users = useQueryData({queryKey: ["registeredUsers"]});
    const items: UserPickerValue[] = useMemo(() => {
      const lowerName = name.toLowerCase();
      const filteredUsers = users
        .map(({user}) => user)
        .filter(
          user => user.name.toLowerCase().includes(lowerName) || user.primary_email.toLowerCase().includes(lowerName),
        )
        .sort((a, b) => {
          const score = b.name.length / (lowerName.length + 1) - a.name.length / (lowerName.length + 1);
          if (score === 0) {
            return a.name.localeCompare(b.name);
          } else {
            return score;
          }
        });
      const extraItems: UserPickerValue[] = filteredUsers.some(
        user => user.name.toLowerCase() === lowerName || user.primary_email.toLowerCase() === lowerName,
      )
        ? []
        : [
            {
              type: "Invite",
              nameOrEmail: name,
            },
          ];
      return [...extraItems, ...filteredUsers.map(user => ({type: "User", user}) as const)];
    }, [users, name]);

    const selectedItem = items.find(item => valueEq(item, value));

    useEffect(() => {
      if (!valueEq(selectedItem, value)) {
        onSelect(selectedItem ?? {type: "Invite", nameOrEmail: name});
      }
      if (!selectedItem && (items.length === 1 || (items.length === 2 && items[0].type === "Invite"))) {
        onSelect(items[items.length - 1]);
      }
    }, [items, selectedItem, value, onSelect, name]);

    const keyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && selectedItem && onSubmit) {
          e.preventDefault();
          onSubmit();
        }
      },
      [onSubmit, selectedItem],
    );

    return (
      <FormControl>
        <Input
          placeholder="Search..."
          value={name}
          onChange={e => setName(e.target.value)}
          borderBottomRadius={0}
          autoFocus={autoFocus}
          onKeyDown={keyDown}
          borderBottom="none"
        />
        <Stack
          overflowY="auto"
          maxH="200px"
          gap={0}
          borderColor="gray.200"
          borderWidth="1px"
          role="listbox"
          roundedBottom="md"
        >
          {items.map(item => {
            const key = item.type === "User" ? item.user.user_id : item.nameOrEmail;
            const selected = valueEq(item, selectedItem);
            return (
              <Box
                key={key}
                _hover={selected ? {} : {bg: "blue.50"}}
                bg={selected ? "blue.500" : "white"}
                fontWeight={selected ? "semibold" : "normal"}
                color={selected ? "white" : "gray.700"}
                onClick={() => onSelect(item)}
                p={2}
                cursor="default"
                role="option"
                fontSize="md"
              >
                {item.type === "User" ? (
                  <HStack>
                    <Avatar src={item.user.avatar_url} name={item.user.name ?? item.user.primary_email} size="xs" />
                    <Text>{item.user.name ?? item.user.primary_email}</Text>
                  </HStack>
                ) : (
                  <HStack>
                    <Stack bg="green.500" color="white" rounded="full" w={6} h={6} justify="center" align="center">
                      <Icon as={UserPlusIcon} />
                    </Stack>
                    <Text>
                      Invite{" "}
                      {item.nameOrEmail ? (
                        <Text as="span" fontWeight="semibold">
                          {item.nameOrEmail}
                        </Text>
                      ) : (
                        "a new user"
                      )}{" "}
                      to this account
                    </Text>
                  </HStack>
                )}
              </Box>
            );
          })}
        </Stack>
      </FormControl>
    );
  },
);
