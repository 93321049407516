import {Dispatch, DispatchWithoutAction, SetStateAction, useCallback, useMemo} from "react";

export type Prefix<A extends any[], L extends number = 0> =
  | A
  | (A extends {length: L} ? never : A extends [...infer H, any] ? Prefix<H, L> : never);

type StackValue<A extends any[]> = {value: A; size: A["length"]};
type StackPop<A extends any[], P2> = A extends P2 ? {pop: DispatchWithoutAction} : unknown;
type StackReplace<A extends any[], P2> = A extends [...infer H, infer T]
  ? {replace: Dispatch<SetStateAction<T>>} & StackPop<H, P2>
  : unknown;
type StackPush<A extends any[], P2> = [...A, any] extends P2
  ? P2 extends [...A, infer N]
    ? {push: Dispatch<N>}
    : never
  : unknown;

export type Stack<P, P2 = P> = P extends [...infer A] ? StackValue<A> & StackReplace<A, P2> & StackPush<A, P2> : never;
export type Sized<S, L extends number> = S extends {length: L} ? S : never;
export type SizedStack<P, L extends number> = Stack<P> & {size: L};

export function useStack<P extends any[]>([value, setValue]: [P, Dispatch<SetStateAction<P>>]): {
  stack: Stack<P>;
  setValue: Dispatch<SetStateAction<P>>;
} {
  const push = useCallback(
    (top: P[number]) => {
      setValue([...value, top] as P);
    },
    [value, setValue],
  );
  const pop = useCallback(() => {
    setValue(value.slice(0, value.length - 1) as P);
  }, [value, setValue]);
  const replace = useCallback(
    (top: SetStateAction<P[number]>) => {
      setValue(prev =>
        // If previous value length has changed since last re-render, then "replace" call is invalid, so do nothing
        prev.length === value.length
          ? ([...prev.slice(0, prev.length - 1), top instanceof Function ? top(prev[prev.length - 1]) : top] as P)
          : prev,
      );
    },
    [value, setValue],
  );
  return useMemo(
    () => ({
      stack: {
        push,
        pop: value.length > 0 ? pop : undefined,
        replace: value.length > 0 ? replace : undefined,
        value,
        size: value.length,
      } as unknown as Stack<P>,
      setValue,
    }),
    [push, pop, replace, value, setValue],
  );
}
