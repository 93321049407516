import {Dispatch, ReactNode, SetStateAction, useState} from "react";
import ResponseYesNo from "../../../../components/QuestionResponses/ResponseYesNo";
import ResponseSelect from "../../../../components/QuestionResponses/ResponseSelect";
import ResponseTextarea from "../../../../components/QuestionResponses/ResponseTextarea";
import ResponsePreview from "./ResponsePreview";
import {useResetting} from "../../../../../../hooks/resetting";

export type ColumnHeading =
  | "SectionTitle"
  | "Number"
  | "Question"
  | "Guidance"
  | "Parts"
  | "ResponseYesNo"
  | "SelectOptions"
  | "ResponseSelect"
  | "ResponseText";

export type ColumnDesc = {
  heading: ColumnHeading;
  tooltip?: ReactNode;
  required?: boolean;
  default?: boolean;
};

export type ColumnGroup = {
  title?: ReactNode;
  tooltip?: ReactNode;
  columns: ColumnDesc[];
};

export type Column = {
  heading: ColumnHeading;
  enabled: boolean;
};

export const COLUMN_GROUPS: ColumnGroup[] = [
  {
    title: "General",
    columns: [
      {
        heading: "SectionTitle",
        tooltip:
          "The section title. Adjacent questions with the same section title will be combined into the same section.",
        default: true,
      },
      {
        heading: "Number",
        default: true,
        tooltip: "The question number. May contain letters and symbols, eg. `1.a`",
      },
      {
        heading: "Question",
        tooltip: "The question text.",
        required: true,
      },
      {
        heading: "Guidance",
        tooltip: "Guidance for humans answering the question. Not used by the AI.",
      },
      {
        heading: "Parts",
        required: true,
        tooltip: "The types of answers possible for this question, eg. yesno/text/etc.",
      },
    ],
  },
  {
    title: "Yes/No",
    columns: [{heading: "ResponseYesNo"}],
    tooltip: (
      <ResponsePreview>
        <ResponseYesNo value={true} onCommit={async () => {}} />
      </ResponsePreview>
    ),
  },
  {
    title: "Select",
    columns: [
      {heading: "SelectOptions", tooltip: "The options from which the user can select."},
      {heading: "ResponseSelect"},
    ],
    tooltip: (
      <ResponsePreview>
        <ResponseSelect
          value={[{value: "option1"}]}
          config={{
            options: [
              {value: "option1", label: "Option 1", allow_other_text: false},
              {value: "option2", label: "Option 2", allow_other_text: false},
              {value: "option3", label: "Option 3", allow_other_text: true},
            ],
            max_selected: 1,
          }}
          onCommit={async () => {}}
        />
        <ResponseSelect
          value={[{value: "option1"}]}
          config={{
            options: [
              {value: "option1", label: "Option 1", allow_other_text: false},
              {value: "option2", label: "Option 2", allow_other_text: false},
              {value: "option3", label: "Option 3", allow_other_text: false},
            ],
          }}
          onCommit={async () => {}}
        />
      </ResponsePreview>
    ),
  },
  {
    title: "Text",
    columns: [{heading: "ResponseText", default: true}],
    tooltip: (
      <ResponsePreview>
        <ResponseTextarea value="Example text" onCommit={async () => {}} />
      </ResponsePreview>
    ),
  },
];

export type TemplateState = {
  enabledColumns: Column[];
  setEnabledColumns: Dispatch<SetStateAction<Column[]>>;
};

export default function useTemplateState(isOpen: boolean): TemplateState {
  const [enabledColumns, setEnabledColumns] = useResetting(
    useState<Column[]>(
      COLUMN_GROUPS.flatMap(group => group.columns).map(col => ({
        heading: col.heading,
        enabled: col.required ?? col.default ?? false,
      })),
    ),
    isOpen,
  );
  return {enabledColumns, setEnabledColumns};
}
