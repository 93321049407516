import {Box, Img, Link, Text} from "@chakra-ui/react";
import {AccountFeature} from "../../Types";
import {Feature} from ".";
import NotificationCenterScreenshot from "../../../assets/new_features/notification_center.png";

export default {
  heading: "Introducing Showcase Notifications",
  text: (
    <>
      <Text>
        You can now turn on the subscription function in Showcase, allowing clients to subscribe to any updates you
        have. You can send such updates to your mailing list in your Notification Center.
      </Text>
      <Link
        href="https://platformed.com/blog/notify-customers-about-changes-made-to-your-trust-center-with-platformed-showcase"
        target="_blank"
        color="blue.500"
        fontWeight="semibold"
      >
        Find out more in our blog post.
      </Link>
    </>
  ),
  image: (
    <Box bg="orange.300" bgGradient="linear(165deg, yellow.200, orange.200, orange.300)" p={4}>
      <Img src={NotificationCenterScreenshot} rounded="md" gridRow="1/2" gridColumn="1/2" />
    </Box>
  ),
  publishDate: new Date(Date.parse("2 Sep 2024")),
  actionText: "Go to Showcase",
  actionRequiredAccountFeatures: [AccountFeature.TrustCenter],
  url: "/vendor-toolkit/trust-center/config",
} satisfies Feature;
