import {withSuspense} from "../../../../state/withSuspense.tsx";
import {useQueryData} from "../../../../state/index.ts";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom";
import Page from "../../../../components/Page.tsx";
import FormSection, {FormQuestion} from "./FormSection.tsx";
import CommitInput from "../../../../components/CommitInput.tsx";
import ContentSelector from "./ContentSelector.tsx";
import {useCallback} from "react";
import api from "../../../../api/index.ts";
import AssetSelector from "./AssetSelector.tsx";
import {ArrowRightIcon, ArrowTopRightOnSquareIcon} from "@heroicons/react/24/solid";
import {getFrontendEnvironment} from "../../../../utils/environment.ts";
import {
  AssetId,
  ContentId,
  DocumentId,
  EntityId,
  QuestionnaireId,
  TrustCenterAccess,
  TrustCenterConfigMeta,
  TrustCenterDataUseItemPii,
  TrustCenterTrustedPartneredType,
  WhoAmI,
} from "../../../../Types.ts";
import {usePromiseState} from "../../../../hooks/promiseState.ts";
import {DeleteModalIconButton} from "../../../../components/DeleteModalButton.tsx";
import DocumentSelector from "../../components/DocumentSelector.tsx";
import QuestionnaireSelector from "../../components/QuestionnaireSelector.tsx";
import SubprocessorSelector from "../../components/SubprocessorSelector.tsx";
import CommitTextarea from "../../../../components/CommitTextarea.tsx";
import {COUNTRIES} from "../../../../utils/country.ts";

function getTrustCenterUrl(whoami: WhoAmI) {
  const {trust_url} = getFrontendEnvironment();
  return `${trust_url}/${whoami.account?.slug ?? ""}`;
}

const DeleteAiDataUseItemButton = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [deletingAiDataUseItem, deleteAiDataUseItem] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      ai: {
        ...config.meta.ai,
        data_use_items: config.meta.ai?.data_use_items?.toSpliced(idx, 1),
      },
    });
    onClose();
  }, [config.meta, idx, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteAiDataUseItem}
      deleting={deletingAiDataUseItem.inProgress}
      modalHeader="Delete entry"
      modalBody="Are you sure you want to delete this entry?"
    />
  );
};

const DeleteDataProtectionOperatingCountryItemButton = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [deletingDataProtectionOperatingCountryItem, deleteDataProtectionOperatingCountryItem] =
    usePromiseState(async () => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        data_protection: {
          ...config.meta.data_protection,
          operating_countries: config.meta.data_protection?.operating_countries?.toSpliced(idx, 1),
        },
      });
      onClose();
    }, [config.meta, idx, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteDataProtectionOperatingCountryItem}
      deleting={deletingDataProtectionOperatingCountryItem.inProgress}
      modalHeader="Delete entry"
      modalBody="Are you sure you want to delete this entry?"
    />
  );
};

const DeleteDataProtectionDataUseItemButton = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [deletingDataProtectionDataUseItem, deleteDataProtectionDataUseItem] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      data_protection: {
        ...config.meta.data_protection,
        data_use_items: config.meta.data_protection?.data_use_items?.toSpliced(idx, 1),
      },
    });
    onClose();
  }, [config.meta, idx, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteDataProtectionDataUseItem}
      deleting={deletingDataProtectionDataUseItem.inProgress}
      modalHeader="Delete entry"
      modalBody="Are you sure you want to delete this entry?"
    />
  );
};

const DeleteAiCheckItemButton = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [deletingAiCheckItem, deleteAiCheckItem] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      ai: {
        ...config.meta.ai,
        check_items: config.meta.ai?.check_items?.toSpliced(idx, 1),
      },
    });
    onClose();
  }, [config.meta, idx, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteAiCheckItem}
      deleting={deletingAiCheckItem.inProgress}
      modalHeader="Delete entry"
      modalBody="Are you sure you want to delete this entry?"
    />
  );
};

const DeleteAiModelProviderButton = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [deletingAiModelProvider, deleteAiModelProvider] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      ai: {
        ...config.meta.ai,
        model_providers: config.meta.ai?.model_providers?.toSpliced(idx, 1),
      },
    });
    onClose();
  }, [config.meta, idx, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteAiModelProvider}
      deleting={deletingAiModelProvider.inProgress}
      modalHeader="Delete model provider"
      modalBody="Are you sure you want to delete this model provider entry?"
    />
  );
};

const DeleteAiRelatedDocumentButton = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [deletingAiRelatedDocument, deleteAiRelatedDocument] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      ai: {
        ...config.meta.ai,
        related_documents: config.meta.ai?.related_documents?.toSpliced(idx, 1),
      },
    });
    onClose();
  }, [config.meta, idx, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteAiRelatedDocument}
      deleting={deletingAiRelatedDocument.inProgress}
      modalHeader="Delete document"
      modalBody="Are you sure you want to remove this document from the AI page?"
    />
  );
};

const DeleteDataProtectionRelatedDocumentButton = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [deletingDataProtectionRelatedDocument, deleteDataProtectionRelatedDocument] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      data_protection: {
        ...config.meta.data_protection,
        related_documents: config.meta.data_protection?.related_documents?.toSpliced(idx, 1),
      },
    });
    onClose();
  }, [config.meta, idx, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteDataProtectionRelatedDocument}
      deleting={deletingDataProtectionRelatedDocument.inProgress}
      modalHeader="Delete document"
      modalBody="Are you sure you want to remove this document from the Data Protection page?"
    />
  );
};

const DeleteTrustedByButton = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [deletingTrusted, deleteTrustedBy] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      overview: {
        ...config.meta.overview,
        trusted_by: config.meta.overview?.trusted_by?.toSpliced(idx, 1),
      },
    });
    onClose();
  }, [config.meta, idx, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteTrustedBy}
      deleting={deletingTrusted.inProgress}
      modalHeader="Delete logo"
      modalBody="Are you sure you want to delete this logo?"
    />
  );
};

const DeleteBusinessContentButton = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [deletingBusinessContent, deleteBusinessContent] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      business_info: config.meta.business_info?.toSpliced(idx, 1),
    });
    onClose();
  }, [config.meta, idx, onClose]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteBusinessContent}
      deleting={deletingBusinessContent.inProgress}
      modalHeader="Delete content"
      modalBody="Are you sure you want to delete this content?"
    />
  );
};

const TrustedBy = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const trustedBy = config.meta.overview?.trusted_by?.[idx];
  const updateLogoAssetId = useCallback(
    async (logo_asset_id: AssetId | undefined) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        overview: {
          ...config.meta.overview,
          trusted_by: config.meta.overview?.trusted_by?.toSpliced(idx, 1, {...trustedBy, logo_asset_id}),
        },
      });
    },
    [config.meta, idx, trustedBy],
  );

  const updateLogoLinkUrl = useCallback(
    async (logo_link_url: string) => {
      if (logo_link_url) {
        try {
          // Validate the URL
          new URL(logo_link_url);
        } catch {
          throw new Error("Not a valid URL");
        }
      }
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        overview: {
          ...config.meta.overview,
          trusted_by: config.meta.overview?.trusted_by?.toSpliced(idx, 1, {...trustedBy, logo_link_url}),
        },
      });
    },
    [config.meta, idx, trustedBy],
  );
  return (
    <HStack justify="space-between">
      <AssetSelector assetId={trustedBy?.logo_asset_id} onChangeAsset={updateLogoAssetId} />
      <CommitInput
        type="url"
        placeholder="https://example.com"
        value={trustedBy?.logo_link_url ?? ""}
        onCommit={updateLogoLinkUrl}
      />

      <DeleteTrustedByButton idx={idx} />
    </HStack>
  );
};

const BusinessContent = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const businessContent = config.meta.business_info?.[idx];
  const updateBusinessTitle = useCallback(
    async (title: string | undefined) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        business_info: config.meta.business_info?.toSpliced(idx, 1, {...businessContent, title}),
      });
    },
    [config.meta, idx, businessContent],
  );
  const updateBusinessContent = useCallback(
    async (content_id: ContentId | undefined) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        business_info: config.meta.business_info?.toSpliced(idx, 1, {...businessContent, content_id}),
      });
    },
    [businessContent, config.meta, idx],
  );
  return (
    <HStack justify="space-between">
      <CommitInput
        type="text"
        placeholder="Content heading"
        value={businessContent?.title ?? ""}
        onCommit={updateBusinessTitle}
      />
      <ContentSelector contentId={businessContent?.content_id} onChangeContent={updateBusinessContent} />
      <DeleteBusinessContentButton idx={idx} />
    </HStack>
  );
};

const AiCheckItem = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});

  // ! justification: This component is only rendered if the item exists.
  const entry = config.meta.ai!.check_items![idx];

  const updateTitle = useCallback(
    async (title: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          check_items: config.meta.ai?.check_items?.toSpliced(idx, 1, {...entry, title}),
        },
      });
    },
    [config.meta, entry, idx],
  );

  const updateDescription = useCallback(
    async (description: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          check_items: config.meta.ai?.check_items?.toSpliced(idx, 1, {...entry, description}),
        },
      });
    },
    [config.meta, entry, idx],
  );

  return (
    <HStack justify="space-between" alignItems="flex-start">
      <Stack p={4} rounded="md" border="1px solid" borderColor="gray.200" flexGrow="1">
        <FormQuestion title="Title">
          <CommitInput value={entry.title} onCommit={updateTitle} />
        </FormQuestion>
        <FormQuestion title="Description">
          <CommitInput value={entry.description} onCommit={updateDescription} />
        </FormQuestion>
      </Stack>
      <DeleteAiCheckItemButton idx={idx} />
    </HStack>
  );
};

const DataProtectionDataUseItem = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});

  // ! justification: This component is only rendered if the item exists.
  const entry = config.meta.data_protection!.data_use_items![idx];

  const [updatingTcConfig, updateTcConfig] = usePromiseState(async (configMeta: TrustCenterConfigMeta) => {
    await api.vendorToolkit.trustCenter.updateConfigMeta(configMeta);
  }, []);

  const updateTitle = useCallback(
    async (title: string) => {
      await updateTcConfig({
        ...config.meta,
        data_protection: {
          ...config.meta.data_protection,
          data_use_items: config.meta.data_protection?.data_use_items?.toSpliced(idx, 1, {...entry, title}),
        },
      });
    },
    [config.meta, entry, idx, updateTcConfig],
  );

  const updateDescription = useCallback(
    async (description: string) => {
      await updateTcConfig({
        ...config.meta,
        data_protection: {
          ...config.meta.data_protection,
          data_use_items: config.meta.data_protection?.data_use_items?.toSpliced(idx, 1, {...entry, description}),
        },
      });
    },
    [config.meta, entry, idx, updateTcConfig],
  );

  const updatePii = useCallback(
    async (pii: TrustCenterDataUseItemPii) => {
      await updateTcConfig({
        ...config.meta,
        data_protection: {
          ...config.meta.data_protection,
          data_use_items: config.meta.data_protection?.data_use_items?.toSpliced(idx, 1, {...entry, pii}),
        },
      });
    },
    [config.meta, entry, idx, updateTcConfig],
  );

  const updateIncludesCustomerData = useCallback(
    async (includes_customer_data: boolean) => {
      await updateTcConfig({
        ...config.meta,
        data_protection: {
          ...config.meta.data_protection,
          data_use_items: config.meta.data_protection?.data_use_items?.toSpliced(idx, 1, {
            ...entry,
            includes_customer_data,
          }),
        },
      });
    },
    [config.meta, entry, idx, updateTcConfig],
  );

  const updateIsAnonymized = useCallback(
    async (is_anonymized: boolean) => {
      await updateTcConfig({
        ...config.meta,
        data_protection: {
          ...config.meta.data_protection,
          data_use_items: config.meta.data_protection?.data_use_items?.toSpliced(idx, 1, {...entry, is_anonymized}),
        },
      });
    },
    [config.meta, entry, idx, updateTcConfig],
  );

  return (
    <HStack justify="space-between" alignItems="flex-start">
      <Stack spacing={4} p={4} rounded="md" border="1px solid" borderColor="gray.200" flexGrow="1">
        <FormQuestion title="Type of data">
          <CommitInput value={entry.title} onCommit={updateTitle} isDisabled={updatingTcConfig.inProgress} />
        </FormQuestion>
        <FormQuestion title="Description (optional)">
          <CommitInput
            value={entry.description}
            onCommit={updateDescription}
            isDisabled={updatingTcConfig.inProgress}
          />
        </FormQuestion>
        <FormQuestion title="Data classification">
          <RadioGroup
            value={entry.pii}
            onChange={v => updatePii(v as TrustCenterDataUseItemPii)}
            isDisabled={updatingTcConfig.inProgress}
          >
            <HStack spacing={8}>
              <Radio value="None">Normal</Radio>
              <Radio value="Pii">PII</Radio>
              <Radio value="SpecialCategory">Special category PII</Radio>
            </HStack>
          </RadioGroup>
        </FormQuestion>
        <FormQuestion title="Additional information">
          <HStack spacing={8} flexWrap="wrap" rowGap={2}>
            <Checkbox
              isChecked={entry.includes_customer_data}
              onChange={ev => updateIncludesCustomerData(ev.target.checked)}
              isDisabled={updatingTcConfig.inProgress}
            >
              Includes customer data
            </Checkbox>
            <Checkbox
              isChecked={entry.is_anonymized}
              onChange={ev => updateIsAnonymized(ev.target.checked)}
              isDisabled={updatingTcConfig.inProgress}
            >
              Is anonymized
            </Checkbox>
          </HStack>
        </FormQuestion>
      </Stack>
      <DeleteDataProtectionDataUseItemButton idx={idx} />
    </HStack>
  );
};

const DataProtectionOperatingCountryItem = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});

  // ! justification: This component is only rendered if the item exists.
  const entry = config.meta.data_protection!.operating_countries![idx];

  const [updatingTcConfig, updateTcConfig] = usePromiseState(async (configMeta: TrustCenterConfigMeta) => {
    await api.vendorToolkit.trustCenter.updateConfigMeta(configMeta);
  }, []);

  const updateCountry = useCallback(
    async (country: string) => {
      await updateTcConfig({
        ...config.meta,
        data_protection: {
          ...config.meta.data_protection,
          operating_countries: config.meta.data_protection?.operating_countries?.toSpliced(idx, 1, {
            ...entry,
            iso_country_code: country,
          }),
        },
      });
    },
    [config.meta, entry, idx, updateTcConfig],
  );

  const updateDescription = useCallback(
    async (description: string) => {
      await updateTcConfig({
        ...config.meta,
        data_protection: {
          ...config.meta.data_protection,
          operating_countries: config.meta.data_protection?.operating_countries?.toSpliced(idx, 1, {
            ...entry,
            description,
          }),
        },
      });
    },
    [config.meta, entry, idx, updateTcConfig],
  );

  const updateAddress = useCallback(
    async (address: string) => {
      await updateTcConfig({
        ...config.meta,
        data_protection: {
          ...config.meta.data_protection,
          operating_countries: config.meta.data_protection?.operating_countries?.toSpliced(idx, 1, {
            ...entry,
            detailed_address: address,
          }),
        },
      });
    },
    [config.meta, entry, idx, updateTcConfig],
  );

  const COUNTRY_OPTIONS = Object.entries(COUNTRIES)
    .sort((a, b) => a[1].localeCompare(b[1]))
    .map(([k, v]) => (
      <option key={k} value={k}>
        {v}
      </option>
    ));

  return (
    <HStack justify="space-between" alignItems="flex-start">
      <Stack spacing={4} p={4} rounded="md" border="1px solid" borderColor="gray.200" flexGrow="1">
        <FormQuestion title="Operating country">
          <Select
            placeholder="-- Select country or region --"
            value={entry.iso_country_code}
            onChange={e => updateCountry(e.target.value)}
            isDisabled={updatingTcConfig.inProgress}
          >
            {COUNTRY_OPTIONS}
          </Select>
        </FormQuestion>
        <FormQuestion title="Detailed address (optional)">
          <CommitInput
            value={entry.detailed_address ?? ""}
            onCommit={updateAddress}
            isDisabled={updatingTcConfig.inProgress}
          />
        </FormQuestion>
        <FormQuestion title="Description (optional)">
          <CommitInput
            value={entry.description ?? ""}
            onCommit={updateDescription}
            isDisabled={updatingTcConfig.inProgress}
          />
        </FormQuestion>
      </Stack>
      <DeleteDataProtectionOperatingCountryItemButton idx={idx} />
    </HStack>
  );
};

const AiDataUseItem = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});

  // ! justification: This component is only rendered if the item exists.
  const entry = config.meta.ai!.data_use_items![idx];

  const [updatingTcConfig, updateTcConfig] = usePromiseState(async (configMeta: TrustCenterConfigMeta) => {
    await api.vendorToolkit.trustCenter.updateConfigMeta(configMeta);
  }, []);

  const updateTitle = useCallback(
    async (title: string) => {
      await updateTcConfig({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          data_use_items: config.meta.ai?.data_use_items?.toSpliced(idx, 1, {...entry, title}),
        },
      });
    },
    [config.meta, entry, idx, updateTcConfig],
  );

  const updateDescription = useCallback(
    async (description: string) => {
      await updateTcConfig({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          data_use_items: config.meta.ai?.data_use_items?.toSpliced(idx, 1, {...entry, description}),
        },
      });
    },
    [config.meta, entry, idx, updateTcConfig],
  );

  const updatePii = useCallback(
    async (pii: TrustCenterDataUseItemPii) => {
      await updateTcConfig({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          data_use_items: config.meta.ai?.data_use_items?.toSpliced(idx, 1, {...entry, pii}),
        },
      });
    },
    [config.meta, entry, idx, updateTcConfig],
  );

  const updateIncludesCustomerData = useCallback(
    async (includes_customer_data: boolean) => {
      await updateTcConfig({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          data_use_items: config.meta.ai?.data_use_items?.toSpliced(idx, 1, {...entry, includes_customer_data}),
        },
      });
    },
    [config.meta, entry, idx, updateTcConfig],
  );

  const updateIsAnonymized = useCallback(
    async (is_anonymized: boolean) => {
      await updateTcConfig({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          data_use_items: config.meta.ai?.data_use_items?.toSpliced(idx, 1, {...entry, is_anonymized}),
        },
      });
    },
    [config.meta, entry, idx, updateTcConfig],
  );

  const updateIsUsedForTraining = useCallback(
    async (used_for_training: boolean) => {
      await updateTcConfig({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          data_use_items: config.meta.ai?.data_use_items?.toSpliced(idx, 1, {...entry, used_for_training}),
        },
      });
    },
    [config.meta, entry, idx, updateTcConfig],
  );

  return (
    <HStack justify="space-between" alignItems="flex-start">
      <Stack spacing={4} p={4} rounded="md" border="1px solid" borderColor="gray.200" flexGrow="1">
        <FormQuestion title="Type of data">
          <CommitInput value={entry.title} onCommit={updateTitle} isDisabled={updatingTcConfig.inProgress} />
        </FormQuestion>
        <FormQuestion title="Description (optional)">
          <CommitInput
            value={entry.description}
            onCommit={updateDescription}
            isDisabled={updatingTcConfig.inProgress}
          />
        </FormQuestion>
        <FormQuestion title="Data classification">
          <RadioGroup
            value={entry.pii}
            onChange={v => updatePii(v as TrustCenterDataUseItemPii)}
            isDisabled={updatingTcConfig.inProgress}
          >
            <HStack spacing={8}>
              <Radio value="None">Normal</Radio>
              <Radio value="Pii">PII</Radio>
              <Radio value="SpecialCategory">Special category PII</Radio>
            </HStack>
          </RadioGroup>
        </FormQuestion>
        <FormQuestion title="Additional information">
          <HStack spacing={8} flexWrap="wrap" rowGap={2}>
            <Checkbox
              isChecked={entry.includes_customer_data}
              onChange={ev => updateIncludesCustomerData(ev.target.checked)}
              isDisabled={updatingTcConfig.inProgress}
            >
              Includes customer data
            </Checkbox>
            <Checkbox
              isChecked={entry.is_anonymized}
              onChange={ev => updateIsAnonymized(ev.target.checked)}
              isDisabled={updatingTcConfig.inProgress}
            >
              Is anonymized
            </Checkbox>
            <Checkbox
              isChecked={entry.used_for_training}
              onChange={ev => updateIsUsedForTraining(ev.target.checked)}
              isDisabled={updatingTcConfig.inProgress}
            >
              Is used for training
            </Checkbox>
          </HStack>
        </FormQuestion>
      </Stack>
      <DeleteAiDataUseItemButton idx={idx} />
    </HStack>
  );
};

const AiModelProvider = ({idx}: {idx: number}) => {
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});

  // ! justification: This component is only rendered if the item exists.
  const entry = config.meta.ai!.model_providers![idx];

  const updateSubprocessor = useCallback(
    async (subprocessor_entity_id: EntityId | null) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          model_providers: config.meta.ai?.model_providers?.toSpliced(idx, 1, {
            ...entry,
            subprocessor_entity_id: subprocessor_entity_id ?? undefined,
          }),
        },
      });
    },
    [config.meta, entry, idx],
  );

  const updateModelsUsed = useCallback(
    async (models_used: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          model_providers: config.meta.ai?.model_providers?.toSpliced(idx, 1, {...entry, models_used}),
        },
      });
    },
    [config.meta, entry, idx],
  );

  const updatePurposes = useCallback(
    async (purposes: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          model_providers: config.meta.ai?.model_providers?.toSpliced(idx, 1, {...entry, purposes}),
        },
      });
    },
    [config.meta, entry, idx],
  );

  return (
    <HStack justify="space-between" alignItems="flex-start">
      <Stack p={4} rounded="md" border="1px solid" borderColor="gray.200" flexGrow="1">
        <FormQuestion
          title="Subprocessor"
          description="Choose a subprocessor from your library as this AI model provider."
        >
          <SubprocessorSelector value={entry.subprocessor_entity_id ?? null} onChange={updateSubprocessor} />
        </FormQuestion>
        <FormQuestion title="Models used">
          <CommitInput value={entry.models_used} onCommit={updateModelsUsed} placeholder={`e.g. GPT-4, GPT-4 Turbo`} />
        </FormQuestion>
        <FormQuestion title="Purposes">
          <CommitTextarea value={entry.purposes} onCommit={updatePurposes} />
        </FormQuestion>
      </Stack>
      <DeleteAiModelProviderButton idx={idx} />
    </HStack>
  );
};

const ConfigPage = withSuspense(() => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const config = useQueryData({queryKey: ["vendorToolkit", "trustCenterConfig"]});
  const updateName = useCallback(
    async (name: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({...config.meta, name});
    },
    [config.meta],
  );
  const updateLogo = useCallback(
    async (logo_asset_id: AssetId | undefined) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({...config.meta, logo_asset_id});
    },
    [config.meta],
  );
  const updateFavicon = useCallback(
    async (favicon_asset_id: AssetId | undefined) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({...config.meta, favicon_asset_id});
    },
    [config.meta],
  );
  const updateHomepageUrl = useCallback(
    async (homepage_url: string) => {
      if (homepage_url) {
        try {
          // Validate the URL
          new URL(homepage_url);
        } catch {
          throw new Error("Not a valid URL");
        }
      }
      await api.vendorToolkit.trustCenter.updateConfigMeta({...config.meta, homepage_url});
    },
    [config.meta],
  );
  const updatePrivacyPolicyUrl = useCallback(
    async (url: string) => {
      if (url) {
        try {
          // Validate the URL
          new URL(url);
        } catch {
          throw new Error("Not a valid URL");
        }
      }
      await api.vendorToolkit.trustCenter.updateConfigMeta({...config.meta, privacy_policy: url});
    },
    [config.meta],
  );
  const updateDocumentRequestEmail = useCallback(async (email: string) => {
    await api.vendorToolkit.trustCenter.updateConfigDocumentRequestEmail(email);
  }, []);
  const updateHeroHeading = useCallback(
    async (heading: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({...config.meta, hero: {...config.meta.hero, heading}});
    },
    [config.meta],
  );
  const updateHeroSubheading = useCallback(
    async (subheading: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({...config.meta, hero: {...config.meta.hero, subheading}});
    },
    [config.meta],
  );
  const updateOverviewWelcome = useCallback(
    async (welcome_id: ContentId | undefined) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        overview: {...config.meta.overview, welcome_id},
      });
    },
    [config.meta],
  );
  const updateOverviewContent = useCallback(
    async (content_id: ContentId | undefined) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        overview: {...config.meta.overview, content_id},
      });
    },
    [config.meta],
  );

  const updateAiOverview = useCallback(
    async (overview_content_id: ContentId | undefined) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {...config.meta.ai, overview_content_id},
      });
    },
    [config.meta],
  );

  const updateDataProtectionOverview = useCallback(
    async (overview_content_id: ContentId | undefined) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        data_protection: {...config.meta.data_protection, overview_content_id},
      });
    },
    [config.meta],
  );

  const [addingTrustedBy, addTrustedBy] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      overview: {...config.meta.overview, trusted_by: [...(config.meta.overview?.trusted_by ?? []), {}]},
    });
  }, [config.meta]);

  const [addingBusinessContent, addBusinessContent] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      business_info: [...(config.meta.business_info ?? []), {title: "", content_id: undefined}],
    });
  }, [config.meta]);

  const toggleAiEnabled = useCallback(async () => {
    const enabled = !config.meta.ai?.enabled;
    await api.vendorToolkit.trustCenter.updateConfigMeta({...config.meta, ai: {...config.meta.ai, enabled}});
  }, [config.meta]);

  const toggleDataProtectionEnabled = useCallback(async () => {
    const enabled = !config.meta.data_protection?.enabled;
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      data_protection: {...config.meta.data_protection, enabled},
    });
  }, [config.meta]);

  const toggleDataProtectionSarEnabled = useCallback(async () => {
    const enabled = !config.meta.data_protection?.sar_enabled;
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      data_protection: {...config.meta.data_protection, sar_enabled: enabled},
    });
  }, [config.meta]);

  const toggleDataProtectionSubprocessorsEnabled = useCallback(async () => {
    const enabled = !config.meta.data_protection?.subprocessors_enabled;
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      data_protection: {...config.meta.data_protection, subprocessors_enabled: enabled},
    });
  }, [config.meta]);

  const [togglingSubscribeEnabled, toggleSubscribeEnabled, clearError] = usePromiseState(async () => {
    clearError();
    const enabled = !config.meta.subscribe?.enabled;
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      subscribe: {...config.meta.subscribe, enabled},
    });
  }, [config.meta]);

  const [addingAiDataUseItem, addAiDataUseItem] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      ai: {
        ...config.meta.ai,
        data_use_items: [
          ...(config.meta.ai?.data_use_items ?? []),
          {
            title: "",
            description: "",
            pii: TrustCenterDataUseItemPii.None,
            includes_customer_data: false,
            is_anonymized: false,
            used_for_training: false,
          },
        ],
      },
    });
  }, [config.meta]);

  const [addingDataProtectionDataUseItem, addDataProtectionDataUseItem] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      data_protection: {
        ...config.meta.data_protection,
        data_use_items: [
          ...(config.meta.data_protection?.data_use_items ?? []),
          {
            title: "",
            description: "",
            pii: TrustCenterDataUseItemPii.None,
            includes_customer_data: false,
            is_anonymized: false,
          },
        ],
      },
    });
  }, [config.meta]);

  const [addingDataProtectionOperatingCountryItem, addDataProtectionOperatingCountryItem] =
    usePromiseState(async () => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        data_protection: {
          ...config.meta.data_protection,
          operating_countries: [
            ...(config.meta.data_protection?.operating_countries ?? []),
            {
              iso_country_code: "",
              description: "",
              detailed_address: "",
              gdpr_sccs: false,
              gdpr_europe: false,
              gdpr_equivalency: false,
            },
          ],
        },
      });
    }, [config.meta]);

  const [updatingTrustedPartnered, updateTrustedPartnered] = usePromiseState(
    async (logo_type: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        overview: {
          ...config.meta.overview,
          trusted_partnered:
            logo_type === "Partnered"
              ? TrustCenterTrustedPartneredType.Partnered
              : TrustCenterTrustedPartneredType.Trusted,
        },
      });
    },
    [config.meta],
  );

  const [addingAiCheckItem, addAiCheckItem] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      ai: {
        ...config.meta.ai,
        check_items: [...(config.meta.ai?.check_items ?? []), {title: "", description: ""}],
      },
    });
  }, [config.meta]);

  const [addingAiRelatedDocument, addAiRelatedDocument] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      ai: {
        ...config.meta.ai,
        related_documents: [...(config.meta.ai?.related_documents ?? []), {document_id: undefined}],
      },
    });
  }, [config.meta]);

  const [addingDataProtectionRelatedDocument, addDataProtectionRelatedDocument] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      data_protection: {
        ...config.meta.data_protection,
        related_documents: [...(config.meta.data_protection?.related_documents ?? []), {document_id: undefined}],
      },
    });
  }, [config.meta]);

  const [addingAiModelProvider, addAiModelProvider] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      ai: {
        ...config.meta.ai,
        model_providers: [...(config.meta.ai?.model_providers ?? []), {models_used: "", purposes: ""}],
      },
    });
  }, [config.meta]);

  const [_updatingAiRelatedDocument, updateAiRelatedDocument] = usePromiseState(
    async (idx: number, document_id: DocumentId | null) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          related_documents: config.meta.ai?.related_documents?.toSpliced(idx, 1, {
            document_id: document_id ?? undefined,
          }),
        },
      });
    },
    [config.meta],
  );

  const [_updatingDataProtectionRelatedDocument, updateDataProtectionRelatedDocument] = usePromiseState(
    async (idx: number, document_id: DocumentId | null) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        data_protection: {
          ...config.meta.data_protection,
          related_documents: config.meta.data_protection?.related_documents?.toSpliced(idx, 1, {
            document_id: document_id ?? undefined,
          }),
        },
      });
    },
    [config.meta],
  );

  const [_updatingAiFaqQuestionnaire, updateAiFaqQuestionnaire] = usePromiseState(
    async (questionnaireId: QuestionnaireId | null) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        ai: {
          ...config.meta.ai,
          faq_questionnaire_id: questionnaireId ?? undefined,
        },
      });
    },
    [config.meta],
  );

  const [togglingIsPublic, toggleIsPublic] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateIsPublic(!config.is_public);
  }, [config.is_public]);

  const [togglingPassword, togglePassword] = usePromiseState(async () => {
    await api.vendorToolkit.trustCenter.updateConfigMeta({
      ...config.meta,
      password_protected: {
        ...config.meta.password_protected,
        enabled: !config.meta.password_protected?.enabled,
      },
    });
  }, [config.meta]);

  const updatePassword = useCallback(
    async (password: string) => {
      await api.vendorToolkit.trustCenter.updateConfigMeta({
        ...config.meta,
        password_protected: {
          ...config.meta.password_protected,
          password,
        },
      });
    },
    [config.meta],
  );

  const trustCenterUrl = getTrustCenterUrl(whoami);

  const toast = useToast();
  const searchParams = new URLSearchParams({
    client_account_id: whoami.account?.account_id ?? "",
    password: config.meta.password_protected?.password ?? "",
  });
  const pwdUrl = trustCenterUrl + "?" + searchParams;
  const copyUrl = useCallback(() => {
    navigator.clipboard.writeText(pwdUrl);
    toast({description: "Trust Center URL copied to clipboard!", status: "success"});
  }, [pwdUrl, toast]);

  const trustCenterPassword = config.meta.password_protected?.enabled
    ? "?password=" + config.meta.password_protected?.password + "&client_account_id=" + whoami.account!.account_id
    : "";

  return (
    <Page title="Assets" pb={12}>
      <Flex justifyContent="space-between" alignItems="center" p={8}>
        <Heading size="lg">Trust center configuration</Heading>
        <Button
          as="a"
          target="_blank"
          rightIcon={<Icon as={ArrowTopRightOnSquareIcon} />}
          cursor="pointer"
          colorScheme="green"
          size="sm"
          href={trustCenterUrl}
        >
          View trust center
        </Button>
      </Flex>
      <Stack divider={<Divider />} spacing={4} p={4}>
        <FormSection title="Main" description="">
          <FormQuestion title="Displayed name">
            <CommitInput value={config.meta.name ?? ""} onCommit={updateName} />
          </FormQuestion>
          <FormQuestion title="Logo" description="A company logo to show at the top of every page.">
            <AssetSelector assetId={config.meta.logo_asset_id} onChangeAsset={updateLogo} />
          </FormQuestion>
          <FormQuestion
            title="Favicon"
            description="A visual reminder of the site identity shown in the address bar or in tabs. Recommended size: 64x64px."
          >
            <AssetSelector assetId={config.meta.favicon_asset_id} onChangeAsset={updateFavicon} />
          </FormQuestion>
          <FormQuestion
            title="Homepage URL"
            description="The site users will be taken to when they click your company logo on the trust center."
          >
            <CommitInput value={config.meta.homepage_url ?? ""} onCommit={updateHomepageUrl} />
          </FormQuestion>
          <FormQuestion
            title="Request email"
            description="Document or subject access requests will be forwarded to this email address."
            id={"request-email"}
          >
            <CommitInput value={config.document_request_email ?? ""} onCommit={updateDocumentRequestEmail} />
          </FormQuestion>
          <FormQuestion
            title="Privacy Policy URL"
            description="Your privacy policy link which users can view when they contact you."
          >
            <CommitInput value={config.meta.privacy_policy ?? ""} onCommit={updatePrivacyPolicyUrl} />
          </FormQuestion>
        </FormSection>
        <FormSection title="Hero banner" description="Banner displayed at the top of every page">
          <FormQuestion title="Heading">
            <CommitInput value={config.meta.hero?.heading ?? ""} onCommit={updateHeroHeading} />
          </FormQuestion>
          <FormQuestion title="Subheading">
            <CommitInput value={config.meta.hero?.subheading ?? ""} onCommit={updateHeroSubheading} />
          </FormQuestion>
        </FormSection>
        <FormSection title="Overview" description="Configure the Overview page">
          <FormQuestion title="Welcome message" description="This content appears at the top of the Overview page">
            <ContentSelector contentId={config.meta.overview?.welcome_id} onChangeContent={updateOverviewWelcome} />
          </FormQuestion>
          <FormQuestion title="Trusted by/Partnered with" description="These logos appear after your welcome message">
            <Select
              value={config.meta.overview?.trusted_partnered ?? "Trusted"}
              onChange={e => updateTrustedPartnered(e.target.value)}
              disabled={updatingTrustedPartnered.inProgress}
            >
              <option value="Trusted">Trusted by</option>
              <option value="Partnered">Partnered with</option>
            </Select>
            {config.meta.overview?.trusted_by?.map((_trustedBy, idx) => <TrustedBy key={idx} idx={idx} />)}
            <Box>
              <Button
                onClick={addTrustedBy}
                isDisabled={addingTrustedBy.inProgress}
                isLoading={addingTrustedBy.inProgress}
                size="sm"
              >
                Add logo
              </Button>
            </Box>
          </FormQuestion>
          <FormQuestion title="Page content" description="This content populates the body of the Overview page">
            <ContentSelector contentId={config.meta.overview?.content_id} onChangeContent={updateOverviewContent} />
          </FormQuestion>
        </FormSection>
        <FormSection title="Subscribe" description="Allow clients to subscribe to mailing list for updates">
          <Flex justifyContent="space-between" alignItems="center">
            <FormQuestion title="Enabled" description="Show subscribe button" aria-label={"toggle-tc-subscribe"}>
              <HStack>
                <Switch
                  isChecked={config.meta.subscribe?.enabled}
                  onChange={toggleSubscribeEnabled}
                  isDisabled={togglingSubscribeEnabled.inProgress}
                />
                {togglingSubscribeEnabled.lastError ? (
                  <Box fontSize="sm" color={"red"}>
                    Error turning on subscription
                  </Box>
                ) : null}
              </HStack>
            </FormQuestion>
            <Stack>
              <Link as={RouterLink} to="/vendor-toolkit/trust-center/notification-center">
                <Button
                  as="a"
                  target="_blank"
                  rightIcon={<Icon as={ArrowRightIcon} />}
                  cursor="pointer"
                  colorScheme="green"
                  size="sm"
                >
                  View notification center
                </Button>
              </Link>
            </Stack>
          </Flex>
        </FormSection>
        <FormSection
          title="Business Information"
          description="Additional content for the Business Information page. Any content you configure here will appear
          beneath the basic company information (company number, registered address, ...) provided under Graph."
        >
          <Flex justifyContent="end" alignItems="center">
            <Button
              as="a"
              target="_blank"
              rightIcon={<Icon as={ArrowTopRightOnSquareIcon} />}
              cursor="pointer"
              colorScheme="green"
              size="sm"
              href={trustCenterUrl + "/info" + trustCenterPassword}
            >
              View business information
            </Button>
          </Flex>
          <FormQuestion
            title="Content"
            description="Configured content will appear after any legal information on the Business Information page"
          >
            {config.meta.business_info?.map((_businessContent, idx) => <BusinessContent key={idx} idx={idx} />)}
            <Box>
              <Button
                onClick={addBusinessContent}
                isDisabled={addingBusinessContent.inProgress}
                isLoading={addingBusinessContent.inProgress}
                size="sm"
              >
                Add content
              </Button>
            </Box>
          </FormQuestion>
        </FormSection>
        <FormSection title="Data Protection" description="Configure the Data Protection page">
          <Flex justifyContent="space-between" alignItems="center">
            <FormQuestion
              title="Enabled"
              description="Show the Data Protection page"
              aria-label={"toggle-tc-data-protection-page"}
            >
              <Switch isChecked={config.meta.data_protection?.enabled} onChange={toggleDataProtectionEnabled} />
            </FormQuestion>
            <Button
              as="a"
              target="_blank"
              rightIcon={<Icon as={ArrowTopRightOnSquareIcon} />}
              cursor="pointer"
              colorScheme="green"
              size="sm"
              href={trustCenterUrl + "/data" + trustCenterPassword}
              isDisabled={!config.meta.data_protection?.enabled}
            >
              View data protection
            </Button>
          </Flex>
          <FormQuestion
            title="Overview message"
            description="This content appears at the top of the Data Protection page"
          >
            <ContentSelector
              contentId={config.meta.data_protection?.overview_content_id}
              onChangeContent={updateDataProtectionOverview}
            />
          </FormQuestion>
          <FormQuestion title="Data use">
            <Stack>
              {config.meta.data_protection?.data_use_items?.map((_, idx) => (
                <DataProtectionDataUseItem key={idx} idx={idx} />
              ))}
            </Stack>
            <Box>
              <Button
                onClick={addDataProtectionDataUseItem}
                isDisabled={addingDataProtectionDataUseItem.inProgress}
                isLoading={addingDataProtectionDataUseItem.inProgress}
                size="sm"
              >
                Add entry
              </Button>
            </Box>
          </FormQuestion>
          <FormQuestion title="Operating countries">
            <Stack>
              {config.meta.data_protection?.operating_countries?.map((_, idx) => (
                <DataProtectionOperatingCountryItem key={idx} idx={idx} />
              ))}
            </Stack>
            <Box>
              <Button
                onClick={addDataProtectionOperatingCountryItem}
                isDisabled={addingDataProtectionOperatingCountryItem.inProgress}
                isLoading={addingDataProtectionOperatingCountryItem.inProgress}
                size="sm"
              >
                Add country
              </Button>
            </Box>
          </FormQuestion>
          <FormQuestion
            title="Related documents"
            description="Documents related to Data Protection to show on the Data Protection page"
          >
            {config.meta.data_protection?.related_documents?.map((d, idx) => (
              <HStack justifyContent="space-between" key={idx}>
                <DocumentSelector
                  value={d.document_id ?? null}
                  onChange={id => updateDataProtectionRelatedDocument(idx, id)}
                  showCategoryPrefix
                  filter={d => d.trust_center_visibility === TrustCenterAccess.Allowed}
                />
                <DeleteDataProtectionRelatedDocumentButton idx={idx} />
              </HStack>
            ))}
            <Box>
              <Button
                onClick={addDataProtectionRelatedDocument}
                isDisabled={addingDataProtectionRelatedDocument.inProgress}
                isLoading={addingDataProtectionRelatedDocument.inProgress}
                size="sm"
              >
                Add document
              </Button>
            </Box>
          </FormQuestion>
          <FormQuestion
            title="Subprocessors"
            description="Show subprocessors from Graph on this page"
            aria-label={"toggle-tc-data-protection-subprocessors"}
          >
            <Switch
              isChecked={config.meta.data_protection?.subprocessors_enabled}
              onChange={toggleDataProtectionSubprocessorsEnabled}
            />
          </FormQuestion>
          <FormQuestion
            title="SAR"
            description={
              <Text>
                Allow visitors to submit subject access requests. SARs will go to the email configured under 'Main {">"}{" "}
                Request email'.{" "}
                <Link href={"#request-email"} color={"blue.500"}>
                  Click here
                </Link>{" "}
                to change the email address.
              </Text>
            }
            aria-label={"toggle-tc-data-protection-sar"}
          >
            <Switch isChecked={config.meta.data_protection?.sar_enabled} onChange={toggleDataProtectionSarEnabled} />
          </FormQuestion>
        </FormSection>
        <FormSection title="AI" description="Configure the AI page">
          <Flex justifyContent="space-between" alignItems="center">
            <FormQuestion title="Enabled" description="Show the AI page" aria-label={"toggle-tc-ai"}>
              <Switch isChecked={config.meta.ai?.enabled} onChange={toggleAiEnabled} />
            </FormQuestion>
            <Button
              as="a"
              target="_blank"
              rightIcon={<Icon as={ArrowTopRightOnSquareIcon} />}
              cursor="pointer"
              colorScheme="green"
              size="sm"
              href={trustCenterUrl + "/ai" + trustCenterPassword}
              isDisabled={!config.meta.ai?.enabled}
            >
              View AI
            </Button>
          </Flex>
          <FormQuestion title="Overview message" description="This content appears at the top of the AI page">
            <ContentSelector contentId={config.meta.ai?.overview_content_id} onChangeContent={updateAiOverview} />
          </FormQuestion>
          <FormQuestion title="Data use">
            <Stack>{config.meta.ai?.data_use_items?.map((_, idx) => <AiDataUseItem key={idx} idx={idx} />)}</Stack>
            <Box>
              <Button
                onClick={addAiDataUseItem}
                isDisabled={addingAiDataUseItem.inProgress}
                isLoading={addingAiDataUseItem.inProgress}
                size="sm"
              >
                Add entry
              </Button>
            </Box>
          </FormQuestion>
          <FormQuestion title="Trust and compliance">
            <Stack>{config.meta.ai?.check_items?.map((_, idx) => <AiCheckItem key={idx} idx={idx} />)}</Stack>
            <Box>
              <Button
                onClick={addAiCheckItem}
                isDisabled={addingAiCheckItem.inProgress}
                isLoading={addingAiCheckItem.inProgress}
                size="sm"
              >
                Add entry
              </Button>
            </Box>
          </FormQuestion>
          <FormQuestion title="AI model providers">
            {config.meta.ai?.model_providers?.map((_, idx) => <AiModelProvider key={idx} idx={idx} />)}
            <Box>
              <Button
                onClick={addAiModelProvider}
                isDisabled={addingAiModelProvider.inProgress}
                isLoading={addingAiModelProvider.inProgress}
                size="sm"
              >
                Add model provider
              </Button>
            </Box>
          </FormQuestion>
          <FormQuestion title="Related documents" description="Documents related to AI to show on the AI page">
            {config.meta.ai?.related_documents?.map((d, idx) => (
              <HStack justifyContent="space-between" key={idx}>
                <DocumentSelector
                  value={d.document_id ?? null}
                  onChange={id => updateAiRelatedDocument(idx, id)}
                  showCategoryPrefix
                  filter={d => d.trust_center_visibility === TrustCenterAccess.Allowed}
                />
                <DeleteAiRelatedDocumentButton idx={idx} />
              </HStack>
            ))}
            <Box>
              <Button
                onClick={addAiRelatedDocument}
                isDisabled={addingAiRelatedDocument.inProgress}
                isLoading={addingAiRelatedDocument.inProgress}
                size="sm"
              >
                Add document
              </Button>
            </Box>
          </FormQuestion>
          <FormQuestion
            title="Other questions"
            description={`Link a questionnaire to show in the "Other questions" section`}
          >
            <QuestionnaireSelector
              value={config.meta.ai?.faq_questionnaire_id ?? null}
              onChange={updateAiFaqQuestionnaire}
            />
          </FormQuestion>
        </FormSection>
        <FormSection
          title="Liveness"
          description="Is your trust center live and accessible to the public? Does it required a password to be viewed?"
        >
          <HStack justifyContent={"space-between"} width={"80%"}>
            <Box aria-label={"trust-center-liveness"}>
              <FormLabel>Live?</FormLabel>
              <Switch isChecked={config.is_public} onChange={toggleIsPublic} isDisabled={togglingIsPublic.inProgress} />
            </Box>
            <Box aria-label={"trust-center-password"}>
              <FormLabel>Password protected?</FormLabel>
              <Switch
                isChecked={config.meta.password_protected?.enabled}
                onChange={togglePassword}
                isDisabled={togglingPassword.inProgress || !config.is_public}
              />
            </Box>
          </HStack>
          {config.meta.password_protected?.enabled && (
            <FormQuestion
              title="Password"
              description="The password users will need to provide to access your trust center."
            >
              <CommitInput
                value={config.meta.password_protected?.password ?? ""}
                onCommit={updatePassword}
                data-testid="tc-password-field"
              />
            </FormQuestion>
          )}
          {config.meta.password_protected?.enabled && config.meta.password_protected?.password && (
            <FormControl>
              <FormLabel>Shareable URL</FormLabel>
              <InputGroup>
                <Input value={pwdUrl} readOnly pr="4.5rem" textOverflow="ellipsis" overflow="hidden" />
                <InputRightElement width="4.5rem">
                  <Button onClick={copyUrl} height="1.75rem" size="sm">
                    Copy
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          )}
        </FormSection>
      </Stack>
    </Page>
  );
});

export default ConfigPage;
