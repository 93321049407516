/* eslint-disable import/no-restricted-paths */
import {
  ApiDateTime,
  Assessment as RawAssessment,
  AssessmentId,
  AssessmentMin as RawAssessmentMin,
  AssetId,
  ControlId,
  CreateQuestionInSection,
  CreateSection,
  EntityId,
  GraphComponentType as RawGraphComponentType,
  GraphEntity as RawGraphEntity,
  LoginMethod,
  Owner,
  SourceMin as RawSourceMin,
  SubGraph as RawSubGraph,
  UnauthorizedError as UnauthorizedErrorData,
  WhoAmIResponse,
} from "./generatedTypes";

export type {
  Account,
  AccountType,
  Activity,
  ActivityStream,
  AnswerParts,
  ApiDateTime,
  ApiDate,
  BackgroundTask,
  BulkQuestionAction,
  BulkQuestionUpdate,
  BulkQuestionUpdateItem,
  CommentPayload,
  Config,
  Counterparty,
  CreateComment,
  CreateCounterparty,
  CreateDocument,
  CreateDocumentSource,
  CreateQuestionnaireDocumentation,
  CreateDocumentationAttachment,
  CreateExternalAuthorization,
  CreateMention,
  CreateQuestion,
  CreateQuestionDocumentation,
  CreateQuestionnaire,
  CreateSection,
  CreateQuestionInSection,
  CreateStandaloneFile,
  CreateRegisteredUser,
  Document,
  DocumentMin,
  DocumentSource,
  DocumentSourceConfig,
  DocumentSourceConnector,
  DocumentSourceMin,
  Documentation,
  CreatedExternalAuthorization,
  ExternalAuthorization,
  File,
  OriginalFile,
  OriginalSource,
  RequestLoginReset,
  FrontendEnvironment,
  InviteUser,
  ListActivitiesArgs,
  LoginRequest,
  Mention,
  PasswordConfig,
  PasswordPolicyConfig,
  Question,
  ActivitySummary,
  Questionnaire,
  QuestionnaireMin,
  CreateLoginMethod,
  ReviewPeriod,
  RichText,
  RichTextAttributes,
  RichTextElement,
  SectionMin,
  SelfEnrollmentDomain,
  StandaloneFile,
  Task,
  ToolsConfig,
  UpdateDocumentContent,
  UpdateDocumentSource,
  UserMin,
  TeamMin,
  RegisteredUser,
  WhoAmIResponse,
  ConfluenceDocumentSourceConfig,
  ConfluenceLabel,
  ResponseOption,
  SelectAnswerConfig,
  AnswerOption,
  Section,
  SendSupportEmail,
  SourcedFact,
  FactSourceQuestion,
  FactSourceDocument,
  FactTextMin,
  GenerateTotpResponse,
  UpdateTwoFa,
  LoginCredentials,
  GettingStartedChecklist,
  Rational,
  AccountAccessGrant,
  CrmConnector,
  UpdateCrmConnection,
  CreateCrmConnection,
  CrmConnection,
  CrmConnectionMin,
  CrmConnectionConfig,
  CrmAccountInfo,
  AdHocQuestion,
  CreateAdHocQuestion,
  UpdateQuestionSource,
  CreateQuestionSource,
  QuestionSource,
  QuestionSourceConfig,
  QuestionSourceAccountInfo,
  QuestionSourceMin,
  QuestionSourceConnector,
  Deal,
  ResponseLayer,
  FactMin,
  Paginated,
  PaginationArgs,
  AccountUser,
  Role,
  NotificationChannel,
  NotificationChannelConnector,
  CreateNotificationChannel,
  UpdateNotificationChannel,
  NotificationChannelConfig,
  NotificationChannelMin,
  LinkNotificationChannel,
  LinkedNotificationChannelMin,
  EsignatureProvider,
  EsignatureProviderConnector,
  EsignatureProviderConfig,
  EsignatureProviderMin,
  DocusignEsignatureProviderConfig,
  CreateEsignatureProvider,
  UpdateEsignatureProvider,
  EsignatureTemplate,
  ResetLoginInfo,
  PublicCredentials,
  AccountMin,
  CreateAccessToken,
  AccessToken,
  ConsentResponse,
  ConsentArgs,
  RequestRegistration,
  DocumentExternal,
  DocumentAccessRequest,
  TrustCenterContent,
  TrustCenterConfigMin,
  TrustCenterConfig,
  TrustCenterContentMin,
  CreateTrustCenterContent,
  IObject,
  IValue,
  TrustCenterConfigMeta,
  TrustCenterFaq,
  TrustCenterFaqQuestion,
  DownloadOptions,
  FactCluster,
  ClusteredFact,
  Asset,
  AssetMin,
  CreateAsset,
  ListAssets,
  ScopeMin,
  CreateScope,
  NotionDocumentSourceConfig,
  Resolution,
  CreateScopeAxis,
  ScopeAxisMin,
  ScopeMatrix,
  ScopeIdList,
  DeleteDocumentSourceArgs,
  FactSourceEntity,
  CreateLibrarySection,
  UpdateLibrarySection,
  LibrarySectionMin,
  FactSearchArgs,
  ListLibrarySectionFactsArgs,
  ListScopeFactsArgs,
  ReportedError,
  ReportedErrorCatalogMin,
  Team,
  CreateTeam,
  RoleMin,
  Owner,
  OwnerPayload,
  ResolveOwner,
  Uuid,
  UserId,
  OwnerId,
  QuestionId,
  SectionId,
  QuestionnaireId,
  AccountId,
  CounterpartyId,
  DocumentId,
  FileId,
  StandaloneFileId,
  DocumentationId,
  TeamId,
  RoleId,
  DealId,
  ExternalAuthorizationId,
  AssetId,
  ActivityStreamId,
  BackgroundTaskId,
  FactTextId,
  FactClusterId,
  ScopeId,
  ScopeAxisId,
  EntityId,
  ContentId,
  ResolutionId,
  ErrorCatalogId,
  DocumentAccessRequestId,
  CrmConnectionId,
  DocumentSourceId,
  EsignatureProviderId,
  EsignatureTemplateId,
  NotificationChannelId,
  QuestionSourceId,
  LibrarySectionId,
  PromptModifiers,
  Invalidation,
  Fact,
  FactId,
  ScopedLibrarySectionId,
  RelevantFact,
  TrustCenterAiCheckItemConfig,
  TrustCenterAiDataUseItemConfig,
  TrustCenterDataProtectionDataUseItemConfig,
  TrustCenterDataProtectionOperatingCountriesConfig,
  TrustCenterAiModelProviderConfig,
  TrustCenterPasswordConfig,
  CoreResponse,
  CreateCoreResponse,
  CoreResponseId,
  RelevantCoreResponse,
  MailingListId,
  SubscribeRequest,
  UploadOptions,
  TrustCenterSubscribeConfig,
  MailingList,
  Subscriber,
  ShowcaseUpdateMessage,
  ShowcaseMessageIds,
  ShowcaseUpdateMessageId,
  CreateOrUpdateShowcaseUpdateMessage,
  ApikeyId,
  AssessmentId,
  ControlGroupId,
  ControlId,
  FrameworkId,
  FrameworkVersionId,
  MailSubscriptionId,
  SourceChunkId,
  SourceId,
  ThirdPartyId,
  ThirdPartyStatusId,
  QuestionMin,
  Nominal,
  ListResolutionsArgs,
  CountResolutionsArgs,
  ThirdPartyStatusMin,
  Control,
  FrameworkVersion,
  FrameworkVersionMin,
  FrameworkMin,
  IdentifiedSheetSection,
  IdentifiedSheetCell,
  IdentifiedSheetRange,
  QuestionUpdatedPayload,
  ResolutionMin,
  ActivityPayload,
  SARRequest,
  ThirdPartyTierId,
  ThirdPartyTierMin,
  ThirdPartyTagId,
  ThirdPartyTagMin,
  ThirdParty,
  ThirdPartyMin,
  CreateThirdParty,
  CreateThirdPartyTag,
  CreateThirdPartyTier,
  CreateThirdPartyStatus,
} from "./generatedTypes";

export {
  OAuthProvider,
  LoginMethod,
  PasswordManager,
  Sso,
  SharingClassification,
  QuestionStatus,
  DocumentAuthority,
  DocumentCategory,
  ExternalAuthorizationProvider,
  QuestionnaireStatus,
  Environment,
  AiStatus,
  LayerType,
  OAuthRedirectTarget,
  SystemRole,
  NotificationChannelConnectorType,
  EsignatureProviderConnectorType,
  CustomDomainType,
  TrustCenterAccess,
  AccountFeature,
  AssetOwner,
  ResolutionType,
  OwnerType,
  nominate,
  CertificationType,
  PovModifier,
  VerbosityModifier,
  PromptModifierType,
  TrustCenterDataUseItemPii,
  TrustCenterTrustedPartneredType,
  SubscriptionAction,
  UpdateNotificationStatus,
} from "./generatedTypes";

export type {
  GetGraphQuery,
  GraphOperation,
  GraphOperationEntityRef,
  GraphPropertyValueRelationship,
  GraphProperty,
} from "./generatedTypes";

// The frontend knows about the existence of some properties on entities.
export type GraphEntity = RawGraphEntity & {properties: Partial<KnownEntityProperties>};
export type SubGraph = Omit<RawSubGraph, "entities"> & {entities: Record<string, GraphEntity>};

// The frontend uses session authentication and so will always have a user
export type WhoAmI = WhoAmIResponse & {user: {login_method: LoginMethod}};

// We always supply questions and sections fields
export type ImportQuestions = {
  questions: CreateQuestionInSection[];
  sections: CreateSection[];
  automate?: boolean;
};

export class UnauthorizedError extends Error {
  data: UnauthorizedErrorData;
  constructor(data: UnauthorizedErrorData) {
    super("UnauthorizedError");
    this.data = data;
  }
}

// Graph known types

export type GraphComponentType = `${RawGraphComponentType}`;

export const KNOWN_ENTITY_PROPERTIES = {
  name: "String",
  country_of_registration: "String",
  companies_house_number: "String",
  duns_number: "String",
  vat_number: "String",
  ico_registration: "String",
  registered_address: "String",
  website_url: "String",
  job_title: "String",
  legal_entity_type: "String",
  trust_center_url: "String",
  description: "String",
  shared_data: "String",
  iso_country_code: "String",
  incorporation_date: "String",
  industry_type: "String",
  growth_plan: "String",
  official_legal_entity_type: "String",

  number_of_employees: "Integer",

  is_root_legal_entity: "Boolean",
  gdpr_sccs: "Boolean",
  gdpr_based_in_europe: "Boolean",
  gdpr_equivalence: "Boolean",

  photo: "Asset",

  beneficial_owner: "Relationship",
  senior_leadership: "Relationship",
  board_member: "Relationship",
  subprocessor: "Relationship",
} as const satisfies {[k: string]: GraphComponentType};

/**
 * A helper type to map from a known property enum to its TypeScript type.
 */
export type Relationship = {parent_entity_id: EntityId; child_entity_id: EntityId};
type KnownPropertyMap = {
  String: string;
  Integer: number;
  Boolean: boolean;
  Asset: AssetId;
  Relationship: Relationship;
};

export type KnownEntityPropertyOfType<V extends GraphComponentType> = {
  [K in keyof typeof KNOWN_ENTITY_PROPERTIES]: (typeof KNOWN_ENTITY_PROPERTIES)[K] extends V ? K : never;
}[keyof typeof KNOWN_ENTITY_PROPERTIES];

export type KnownEntityProperty = keyof typeof KNOWN_ENTITY_PROPERTIES;
export type KnownEntityProperties = {[K in KnownEntityProperty]: KnownPropertyMap[(typeof KNOWN_ENTITY_PROPERTIES)[K]]};

type KnownEntityPropertiesByType = {[K in GraphComponentType]: KnownEntityPropertyOfType<K>[]};

function groupEntityPropertiesByType(): KnownEntityPropertiesByType {
  const res: Partial<KnownEntityPropertiesByType> = {};
  for (const [k, v] of Object.entries(KNOWN_ENTITY_PROPERTIES)) {
    if (!Object.hasOwn(res, v)) {
      res[v] = [];
    }
    res[v]!.push(k as never);
  }
  return res as KnownEntityPropertiesByType;
}

export const KNOWN_ENTITY_PROPERTIES_BY_TYPE = groupEntityPropertiesByType();

// TPRM Types below

export enum AssessmentStatus {
  Conforms,
  Unassessed,
  NonConformities,
}
export type AssessmentMin = RawAssessmentMin & {
  control_count: number;
  unmitigated_risks: number;
  status: AssessmentStatus;
  owner?: Owner;
};

export type Assessment = RawAssessment & {
  control_count: number;
  unmitigated_risks: number;
  status: AssessmentStatus;
  owner?: Owner;
};

export type SourceProvider =
  | {
      type: "File";
      file_type: string;
      words: number;
    }
  | {
      type: "Audit";
      standard: string;
      verified: boolean;
      verification_url?: string;
      auditor: {name: string; url?: string};
    }
  | {
      type: "Questionnaire";
      questions: number;
      completed_questions: number;
    }
  | {
      type: "Website";
      url: string;
      pages: number;
    }
  | {
      type: "Google News";
      articles: number;
    };

export type SourceConfidence = {
  score: number;
  name: string;
};

export type SourceMin = RawSourceMin & {
  name: string;
  provider: SourceProvider;
  updated_at: ApiDateTime;
  synced_at: ApiDateTime;
  monitored: boolean;
  confidence?: {
    score: number;
    name: string;
  };
};

export enum RiskLevel {
  HIGH,
  MEDIUM,
  LOW,
  UNDEFINED,
}

export enum MitigationStatus {
  MITIGATED,
  UNMITIGATED,
  IGNORED,
}

export type Risk = {
  risk_id: string;
  title: string;
  description: string;
  severity: RiskLevel;
  likelihood: RiskLevel;
  management: {
    title: string;
    status: MitigationStatus;
  };
  overridden: boolean;
  source: {
    assessment_id: AssessmentId;
    control_id: ControlId;
  };
};
