import {IdentifiedSheetCell, IdentifiedSheetRange} from "../../../../../../Types";
import type * as XLSX from "xlsx";

export function convertCell(v: IdentifiedSheetCell): XLSX.CellAddress {
  return {r: v[1], c: v[0]};
}

export function unconvertCell(v: XLSX.CellAddress): IdentifiedSheetCell {
  return [v.c, v.r];
}

export function convertRange(v: IdentifiedSheetRange): XLSX.Range {
  return {s: convertCell(v.start), e: convertCell(v.end)};
}

export function unconvertRange(v: XLSX.Range): IdentifiedSheetRange {
  return {start: unconvertCell(v.s), end: unconvertCell(v.e)};
}
