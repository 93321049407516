import {
  Button,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  Stack,
  Table,
  TableProps,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {MitigationStatus, RiskLevel as RiskLevelT, Risk as RiskT} from "../../../../Types";
import {CheckCircleIcon, PlusIcon} from "@heroicons/react/20/solid";
import TagMenuButton from "../../../../components/TagMenuButton";
import PortalMenuList from "../../../../components/PortalMenuList";
import {XCircleIcon} from "@heroicons/react/24/solid";

const Management = ({management}: {management: {title: string; status: MitigationStatus}}) => {
  let color = "green";
  if (management.status == MitigationStatus.IGNORED) {
    color = "gray";
  } else if (management.status == MitigationStatus.UNMITIGATED) {
    color = "red";
  }

  let icon = CheckCircleIcon;
  if (management.status == MitigationStatus.IGNORED) {
    icon = XCircleIcon;
  } else if (management.status == MitigationStatus.UNMITIGATED) {
    icon = XCircleIcon;
  }

  return (
    <Menu>
      <TagMenuButton colorScheme={color}>
        <Icon color={`${color}.500`} as={icon} />
        <Text>{management.title}</Text>
      </TagMenuButton>
      <PortalMenuList fontSize="md">
        <MenuItem>Unmanaged</MenuItem>
        <MenuDivider />
        <MenuItem>Accept Risk</MenuItem>
        <MenuItem>Mitigated</MenuItem>
        <MenuItem>Ignore</MenuItem>
      </PortalMenuList>
    </Menu>
  );
};

const RISK_LEVEL_COLOR: Record<RiskLevelT, string> = {
  [RiskLevelT.HIGH]: "red",
  [RiskLevelT.MEDIUM]: "orange",
  [RiskLevelT.LOW]: "green",
  [RiskLevelT.UNDEFINED]: "gray",
};

const RISK_LEVEL_LABEL: Record<RiskLevelT, string> = {
  [RiskLevelT.HIGH]: "High",
  [RiskLevelT.MEDIUM]: "Medium",
  [RiskLevelT.LOW]: "Low",
  [RiskLevelT.UNDEFINED]: "Undefined",
};

const RiskLevel = ({riskLevel}: {riskLevel: RiskLevelT}) => (
  <Menu>
    <TagMenuButton colorScheme={RISK_LEVEL_COLOR[riskLevel]}>
      <Text whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
        {RISK_LEVEL_LABEL[riskLevel]}
      </Text>
    </TagMenuButton>
    <PortalMenuList fontSize="md">
      <MenuItem>Undefined</MenuItem>
      <MenuDivider />
      <MenuItem>Low</MenuItem>
      <MenuItem>Medium</MenuItem>
      <MenuItem>High</MenuItem>
    </PortalMenuList>
  </Menu>
);

const RiskRow = ({risk}: {risk: RiskT}) => (
  <Tr>
    <Td>
      <Stack align="flex-start" gap={4}>
        <Stack gap={0}>
          <Text fontSize="xs" textTransform="uppercase" color="gray.500" fontWeight="semibold">
            {risk.risk_id}
          </Text>
          <Text fontWeight="semibold" color="gray.700">
            {risk.title}
          </Text>
          <Text color="gray.600">{risk.description}</Text>
        </Stack>
        {risk.overridden && (
          <Tag colorScheme="gray" size="sm">
            User overridden
          </Tag>
        )}
      </Stack>
    </Td>
    <Td>
      <RiskLevel riskLevel={risk.likelihood!} />
    </Td>
    <Td>
      <RiskLevel riskLevel={risk.severity!} />
    </Td>
    <Td>
      <Management management={risk.management} />
    </Td>
  </Tr>
);

const RisksTable = ({risks, onAdd, ...props}: {risks: RiskT[]; onAdd?: () => void} & TableProps) => (
  <Table {...props}>
    <Thead>
      <Tr>
        <Th fontSize="sm" color="gray.500" w="40%">
          Risk
        </Th>
        <Th fontSize="sm" color="gray.500" w="15%">
          Likelihood
        </Th>
        <Th fontSize="sm" color="gray.500" w="15%">
          Severity
        </Th>
        <Th fontSize="sm" color="gray.500">
          Management
        </Th>
      </Tr>
    </Thead>
    <Tbody fontSize="md" color="gray.700">
      {risks.map(risk => (
        <RiskRow risk={risk} key={risk.risk_id} />
      ))}
      {onAdd && (
        <Tr>
          <Td colSpan={4} py={1} px={2} borderBottom="none">
            <Button size="sm" variant="ghost" leftIcon={<Icon as={PlusIcon} />}>
              Add Risk
            </Button>
          </Td>
        </Tr>
      )}
    </Tbody>
  </Table>
);

export default RisksTable;
