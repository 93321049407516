import {
  Avatar,
  Button,
  Divider,
  HStack,
  Heading,
  Icon,
  LinkBox,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {AssessmentMin, ThirdParty} from "../../../../Types";
import {useOutletContext, Link as RouterLink} from "react-router-dom";
import {ActionBar} from "../../../../components/ActionBar";
import {useQueryData} from "../../../../state";
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PlusIcon,
  UserIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import OwnerSelector from "../../../../components/OwnerSelector";
import LinkOverlay from "../../../../components/LinkOverlay";

const ConformStatus = ({controls, passingControls}: {controls: number; passingControls: number}) => {
  const conforms = controls === passingControls;
  const color = conforms ? "green" : "red";
  const icon = conforms ? CheckCircleIcon : XCircleIcon;
  return (
    <HStack align="flex-start">
      <HStack rounded="full" color={`${color}.500`} bg={`${color}.100`} w="initial">
        <HStack pl={2} py={1}>
          <Icon as={icon} color={`${color}.500`} fontSize="lg" />
          <Text fontWeight="semibold">Controls met</Text>
        </HStack>
        <Text fontSize="sm" bg={`${color}.200`} roundedEnd="full" fontWeight="semibold" p={2} py={1}>
          {passingControls}/{controls}
        </Text>
      </HStack>
    </HStack>
  );
};

const Assessment = ({assessment}: {assessment: AssessmentMin}) => (
  <LinkBox as={Tr} transform="scale(1)" _hover={{bg: "gray.50", cursor: "pointer"}}>
    <Td>
      <LinkOverlay as={RouterLink} to={assessment.assessment_id} />
      <HStack>
        <Avatar name={assessment.framework_version.framework.name} size="sm" />
        <Stack gap={0}>
          <Heading size="sm" fontWeight="semibold">
            {assessment.framework_version.framework.name}
          </Heading>
          <Text fontSize="sm" color="gray.500">
            {assessment.framework_version.version}
          </Text>
        </Stack>
      </HStack>
    </Td>
    <Td>
      <ConformStatus
        passingControls={assessment.control_count - assessment.unmitigated_risks}
        controls={assessment.control_count}
      />
    </Td>
    <Td>
      <OwnerSelector owner={assessment.owner} onReassign={() => new Promise(r => r())} />
    </Td>
    <Td>1 day ago</Td>
    <Td textAlign="right">
      <Icon fontSize="3xl" color="gray.500" as={ChevronRightIcon} />
    </Td>
  </LinkBox>
);

const Assessments = ({assessments}: {assessments: AssessmentMin[]}) => (
  <Table borderY="1px solid" borderColor="gray.100">
    <Thead>
      <Tr>
        <Th fontSize="sm" color="gray.500" w="25%">
          Framework
        </Th>
        <Th fontSize="sm" color="gray.500" w="25%">
          Status
        </Th>
        <Th fontSize="sm" color="gray.500" w="25%">
          Owner
        </Th>
        <Th fontSize="sm" color="gray.500">
          Last Updated
        </Th>
        <Th w="8%" />
      </Tr>
    </Thead>
    <Tbody fontSize="md" color="gray.700">
      {assessments.map(a => (
        <Assessment key={a.assessment_id} assessment={a} />
      ))}
    </Tbody>
  </Table>
);

const FrameworksPage = () => {
  const thirdParty: ThirdParty = useOutletContext();

  const assessments: AssessmentMin[] = useQueryData({
    queryKey: ["tprm", "thirdPartyAssessments", thirdParty.third_party_id],
  });

  return (
    <Stack spacing={0}>
      <ActionBar
        actionButtons={
          <>
            <Button leftIcon={<Icon as={UserIcon} />} rightIcon={<Icon as={ChevronDownIcon} />} colorScheme="blue">
              Assessor
            </Button>
            <Button leftIcon={<Icon as={PlusIcon} />} rightIcon={<Icon as={ChevronDownIcon} />} colorScheme="green">
              Add Framework
            </Button>
          </>
        }
      />
      <Divider />
      <Assessments assessments={assessments} />
    </Stack>
  );
};

export default FrameworksPage;
