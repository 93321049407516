import {LibrarySectionMin, CreateLibrarySection, UpdateLibrarySection, LibrarySectionId, OwnerId} from "../../Types";
import {invalidateQueries} from "../../state";
import jsonApi from "../jsonApi";

export async function list() {
  return await jsonApi.get<LibrarySectionMin[]>("/vendor_toolkit/library_sections");
}

export async function get(librarySectionId: LibrarySectionId) {
  return await jsonApi.get<LibrarySectionMin>(`/vendor_toolkit/library_sections/${librarySectionId}`);
}

export async function create(librarySection: CreateLibrarySection) {
  const res = await jsonApi.post<LibrarySectionMin>("/vendor_toolkit/library_sections", librarySection);
  await invalidateQueries([{queryKey: ["vendorToolkit", "librarySections"]}]);
  return res;
}

export async function update(librarySectionId: LibrarySectionId, librarySection: UpdateLibrarySection) {
  const res = await jsonApi.patch<"ok">(`/vendor_toolkit/library_sections/${librarySectionId}`, librarySection);
  await invalidateQueries([{queryKey: ["vendorToolkit", "librarySections"]}]);
  return res;
}

export async function assign(librarySectionId: LibrarySectionId, owner: OwnerId | null) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/library_sections/${librarySectionId}/assign`, owner);
  await invalidateQueries([{queryKey: ["vendorToolkit", "librarySections"]}]);
  return res;
}

export async function deleteLibrarySection(librarySectionId: LibrarySectionId) {
  const res = await jsonApi.delete_<"ok">(`/vendor_toolkit/library_sections/${librarySectionId}`);
  await invalidateQueries([{queryKey: ["vendorToolkit", "librarySections"]}]);
  return res;
}
