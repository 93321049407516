import {Box, Button, Checkbox, Flex, Tooltip, Text, UnorderedList, ListItem} from "@chakra-ui/react";
import {AIAssistanceTooltip} from "../../../../../../../components/TooltipWithIcon";
import RequireAccountFeature from "../../../../../../../components/RequireAccountFeature";
import {AccountFeature} from "../../../../../../../Types";
import SelectButtonGroup from "../../../../../../../components/SelectButtonGroup";
import {AnsweringMode} from ".";

type AnsweringModeProps = {
  value: AnsweringMode;
  onChange: (newValue: AnsweringMode) => void;
};

export default function SelectAnsweringMode({value, onChange}: AnsweringModeProps) {
  return (
    <Flex direction="column" gap={2}>
      <Checkbox
        isChecked={value !== AnsweringMode.None}
        onChange={e => onChange(e.target.checked ? AnsweringMode.Considered : AnsweringMode.None)}
      >
        <AIAssistanceTooltip />
      </Checkbox>
      <RequireAccountFeature feature={AccountFeature.InstantAnswering} showInternally>
        {value !== AnsweringMode.None && (
          <Tooltip
            label={
              <Box>
                <Text>Determines the response speed and quality of AI-generated answers.</Text>
                <UnorderedList>
                  <ListItem>Instant answering: responses will be generated more quickly.</ListItem>
                  <ListItem>Considered answering: responses will go through a more in-depth review process.</ListItem>
                </UnorderedList>
              </Box>
            }
          >
            <SelectButtonGroup
              variant="outline"
              colorScheme="purple"
              isAttached
              value={value}
              onChange={newValue => onChange(newValue as AnsweringMode)}
              size="sm"
              ml={6}
            >
              <Button value={AnsweringMode.Instant}>Instant</Button>
              <Button value={AnsweringMode.Considered}>Considered</Button>
            </SelectButtonGroup>
          </Tooltip>
        )}
      </RequireAccountFeature>
    </Flex>
  );
}
