import {createContext} from "react";
import {ModalId} from ".";

type OptionalIfUndefined<T> = undefined extends T ? [modalProps?: T] : [modalProps: T];

export interface OpenModal {
  <T>(modalId: ModalId<T>, ...[modalProps]: OptionalIfUndefined<T>): void;
}

type ModalContext = {
  open: OpenModal;
};

function defaultOpen<T>(modalId: ModalId<T>, _modalProps?: T) {
  console.error(`No such modal: ${modalId.description}`);
}

export const modalContext = createContext<ModalContext>({open: defaultOpen});
