import {AssessmentId, ThirdPartyId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["tprm", "thirdParties", "risks", param<ThirdPartyId>("thirdPartyId")], async context => {
    return await api.tprm.risks.listByThirdParty(context.queryKey[3]);
  }),
  typedQuery(["tprm", "assessments", "risks", param<AssessmentId>("assessmentId")], async context => {
    return await api.tprm.risks.listByAssessment(context.queryKey[3]);
  }),
];
