import {useState} from "react";
import {ArrowDownIcon} from "@heroicons/react/24/solid";
import {
  Flex,
  Heading,
  Icon,
  Button,
  GridItem,
  Spinner,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
} from "@chakra-ui/react";

import api from "../../../../../api";
import {Config, DocumentAuthority, Document} from "../../../../../Types";
import {usePromiseState} from "../../../../../hooks/promiseState";
import {useDebouncedEffect} from "../../../../../hooks/debouncedEffect";
import {useQueryData} from "../../../../../state";
import TabsOutlet from "../../../../../components/RouterTabs/Outlet";
import {withSuspense} from "../../../../../state/withSuspense";
import {useTypedParams} from "../../../../../hooks/typedParams";
import {DocumentParams} from "./Crumb";

export type TabOutletContext = {document: Document; config?: Config; setConfig: (config: Config) => void};

const Loading = () => (
  <GridItem colSpan={8} display="flex" alignItems="center" justifyContent="center">
    <Spinner size="xl" color="gray.200" />
  </GridItem>
);

const DocumentActionsPage = withSuspense(
  () => {
    const {documentId} = useTypedParams(DocumentParams);
    const document = useQueryData({queryKey: ["vendorToolkit", "document", documentId]});

    const [editedConfig, setEditedConfig] = useState<Config | null>(null);
    const [updatingConfig, updateConfig] = usePromiseState(
      async (config: Config) => {
        setEditedConfig(null);
        await api.vendorToolkit.documents.updateContent(document.document_id, {
          authority: DocumentAuthority.Template,
          authoring_config: config,
          authoring_template: document.authoring_template,
        });
      },
      [document.document_id, document.authoring_template],
    );
    useDebouncedEffect(
      () => {
        if (!updatingConfig.inProgress && editedConfig) {
          updateConfig(editedConfig);
        }
      },
      [updatingConfig.inProgress, editedConfig, updateConfig],
      500,
    );

    const currentConfig =
      editedConfig ?? (updatingConfig.inProgress ? updatingConfig.lastArgs?.[0] : null) ?? document.authoring_config;

    const enabledTabs = ["file", "meta"];
    if (document.authority === DocumentAuthority.File) {
      enabledTabs.push("ai-text");
    } else if (document.authority === DocumentAuthority.Template) {
      enabledTabs.push("template");
      if (document.authoring_config!.type !== "None") {
        enabledTabs.push("config");
      }
    }
    enabledTabs.push("facts", "activity");

    const context: TabOutletContext = {document, config: currentConfig, setConfig: setEditedConfig};
    const isAnalyzing = !document.last_analyzed || document.last_update.at > document.last_analyzed;

    return (
      <Flex direction="column" data-testid="document-actions" flex={1}>
        {isAnalyzing ? (
          <Alert status="info">
            <AlertIcon />
            <AlertTitle fontSize="md">Analyzing document</AlertTitle>
            <AlertDescription fontSize="md">
              Platformed is currently analyzing this document to extract a set of facts.
            </AlertDescription>
          </Alert>
        ) : null}
        <Flex p="8" align="center" bg="white" gap={8}>
          <Heading flex="1" size="lg" overflow="hidden">
            {document.name}
          </Heading>
          <Button
            onClick={() => {
              api.files.download(document.file!.file_id, {override_name: document.name});
            }}
            leftIcon={<Icon as={ArrowDownIcon} h="6" />}
            colorScheme="blue"
          >
            Download
          </Button>
        </Flex>
        <TabsOutlet context={context} enabledTabs={enabledTabs} />
      </Flex>
    );
  },
  <Loading />,
);

export default DocumentActionsPage;
