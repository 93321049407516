import {Badge, Highlight, HStack, Icon, LinkBox, LinkOverlay, Stack, Tag, Text} from "@chakra-ui/react";
import {Owner, ResolutionMin} from "../../../../../../Types";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import {relativeDateView} from "../../../../../../utils/time";
import {useTime} from "../../../../../../hooks/time";
import {Link as RouterLink} from "react-router-dom";
import {formatResolutionReason, resolutionRenderInfo} from "../../utility/review";
import OwnerSelector from "../../../../../../components/OwnerSelector";
import {useCallback} from "react";
import api from "../../../../../../api";

type Props = {
  resolution: ResolutionMin;
  queries?: string[];
};

export function NecessityBadge({necessity}: {necessity: number}) {
  return <Tag fontWeight="600">Importance: {(necessity * 100).toFixed(0)}%</Tag>;
}

function ReviewItemRow({resolution, queries}: Props) {
  const time = useTime(relativeDateView(resolution.created_at), [resolution.created_at]);

  const reason = formatResolutionReason(resolution.reason);
  const r = resolutionRenderInfo(resolution);

  const reassign = useCallback(
    async (owner?: Owner) => {
      await api.vendorToolkit.facts.assignResolution(resolution.resolution_id, owner?.owner_id ?? null);
    },
    [resolution.resolution_id],
  );

  return (
    <LinkBox as={Stack} px={6} py={4} gap={4} borderBottom="1px solid" borderColor="gray.100" _hover={{bg: "gray.50"}}>
      <HStack spacing={4} alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <HStack spacing={4} flexGrow="1">
          <NecessityBadge necessity={resolution.review_necessity} />
          <Badge fontSize="sm" colorScheme={r.badge.color}>
            {r.badge.label}
          </Badge>
          <LinkOverlay as={RouterLink} to={`../review-items/${resolution.resolution_id}`}>
            <Text fontSize="md" fontWeight={600} flexGrow={1}>
              {r.description}
            </Text>
          </LinkOverlay>
        </HStack>
        <HStack spacing={4}>
          <OwnerSelector owner={resolution.owner} onReassign={reassign} />
          <Text fontSize="md" color="gray.500">
            {time}
          </Text>
        </HStack>
        <Icon as={ChevronRightIcon} />
      </HStack>
      <Text fontSize="md">
        <Highlight query={queries ?? []} styles={{bg: "yellow.200"}}>
          {reason}
        </Highlight>
      </Text>
    </LinkBox>
  );
}

export default ReviewItemRow;
