import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {CloudArrowUpIcon, DocumentPlusIcon} from "@heroicons/react/20/solid";
import {memo, useState} from "react";

import {
  AccountFeature,
  ApiDateTime,
  CertificationType,
  DocumentAuthority,
  DocumentCategory,
  DocumentId,
  SharingClassification,
  TrustCenterAccess,
} from "../../../../Types";
import api from "../../../../api";
import SingleFileUpload from "../../../../components/fileUploads/SingleFileUpload";
import {useQueryData} from "../../../../state";
import {router} from "../../../../router";
import {FileUpload} from "../../../../hooks/fileUpload";
import {useAsyncOperation} from "../../../../hooks/asyncOperation";
import {useValidation, useValidatedPromiseState} from "../../../../hooks/validationState";
import {useResetting} from "../../../../hooks/resetting";
import {SUPPORTED_DOCUMENT_FORMATS} from "../../../../components/fileUploads";
import {MultiSelect} from "chakra-multiselect";
import {DateUI} from "../../../../components/Date";
import RequireAccountFeature from "../../../../components/RequireAccountFeature";
import {
  CERTIFICATION_TYPE_OPTIONS,
  fromCertificationTypeOptionValue,
  toCertificationTypeOptionValue,
} from "./certification_helpers";
import DocumentSelector from "../../components/DocumentSelector";
import {useAbortable} from "../../../../hooks/abortable";

const NewCertification = memo(() => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const whoami = useQueryData({queryKey: ["whoAmI"]});

  const [name, setName] = useResetting(useState(""), isOpen);
  const [fileUpload, setFileUpload] = useResetting(useAbortable(useState<FileUpload | null>(null)), isOpen);
  const [certificationType, setCertificationType] = useResetting(
    useState<CertificationType | undefined>(undefined),
    isOpen,
  );
  const [dateGranted, setDateGranted] = useResetting(useState<ApiDateTime | undefined>(undefined), isOpen);
  const [dateOfExpiry, setDateOfExpiry] = useResetting(useState<ApiDateTime | undefined>(undefined), isOpen);
  const [auditorUrl, setAuditorUrl] = useResetting(useState(""), isOpen);
  const [auditReport, setAuditReport] = useResetting(useState<DocumentId | null>(null), isOpen);

  const [validationErrors, setValidationErrors] = useValidation(useResetting(useState({}), isOpen), {name});
  const fileUploadState = useAsyncOperation(fileUpload);

  const [saving, save] = useValidatedPromiseState(
    async () => {
      if (fileUploadState?.id !== "Uploaded") {
        // Not ready
        return;
      }

      const {document_id} = await api.vendorToolkit.documents.create({
        review_period: {type: "None"},
        sharing_classification: SharingClassification.Internal,
        name,
        category: DocumentCategory.Certification,
        owner_id: whoami.user_owner!.owner_id,
        authority: DocumentAuthority.File,
        file_id: fileUploadState.result.file_id,
        certification_type: certificationType,
        certification_date_granted: dateGranted,
        certification_date_expires: dateOfExpiry,
        certification_auditor_url: auditorUrl,
        certification_audit_report: auditReport ?? undefined,
      });

      router!.navigate(`/vendor-toolkit/library/certifications/${document_id}`);
      onClose();
    },
    [
      fileUploadState,
      name,
      whoami.user_owner,
      onClose,
      certificationType,
      dateGranted,
      dateOfExpiry,
      auditorUrl,
      auditReport,
    ],
    setValidationErrors,
  );

  return (
    <>
      <Button leftIcon={<Icon as={DocumentPlusIcon} />} onClick={onOpen} colorScheme="green">
        New certification
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent as="form" onSubmit={save}>
          <ModalHeader>Create new certification</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="8" mb="4">
              <FormControl isRequired isInvalid={validationErrors.name !== undefined}>
                <FormLabel>Certification name</FormLabel>
                <Input
                  onChange={e => setName(e.target.value)}
                  value={name}
                  isDisabled={saving.inProgress}
                  placeholder="e.g. ISO 27001"
                  autoFocus
                />
                {validationErrors.name && validationErrors.name[0] && (
                  <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
                )}
              </FormControl>

              <SingleFileUpload
                Cls={FileUpload}
                value={fileUpload}
                accept={SUPPORTED_DOCUMENT_FORMATS}
                onChange={setFileUpload}
              >
                {isDragActive => (
                  <HStack align="center" spacing={2}>
                    <Icon as={CloudArrowUpIcon} fontSize="xl" color={isDragActive ? "blue.500" : "gray.500"} />
                    <Text>Drag certification here</Text>
                  </HStack>
                )}
              </SingleFileUpload>

              <RequireAccountFeature feature={AccountFeature.TrustCenter}>
                <Divider />

                <Stack>
                  <Text fontSize="md" fontWeight={600} color="gray.500">
                    Additional information:
                  </Text>
                  <Stack spacing="8" p="4" border="1px solid" borderColor="gray.200" borderRadius={8}>
                    <FormControl>
                      <FormLabel>Certification type</FormLabel>
                      <MultiSelect
                        options={CERTIFICATION_TYPE_OPTIONS}
                        value={toCertificationTypeOptionValue(certificationType)}
                        onChange={v => setCertificationType(fromCertificationTypeOptionValue(v as unknown as string))}
                        single
                        spellCheck={false}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Date granted</FormLabel>
                      <HStack>
                        <DateUI date={dateGranted} onDateChange={setDateGranted} />
                        <Button size="xs" variant="outline" onClick={() => setDateGranted(undefined)}>
                          Unset
                        </Button>
                      </HStack>
                    </FormControl>

                    <FormControl>
                      <FormLabel>Date of expiry</FormLabel>
                      <HStack>
                        <DateUI date={dateOfExpiry} onDateChange={setDateOfExpiry} />
                        <Button size="xs" variant="outline" onClick={() => setDateOfExpiry(undefined)}>
                          Unset
                        </Button>
                      </HStack>
                    </FormControl>

                    <FormControl>
                      <FormLabel>Auditor URL</FormLabel>
                      <Input
                        placeholder="https://www.example.com"
                        value={auditorUrl}
                        onChange={e => setAuditorUrl(e.target.value)}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Audit report</FormLabel>
                      <DocumentSelector
                        value={auditReport}
                        onChange={setAuditReport}
                        filter={d => d.trust_center_visibility === TrustCenterAccess.Allowed}
                        showCategoryPrefix
                      />
                      <FormHelperText>Only documents visible in your Showcase can be selected.</FormHelperText>
                    </FormControl>
                  </Stack>
                  <Text fontSize="md" color="gray.500">
                    This information will be shown on your Showcase if you set the certification to be public. Only
                    fields you have set will be shown.
                  </Text>
                </Stack>
              </RequireAccountFeature>

              {saving.lastError ? (
                <Alert status="error" mt="12">
                  <AlertIcon boxSize="40px" />
                  <Box>
                    <AlertTitle fontSize="md">Error creating certification</AlertTitle>
                    <AlertDescription fontSize="md">{`${saving.lastError}`}</AlertDescription>
                  </Box>
                </Alert>
              ) : null}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing="3">
              <Button variant="ghost" onClick={onClose} isDisabled={saving.inProgress}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={saving.inProgress}
                isDisabled={validationErrors.name !== undefined || fileUploadState?.id !== "Uploaded"}
              >
                Create
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

export default NewCertification;
