import {CreateThirdPartyTag, ThirdPartyTagId, ThirdPartyTagMin} from "../../Types";
import {invalidateQueries} from "../../state";
import jsonApi from "../jsonApi";

export async function list(): Promise<ThirdPartyTagMin[]> {
  return await jsonApi.get<ThirdPartyTagMin[]>("/tprm/third_party_tags");
}

export async function create(tag: CreateThirdPartyTag) {
  const res = await jsonApi.post<"ok">("/tprm/third_party_tags", tag);
  await invalidateQueries([{queryKey: ["tprm", "thirdPartyTags"]}]);
  return res;
}

export async function updateTitle(tagId: ThirdPartyTagId, title: string) {
  const res = await jsonApi.post<"ok">(`/tprm/third_party_tags/${tagId}/title`, title);
  await invalidateQueries([{queryKey: ["tprm", "thirdPartyTags"]}]);
  return res;
}

export async function updateColor(tagId: ThirdPartyTagId, color: string) {
  const res = await jsonApi.post<"ok">(`/tprm/third_party_tags/${tagId}/color`, color);
  await invalidateQueries([{queryKey: ["tprm", "thirdPartyTags"]}]);
  return res;
}

export async function delete_(tagId: ThirdPartyTagId) {
  const res = await jsonApi.delete_<"ok">(`/tprm/third_party_tags/${tagId}`);
  await invalidateQueries([{queryKey: ["tprm", "thirdPartyTags"]}]);
  return res;
}
