import {Resolution, ResolutionMin, ResolutionType} from "../../../../../Types";
import * as _ from "lodash-es";

export type FormatResolutionLevelOpts = Partial<{
  capitalize: boolean;
}>;

export type ResolutionRenderInfo = {
  badge: {
    label: string;
    color: string;
  };

  description: React.ReactNode;
};

export function resolutionRenderInfo(resolution: ResolutionMin | Resolution): ResolutionRenderInfo {
  const count = "num_involved_facts" in resolution ? resolution.num_involved_facts : resolution.involved_facts.length;

  switch (resolution.resolution_type) {
    case ResolutionType.Contradiction:
      return {
        badge: {
          label: "Contradiction",
          color: "red",
        },
        description: `${count} contradicting fact${count !== 1 ? "s" : ""}`,
      };

    case ResolutionType.Recurrent:
      return {
        badge: {
          label: "Recurrent",
          color: "blue",
        },

        description: `${count} potentially recurrent fact${count !== 1 ? "s" : ""}`,
      };

    case ResolutionType.Sensitive:
      return {
        badge: {
          label: "Sensitive",
          color: "purple",
        },

        description: `${count} sensitive fact${count !== 1 ? "s" : ""}`,
      };

    case ResolutionType.Stale:
      return {
        badge: {
          label: "Stale",
          color: "orange",
        },

        description: `${count} stale fact${count !== 1 ? "s" : ""} expired`,
      };
  }
}

export function canResolveResolution(resolution: Resolution): boolean {
  switch (resolution.resolution_type) {
    case ResolutionType.Contradiction:
      return resolution.involved_facts.some(fact => fact.disabled != null || fact.deleted != null);
    default:
      return true;
  }
}

export function formatResolutionReason(reason: string): string {
  // Not all reasons end with punctuation, so add a period if it's missing.
  if (!reason.match(/[.!?]$/)) {
    reason += ".";
  }

  return reason;
}
