import api from "../../../api";
import {LayerType, PaginationArgs} from "../../../Types";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["vendorToolkit", "tasks", "count", param<LayerType>("layerType")], async context => {
    return await api.vendorToolkit.tasks.count(context.queryKey[3]);
  }),
  typedQuery(
    ["vendorToolkit", "tasks", param<LayerType>("layerType"), param<PaginationArgs>("args")],
    async context => {
      return await api.vendorToolkit.tasks.list(context.queryKey[2], context.queryKey[3]);
    },
  ),
  typedQuery(["vendorToolkit", "gettingStartedChecklist"], async () => {
    return await api.vendorToolkit.tasks.getGettingStartedChecklist();
  }),
];
