import {useEffect, useState} from "react";
import {IAbortable} from "./abortable";

export abstract class AsyncOperation<TState> {
  #state: TState;
  #subscribers: (() => void)[];

  protected constructor(state: TState) {
    this.#state = state;
    this.#subscribers = [];
    setTimeout(async () => {
      try {
        await this.run();
      } catch (error) {
        this.state = this.errorState(error);
      }
    }, 0);
  }

  get state(): TState {
    return this.#state;
  }

  protected set state(state: TState) {
    this.#state = state;
    this.#notify();
  }

  #notify() {
    for (const subscriber of this.#subscribers) {
      setTimeout(subscriber, 0);
    }
  }

  subscribe(cb: () => void): () => void {
    this.#subscribers.push(cb);
    return () => {
      const idx = this.#subscribers.indexOf(cb);
      this.#subscribers.splice(idx, 1);
    };
  }

  protected abstract run(): Promise<void>;
  protected abstract errorState(error: unknown): TState;
}

export abstract class AbortableAsyncOperation<TState> extends AsyncOperation<TState> implements IAbortable {
  #controller: AbortController;
  protected constructor(state: TState, controller?: AbortController) {
    super(state);
    this.#controller = controller ?? new AbortController();
  }
  protected get signal(): AbortSignal {
    return this.#controller.signal;
  }
  abort(reason?: any): void {
    this.#controller.abort(reason);
  }
}

export function useAsyncOperation<TState>(op: AsyncOperation<TState>): TState;
export function useAsyncOperation<TState>(op: AsyncOperation<TState> | null): TState | null;
export function useAsyncOperation<TState>(op: AsyncOperation<TState> | null): TState | null {
  const [state, setState] = useState(op?.state);
  useEffect(() => {
    setState(op?.state);
    return op?.subscribe(() => setState(op?.state));
  }, [op]);
  return state ?? null;
}

export function useAsyncOperations<TState>(ops: readonly AsyncOperation<TState>[]): TState[] {
  const [states, setStates] = useState(ops.map(op => op.state));
  useEffect(() => {
    setStates(ops.map(op => op.state));
    const update = () => {
      setStates(ops.map(op => op.state));
    };
    const unsubscribes = ops.map(op => op.subscribe(update));
    return () => {
      unsubscribes.forEach(unsubscribe => unsubscribe());
    };
  }, [ops]);
  return states;
}
