import {
  Box,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";
import {memo, useState} from "react";
import api from "../../../api";
import {EnvelopeIcon} from "@heroicons/react/24/outline";
import {RegisteredUser} from "../../../Types";
import {useResetting} from "../../../hooks/resetting";
import {useValidation, useValidatedPromiseState} from "../../../hooks/validationState";

export const InviteUserModal = memo(
  ({
    initialName,
    initialEmail,
    isOpen,
    onClose,
    onInvite,
  }: {
    initialName?: string;
    initialEmail?: string;
    isOpen: boolean;
    onClose: () => void;
    onInvite?: (user: RegisteredUser) => void;
  }) => {
    const [name, setName] = useResetting(useState(initialName ?? ""), isOpen);
    const [email, setEmail] = useResetting(useState(initialEmail ?? ""), isOpen);
    const [validationErrors, setValidationErrors] = useValidation(useResetting(useState({}), isOpen), {
      name,
      email,
    });
    const [inviting, invite] = useValidatedPromiseState(
      async () => {
        const user = await api.users.invite({
          name,
          email,
        });
        onInvite && onInvite(user);
        onClose();
      },
      [name, email, onClose, onInvite],
      setValidationErrors,
    );

    return (
      <>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent as="form" onSubmit={invite}>
            <ModalHeader>Invite new user</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing="8" mb="4">
                <FormControl isRequired>
                  <FormLabel>Name</FormLabel>
                  <Input onChange={e => setName(e.target.value)} value={name} placeholder="Jane Smith" autoFocus />
                </FormControl>
                <FormControl isRequired isInvalid={validationErrors.email !== undefined}>
                  <FormLabel>Email</FormLabel>
                  <Input onChange={e => setEmail(e.target.value)} value={email} placeholder="jane@example.com" />
                  {validationErrors.email && validationErrors.email[0] && (
                    <FormErrorMessage>{validationErrors.email[0].message}</FormErrorMessage>
                  )}
                </FormControl>

                {inviting.lastError ? (
                  <Alert status="error" mt="12">
                    <AlertIcon boxSize="40px" />
                    <Box>
                      <AlertTitle fontSize="md">Error inviting user</AlertTitle>
                      <AlertDescription fontSize="md">{`${inviting.lastError}`}</AlertDescription>
                    </Box>
                  </Alert>
                ) : null}
              </Stack>
            </ModalBody>

            <ModalFooter>
              <HStack spacing="3">
                <Button variant="ghost" onClick={onClose} isDisabled={inviting.inProgress}>
                  Cancel
                </Button>
                <Button colorScheme="blue" type="submit" isLoading={inviting.inProgress}>
                  Send invitation
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  },
);

const InviteUser = memo(() => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <Button leftIcon={<Icon as={EnvelopeIcon} />} onClick={onOpen} colorScheme="green">
        Invite user
      </Button>
      {isOpen && <InviteUserModal isOpen={isOpen} onClose={onClose} />}
    </>
  );
});

export default InviteUser;
