import {useResetting} from "../../../../hooks/resetting.ts";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import api from "../../../../api/index.ts";
import {MultiFileUpload} from "../../../../components/fileUploads/MultiFileUpload.tsx";
import {FileUpload, useFileUploads} from "../../../../hooks/fileUpload.ts";
import {useValidation, useValidatedPromiseState} from "../../../../hooks/validationState.ts";
import {RateUsModal} from "./RateUsModal.tsx";
import {QuestionnaireId} from "../../../../Types.ts";
import {useState} from "react";
import {useAbortable} from "../../../../hooks/abortable";

const ExportPortalModal = ({
  isOpen,
  onClose,
  instructions,
  initialBody,
  questionnaireId,
}: {
  isOpen: boolean;
  onClose: () => void;
  instructions?: string;
  initialBody?: string;
  questionnaireId: QuestionnaireId;
}) => {
  const {isOpen: isRateUsOpen, onClose: closeRateUs, onOpen: openRateUs} = useDisclosure();

  const [url, setUrl] = useResetting(useState(""), isOpen);
  const [username, setUsername] = useResetting(useState(""), isOpen);
  const [password, setPassword] = useResetting(useState(""), isOpen);
  const [body, setBody] = useResetting(useState(initialBody ? initialBody : ""), isOpen);
  const [attachmentUploads, setAttachmentUploads] = useResetting(useAbortable(useState<FileUpload[]>([])), isOpen);
  const [attachments, isUploading] = useFileUploads(attachmentUploads);
  const [validationErrors, setValidationErrors] = useValidation(useResetting(useState({}), isOpen), {url});

  const [sending, send] = useValidatedPromiseState(
    async () => {
      const subject = "Export questionnaire request (portal)";
      const fullBody = `\n${body}\n\nURL: ${url}\nLogin: ${username}\n${password}\n\nReference: ${questionnaireId}`;

      await api.supportEmails.send({
        action: "export-portal",
        subject,
        body: fullBody,
        attachments: attachments.map(a => a.file_id),
        cc_user: true,
      });
      await api.vendorToolkit.questionnaires.createOriginalSource(questionnaireId, url);
      openRateUs();
      onClose();
    },
    [body, url, username, password, questionnaireId, attachments, onClose, openRateUs],
    setValidationErrors,
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent as="form" onSubmit={send}>
          <ModalHeader>Export to portal</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Text fontSize="md" fontStyle="italic">
                {"Enter the URL to the portal for the questionnaire you want to export to, along with the credentials required to access it. " +
                  (instructions ? instructions : "")}
              </Text>
              <FormControl isRequired isInvalid={validationErrors.url !== undefined}>
                <FormLabel>Portal URL</FormLabel>
                <Input type="url" onChange={e => setUrl(e.target.value)} />
                {validationErrors.url && validationErrors.url[0] && (
                  <FormErrorMessage>{validationErrors.url[0].message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl>
                <FormLabel>Login username or email</FormLabel>
                <Input type="text" onChange={e => setUsername(e.target.value)} />
              </FormControl>
              <FormControl>
                <FormLabel>Password</FormLabel>
                <Input type="password" onChange={e => setPassword(e.target.value)} />
              </FormControl>
              <FormControl>
                <FormLabel mb={1}>Message</FormLabel>
                <Text mb={1} fontSize="sm" fontStyle="italic">
                  Provide any additional information required to access the portal, or any other instructions regarding
                  the export.
                </Text>
                <Textarea defaultValue={initialBody ? initialBody : ""} onChange={e => setBody(e.target.value)} />
              </FormControl>
              <FormControl>
                <FormLabel>Attachments (optional)</FormLabel>
                <MultiFileUpload Cls={FileUpload} value={attachmentUploads} onChange={setAttachmentUploads} />
              </FormControl>
              {sending.lastError ? (
                <Alert status="error" mt="12">
                  <AlertIcon boxSize="40px" />
                  <Box>
                    <AlertTitle fontSize="md">Error sending export request</AlertTitle>
                    <AlertDescription fontSize="md">{`${sending.lastError}`}</AlertDescription>
                  </Box>
                </Alert>
              ) : null}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing={3}>
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={sending.inProgress}
                isDisabled={validationErrors.url !== undefined || isUploading || sending.inProgress}
              >
                Send
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <RateUsModal isOpen={isRateUsOpen} onClose={closeRateUs} questionnaireId={questionnaireId} />
    </>
  );
};

export default ExportPortalModal;
