import {Box, Button, Divider, Heading, HStack, Icon, Menu, MenuItem, Stack, Text} from "@chakra-ui/react";
import Page from "../../../../components/Page";
import {AssessmentId, ControlId, ThirdPartyId} from "../../../../Types";
import {useParams} from "react-router-dom";
import {useQueryData} from "../../../../state";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid";
import {useMemo} from "react";
import {CheckIcon} from "@heroicons/react/24/solid";
import OwnerSelector from "../../../../components/OwnerSelector";
import RisksTable from "../../ThirdParty/components/RisksTable";
import Evidence from "./Evidence";
import ControlList from "./ControlList";
import TagMenuButton from "../../../../components/TagMenuButton";
import PortalMenuList from "../../../../components/PortalMenuList";

const ControlPage = () => {
  const {assessmentId, thirdPartyId, controlId} = useParams() as {
    assessmentId: AssessmentId;
    thirdPartyId: ThirdPartyId;
    controlId: ControlId;
  };
  const thirdParty = useQueryData({queryKey: ["tprm", "thirdParties", thirdPartyId]});
  const assessment = useQueryData({queryKey: ["tprm", "assessment", assessmentId]});
  const risks = useQueryData({queryKey: ["tprm", "assessments", "risks", assessmentId]}).filter(
    r => r.source.control_id == controlId,
  );

  const controls = useMemo(() => assessment.framework_version.control_groups.flatMap(cg => cg.controls), [assessment]);
  const control = useMemo(() => controls.find(c => c.control_id == controlId)!, [controlId, controls]);

  const title = `${thirdParty.name} - ${assessment.framework_version.framework.name} - ${controlId}`;
  return (
    <Page title={title}>
      <HStack align="stretch" gap={0}>
        <ControlList />
        <Stack spacing={0} divider={<Divider borderColor="gray.200" />} flex={3}>
          <HStack bg="gray.50" p={4} justify="space-between">
            <HStack>
              <Button size="sm" leftIcon={<Icon as={ChevronLeftIcon} />}>
                Previous
              </Button>
              <Button size="sm" rightIcon={<Icon as={ChevronRightIcon} />}>
                Next
              </Button>
            </HStack>
            <HStack>
              <Button
                size="sm"
                rightIcon={<Icon as={ChevronRightIcon} />}
                leftIcon={<Icon as={CheckIcon} />}
                colorScheme="green"
              >
                Complete
              </Button>
            </HStack>
          </HStack>
          <Stack p={6}>
            <HStack>
              {control.control_number && (
                <Heading size="sm" pt="2px" color="gray.500">
                  {control.control_number}
                </Heading>
              )}
              <Heading size="md">{control.title}</Heading>
            </HStack>
            <Text color="gray.600" fontSize="md">
              {control.description}
            </Text>
            <HStack pt={2}>
              <Menu>
                <TagMenuButton colorScheme="green">
                  <Text fontSize="md" fontWeight="semibold">
                    Control met
                  </Text>
                </TagMenuButton>
                <PortalMenuList fontSize="md">
                  <MenuItem>Control met</MenuItem>
                  <MenuItem>Control not met</MenuItem>
                </PortalMenuList>
              </Menu>
              <OwnerSelector />
            </HStack>
          </Stack>
          <Stack p={6} gap={6}>
            <Heading size="md" color="gray.600">
              Evaluation
            </Heading>
            <Box border="1px" borderColor="gray.200" borderRadius="md" overflow="hidden">
              <RisksTable onAdd={console.log} risks={risks} />
            </Box>
          </Stack>
          <Evidence controls={controls} control={control} />
        </Stack>
      </HStack>
    </Page>
  );
};

export default ControlPage;
