import {
  Button,
  Center,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
} from "@chakra-ui/react";

type PaletteEntry = {
  value: string;
  label: string;
};

/**
 * Matches Chakra UI's color scheme palette.
 */
const PALETTE = [
  {value: "gray", label: "Gray"},
  {value: "red", label: "Red"},
  {value: "orange", label: "Orange"},
  {value: "yellow", label: "Yellow"},
  {value: "green", label: "Green"},
  {value: "teal", label: "Teal"},
  {value: "blue", label: "Blue"},
  {value: "cyan", label: "Cyan"},
  {value: "purple", label: "Purple"},
  {value: "pink", label: "Pink"},
] as const satisfies PaletteEntry[];

function colorBackground(value: string): string {
  return `${value}.200`;
}

function colorForeground(value: string): string {
  return `${value}.800`;
}

export type ColorSchemePickerProps = {
  value: string;
  onChange: (newValue: string) => void;
};

export function ColorSchemePicker({value, onChange}: ColorSchemePickerProps) {
  const label = PALETTE.find(x => x.value === value)?.label ?? value;

  return (
    <Popover variant="picker">
      <PopoverTrigger>
        <Button
          aria-label={label}
          colorScheme={value}
          height="22px"
          width="22px"
          padding={0}
          minWidth="unset"
          rounded="sm"
        ></Button>
      </PopoverTrigger>
      <PopoverContent width="170px" overflow="hidden">
        <PopoverArrow bg={colorBackground(value)} />
        <PopoverCloseButton color={colorForeground(value)} />
        <PopoverHeader height="100px" backgroundColor={colorBackground(value)} color={colorForeground(value)}>
          <Center height="100%" fontWeight="500" fontSize="md">
            {label}
          </Center>
        </PopoverHeader>
        <PopoverBody height="120px">
          <SimpleGrid columns={5} spacing={2}>
            {PALETTE.map(paletteEntry => (
              <Button
                key={paletteEntry.value}
                aria-label={paletteEntry.label}
                colorScheme={paletteEntry.value}
                height="22px"
                width="22px"
                padding={0}
                minWidth="unset"
                rounded="sm"
                onClick={() => {
                  onChange(paletteEntry.value);
                }}
              ></Button>
            ))}
          </SimpleGrid>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
