import {Stack, Text} from "@chakra-ui/react";
import {withSuspense} from "../../../../state/withSuspense";
import {useQueryData} from "../../../../state";
import {FactRow} from "../../Library/Facts/FactRow";
import {QuestionId} from "../../../../Types";

const QuestionUsedFacts = withSuspense(({questionId}: {questionId: QuestionId}) => {
  const usedFacts = useQueryData({queryKey: ["vendorToolkit", "questionUsedFacts", questionId]});
  return usedFacts.length > 0 ? (
    <Stack spacing={4}>
      <Stack spacing={0}>
        <Text fontWeight="600">Used facts</Text>
        <Text fontSize="md" color="gray.500">
          These are facts the AI thought were relevant when attempting to answer this question.
        </Text>
      </Stack>
      <Stack
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        maxH="400"
        overflowY="auto"
        overflowX="hidden"
        spacing={0}
        css={{
          scrollbarWidth: "thin",
        }}
      >
        {usedFacts.map(usedFact => (
          <FactRow
            fact={usedFact.fact}
            key={usedFact.fact.fact_id}
            cosineDistance={
              usedFact.answer_cosine_distance != null
                ? usedFact.answer_cosine_distance + usedFact.question_cosine_distance
                : undefined
            }
          />
        ))}
      </Stack>
    </Stack>
  ) : null;
});

export default QuestionUsedFacts;
