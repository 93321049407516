import {InformationCircleIcon} from "@heroicons/react/24/solid";
import {RouteTab} from "../../../../components/RouterTabs/index.tsx";
import SuppliersPage from "./SuppliersPage.tsx";

export default {
  title: "Suppliers",
  path: "suppliers",
  icon: InformationCircleIcon,
  element: <SuppliersPage />,
} satisfies RouteTab;
