import {useToken} from "@chakra-ui/react";
import {Questionnaire, QuestionStatus} from "../../../../../Types.ts";
import {ProgressChart} from "../../../components/ProgressChart.tsx";
import {getResponseLayer, useLayerType} from "../../../../../hooks/layerType.ts";
import {useFilterState} from "../../../../../hooks/filterState.ts";
import {QuestionFilters} from "../../../../components/Filters/index.tsx";
import {DEFAULT_FILTERS} from "../../../Questions/index.tsx";
import {router} from "../../../../../router/index.tsx";
import {useCallback} from "react";
import {useQueriesData} from "../../../../../state";
import questionKeys from "../../../../../utils/questionKeys";

const ProgressPie = ({questionnaire}: {questionnaire: Questionnaire}) => {
  const [layerType] = useLayerType();
  const [completedColor, reviewColor, respondColor, automatingColor] = useToken("colors", [
    "green.600",
    "green.300",
    "gray.300",
    "purple.300",
  ]);
  const questions = useQueriesData({queries: questionKeys(questionnaire)});

  const {setFilter} = useFilterState<QuestionFilters>("questionFilters", DEFAULT_FILTERS);

  const redirectFilter = useCallback(
    async (label: string) => {
      setFilter("statuses", [label]);
      return router!.navigate("../questions");
    },
    [setFilter],
  );

  const allResponseLayers = questions.map(q => getResponseLayer(q.response_layers, layerType));
  return (
    <ProgressChart
      title="Questionnaire progress"
      groups={[
        {
          label: "Complete",
          value: allResponseLayers.filter(q => q.status === QuestionStatus.Complete).length,
          color: completedColor,
        },
        {
          label: "Review",
          value: allResponseLayers.filter(q => q.status === QuestionStatus.Review).length,
          color: reviewColor,
        },
        {
          label: "Respond",
          value: allResponseLayers.filter(q => q.status === QuestionStatus.Respond).length,
          color: respondColor,
        },
        {
          label: "Automating",
          value: allResponseLayers.filter(q => q.status === QuestionStatus.Automating).length,
          color: automatingColor,
        },
      ]}
      onGroupClick={redirectFilter}
    />
  );
};

export default ProgressPie;
