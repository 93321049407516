import {Table, Td, Tr, Thead, Tbody, Box, Heading} from "@chakra-ui/react";
import {LayerType, Questionnaire} from "../../../../../Types";
import {categorizeQuestionAiResult} from "./ai";
import groupBy from "../../../../../utils/groupBy";
import {useMemo} from "react";
import {getResponseLayer, useLayerType} from "../../../../../hooks/layerType";
import {useQueriesData} from "../../../../../state";
import questionKeys from "../../../../../utils/questionKeys";

const AiReviews = ({questionnaire}: {questionnaire: Questionnaire}) => {
  const [layerType] = useLayerType();
  const questions = useQueriesData({queries: questionKeys(questionnaire)});
  const aiResults = useMemo(() => {
    const aiResults = groupBy(
      questions
        .map(q => ({
          currentLayer: getResponseLayer(q.response_layers, layerType),
          aiLayer: getResponseLayer(q.response_layers, LayerType.AI),
        }))
        .filter(x => x.currentLayer.ai_attempted),
      categorizeQuestionAiResult,
    );
    aiResults.sort((a, b) => b.items.length - a.items.length);
    return aiResults;
  }, [questions, layerType]);
  return (
    <Box m={8}>
      <Heading m={4} size="md">
        AI Reviews
      </Heading>
      <Table>
        <Thead fontWeight="semibold">
          <Tr>
            <Td>Count</Td>
            <Td>Result</Td>
            <Td>Reviews</Td>
          </Tr>
        </Thead>
        <Tbody>
          {aiResults.map(row => (
            <Tr key={row.key}>
              <Td>{row.items.length}</Td>
              <Td>{row.key}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default AiReviews;
