import {Navigate, Route} from "react-router-dom";
import ControlPage from "./ControlPage.tsx";
import Crumb from "./Crumb.tsx";

export default (
  <Route path="controls" handle={{crumb: "Controls"}}>
    <Route path=":controlId" Component={ControlPage} handle={{Crumb}} />;
    <Route index element={<Navigate replace to="../" />} />
  </Route>
);
