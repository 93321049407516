import api from "../../../api";
import {ScopeIdOrGlobal} from "../../../Products/VendorToolkit/Library/FactLibrary/utility/hierarchy";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["vendorToolkit", "scopes"], async () => {
    return await api.vendorToolkit.scopes.list();
  }),
  typedQuery(["vendorToolkit", "scopes", param<ScopeIdOrGlobal>("scopeId"), "owner"], async context => {
    return await api.vendorToolkit.scopes.getOwner(context.queryKey[2]);
  }),
  typedQuery(["vendorToolkit", "scopeAxes"], async () => {
    return await api.vendorToolkit.scopes.listAxes();
  }),
];
