import {ExclamationTriangleIcon} from "@heroicons/react/20/solid";
import {RouteTab} from "../../../../components/RouterTabs/index.tsx";
import RisksPage from "./RisksPage";
import Title from "./Title.tsx";

export default {
  title: <Title />,
  path: "risks",
  icon: ExclamationTriangleIcon,
  element: <RisksPage />,
} satisfies RouteTab;
