import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuOptionGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import {useCallback, useContext, useState} from "react";
import {ArrowRightOnRectangleIcon, CheckCircleIcon, ChevronDownIcon, XCircleIcon} from "@heroicons/react/20/solid";
import {ActionBar} from "../../components/ActionBar";
import {Question} from "../components/Question";
import {
  QuestionStatus,
  Questionnaire,
  BulkQuestionUpdateItem,
  CreateQuestion,
  LayerType,
  QuestionMin,
} from "../../Types";
import {useNavigate, useRevalidator} from "react-router-dom";
import api from "../../api";
import {ImportedQuestion as ImportedQuestionT} from "platformed-browser-api/platformed";
import {extensionContext, extensionClient} from "../provider";
import {usePromiseState} from "../../hooks/promiseState";
import PortalMenuList from "../../components/PortalMenuList";

const OverwriteButton = ({
  question,
  updateQuestion,
}: {
  question: ImportedQuestionT;
  updateQuestion: (question: ImportedQuestionT) => void;
}) => (
  <Menu isLazy>
    <MenuButton
      as={Button}
      color="orange.500"
      rounded="none"
      size="sm"
      variant="ghost"
      rightIcon={<Icon as={ChevronDownIcon} />}
    >
      {question.overrideExisting ? "Overwrite" : "Skip"}
    </MenuButton>
    <PortalMenuList fontSize="md">
      <MenuOptionGroup
        value={question.overrideExisting ? "overwrite" : "skip"}
        onChange={val => updateQuestion({...question, overrideExisting: val === "overwrite"})}
      >
        <MenuItemOption value="overwrite">Overwrite existing question</MenuItemOption>
        <MenuItemOption value="skip">Skip</MenuItemOption>
      </MenuOptionGroup>
    </PortalMenuList>
  </Menu>
);

const ImportedQuestion = ({
  question,
  updateQuestion,
  deleteQuestion,
  existing,
}: {
  question: ImportedQuestionT;
  updateQuestion: (question: ImportedQuestionT) => void;
  deleteQuestion: () => void;
  existing: boolean;
}) => {
  const [originalNumber] = useState(question.question_number);
  const [originalText] = useState(question.text);
  const [number, setNumber] = useState(question.question_number);
  const [text, setText] = useState(question.text);
  const [editable, setEditable] = useState(false);

  const reset = useCallback(() => {
    updateQuestion({question_number: originalNumber, text: originalText});
    setNumber(originalNumber);
    setText(originalText);
    setEditable(false);
  }, [originalNumber, originalText, updateQuestion]);

  const update = () => {
    updateQuestion({text, question_number: number});
    setEditable(false);
  };

  const showReset = text !== originalText || number !== originalNumber;

  return (
    <Question
      skip={existing && question.overrideExisting !== true}
      editable={editable}
      number={number}
      text={text}
      setNumber={setNumber}
      setText={setText}
      leftActions={existing ? <OverwriteButton question={question} updateQuestion={updateQuestion} /> : undefined}
      rightActions={
        editable ? (
          <ButtonGroup size="sm" isAttached variant="ghost">
            {showReset && (
              <Button flex={1} color="red.500" rounded="none" onClick={reset}>
                Reset
              </Button>
            )}
            <Button flex={1} colorScheme="green" variant="solid" rounded="none" onClick={update}>
              Update
            </Button>
          </ButtonGroup>
        ) : (
          <ButtonGroup size="sm" isAttached variant="ghost">
            <Button flex={1} roundedTop="none" color="gray.500" rounded="none" onClick={() => setEditable(true)}>
              Edit
            </Button>
            <Button flex={1} roundedTop="none" color="gray.500" onClick={deleteQuestion}>
              Delete
            </Button>
          </ButtonGroup>
        )
      }
    />
  );
};

const XIcon = () => <Icon as={XCircleIcon} mr={2} mt="1px" fontSize="2xl" color="gray.200" />;
const CheckIcon = () => <Icon as={CheckCircleIcon} mt="1px" mr={2} fontSize="2xl" color="green.400" />;

function base64toBlob(base64Data: string, contentType: string) {
  contentType = contentType || "";
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, {type: contentType});
}

const Import = ({
  questionnaire,
  existingQuestionsByNumber,
}: {
  questionnaire: Questionnaire;
  existingQuestionsByNumber: {[n in string]: QuestionMin};
}) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [questions, setQuestions] = useState<ImportedQuestionT[]>([]);
  const {fingerprint} = useContext(extensionContext);
  const navigate = useNavigate();
  const {revalidate} = useRevalidator();

  const save = async () => {
    setSaveLoading(true);

    const newQuestions: CreateQuestion[] = questions
      .filter(q => q.question_number === undefined || !(q.question_number in existingQuestionsByNumber))
      .map(q => ({
        ...q,
        response_layer: {
          status: QuestionStatus.Respond,
        },
      }));

    const bulkUpdates: BulkQuestionUpdateItem[] = questions
      .filter(
        q => q.question_number !== undefined && q.overrideExisting && q.question_number in existingQuestionsByNumber,
      )
      .map(q => ({
        question_id: existingQuestionsByNumber[q.question_number as string].question_id,
        action: {type: "SetText", content: q.text},
      }));

    if (newQuestions.length > 0) {
      await api.vendorToolkit.questionnaires.importQuestions(questionnaire.questionnaire_id, {
        questions: [],
        sections: [
          {
            title: "Untitled",
            description: "",
            questions: newQuestions,
          },
        ],
      });
    }
    if (bulkUpdates.length > 0) {
      await api.vendorToolkit.questions.bulkUpdate({items: bulkUpdates, layer_type: LayerType.External});
    }

    if (fingerprint) {
      await api.vendorToolkit.questionnaires.createOriginalSource(questionnaire.questionnaire_id, fingerprint.url);
    }

    revalidate();
    navigate("../");

    setSaveLoading(false);
    setQuestions([]);
  };

  const doImport = async () => {
    setImportLoading(true);
    const questions = await extensionClient.request("getQuestions");
    setQuestions(questions);
    setImportLoading(false);
  };

  const updateQuestion = (i: number) => (question: ImportedQuestionT) => {
    const newQuestions = [...questions];
    newQuestions[i] = question;
    setQuestions(newQuestions);
  };

  const deleteQuestion = (i: number) => () => {
    const newQuestions = [...questions];
    newQuestions.splice(i, 1);
    setQuestions(newQuestions);
  };

  const [downloadingSnapshot, downloadSnapshot] = usePromiseState(async () => {
    const snapshotBase64 = await extensionClient.request("getPageSnapshotAsBase64");
    const url = URL.createObjectURL(await base64toBlob(snapshotBase64, "application/zip"));
    window.open(url, "_blank");
  }, []);

  const unsupportedProvider = !fingerprint;
  const unsupportedPage = !fingerprint?.identifier;

  return (
    <>
      <ActionBar px={4}>
        <Flex justifyContent="space-between" flex="1">
          <Button
            colorScheme={questions.length === 0 ? "purple" : "gray"}
            leftIcon={<Icon as={ArrowRightOnRectangleIcon} />}
            isLoading={importLoading}
            onClick={doImport}
            isDisabled={unsupportedPage || saveLoading}
          >
            {questions.length === 0 ? "Link & Import" : "Re-import"}
          </Button>
          <Button
            colorScheme="green"
            rightIcon={<Icon as={ArrowRightOnRectangleIcon} />}
            isDisabled={questions.length === 0 || importLoading}
            isLoading={saveLoading}
            onClick={save}
          >
            Save questions
          </Button>
        </Flex>
      </ActionBar>
      {questions.length > 0 ? (
        <Stack spacing={0} flex={1} overflow="auto" bg="white" divider={<Divider borderColor="gray.300" />}>
          {questions.map((q, i) => (
            <ImportedQuestion
              question={q}
              key={q.text}
              updateQuestion={updateQuestion(i)}
              deleteQuestion={deleteQuestion(i)}
              existing={q.question_number !== undefined && q.question_number in existingQuestionsByNumber}
            />
          ))}
        </Stack>
      ) : (
        <Flex flex={1} justify="center" align="flex-start" pt={24} fontSize="md">
          <Stack spacing={4}>
            <Flex align="flex-start" color={unsupportedProvider ? "gray.400" : "gray.500"}>
              {unsupportedProvider ? <XIcon /> : <CheckIcon />}
              <Box>
                {fingerprint && (
                  <Box fontWeight="600" color="gray.500">
                    {fingerprint.providerType}
                  </Box>
                )}
                <Box>{unsupportedProvider ? "Unsupported provider" : "Supported provider"}</Box>
              </Box>
            </Flex>
            <Flex align="flex-start" color={unsupportedPage ? "gray.400" : "gray.500"}>
              {unsupportedPage ? <XIcon /> : <CheckIcon />}
              {unsupportedPage ? "Unsupported page" : "Supported page"}
            </Flex>
            <Button isLoading={downloadingSnapshot.inProgress} onClick={downloadSnapshot} colorScheme="blue">
              Download snapshot
            </Button>
            <Text>{`${downloadingSnapshot.lastError}`}</Text>
          </Stack>
        </Flex>
      )}
    </>
  );
};

export default Import;
