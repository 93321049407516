import {Box, Button, Flex, HStack, Heading, Icon, SimpleGrid, Stack, Switch, Text} from "@chakra-ui/react";
import {Link as RouterLink, useOutletContext} from "react-router-dom";
import {MitigationStatus, Risk, ThirdParty, AssessmentMin} from "../../../../Types";
import {useQueryData} from "../../../../state";
import {useState} from "react";
import RisksTable from "../components/RisksTable";
import Assessment from "../../components/Assessment";
import {ActionBar} from "../../../../Extension/components/ActionBar";
import {ChevronLeftIcon} from "@heroicons/react/24/solid";
import ReportHeader from "./components/ReportHeader";

const Letter = ({color, letter, percentage}: {color: string; letter: string; percentage: number}) => (
  <Stack gap={1} align="center" h="full" mx="-5px">
    <Flex
      bg={`${color}.100`}
      w={6}
      h={6}
      align="center"
      justify="center"
      color={`${color}.500`}
      fontWeight="bold"
      border="1px"
      borderColor={`${color}.200`}
      rounded="md"
    >
      {letter}
    </Flex>
    <Box w="1px" flex={1} minH="30px" borderLeft="1px dashed" borderColor="gray.200" />
    <Text fontSize="sm" color="gray.500" fontWeight="semibold">
      {percentage}%
    </Text>
  </Stack>
);

const DetailedReport = () => {
  const [showMitigated, setShowMitigated] = useState(false);

  const thirdParty: ThirdParty = useOutletContext();
  const assessments: AssessmentMin[] = useQueryData({
    queryKey: ["tprm", "thirdPartyAssessments", thirdParty.third_party_id],
  });

  const risks: Risk[] = useQueryData({
    queryKey: ["tprm", "thirdParties", "risks", thirdParty.third_party_id],
  }).filter(r => showMitigated || r.management.status != MitigationStatus.MITIGATED);

  return (
    <>
      <ActionBar actionButtons={<Button colorScheme="green">Download as PDF</Button>}>
        <Button as={RouterLink} to=".." leftIcon={<Icon as={ChevronLeftIcon} />}>
          Back
        </Button>
      </ActionBar>
      <ReportHeader name="Detailed Report" />
      <Stack spacing={12} p={6}>
        <Stack spacing={4}>
          <Heading size="sm" fontWeight="semibold">
            Overall Risk
          </Heading>
          <HStack p={6} gap={6} border="1px" borderColor="gray.200" rounded="lg">
            <Stack align="flex-start" pr={16}>
              <Text fontWeight="semibold" color="gray.500">
                Risk Score
              </Text>
              <Heading>{thirdParty.score}%</Heading>
              <Heading
                size="sm"
                color="blue.500"
                bg="blue.100"
                p={1}
                px={2}
                border="1px"
                rounded="md"
                borderColor="blue.200"
              >
                Grade A
              </Heading>
            </Stack>
            <HStack h={48} flex={1} gap={0} position="relative" py={8}>
              <Box position="absolute" top={6} left="7px" right="10px" bottom={6}>
                <Box
                  w="1px"
                  h="full"
                  borderLeft="1px dashed"
                  borderColor="gray.700"
                  left={`${thirdParty.score}%`}
                  position="absolute"
                >
                  <Flex position="absolute" left="-100px" right="-100px" bottom="-18px" mb={-2} justify="center">
                    <Box bg="gray.700" p={1} color="white" fontSize="sm" rounded="md" fontWeight="semibold">
                      {thirdParty.name}
                    </Box>
                  </Flex>
                </Box>
              </Box>
              <Letter color="red" letter="F" percentage={0} />
              <Box flex={1} h={2} rounded="sm" minW="20px" bg="red.500" />
              <Letter color="orange" letter="E" percentage={20} />
              <Box flex={1} h={2} rounded="sm" minW="20px" bg="orange.500" />
              <Letter color="orange" letter="D" percentage={40} />
              <Box flex={1} h={2} rounded="sm" minW="20px" bg="yellow.500" />
              <Letter color="yellow" letter="C" percentage={60} />
              <Box flex={1} h={2} rounded="sm" minW="20px" bg="green.500" />
              <Letter color="green" letter="B" percentage={80} />
              <Box flex={1} h={2} rounded="sm" minW="20px" bg="blue.500" />
              <Letter color="blue" letter="A" percentage={100} />
            </HStack>
          </HStack>
        </Stack>
        <Stack spacing={4}>
          <Heading size="sm" fontWeight="semibold">
            Frameworks Assessed
          </Heading>
          <SimpleGrid spacing={8} columns={4} alignItems="flex-start">
            {assessments.map(assessment => (
              <Assessment assessment={assessment} key={assessment.assessment_id} />
            ))}
          </SimpleGrid>
        </Stack>
        <Stack spacing={4}>
          <HStack justify="space-between">
            <Heading size="sm" fontWeight="semibold">
              Consolidated Risks
            </Heading>
            <HStack fontSize="sm" color="gray.500" fontWeight="semibold">
              <Text>Show Mitigated</Text>
              <Switch isChecked={showMitigated} onChange={() => setShowMitigated(!showMitigated)} />
            </HStack>
          </HStack>
          <Box border="1px" borderColor="gray.200" rounded="md">
            <RisksTable borderY="none" risks={risks} />
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default DetailedReport;
