import {ReactNode, useContext} from "react";
import {modalContext} from "./context";
import {ModalProps} from "@chakra-ui/react";

export type ModalOptions = Omit<ModalProps, "isOpen" | "onClose" | "children">;

export function useModalContext() {
  return useContext(modalContext);
}

const modalProps = Symbol("modalProps");
export const MODAL_OPTIONS: {[modalId: ModalId<any>]: ModalOptions} = {};
export type ModalId<T> = symbol & {[modalProps]: T; options?: ModalOptions};
export type ProvidedModal<T = any> = {[modalId: ModalId<T>]: (close: () => void, modalProps: T) => ReactNode};

export function declareModal<T = undefined>(options?: ModalOptions): ModalId<T> {
  const res = Symbol() as ModalId<T>;
  MODAL_OPTIONS[res] = options ?? {};
  return res;
}

export function provideModal<T>(
  modalId: ModalId<T>,
  f: (close: () => void, modalProps: T) => ReactNode,
): ProvidedModal {
  return {[modalId]: f};
}
