import {useCallback, useMemo} from "react";
import {useQueryData} from "../../../../../state";
import {withSuspense} from "../../../../../state/withSuspense";
import {AttributesUi, Attribute, AttributePayload} from "../Attributes";
import api from "../../../../../api";
import {nominate} from "../../../../../Types";

const StatusesPage = withSuspense(() => {
  const statuses = useQueryData({queryKey: ["tprm", "thirdPartyStatuses"]});

  const attributes: Attribute[] = useMemo(
    () => statuses.map(s => ({id: s.status_id, title: s.title, color: s.color})),
    [statuses],
  );

  const onCreate = useCallback(async (attribute: AttributePayload) => {
    await api.tprm.thirdPartyStatuses.create(attribute);
  }, []);

  const onUpdate = useCallback(async (id: string, attribute: AttributePayload) => {
    const nid = nominate("thirdPartyStatusId", id);

    await api.tprm.thirdPartyStatuses.updateTitle(nid, attribute.title);
    await api.tprm.thirdPartyStatuses.updateColor(nid, attribute.color);
  }, []);

  const onDelete = useCallback(async (id: string) => {
    const nid = nominate("thirdPartyStatusId", id);
    await api.tprm.thirdPartyStatuses.delete_(nid);
  }, []);

  return (
    <AttributesUi
      label="status"
      attributes={attributes}
      onCreate={onCreate}
      onUpdate={onUpdate}
      onDelete={onDelete}
      canDeleteLast={false}
    />
  );
});

export default StatusesPage;
