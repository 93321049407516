import api from "..";
import {FrameworkVersion, FrameworkVersionId} from "../../Types";
import jsonApi from "../jsonApi";

export async function get(frameworkVersionId: FrameworkVersionId): Promise<FrameworkVersion> {
  return jsonApi.get(`/tprm/framework_versions/${frameworkVersionId}`);
}

/**
 * Temporary helper to make generating the hardcoded demo data easier.
 * Lists the first framework version for each framework.
 */
export async function debugList(): Promise<FrameworkVersion[]> {
  const frameworks = await api.tprm.frameworks.list();

  // Fetch a full framework version for each framework
  const frameworkVersions = await Promise.all(
    frameworks.map(f =>
      api.tprm.frameworks
        .listVersions(f.framework_id)
        .then(a => api.tprm.frameworkVersions.get(a[0].framework_version_id)),
    ),
  );

  return frameworkVersions;
}
