import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {CloudArrowUpIcon, DocumentPlusIcon} from "@heroicons/react/20/solid";
import {memo, useState} from "react";

import {DocumentAuthority, DocumentCategory, SharingClassification} from "../../../../Types";
import api from "../../../../api";
import SingleFileUpload from "../../../../components/fileUploads/SingleFileUpload";
import {useQueryData} from "../../../../state";
import {router} from "../../../../router";
import {useAsyncOperation} from "../../../../hooks/asyncOperation";
import {FileUpload} from "../../../../hooks/fileUpload";
import {useValidation, useValidatedPromiseState} from "../../../../hooks/validationState";
import {useResetting} from "../../../../hooks/resetting";
import {SUPPORTED_DOCUMENT_FORMATS} from "../../../../components/fileUploads";
import {useAbortable} from "../../../../hooks/abortable";

const NewDocument = memo(() => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const [name, setName] = useResetting(useState(""), isOpen);
  const [fileUpload, setFileUpload] = useResetting(useAbortable(useState<FileUpload | null>(null)), isOpen);
  const [validationErrors, setValidationErrors] = useValidation(useResetting(useState({}), isOpen), {name});
  const fileUploadState = useAsyncOperation(fileUpload);

  const [saving, save] = useValidatedPromiseState(
    async () => {
      if (fileUploadState?.id !== "Uploaded") {
        // Not ready
        return;
      }
      const {document_id} = await api.vendorToolkit.documents.create({
        review_period: {type: "None"},
        sharing_classification: SharingClassification.Internal,
        name,
        category: DocumentCategory.Other,
        owner_id: whoami.user_owner!.owner_id,
        authority: DocumentAuthority.File,
        file_id: fileUploadState.result.file_id,
      });

      router!.navigate(`/vendor-toolkit/library/other-documents/${document_id}`);
      onClose();
    },
    [fileUploadState, name, whoami.user_owner, onClose],
    setValidationErrors,
  );

  return (
    <>
      <Button leftIcon={<Icon as={DocumentPlusIcon} />} onClick={onOpen} colorScheme="green">
        New document
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={save}>
          <ModalHeader>Create new document</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="8" mb="4">
              <FormControl isRequired isInvalid={validationErrors.name !== undefined}>
                <FormLabel>Document name</FormLabel>
                <Input
                  onChange={e => setName(e.target.value)}
                  value={name}
                  isDisabled={saving.inProgress}
                  placeholder="Platformed document"
                  autoFocus
                />
                {validationErrors.name && validationErrors.name[0] && (
                  <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
                )}
              </FormControl>

              <SingleFileUpload
                Cls={FileUpload}
                value={fileUpload}
                accept={SUPPORTED_DOCUMENT_FORMATS}
                onChange={setFileUpload}
              >
                {isDragActive => (
                  <HStack align="center" spacing={2}>
                    <Icon as={CloudArrowUpIcon} fontSize="xl" color={isDragActive ? "blue.500" : "gray.500"} />
                    <Text>Drag document here</Text>
                  </HStack>
                )}
              </SingleFileUpload>

              {saving.lastError ? (
                <Alert status="error" mt="12">
                  <AlertIcon boxSize="40px" />
                  <Box>
                    <AlertTitle fontSize="md">Error creating document</AlertTitle>
                    <AlertDescription fontSize="md">{`${saving.lastError}`}</AlertDescription>
                  </Box>
                </Alert>
              ) : null}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing="3">
              <Button variant="ghost" onClick={onClose} isDisabled={saving.inProgress}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={saving.inProgress}
                isDisabled={validationErrors.name !== undefined || fileUploadState?.id !== "Uploaded"}
              >
                Create
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

export default NewDocument;
