import {Box, Button, Flex, Heading, HStack, Icon, Stack, Text, useDisclosure} from "@chakra-ui/react";
import {
  CheckIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  EyeSlashIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import {GettingStartedChecklist, Rational} from "../../../../Types.ts";
import {ReactNode} from "react";
import {Link as RouterLink} from "react-router-dom";
import {ContactSupportModal} from "../../../../components/ContactSupportModal.tsx";
import {useAccountLocalStorage} from "../../../../hooks/accountLocalStorage.ts";
import {useIsAdmin} from "../../../../hooks/roles.ts";

type ChecklistItem = {
  key: keyof GettingStartedChecklist;
  title: ReactNode;
  description: ReactNode;
  actions: ChecklistAction[];
};

type ChecklistAction = {
  label: ReactNode;
  path: string;
  adminOnly?: boolean;
};

const CHECKLIST_ITEMS: ChecklistItem[] = [
  {
    key: "uploaded_documents",
    title: "Upload policies & documents",
    description: (
      <>
        Uploading your internal policies allows Platformed's AI to automatically generate answers to questionnaires.{" "}
        <Box as="span" fontWeight="semibold" display="inline" color="inherit">
          We recommend at least 5 to get started.
        </Box>
      </>
    ),
    actions: [
      {
        label: "Upload policies",
        path: "/vendor-toolkit/library/policies",
      },
      {
        label: "Sync policies in",
        path: "/vendor-toolkit/configuration/integrations/document-sources",
        adminOnly: true,
      },
    ],
  },
  {
    key: "uploaded_historical_questionnaires",
    title: "Upload some historical questionnaires",
    description: (
      <>
        Platformed's AI uses previous answers that you've given when answering new questionnaires.{" "}
        <Box as="span" fontWeight="semibold" display="inline" color="inherit">
          We recommend at least 3.
        </Box>{" "}
        The more you upload, the better your automation results will be.
      </>
    ),
    actions: [
      {
        label: "Upload historical questionnaire",
        path: "/vendor-toolkit/library/historical-questionnaires",
      },
    ],
  },
  {
    key: "invited_colleagues",
    title: "Invite your colleagues",
    description:
      "Bring in subject matter experts across your organisation to support the review and completion of your first questionnaire.",
    actions: [
      {
        label: "Invite a colleague",
        path: "/vendor-toolkit/configuration/user-management/users",
      },
    ],
  },
  {
    key: "uploaded_questionnaire",
    title: "Upload your first questionnaire",
    description:
      "Once you've completed the steps above, you're ready to automate your first due diligence questionnaire!",
    actions: [
      {
        label: "Create your first questionnaire",
        path: "/vendor-toolkit/questionnaires",
      },
    ],
  },
];

const ActionButton = ({
  action,
  prevCompleted,
  completed,
}: {
  action: ChecklistAction;
  completed: boolean;
  prevCompleted: boolean;
}) => (
  <Button
    as={RouterLink}
    to={action.path}
    colorScheme={completed || prevCompleted ? "green" : undefined}
    variant={completed ? "outline" : undefined}
    size="sm"
    rightIcon={<Icon as={ChevronRightIcon} />}
  >
    {action.label}
  </Button>
);

const GettingStartedStep = ({
  item,
  prevCompleted,
  completed,
  progress,
  ...props
}: {
  item: ChecklistItem;
  progress: Rational;
  completed: boolean;
  prevCompleted: boolean;
}) => {
  const isAdmin = useIsAdmin();
  return (
    <HStack
      {...props}
      bg={completed ? "green.50" : "gray.50"}
      px={4}
      py={4}
      rounded="md"
      border="1px"
      borderColor={completed ? "green.200" : "gray.200"}
      gap={4}
    >
      <Flex bg={completed ? "green.500" : "gray.500"} w="8" h="8" rounded="full" align="center" justify="center">
        <Icon as={completed ? CheckIcon : EllipsisHorizontalIcon} color="white" fontSize="18" />
      </Flex>
      <Stack gap={1} flex={1} pr={4}>
        <Heading size="sm" color={completed ? "green.800" : "gray.900"}>
          {item.title}
        </Heading>
        <Text fontSize="md" color={completed ? "green.700" : "gray.700"}>
          {item.description}
        </Text>
        {progress.num > 0 && !completed && (
          <Flex alignItems="center" gap={2} mt={2}>
            <Text fontSize="sm">
              {progress.num} / {progress.den}
            </Text>
            <Flex bg="gray.300" w="300px" h={2} borderRadius="full" overflow="hidden">
              <Box flex={progress.num / progress.den} bg="green.500" borderRadius="full" />
            </Flex>
          </Flex>
        )}
      </Stack>
      <HStack>
        {item.actions.map(
          (action, i) =>
            (!action.adminOnly || isAdmin) && (
              <ActionButton key={i} action={action} completed={completed} prevCompleted={prevCompleted} />
            ),
        )}
      </HStack>
    </HStack>
  );
};

const HelpButton = () => {
  const {isOpen: supportIsOpen, onOpen: supportOnOpen, onClose: supportOnClose} = useDisclosure();

  return (
    <>
      <Button
        colorScheme="orange"
        bg="orange.50"
        variant="outline"
        leftIcon={<Icon as={QuestionMarkCircleIcon} />}
        size="sm"
        onClick={supportOnOpen}
      >
        Need help?
      </Button>
      <ContactSupportModal
        isOpen={supportIsOpen}
        onClose={supportOnClose}
        title="Contact support"
        action="help"
        subject="Help getting started"
        initialBody={"Hi Platformed,\n"}
        instructions={"Tell us how we can help!"}
      />
    </>
  );
};

const GettingStarted = ({checklist}: {checklist: GettingStartedChecklist}) => {
  const [hidden, setHidden] = useAccountLocalStorage("gettingStarted.hidden", false);

  if (hidden) {
    return null;
  }

  // Hide component if all onboarding actions are complete
  if (Object.values(checklist).every(v => v.num >= v.den)) {
    return null;
  }
  let prevCompleted = true;

  return (
    <Stack p={8} spacing={4} borderBottom="1px solid" borderColor="gray.200">
      <HStack justify="space-between" alignItems="flex-start">
        <Stack spacing={1}>
          <Heading size="md">Ready to use Platformed?</Heading>
          <Text fontSize="md">It's only 4 steps, let's get started...</Text>
        </Stack>
        <Box>
          <Button leftIcon={<Icon as={EyeSlashIcon} />} onClick={() => setHidden(true)}>
            Hide
          </Button>
        </Box>
      </HStack>
      <Stack spacing={4}>
        {CHECKLIST_ITEMS.map(item => {
          const progress = checklist[item.key];
          const completed = progress.num >= progress.den;
          const wasPrevCompleted = prevCompleted;
          prevCompleted &&= completed;
          return (
            <GettingStartedStep
              key={item.key}
              item={item}
              completed={completed}
              prevCompleted={wasPrevCompleted}
              progress={progress}
            />
          );
        })}
      </Stack>
      <HStack justify="center">
        <HelpButton />
      </HStack>
    </Stack>
  );
};

export default GettingStarted;
