import {
  Box,
  Button,
  HStack,
  ModalBody,
  ModalFooter,
  Progress,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
} from "@chakra-ui/react";
import {ReactNode} from "react";

const steps = [
  {title: "Upload", description: "Upload the spreadsheet"},
  {title: "Select", description: "Select sheets"},
  {title: "Annotate", description: "Identify question ranges"},
  {title: "Import", description: "Import the questions"},
];

export const CommonBody = ({
  step,
  progress,
  children,
}: {
  step: number;
  progress?: number | null;
  children?: ReactNode;
}) => (
  <ModalBody display="flex" flexDir="column" flex="1">
    <Stack spacing={4} flex="1">
      <Stepper index={step} my={4}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      {children}
      {progress != null && <Progress min={0} max={1} value={progress} />}
    </Stack>
  </ModalBody>
);

export const CommonFooter = ({onClose, children}: {onClose: () => void; children?: ReactNode}) => (
  <ModalFooter>
    <HStack spacing="3">
      <Button variant="ghost" onClick={onClose}>
        Cancel
      </Button>
      {children}
    </HStack>
  </ModalFooter>
);
