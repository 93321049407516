import {Box} from "@chakra-ui/react";

import Page from "../../../components/Page.tsx";
import {withSuspense} from "../../../state/withSuspense.tsx";
import {useQueriesData} from "../../../state/index.ts";
import SingleQuestionView from "../Questionnaires/QuestionnaireViewer/Questions/SingleQuestionView.tsx";
import {useMemo} from "react";
import {useTypedParams} from "../../../hooks/typedParams";
import {QuestionParams} from "./Crumb";
import {useLayerType} from "../../../hooks/layerType";
import {useFilterState} from "../../../hooks/filterState";
import {AdHocQuestionFilters, facetedSearchAdHocQuestion} from "../../components/Filters";
import {useFacetedSearch} from "../../../hooks/search";
import {QuestionId, AdHocQuestion} from "../../../Types";
import {DEFAULT_FILTERS} from ".";
import SidePane from "../../../components/SidePane";
import QuestionNav from "./QuestionNav";

const nextQuestion = (
  reverse: boolean,
  baseQuestionId: QuestionId,
  filteredQuestions: AdHocQuestion[],
  unfilteredQuestions: AdHocQuestion[],
): AdHocQuestion | undefined => {
  const visibleQuestionIds = new Set(filteredQuestions.map(({question}) => question.question_id));

  const currentIdx = unfilteredQuestions.findIndex(({question}) => question.question_id === baseQuestionId);
  const candidateQuestions = reverse
    ? unfilteredQuestions.slice(0, currentIdx).reverse()
    : unfilteredQuestions.slice(currentIdx + 1);
  return candidateQuestions.find(({question}) => visibleQuestionIds.has(question.question_id));
};

const QuestionViewer = withSuspense(() => {
  const {questionId} = useTypedParams(QuestionParams);
  const [adhocQuestions, adhocQuestion, whoami] = useQueriesData({
    queries: [
      {queryKey: ["vendorToolkit", "adhocQuestions"]},
      {
        queryKey: ["vendorToolkit", "adhocQuestion", questionId],
      },
      {
        queryKey: ["whoAmI"],
      },
    ],
  });
  const [layerType] = useLayerType();
  const {filters, clearFilters, filterCount} = useFilterState<AdHocQuestionFilters>(
    "adhocQuestionFilters",
    DEFAULT_FILTERS,
  );

  // Using the facetedSearch hook here as it ensures the logic is the same as the main question page
  const {filter} = useFacetedSearch(
    q => q.question.text,
    facetedSearchAdHocQuestion(filters, layerType, whoami.associated_owners),
    [filters],
  );

  const {result} = useMemo(() => filter(adhocQuestions), [adhocQuestions, filter]);
  const {question_number, text} = adhocQuestion.question;
  const account = whoami.account!;

  const nextQuestionId = nextQuestion(false, questionId, result, adhocQuestions)?.question?.question_id;
  const prevQuestionId = nextQuestion(true, questionId, result, adhocQuestions)?.question?.question_id;

  const title = `${question_number ?? ""} ${text}`.trim();
  const parent = useMemo(
    () =>
      ({
        type: "Account",
        promptModifiers: account.prompt_modifiers,
      }) as const,
    [account.prompt_modifiers],
  );

  return (
    <Page title={title}>
      <Box display="flex" flexDir="row">
        <SidePane>
          <Box overflow="hidden">
            <QuestionNav
              questionId={questionId}
              clearFilters={clearFilters}
              filterCount={filterCount}
              adhocQuestions={result}
            />
          </Box>
        </SidePane>
        <SingleQuestionView
          key={questionId}
          question={adhocQuestion.question}
          parent={parent}
          nextQuestionId={nextQuestionId}
          prevQuestionId={prevQuestionId}
          asker={adhocQuestion.asker}
        />
      </Box>
    </Page>
  );
});

export default QuestionViewer;
