import {
  Button,
  HStack,
  Icon,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import {Owner, OwnerType, RegisteredUser} from "../../Types.ts";
import {UsersIcon} from "@heroicons/react/24/solid";
import {memo, useState} from "react";
import {UserPicker, UserPickerValue} from "../../components/UserPicker.tsx";
import {InviteUserModal} from "../../Account/Settings/Users/InviteUser.tsx";
import {useResetting} from "../../hooks/resetting.ts";
import {useDebounceValue} from "usehooks-ts";
import api from "../../api/index.ts";
import {usePromiseState} from "../../hooks/promiseState.ts";

export const ReassignUserModal = memo(
  ({onReassign, isReassigning, text}: {onReassign: (owner?: Owner) => void; isReassigning: boolean; text: string}) => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {isOpen: isInviteOpen, onOpen: onInviteOpen, onClose: onInviteClose} = useDisclosure();
    const [userOrInvite, setUserOrInvite] = useResetting(
      useState<UserPickerValue>({type: "Invite", nameOrEmail: ""}),
      isOpen,
    );

    const [reassigning, reassign] = usePromiseState(async () => {
      if (userOrInvite?.type === "User") {
        onClose();
        const owner = await api.owners.resolve([{type: OwnerType.User, content: userOrInvite.user.user_id}]);
        onReassign(owner[0]);
      } else if (userOrInvite?.type === "Invite") {
        onInviteOpen();
      }
    }, [onReassign, onClose, onInviteOpen, userOrInvite]);
    const [reassigningUser, reassignUser] = usePromiseState(
      async (user: RegisteredUser) => {
        onClose();
        const owner = await api.owners.resolve([{type: OwnerType.User, content: user.user_id}]);
        onReassign(owner[0]);
      },
      [onReassign, onClose],
    );

    // Autofocus doesn't work properly unless we add a delay between closing and re-opening a modal
    const [isInviteOpenDebounce] = useDebounceValue(isInviteOpen, 200);

    const initialInviteName =
      userOrInvite?.type === "Invite" && !userOrInvite.nameOrEmail.includes("@") ? userOrInvite.nameOrEmail : "";
    const initialInviteEmail =
      userOrInvite?.type === "Invite" && userOrInvite.nameOrEmail.includes("@") ? userOrInvite.nameOrEmail : "";

    return (
      <>
        <MenuItem onClick={onOpen}>
          <Icon as={UsersIcon} h="6" mr="2" color="gray.500" />
          {text}
        </MenuItem>
        <Modal isOpen={isOpen && !isInviteOpen && !isInviteOpenDebounce} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Reassign to</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <UserPicker value={userOrInvite} onSelect={setUserOrInvite} onSubmit={reassign} autoFocus />
            </ModalBody>
            <ModalFooter>
              <HStack spacing="3">
                <Button variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={reassign}
                  isDisabled={!userOrInvite || reassigning.inProgress || reassigningUser.inProgress || isReassigning}
                  isLoading={reassigning.inProgress || reassigningUser.inProgress || isReassigning}
                >
                  {userOrInvite?.type === "Invite" ? "Invite and reassign" : "Reassign"}
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <InviteUserModal
          initialName={initialInviteName}
          initialEmail={initialInviteEmail}
          isOpen={isInviteOpenDebounce && isInviteOpen}
          onInvite={reassignUser}
          onClose={onInviteClose}
        />
      </>
    );
  },
);
