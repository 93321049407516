import {FrameworkMin, FrameworkId, FrameworkVersionMin} from "../../Types";
import jsonApi from "../jsonApi";

export async function list(): Promise<FrameworkMin[]> {
  return jsonApi.get("/tprm/frameworks");
}

export async function get(frameworkId: FrameworkId): Promise<FrameworkMin> {
  return jsonApi.get(`/tprm/frameworks/${frameworkId}`);
}

export async function listVersions(frameworkId: FrameworkId): Promise<FrameworkVersionMin[]> {
  return jsonApi.get(`/tprm/frameworks/${frameworkId}/versions`);
}
