import {useCallback, useMemo} from "react";
import {useQueryData} from "../../../../../state";
import {withSuspense} from "../../../../../state/withSuspense";
import {AttributesUi, Attribute, AttributePayload} from "../Attributes";
import api from "../../../../../api";
import {nominate} from "../../../../../Types";

const TagsPage = withSuspense(() => {
  const tags = useQueryData({queryKey: ["tprm", "thirdPartyTags"]});

  const attributes: Attribute[] = useMemo(
    () => tags.map(t => ({id: t.tag_id, title: t.title, color: t.color})),
    [tags],
  );

  const onCreate = useCallback(async (attribute: AttributePayload) => {
    await api.tprm.thirdPartyTags.create(attribute);
  }, []);

  const onUpdate = useCallback(async (id: string, attribute: AttributePayload) => {
    const nid = nominate("thirdPartyTagId", id);

    await api.tprm.thirdPartyTags.updateTitle(nid, attribute.title);
    await api.tprm.thirdPartyTags.updateColor(nid, attribute.color);
  }, []);

  const onDelete = useCallback(async (id: string) => {
    const nid = nominate("thirdPartyTagId", id);
    await api.tprm.thirdPartyTags.delete_(nid);
  }, []);

  return (
    <AttributesUi label="tag" attributes={attributes} onCreate={onCreate} onUpdate={onUpdate} onDelete={onDelete} />
  );
});

export default TagsPage;
