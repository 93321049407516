import {Avatar, Box, HStack, Icon, LinkBox, LinkOverlay, Spinner, Stack, StackProps, Text} from "@chakra-ui/react";
import {Card, CardHeading} from "../Card";
import {ChevronRightIcon} from "@heroicons/react/24/solid";
import {withSuspense} from "../../../../state/withSuspense";
import {useQueryData} from "../../../../state";
import {QuestionnaireMin, QuestionnaireStatus} from "../../../../Types";
import {Link as RouterLink} from "react-router-dom";

function StatusText({status}: {status: QuestionnaireStatus}): string {
  switch (status) {
    case QuestionnaireStatus.Importing:
      return "importing";
    case QuestionnaireStatus.Automating:
      return "automating";
    case QuestionnaireStatus.Complete:
      return "complete";
    case QuestionnaireStatus.InProgress:
      return "in progress";
  }
}

function Row({questionnaire}: {questionnaire: QuestionnaireMin}) {
  return (
    <LinkBox
      as={HStack}
      bg="white"
      fontSize="md"
      p={3}
      px={4}
      spacing={4}
      borderBottom="1px solid"
      borderColor="gray.200"
      _hover={{
        bg: "gray.50",
      }}
    >
      <Avatar
        size="xs"
        src={`https://logo.clearbit.com/${questionnaire.counterparty.url}`}
        name={questionnaire.counterparty.name}
      />
      <Text flex="1">
        <Text as="span" fontWeight="600">
          <LinkOverlay
            as={RouterLink}
            to={`/vendor-toolkit/questionnaires/${questionnaire.questionnaire_id}/questions`}
          >
            {questionnaire.name}
          </LinkOverlay>
        </Text>{" "}
        <Text as="span" whiteSpace="nowrap">
          is <StatusText status={questionnaire.status} />.
        </Text>
      </Text>
      <Icon as={ChevronRightIcon} />
    </LinkBox>
  );
}

function Empty() {
  return (
    <Box p={8} display="grid" placeItems="center" bg="gray.50" flex="1" fontSize="md" textAlign="center">
      <Text color="gray.500">No questionnaires are importing, automating, or in progress.</Text>
    </Box>
  );
}

const SHOWN_STATUSES = [QuestionnaireStatus.InProgress, QuestionnaireStatus.Automating, QuestionnaireStatus.Importing];

const OpenQuestionnairesCard = withSuspense(
  ({...props}: StackProps) => {
    const questionnaires = useQueryData({queryKey: ["vendorToolkit", "questionnaires", {historical: false}]}).filter(
      q => SHOWN_STATUSES.includes(q.status),
    );

    return (
      <Card {...props}>
        <CardHeading hasBorder>Open questionnaires ({questionnaires.length})</CardHeading>
        {questionnaires.length === 0 ? (
          <Empty />
        ) : (
          <Stack
            spacing="0"
            bg="gray.50"
            minHeight="140px"
            flex="1 1 0px"
            overflowY="auto"
            css={{scrollbarWidth: "thin"}}
            alignItems="stretch"
          >
            {questionnaires.map(q => (
              <Row key={q.questionnaire_id} questionnaire={q} />
            ))}
          </Stack>
        )}
      </Card>
    );
  },
  <Card>
    <CardHeading>Open questionnaires</CardHeading>
    <Box p={8} display="grid" placeItems="center" bg="gray.50" flex="1">
      <Spinner size="sm" />
    </Box>
  </Card>,
);

export default OpenQuestionnairesCard;
