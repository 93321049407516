import {Alert, AlertDescription, AlertIcon, AlertTitle, Button, Flex, HStack, Icon, Text} from "@chakra-ui/react";
import {useCallback, useState} from "react";
import SingleFileUpload from "../../../../../../../components/fileUploads/SingleFileUpload.tsx";
import {CloudArrowUpIcon} from "@heroicons/react/20/solid";
import {FileUpload} from "../../../../../../../hooks/fileUpload.ts";
import {SizedStack} from "../../../../../../../hooks/stack.ts";
import {ImportState} from "../importState.ts";
import {useAsyncOperation} from "../../../../../../../hooks/asyncOperation.ts";
import {CommonBody, CommonFooter} from "../Common.tsx";
import {usePromiseState} from "../../../../../../../hooks/promiseState.ts";
import {readBlob} from "../../../../../../../utils/file.ts";
import {unmergeCellsInWorkbook} from "../importSpreadsheet.ts";

const UploadStep = ({
  stack: {value, replace, push},
  onClose,
}: {
  stack: SizedStack<ImportState, 1>;
  onClose: () => void;
}) => {
  const handleFileChange = useCallback(
    (fileUpload: FileUpload | null) => {
      replace({...value[0], fileUpload});
    },
    [value, replace],
  );

  const uploadState = useAsyncOperation(value[0].fileUpload);
  const [progress, setProgress] = useState<number | null>(null);

  const [advancing, advance] = usePromiseState(async () => {
    if (uploadState?.id !== "Uploaded" || !value[0].fileUpload) return;
    const buffer = await readBlob(value[0].fileUpload.file, setProgress);

    const workbook = value[0].xlsx.read(buffer);
    unmergeCellsInWorkbook(workbook, value[0].xlsx);
    const selectedSheetNames = workbook.SheetNames.filter(
      (_sheetName, sheetIndex) => !workbook.Workbook?.Sheets?.[sheetIndex]?.Hidden,
    );
    push({file: uploadState.result, workbook, selectedSheetNames});
  }, [value, uploadState, push]);

  return (
    <>
      <CommonBody step={0} progress={progress}>
        <SingleFileUpload
          Cls={FileUpload}
          value={value[0].fileUpload}
          onChange={handleFileChange}
          accept={{
            "application/vnd.ms-excel": [".xls"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx", ".xlsm"],
            "text/csv": [".csv"],
          }}
          isDisabled={advancing.inProgress}
        >
          {isDragActive => (
            <HStack align="center" spacing={2}>
              <Icon as={CloudArrowUpIcon} fontSize="xl" color={isDragActive ? "blue.500" : "gray.500"} />
              <Text>Drag your spreadsheet here</Text>
            </HStack>
          )}
        </SingleFileUpload>
        {advancing.lastError ? (
          <Alert status="error" variant="subtle" flexDirection="column" gap={2}>
            <Flex alignItems="center">
              <AlertIcon />
              <AlertTitle fontSize="md" color="red.700">
                There was a problem with the uploaded file
              </AlertTitle>
            </Flex>
            <AlertDescription fontWeight="thin" fontSize="md" color="red.700" whiteSpace="pre-line" w="full">
              {advancing.lastError.toString()}
            </AlertDescription>
          </Alert>
        ) : null}
      </CommonBody>
      <CommonFooter onClose={onClose}>
        <Button
          onClick={advance}
          isDisabled={uploadState?.id !== "Uploaded" || advancing.inProgress}
          isLoading={advancing.inProgress}
        >
          Next
        </Button>
      </CommonFooter>
    </>
  );
};

export default UploadStep;
