import {ComponentType, ReactNode, lazy} from "react";
import {generateId, getHeaders} from "./components/Markdown/index.tsx";
import {ConfigT, TrustCenterState} from "./config.ts";
import {handleImportError} from "../utils/import/index.ts";

export type PageT = {
  path: string;
  text: string;
  right?: boolean;
  subpages?: (config: ConfigT) => PageT[];
  to?: string;
  visible?: (state: TrustCenterState) => boolean;
} & ({element?: ReactNode} | {Component?: ComponentType});

const Pages: PageT[] = [
  {
    path: "overview",
    to: "overview",
    text: "Overview",
    Component: lazy(() => import("./Overview").catch(handleImportError)),
    subpages: config =>
      getHeaders(config.overview.content ?? "").map(h => ({
        path: `overview#${generateId([h.text])}`,
        text: h.text,
      })),
  },
  {
    path: "certifications",
    text: "Certifications",
    Component: lazy(() => import("./Certifications").catch(handleImportError)),
    visible: state => state.certifications.length > 0,
  },
  {
    path: "documents",
    text: "Documents",
    Component: lazy(() => import("./Documents").catch(handleImportError)),
    visible: state => state.documents.length > 0,
  },
  {
    path: "info",
    text: "Business information",
    Component: lazy(() => import("./Legal").catch(handleImportError)),
    visible: state =>
      state.config.kyb.length > 0 || (state.config.business_info != null && state.config.business_info.length > 0),
  },
  {
    path: "data",
    text: "Data Protection",
    Component: lazy(() => import("./DataProtection.tsx").catch(handleImportError)),
    visible: state => state.config.data_protection != null,
  },
  {
    path: "ai",
    text: "AI",
    Component: lazy(() => import("./AiPosture").catch(handleImportError)),
    visible: state => state.config.ai != null,
  },
  {
    path: "faq",
    text: "FAQ",
    Component: lazy(() => import("./Questionnaire.tsx").catch(handleImportError)),
    visible: state => state.config.faq.sections.some(s => s.questions.length > 0),
  },
  {
    path: "email-verification",
    text: "Email verification",
    Component: lazy(() => import("./EmailVerification.tsx").catch(handleImportError)),
    visible: () => false,
  },
];

export default Pages;
