import {
  Avatar,
  Box,
  Circle,
  Heading,
  HStack,
  Icon,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import ContentWrapper from "./components/ContentWrapper";
import {DocumentExternal, TrustCenterAiDataUseItemConfig, TrustCenterDataUseItemPii} from "../Types";
import {useTime} from "../hooks/time";
import {relativeDateView} from "../utils/time";
import {DocumentDownloadButton} from "./Documents";
import {DocumentTextIcon} from "@heroicons/react/24/outline";
import {useQueryData} from "../state";
import {useClientAccount} from "./hooks/clientAccount";
import {ArrowTopRightOnSquareIcon, CheckCircleIcon} from "@heroicons/react/20/solid";
import {AiModelProvider, TrustCenterAi, useTrustCenterConfig} from "./config";
import {useMemo} from "react";
import {HTTPError} from "../api";
import {
  BuildingOfficeIcon,
  FingerPrintIcon,
  IdentificationIcon,
  SparklesIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import {MaybeHighlight} from "./components/SearchModal/resultsComponents";
import {Link as RouterLink} from "react-router-dom";
import MarkdownView from "./components/Markdown/MarkdownView.tsx";
import {useTrustCenterPassword} from "./hooks/accessPassword.ts";

const AiPosture = () => {
  const config = useTrustCenterConfig();

  if (config.ai == null) {
    throw new HTTPError(new Response(null, {status: 404}));
  }

  return (
    <ContentWrapper py={12}>
      <Stack spacing={12}>
        <Header />
        <OverviewSection ai={config.ai} />
        <DataUseSection ai={config.ai} />
        <TrustAndComplianceSection ai={config.ai} />
        <ModelProvidersSection ai={config.ai} />
        <RelatedDocumentsSection ai={config.ai} />
        <FaqSection ai={config.ai} />
      </Stack>
    </ContentWrapper>
  );
};

function QuestionAnswer({question, answer}: {question: string; answer: string}) {
  return (
    <Stack p={4} px={6} _notLast={{borderBottom: "1px solid", borderColor: "gray.200"}}>
      <Text fontWeight="500">{question}</Text>
      <Text color="gray.600">{answer}</Text>
    </Stack>
  );
}

function Header() {
  return (
    <Heading size="lg" fontWeight="600">
      AI posture
    </Heading>
  );
}

function OverviewSection({ai}: {ai: TrustCenterAi}) {
  if (ai.overview === "") {
    return null;
  }
  return (
    <Stack spacing={4}>
      <Heading size="md" fontWeight="600">
        Overview
      </Heading>
      <Box p={4} px={6} border="1px solid" borderColor="gray.200" rounded="md">
        <MarkdownView markdown={ai.overview ?? "NO OVERVIEW"} />
      </Box>
    </Stack>
  );
}

function DataUseSection({ai}: {ai: TrustCenterAi}) {
  if (ai.data_use_items.length === 0) {
    return null;
  }

  return (
    <Stack spacing={4}>
      <Heading size="md" fontWeight="600">
        Data we use
      </Heading>
      <TableContainer border="1px solid" borderColor="gray.200" borderBottom="none" rounded="md" p={0}>
        <Table>
          <Tbody>
            {ai.data_use_items.map((item, idx) => (
              <DataUseItem key={idx} item={item} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

function TrustAndComplianceSection({ai}: {ai: TrustCenterAi}) {
  if (ai.check_items.length === 0) {
    return null;
  }

  return (
    <Stack spacing={4}>
      <Heading size="md" fontWeight="600">
        Trust and compliance
      </Heading>
      <Stack border="1px solid" borderColor="gray.200" rounded="md" p={0} spacing={0}>
        {ai.check_items.map((item, idx) => (
          <CheckItem key={idx} title={item.title} description={item.description} />
        ))}
      </Stack>
    </Stack>
  );
}

function ModelProvidersSection({ai}: {ai: TrustCenterAi}) {
  if (ai.model_providers.length === 0) {
    return null;
  }

  return (
    <Stack spacing={4}>
      <Heading size="md" fontWeight="600">
        AI model providers
      </Heading>
      <Stack border="1px solid" borderColor="gray.200" rounded="md" p={0} spacing={0}>
        {ai.model_providers.map(modelProvider => (
          <ModelProvider key={modelProvider.subprocessor.id} modelProvider={modelProvider} />
        ))}
      </Stack>
    </Stack>
  );
}

function RelatedDocumentsSection({ai}: {ai: TrustCenterAi}) {
  const clientAccount = useClientAccount();
  const password = useTrustCenterPassword(clientAccount.account_id);
  const allDocuments = useQueryData({
    queryKey: ["external", "trustCenterDocuments", clientAccount.account_id, password ?? ""],
  });
  const relatedDocuments = ai.related_documents.flatMap(id => allDocuments.find(d => d.document_id === id) ?? []);

  if (relatedDocuments.length === 0) {
    return null;
  }

  return (
    <Stack spacing={4}>
      <Heading size="md" fontWeight="600">
        Related documentation
      </Heading>
      <Stack border="1px solid" borderColor="gray.200" rounded="md" p={0} spacing={0}>
        {relatedDocuments.map((d, idx) => (
          <Document key={idx} document={d} />
        ))}
      </Stack>
    </Stack>
  );
}

function FaqSection({ai}: {ai: TrustCenterAi}) {
  const questions = useMemo(() => ai.faq.sections.flatMap(s => s.questions), [ai.faq.sections]);

  if (questions.length === 0) {
    return null;
  }

  return (
    <Stack spacing={4}>
      <Heading size="md" fontWeight="600">
        Other questions
      </Heading>
      <Stack border="1px solid" borderColor="gray.200" rounded="md" p={0} spacing={0}>
        {questions.map((q, _idx) => (
          <QuestionAnswer key={q.question_id} question={q.text} answer={q.response} />
        ))}
      </Stack>
    </Stack>
  );
}

function Document({document}: {document: DocumentExternal}) {
  const readableText = document.is_readable ? "Public" : "Restricted";
  const lastUpdatedText = useTime(relativeDateView(document.last_update), [document.last_update]);

  return (
    <HStack p={4} px={6} spacing={6} _notLast={{borderBottom: "1px solid", borderBottomColor: "gray.200"}}>
      <Box w={8} h={8} display="grid" placeItems="center">
        <Icon as={DocumentTextIcon} w={6} h={6} />
      </Box>
      <Stack flex="1 1 auto" spacing={0}>
        <Text fontWeight={500}>{document.name}</Text>
        <Text fontSize="md" color="gray.500">
          {readableText} • Last updated: {lastUpdatedText}
        </Text>
      </Stack>
      <DocumentDownloadButton document={document} />
    </HStack>
  );
}

function CheckItem({title, description}: {title: string; description: string}) {
  if (title.trim().length === 0 && description.trim().length === 0) {
    return null;
  }

  return (
    <HStack p={4} px={6} spacing={6} _notLast={{borderBottom: "1px solid", borderBottomColor: "gray.200"}}>
      <Icon as={CheckCircleIcon} w={6} h={6} color="green.500" />
      <Stack spacing={0}>
        {title.trim().length > 0 && <Text fontWeight="500">{title}</Text>}
        {description.trim().length > 0 && (
          <Text color="gray.600" fontSize="md">
            {description}
          </Text>
        )}
      </Stack>
    </HStack>
  );
}

export function ModelProviderContent({modelProvider, query}: {modelProvider: AiModelProvider; query?: string}) {
  return (
    <>
      {modelProvider.models_used.trim().length > 0 && (
        <Stack spacing={0}>
          <Text fontWeight="500" color="gray.500">
            Models used:
          </Text>
          <Text>
            <MaybeHighlight query={query}>{modelProvider.models_used}</MaybeHighlight>
          </Text>
        </Stack>
      )}
      {modelProvider.purposes.trim().length > 0 && (
        <Stack spacing={0}>
          <Text fontWeight="500" color="gray.500">
            Purposes:
          </Text>
          <Text whiteSpace="pre">
            <MaybeHighlight query={query}>{modelProvider.purposes}</MaybeHighlight>
          </Text>
        </Stack>
      )}
    </>
  );
}

function ModelProvider({modelProvider}: {modelProvider: AiModelProvider}) {
  const subprocessor = modelProvider.subprocessor;

  return (
    <Stack p={4} px={6} spacing={8} _notLast={{borderBottom: "1px solid", borderBottomColor: "gray.200 "}}>
      <HStack spacing={6}>
        <Avatar src={subprocessor.logo_src || `https://logo.clearbit.com/${subprocessor.url}`} size="sm" />
        <Text fontWeight="500" flexGrow="1">
          {subprocessor.name}
        </Text>
        <Link
          as={RouterLink}
          to={`../subprocessors?q=${subprocessor.name}`}
          fontSize="md"
          textDecoration="underline"
          display="flex"
          flexFlow="row nowrap"
          alignItems="center"
          gap="3"
        >
          <Text>Go to subprocessor</Text>
          <Icon as={ArrowTopRightOnSquareIcon} mt="-0.5" />
        </Link>
      </HStack>
      <ModelProviderContent modelProvider={modelProvider} />
    </Stack>
  );
}

export function DataUseItem({item}: {item: TrustCenterAiDataUseItemConfig}) {
  return (
    <Tr>
      <Td width="99%" verticalAlign="top" borderColor="gray.200">
        <Stack spacing={0}>
          <Text fontWeight="500">{item.title}</Text>
          {item.description.trim().length > 0 && (
            <Text color="gray.600" fontSize="md" whiteSpace="wrap">
              {item.description}
            </Text>
          )}
        </Stack>
      </Td>
      <Td borderColor="gray.200" verticalAlign="top">
        <Stack spacing={0} overflow="hidden" whiteSpace="nowrap" fontSize="md">
          <HStack spacing={4} color="gray.600">
            {item.pii === TrustCenterDataUseItemPii.None && (
              <>
                <Box w="1em" h="1em" display="grid" placeItems="center">
                  <Circle size={2} bg="gray.400" />
                </Box>
                <Text>Not PII or special category data</Text>
              </>
            )}

            {item.pii === TrustCenterDataUseItemPii.Pii && (
              <>
                <Icon as={IdentificationIcon} color="blue.500" />
                <Text>Personally Identifiable Information (PII)</Text>
              </>
            )}

            {item.pii === TrustCenterDataUseItemPii.SpecialCategory && (
              <>
                <Icon as={FingerPrintIcon} color="red.500" />
                <Text>Special category data</Text>
              </>
            )}
          </HStack>
          <HStack spacing={4} color="gray.600">
            {item.includes_customer_data ? (
              <>
                <Icon as={UserGroupIcon} color="gray.600" />
                <Text>Includes customer data</Text>
              </>
            ) : (
              <>
                <Icon as={BuildingOfficeIcon} color="gray.600" />
                <Text>Does not include customer data</Text>
              </>
            )}
          </HStack>
        </Stack>
      </Td>
      <Td borderColor="gray.200" verticalAlign="top">
        <Stack spacing={0} overflow="hidden" whiteSpace="nowrap" fontSize="md">
          <HStack spacing={4} color="gray.600">
            {item.is_anonymized ? (
              <>
                <Icon as={CheckCircleIcon} color="green.500" />
                <Text>Anonymized before use</Text>
              </>
            ) : (
              <>
                <Icon as={UserCircleIcon} color="gray.500" />
                <Text>Not anonymized before use</Text>
              </>
            )}
          </HStack>
          <HStack spacing={4} color="gray.600">
            {item.used_for_training ? (
              <>
                <Icon as={SparklesIcon} color="purple.500" />
                <Text>May be used in AI training data sets</Text>
              </>
            ) : (
              <>
                <Icon as={CheckCircleIcon} color="green.500" />
                <Text>Not used in AI training data sets</Text>
              </>
            )}
          </HStack>
        </Stack>
      </Td>
    </Tr>
  );
}

export default AiPosture;
