import {HStack, Img, Link, Text} from "@chakra-ui/react";
import Screenshot from "../../../assets/new_features/core_responses.png";
import {Feature} from ".";

export default {
  heading: "Introducing core responses",
  text: (
    <>
      <Text>You can now specify verbatim responses for important questions.</Text>
      <Link
        href="https://platformed.com/blog/master-questionnaire-consistency-with-platformeds-core-responses"
        target="_blank"
        color="blue.500"
        fontWeight="semibold"
      >
        Find out more in our blog post.
      </Link>
    </>
  ),
  image: (
    <HStack
      bg="orange.200"
      bgGradient="linear(165deg, green.200, blue.200, cyan.300)"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <Img src={Screenshot} h={64} rounded="md" objectFit="cover" />
    </HStack>
  ),
  publishDate: new Date(Date.parse("22 Aug 2024")),
  actionText: "Go to core responses",
  url: "/vendor-toolkit/library/core-responses",
} satisfies Feature;
