import ThirdPartyPage from "./ThirdPartyPage.tsx";
import {Route} from "react-router-dom";
import {tabbedRoute} from "../../../components/RouterTabs/index.tsx";

import metaTab from "./Meta";
import dataRoomTab from "./DataRoom";
import assessmentsTab from "./Assessments";
import risksTab from "./Risks";
import reportTab from "./Report";
import suppliersTab from "./Suppliers";

import sourceRoutes from "./DataRoom/Source";
import assessmentRoutes from "../Assessment";
import Crumb from "./Crumb.tsx";

const tabs = [metaTab, dataRoomTab, suppliersTab, assessmentsTab, risksTab, reportTab];

export default (
  <Route path="thirdparties" handle={{crumb: "Third Parties"}}>
    <Route path=":thirdPartyId" handle={{Crumb}}>
      {tabbedRoute({tabs, element: <ThirdPartyPage />})}
      {sourceRoutes}
      {assessmentRoutes}
    </Route>
  </Route>
);
