import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {PencilIcon} from "@heroicons/react/20/solid";
import {useCallback, useState} from "react";
import {AnswerParts, Question} from "../../../../../Types.ts";
import api, {HTTPError} from "../../../../../api/index.ts";
import {useResetting} from "../../../../../hooks/resetting.ts";
import {useValidatedPromiseState} from "../../../../../hooks/validationState.ts";
import AnswerPartsEditor from "./AnswerPartsEditor.tsx";
import {AutoResizingTextarea} from "../../../../../components/AutoResizingTextarea.tsx";
import {performBulkUpdate} from "../../../../../state/invalidate";

function EditQuestionButton({question}: {question: Question}) {
  const {question_id, parts} = question;
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [questionNumber, setQuestionNumber] = useResetting(useState(question.question_number), isOpen);
  const [questionInput, setQuestion] = useResetting(useState(question.text), isOpen);
  const [guidanceInput, setGuidance] = useResetting(useState(question.guidance), isOpen);
  const [answerParts, setAnswerParts] = useResetting(useState(parts), isOpen);

  const updateParts = useCallback(
    async (parts: AnswerParts) => {
      try {
        await api.vendorToolkit.questions.updateParts(question_id, parts);
      } catch (ex) {
        if (ex instanceof HTTPError) {
          if (ex.response.status === 422) {
            throw new Error(await ex.response.json());
          }
        }
        throw ex;
      }
    },
    [question_id],
  );

  const [editing, editQuestion] = useValidatedPromiseState(async () => {
    await performBulkUpdate(async () => {
      await api.vendorToolkit.questions.updateNumber(question_id, questionNumber ?? "");
      await api.vendorToolkit.questions.updateText(question_id, questionInput);
      await api.vendorToolkit.questions.updateGuidance(question_id, guidanceInput || "");
      await updateParts(answerParts);
    });

    onClose();
  }, [question_id, questionNumber, questionInput, guidanceInput, updateParts, answerParts, onClose]);

  return (
    <>
      <Tooltip label="Edit question">
        <IconButton flex="0 0 auto" icon={<Icon as={PencilIcon} />} onClick={onOpen} size="sm" aria-label="Edit" />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent as={"form"} onSubmit={editQuestion} minW="3xl">
          <ModalHeader>Edit question</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="8" mb="4">
              <FormControl>
                <HStack gap={4}>
                  <FormLabel m={0}>Question number</FormLabel>
                  <Input
                    width={"fit-content"}
                    data-testid="edit-question-number"
                    value={questionNumber}
                    onChange={e => {
                      setQuestionNumber(e.target.value);
                    }}
                  />
                </HStack>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Question text</FormLabel>
                <AutoResizingTextarea
                  data-testid="edit-question-text"
                  value={questionInput}
                  minRows={2}
                  maxRows={5}
                  onChange={e => {
                    setQuestion(e.target.value);
                  }}
                  autoFocus
                />
              </FormControl>
              <FormControl>
                <FormLabel>Guidance text</FormLabel>
                <AutoResizingTextarea
                  data-testid="edit-guidance-text"
                  value={guidanceInput}
                  minRows={2}
                  maxRows={5}
                  onChange={e => {
                    setGuidance(e.target.value);
                  }}
                />
                <FormHelperText>
                  Additional information on how to answer the question. This is not used for AI answer generation.
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Answer parts</FormLabel>
                <AnswerPartsEditor answerParts={answerParts} setAnswerParts={setAnswerParts} />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing="3" direction="row">
              <Button colorScheme="gray" onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue" type="submit" isLoading={editing.inProgress}>
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EditQuestionButton;
