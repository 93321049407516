type Span = {
  offset: number;
  length: number;
  content: string;
};

const TOKEN = /\b\w{3,}\b/giu;
const SENTENCE_TERMINATOR = /[.!?\n]/giu;

function tokenize(value: string): Map<string, number[]> {
  const map = new Map<string, number[]>();
  for (const tokenMatch of value.matchAll(TOKEN)) {
    const lowerToken = tokenMatch[0].toLowerCase();
    if (!map.has(lowerToken)) {
      map.set(lowerToken, []);
    }
    map.get(lowerToken)!.push(tokenMatch.index);
  }
  return map;
}

function findSentenceIndices(value: string): number[] {
  return [0, ...[...value.matchAll(SENTENCE_TERMINATOR)].map(m => m.index + m[0].length), value.length];
}

export default function locateInText(haystack: string, needle: string): Span {
  const haystackTokens = tokenize(haystack);
  const needleTokens = tokenize(needle);
  const sentences = findSentenceIndices(haystack);
  const scores = new Map<number, number>();
  for (const token of needleTokens.keys()) {
    const indices = haystackTokens.get(token);
    if (!indices) {
      continue;
    }
    for (const idx of indices) {
      const sentenceIdx = sentences.findLastIndex(i => i <= idx)!;
      const weighting = token.length / indices.length;
      scores.set(sentenceIdx, (scores.get(sentenceIdx) ?? 0) + weighting);
    }
  }
  let bestSentenceIdx = 0;
  let bestSentenceScore = 0;
  for (const [sentenceIdx, score] of scores) {
    if (score > bestSentenceScore) {
      bestSentenceIdx = sentenceIdx;
      bestSentenceScore = score;
    }
  }
  const offset = sentences[Math.max(0, bestSentenceIdx - 3)];
  const length = sentences[Math.min(bestSentenceIdx + 5, sentences.length - 1)] - offset;
  return {offset, length, content: haystack.substring(offset, offset + length)};
}
