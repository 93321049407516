import {Navigate} from "react-router-dom";
import {withSuspense} from "../state/withSuspense";
import {useSessionStorage} from "usehooks-ts";

const LandingPage = withSuspense(() => {
  const [landingPage] = useSessionStorage("landing-page", "product-selector");

  // Navigate to session-stored landing page
  return <Navigate to={landingPage} replace />;
});
export default LandingPage;
