import {InformationCircleIcon} from "@heroicons/react/20/solid";
import {RouteTab} from "../../../../components/RouterTabs/index.tsx";
import MetaPage from "./MetaPage";

export default {
  title: "Meta",
  path: "meta",
  icon: InformationCircleIcon,
  element: <MetaPage />,
} satisfies RouteTab;
