import {
  Owner,
  Question,
  QuestionId,
  QuestionnaireId,
  QuestionStatus as QuestionStatusT,
  Section,
  SectionId,
  SectionMin,
} from "../../../../../Types.ts";

import React, {forwardRef, Fragment, memo, useCallback, useEffect, useMemo, useState} from "react";
import {
  Checkbox,
  Editable,
  EditableInput,
  EditablePreview,
  Highlight,
  HStack,
  Icon,
  IconButton,
  LinkBox,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useEditableControls,
  VStack,
} from "@chakra-ui/react";
import {Link as RouterLink, useOutletContext} from "react-router-dom";
import QuestionStatus from "../../../components/QuestionStatus/Selector.tsx";
import {ActionBar, ActionBarSearch} from "../../../../../components/ActionBar.tsx";
import {
  facetedSearchQuestion,
  getQuestionsRelevantOwners,
  QuestionFilters,
} from "../../../../components/Filters/index.tsx";
import api from "../../../../../api/index.ts";
import {useEventCallback} from "usehooks-ts";
import {BulkActions} from "./BulkActions.tsx";
import {useFacetedSearch} from "../../../../../hooks/search.ts";
import AddQuestionButton from "./AddQuestionButton.tsx";
import {useFilterState} from "../../../../../hooks/filterState.ts";
import LinkOverlay from "../../../../../components/LinkOverlay.tsx";
import {useQueriesData, useQueryData} from "../../../../../state/index.ts";
import QuestionNumber from "../../components/QuestionNumber.tsx";
import {defaultRangeExtractor, useWindowVirtualizer} from "@tanstack/react-virtual";
import {STICKY_SECTION_HEADER_OFFSET} from "../../../../../utils/sticky.ts";
import {getResponseLayer, useLayerType} from "../../../../../hooks/layerType.ts";
import {TabOutletContext} from "../index.tsx";
import FilterBanner from "../../../../components/Filters/FilterBanner.tsx";
import FilterStatuses from "../../../../components/Filters/FilterStatuses.tsx";
import FilterOwners from "../../../../components/Filters/FilterOwners.tsx";
import ResponseCell from "./ResponseCell.tsx";
import {usePromiseState} from "../../../../../hooks/promiseState.ts";
import {PencilIcon} from "@heroicons/react/20/solid";
import OwnerSelector from "../../../../../components/OwnerSelector.tsx";
import BackToTopButton from "../../../../components/BackToTopButton.tsx";
import questionKeys from "../../../../../utils/questionKeys";
import filterSections from "../../../../../utils/filterSections";
import {withSuspense} from "../../../../../state/withSuspense";

const EditableControls = () => {
  const {isEditing, getEditButtonProps} = useEditableControls();

  return (
    !isEditing && (
      <IconButton
        icon={<Icon as={PencilIcon} />}
        aria-label={"edit-section"}
        variant={"none"}
        color={"blue.200"}
        {...getEditButtonProps()}
      ></IconButton>
    )
  );
};

const SectionHeader = memo(
  forwardRef<
    HTMLTableRowElement,
    {
      questionnaireId: QuestionnaireId;
      value: SectionMin;
      allChecked: boolean;
      anyChecked: boolean;
      onCheck: (checkId: CheckId, checked: boolean) => void;
      index: number;
      isEmpty: boolean;
    }
  >(({questionnaireId, value, allChecked, anyChecked, onCheck, index, isEmpty}, ref) => {
    const checkSection = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onCheck({type: "section", sectionId: value?.section_id}, e.target.checked);
      },
      [onCheck, value?.section_id],
    );

    const [editingSectionTitle, editSectionTitle] = usePromiseState(
      async (newName: string) => {
        await api.vendorToolkit.questionnaires.updateSectionTitle(questionnaireId, value.section_id, newName);
      },
      [questionnaireId, value.section_id],
    );

    return (
      <Tr ref={ref} data-index={index} height={`${ESTIMATED_SECTION_HEIGHT}px`}>
        <Td
          p="0"
          bg="blue.500"
          position="sticky"
          top={`${STICKY_SECTION_HEADER_OFFSET}px`}
          zIndex="sticky"
          borderTop="1px solid"
          borderTopColor="blue.600"
        >
          {!isEmpty && (
            <Checkbox
              px="8"
              py="5"
              isChecked={allChecked}
              isIndeterminate={anyChecked && !allChecked}
              onChange={checkSection}
              sx={{"& span": {bg: "white"}}}
            />
          )}
        </Td>
        <Td
          bg="blue.500"
          position="sticky"
          top={`${STICKY_SECTION_HEADER_OFFSET}px`}
          zIndex="sticky"
          colSpan={4}
          py="2"
          borderTop="1px solid"
          borderTopColor="blue.600"
          color="white"
          fontWeight="600"
          fontSize="md"
        >
          <Editable defaultValue={value?.title || "No section"} onSubmit={e => editSectionTitle(e)}>
            <HStack justifyContent={"space-between"}>
              <HStack flex={1}>
                <EditablePreview />
                <EditableInput bg={"white"} color={"black"} />
                {editingSectionTitle.inProgress && <Icon as={Spinner} color="blue.200" />}
              </HStack>
              <EditableControls />
            </HStack>
          </Editable>
        </Td>
      </Tr>
    );
  }),
);

const QuestionRowActions = memo(
  ({owner, status, question_id}: {owner?: Owner; status: QuestionStatusT; question_id: QuestionId}) => {
    const [layerType] = useLayerType();
    const onNudge = useCallback(async () => {
      await api.vendorToolkit.questions.nudge(question_id, layerType);
    }, [question_id, layerType]);

    const onReassign = useCallback(
      async (owner?: Owner) => {
        await api.vendorToolkit.questions.assign(question_id, layerType, owner?.owner_id ?? null);
      },
      [question_id, layerType],
    );

    const onSetStatus = useCallback(
      async (status: QuestionStatusT) => {
        await api.vendorToolkit.questions.updateStatus(question_id, layerType, status);
      },
      [question_id, layerType],
    );

    return (
      <>
        <Td>
          <VStack spacing={2} alignItems="stretch">
            <OwnerSelector owner={owner} onReassign={onReassign} onNudge={onNudge} />
            <QuestionStatus status={status} onChangeStatus={onSetStatus} />
          </VStack>
        </Td>
      </>
    );
  },
);

const QuestionRow = memo(
  forwardRef<
    HTMLTableRowElement,
    {
      index: number;
      value: Question;
      queries: string[];
      questionnaireId: QuestionnaireId;
      isChecked: boolean;
      onCheck: (checkId: CheckId, checked: boolean) => void;
    }
  >(({index, value, queries, questionnaireId, isChecked, onCheck}, ref) => {
    const [layerType] = useLayerType();
    const {text, question_id, response_layers, guidance} = value;
    const {owner, status} = getResponseLayer(response_layers, layerType);

    const checkQuestion = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onCheck({type: "question", questionId: question_id}, e.target.checked);
        e.stopPropagation();
      },
      [onCheck, question_id],
    );

    return (
      <Tr
        ref={ref}
        data-index={index}
        verticalAlign="top"
        transform="scale(1)"
        _hover={{bg: "gray.50"}}
        height={`${ESTIMATED_QUESTION_HEIGHT}px`}
      >
        <Td p="0" onClick={e => e.stopPropagation()}>
          <Checkbox px="8" py="6" onChange={checkQuestion} isChecked={isChecked} />
        </Td>
        <LinkBox as={Td}>
          <LinkOverlay
            as={RouterLink}
            to={`/vendor-toolkit/questionnaires/${questionnaireId}/questions/${question_id}`}
          />
          <Stack>
            <HStack
              fontSize="md"
              fontWeight="600"
              overflowWrap="break-word"
              whiteSpace="pre-wrap"
              display={"inline-block"}
            >
              <QuestionNumber question={value} />
              <Highlight query={queries} styles={{bg: "yellow.200"}}>
                {text}
              </Highlight>
            </HStack>
            {guidance !== undefined && (
              <Text fontSize="md" whiteSpace={"pre-wrap"}>
                {guidance}
              </Text>
            )}
          </Stack>
        </LinkBox>
        <Td>
          <ResponseCell questionnaireId={questionnaireId} question={value} />
        </Td>
        <QuestionRowActions owner={owner} question_id={question_id} status={status} />
      </Tr>
    );
  }),
);

type CheckId =
  | {
      type: "all";
    }
  | {
      type: "section";
      sectionId?: SectionId;
    }
  | {
      type: "question";
      questionId: QuestionId;
    };

const DEFAULT_FILTERS = {
  owners: ["ASSIGNED_TO_ME", "ASSIGNED_TO_OTHERS", "UNASSIGNED"],
  statuses: Object.keys(QuestionStatusT),
};

const ESTIMATED_SECTION_HEIGHT = 58;
const ESTIMATED_QUESTION_HEIGHT = 176;
const TBODY_POSITION_AT_SCROLL0 = 361;

type FlattenedItem =
  | {
      type: "section";
      section: Omit<Section, "questions"> & {questions: Question[]};
    }
  | {
      type: "question";
      question: Question;
    };

const QuestionsPage = withSuspense(
  memo(() => {
    const {questionnaire} = useOutletContext<TabOutletContext>();
    const [layerType] = useLayerType();
    const questions = useQueriesData({queries: questionKeys(questionnaire)});
    // Compute a reduced list of users who are relevant given this questionnaire
    const whoami = useQueryData({queryKey: ["whoAmI"]});
    const relevantUsers = getQuestionsRelevantOwners(questions, whoami.user_owner!, layerType);

    // Manage state for filters (including search)
    const {filters, setFilter, clearFilters, filterCount} = useFilterState<QuestionFilters>(
      "questionFilters",
      DEFAULT_FILTERS,
    );

    const {query, queries, setQuery, filter} = useFacetedSearch(
      q => q.text,
      facetedSearchQuestion(filters, layerType, whoami.associated_owners),
      [filters, layerType, whoami.associated_owners],
    );

    // Filter and group the questions by section
    const {result, counts} = useMemo(() => filter(questions), [questions, filter]);
    const filteredSections = useMemo(() => filterSections(questionnaire.sections, result), [questionnaire, result]);

    // Manage state for question selection
    const [selectedIds, setSelectedIds] = useState(new Set<QuestionId>());
    const allQuestionIds = useMemo(() => result.map(q => q.question_id), [result]);
    useEffect(() => {
      // Sometimes selected questions will become filtered out - in that
      // case we want to remove them from the selection.
      const possibleIds = new Set(allQuestionIds);
      const filteredIds = [...selectedIds].filter(id => possibleIds.has(id));
      if (filteredIds.length !== selectedIds.size) {
        setSelectedIds(new Set(filteredIds));
      }
    }, [selectedIds, allQuestionIds]);

    // Callback for updating question selection
    const check = useEventCallback((checkId: CheckId, checked: boolean) => {
      let relevantQuestionsIds: Set<QuestionId>;
      switch (checkId.type) {
        case "all":
          relevantQuestionsIds = new Set(allQuestionIds);
          break;
        case "section":
          relevantQuestionsIds = new Set(
            filteredSections
              .flatMap(s => (s.section_id === checkId.sectionId ? s.questions : []))
              .map(q => q.question_id),
          );
          break;
        case "question":
          relevantQuestionsIds = new Set([checkId.questionId]);
          break;
      }
      setSelectedIds(
        oldIds =>
          new Set(
            checked ? [...oldIds, ...relevantQuestionsIds] : [...oldIds].filter(id => !relevantQuestionsIds.has(id)),
          ),
      );
    });

    // Callback for when the top-level checkbox was changed
    const checkAll = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        check({type: "all"}, e.target.checked);
        e.stopPropagation();
      },
      [check],
    );

    const flattenedItems: FlattenedItem[] = useMemo(
      () =>
        filteredSections
          .filter(section => (filterCount === 0 && queries.length === 0) || section.questions.length > 0)
          .flatMap(section => [
            {type: "section", section} as const,
            ...section.questions.map(question => ({type: "question", question}) as const),
          ]),
      [filteredSections, filterCount, queries.length],
    );

    const getItemKey = useCallback(
      (idx: number) => {
        const item = flattenedItems[idx];
        return item.type === "section" ? item.section.section_id : item.question.question_id;
      },
      [flattenedItems],
    );

    const virtualizer = useWindowVirtualizer({
      estimateSize: idx =>
        flattenedItems[idx].type === "section" ? ESTIMATED_SECTION_HEIGHT : ESTIMATED_QUESTION_HEIGHT,
      count: flattenedItems.length,
      overscan: 5,
      scrollMargin: TBODY_POSITION_AT_SCROLL0 - STICKY_SECTION_HEADER_OFFSET,
      getItemKey,
      rangeExtractor: range => {
        const indices = defaultRangeExtractor(range);
        if (indices.length === 0 || flattenedItems[indices[0]].type === "section") {
          return indices;
        }
        const prevSectionIdx = flattenedItems.slice(0, indices[0] ?? 0).findLastIndex(item => item.type === "section");
        return [prevSectionIdx, ...indices];
      },
    });

    // When virtual scrolling we need to insert extra "blank" rows to take up the space of
    // non-rendered elements. This variable tracks where the last row ended so we know whether
    // we need to insert a blank row before the next row starts.
    let lastPosition = 0;
    return (
      <>
        <ActionBar
          actionButtons={
            <>
              <FilterOwners
                counts={counts.owner}
                owners={filters.owners}
                setOwners={newOwners => setFilter("owners", newOwners)}
                relevantOwners={relevantUsers}
              />
              <FilterStatuses
                counts={counts.status}
                statuses={filters.statuses}
                setStatuses={newStatuses => setFilter("statuses", newStatuses)}
              />
              <AddQuestionButton questionnaire={questionnaire} />
            </>
          }
        >
          <BulkActions selectedIds={selectedIds} />
          <ActionBarSearch value={query} onChange={e => setQuery(e.target.value)} />
        </ActionBar>
        <FilterBanner filterCount={filterCount} clearFilters={clearFilters} />
        <Table style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg" layout="fixed">
          <Thead bg="white" borderBottom="10px solid" borderBottomColor="red.100">
            <Tr color="red">
              <Th width="5%" color="gray.500" p="0">
                <Checkbox
                  px="8"
                  py="5"
                  onChange={checkAll}
                  isChecked={selectedIds.size === allQuestionIds.length}
                  isIndeterminate={selectedIds.size > 0 && selectedIds.size < allQuestionIds.length}
                />
              </Th>
              <Th width="40%" color="gray.500">
                Question
              </Th>
              <Th width="40%" color="gray.500">
                Response
              </Th>
              <Th width="20%" color="gray.500">
                Owner and status
              </Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody height={`${virtualizer.getTotalSize()}px`}>
            {virtualizer.getVirtualItems().map(item => {
              const flattenedItem = flattenedItems[item.index];
              const position = item.start - virtualizer.options.scrollMargin;
              const offset = position - lastPosition;
              lastPosition = position + item.size;
              let row;
              let key;
              if (flattenedItem.type === "section") {
                const {section} = flattenedItem;
                key = section.section_id;
                row = (
                  <SectionHeader
                    questionnaireId={questionnaire.questionnaire_id}
                    ref={virtualizer.measureElement}
                    index={item.index}
                    value={section}
                    allChecked={section.questions.every(q => selectedIds.has(q.question_id))}
                    anyChecked={!section.questions.every(q => !selectedIds.has(q.question_id))}
                    onCheck={check}
                    isEmpty={section.questions.length === 0}
                  />
                );
              } else {
                const {question} = flattenedItem;
                key = question.question_id;
                row = (
                  <QuestionRow
                    ref={virtualizer.measureElement}
                    index={item.index}
                    value={question}
                    questionnaireId={questionnaire.questionnaire_id}
                    queries={queries}
                    isChecked={selectedIds.has(question.question_id)}
                    onCheck={check}
                  />
                );
              }
              return (
                <Fragment key={key}>
                  {offset > 0 && (
                    <Tr height={`${offset}px`}>
                      <Td></Td>
                    </Tr>
                  )}
                  {row}
                </Fragment>
              );
            })}
            <Tr>
              <Td border="none"></Td>
            </Tr>
          </Tbody>
        </Table>
        <BackToTopButton />
      </>
    );
  }),
);

export default QuestionsPage;
