import {ModalCloseButton, ModalContent, ModalHeader} from "@chakra-ui/react";
import UploadStep from "./Steps/UploadStep.tsx";
import AnnotateStep from "./Steps/AnnotateStep.tsx";
import ImportStep from "./Steps/ImportStep.tsx";
import {QuestionnaireId} from "../../../../../../Types";
import useImportState from "./importState";
import SelectStep from "./Steps/SelectStep";
import {Suspense} from "react";
import Loading from "../../../../../../components/Loading";

const ImportSpreadsheetModal = ({
  questionnaireId,
  onClose,
  navigateTo,
}: {
  questionnaireId: QuestionnaireId;
  onClose: () => void;
  navigateTo?: boolean;
}) => {
  const {stack} = useImportState();

  return (
    <ModalContent display="flex" flexDirection="column">
      <ModalHeader>Upload spreadsheet</ModalHeader>
      <ModalCloseButton />
      <Suspense fallback={<Loading />}>
        {stack.size === 1 ? <UploadStep stack={stack} onClose={onClose} /> : null}
        {stack.size === 2 ? <SelectStep stack={stack} onClose={onClose} /> : null}
        {stack.size === 3 ? <AnnotateStep stack={stack} onClose={onClose} /> : null}
        {stack.size === 4 ? (
          <ImportStep stack={stack} onClose={onClose} questionnaireId={questionnaireId} navigateTo={navigateTo} />
        ) : null}
      </Suspense>
    </ModalContent>
  );
};

export default ImportSpreadsheetModal;
