import {
  Button,
  Checkbox,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import {FaRegFaceFrown, FaRegFaceGrinStars, FaRegFaceMeh, FaRegFaceSmile} from "react-icons/fa6";
import {useResetting} from "../../../../hooks/resetting.ts";
import api from "../../../../api/index.ts";
import {usePromiseState} from "../../../../hooks/promiseState.ts";
import {useQueryData} from "../../../../state/index.ts";
import {ReactElement, useState} from "react";
import {QuestionnaireId} from "../../../../Types.ts";

const ratingsOptions: readonly [string, string, ReactElement][] = [
  ["sad", "red", <FaRegFaceFrown />],
  ["meh", "orange", <FaRegFaceMeh />],
  ["happy", "green.500", <FaRegFaceSmile />],
  ["ecstatic", "green", <FaRegFaceGrinStars />],
];

export const RateUsModal = ({
  isOpen,
  onClose,
  questionnaireId,
}: {
  isOpen: boolean;
  onClose: () => void;
  questionnaireId: QuestionnaireId;
}) => {
  const [rating, setRating] = useResetting(useState(""), isOpen);
  const [review, setReview] = useResetting(useState(""), isOpen);
  const [cc, setCc] = useResetting(useState(false), isOpen);
  const {user, account} = useQueryData({queryKey: ["whoAmI"]});
  const {name} = useQueryData({queryKey: ["vendorToolkit", "questionnaire", questionnaireId]});

  const updateRatingValue = (value: string) => {
    if (value === rating) {
      setRating("");
    } else {
      setRating(value);
    }
  };

  const [sending, send] = usePromiseState(async () => {
    const subject = "Rating/Review";
    const fullBody = `\n\nQuestionnaire: \nName: ${name}\n Link: https://app.platformed.com/questionnaires/${questionnaireId}?account_id=${account?.account_id}\n\nRating: ${rating}\n\nReview: ${review}\n\nSubmitted by:\n User: ${user.name}\n Account: ${account?.name}\n\n`;

    await api.supportEmails.send({
      action: "rate-review",
      subject,
      body: cc ? fullBody + "Please follow up on this review" : fullBody,
      attachments: [],
      cc_user: cc,
    });
    onClose();
  }, [name, questionnaireId, account?.account_id, account?.name, rating, review, user.name, cc, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Export confirmed. How did we do?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Text fontSize="md">
              The export is in progress, and will be completed within one working day. Please let us know how you felt
              about the AI automation on this questionnaire.
            </Text>
            <HStack gap={6} justifyContent={"center"}>
              {ratingsOptions.map(([a, b, c], n) => (
                <IconButton
                  variant={"ghost"}
                  size={"lg"}
                  fontSize={"xx-large"}
                  key={n}
                  isActive={rating === a}
                  aria-label={"rate " + a}
                  color={b}
                  icon={c}
                  onClick={() => updateRatingValue(a)}
                />
              ))}
            </HStack>
            <Textarea onChange={e => setReview(e.target.value)} />
            <Checkbox isChecked={cc} onChange={e => setCc(e.target.checked)}>
              Request follow up to this review
            </Checkbox>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing="3">
            <Tooltip label="Please select a smiley" placement="top" isDisabled={rating !== ""}>
              <Button
                colorScheme="blue"
                onClick={send}
                type="submit"
                isDisabled={sending.inProgress || rating === ""}
                isLoading={sending.inProgress}
              >
                Submit
              </Button>
            </Tooltip>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
