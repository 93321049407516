import thirdParties from "./thirdParties.ts";
import thirdPartyTiers from "./thirdPartyTiers.ts";
import thirdPartyStatuses from "./thirdPartyStatuses.ts";
import thirdPartyTags from "./thirdPartyTags.ts";
import frameworks from "./frameworks.ts";
import assessments from "./assessments.ts";
import sources from "./sources.ts";
import risks from "./risks.ts";

export default [
  ...thirdParties,
  ...thirdPartyTiers,
  ...thirdPartyStatuses,
  ...thirdPartyTags,
  ...frameworks,
  ...assessments,
  ...sources,
  ...risks,
];
