import {Flex, Heading, HStack, Icon, Stack, Text} from "@chakra-ui/react";
import {AssessmentId, Control} from "../../../../Types";
import {useParams, NavLink} from "react-router-dom";
import {useQueryData} from "../../../../state";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {Fragment} from "react";
import SidePane from "../../../../components/SidePane";

const ControlLink = ({control}: {control: Control}) => (
  <Flex
    as={NavLink}
    borderLeft="4px solid"
    borderColor="transparent"
    _activeLink={{bg: "blue.50", borderColor: "blue.500"}}
    _hover={{bg: "gray.100"}}
    to={`../${control.control_id}`}
    px={4}
    py={3}
    fontSize="md"
    transitionDuration="200ms"
    borderRadius={2}
  >
    <Text as="span" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
      {control.control_number != null && (
        <Text as="span" fontWeight="semibold" fontSize="sm" color="gray.500" mr={2}>
          {control.control_number}
        </Text>
      )}
      <Text as="span" fontWeight={500}>
        {control.title}
      </Text>
    </Text>
  </Flex>
);

const ControlList = () => {
  const {assessmentId} = useParams() as {assessmentId: AssessmentId};
  const assessment = useQueryData({queryKey: ["tprm", "assessment", assessmentId]});

  return (
    <SidePane>
      <Stack overflow="hidden" h="full" spacing={0} borderRight="1px solid" borderColor="gray.200">
        <Heading as="h2" size="md" padding="6">
          Controls
        </Heading>
        <Stack spacing={1} overflowY="auto" flex={1} pb={4}>
          {assessment.framework_version.control_groups.map(cg => {
            return (
              <Fragment key={cg.control_group_id}>
                <HStack
                  px={4}
                  py={3}
                  fontSize="md"
                  justifyContent="space-between"
                  color="gray.600"
                  cursor="pointer"
                  _hover={{
                    bg: "gray.100",
                  }}
                  fontWeight="700"
                >
                  <Text pl={1}>{cg.title}</Text>
                  <Icon as={ChevronDownIcon} fontSize="3xl" />
                </HStack>
                {cg.controls.map(c => (
                  <ControlLink key={c.control_id} control={c} />
                ))}
              </Fragment>
            );
          })}
        </Stack>
      </Stack>
    </SidePane>
  );
};

export default ControlList;
