import {Box, chakra, HStack, Icon} from "@chakra-ui/react";
import {NavLink} from "react-router-dom";
import React from "react";
import {NotificationDot, NotificationLevel} from "./NotificationDot";

type Props = {
  label: string;
  icon?: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
  >;
  forceActionsVisible?: boolean;
  menuActions?: React.ReactNode;
  count?: number;
  indent?: number;
  gray?: boolean;
  notification?: NotificationLevel;
  href?: string;
  highlightOnSubPath?: boolean;
  isDisabled?: boolean;
};

function formatCount(count: number): string {
  if (count < 1_000) {
    return count.toString();
  } else if (count < 1_000_000) {
    return `${(count / 1_000).toFixed(1)}k`;
  } else {
    return `${(count / 1_000_000).toFixed(1)}m`;
  }
}

const HOVER_COLOR = "gray.50";
const ACTIVE_COLOR = "blue.50";

function BasePageSideBar(props: Props) {
  const indent = props.indent ?? 0;
  const gray = props.gray ?? false;
  const isDisabled = props.isDisabled ?? false;
  const highlightOnSubPath = props.highlightOnSubPath ?? false;
  const href = isDisabled ? undefined : props.href;

  return (
    <>
      <Box
        flex="0 0 auto"
        position="relative"
        display="flex"
        flexDirection="row"
        height="40px"
        alignItems="stretch"
        color={gray ? "gray" : "inherit"}
        fontWeight={indent === 0 ? "medium" : "normal"}
        fontSize="md"
        bg={props.forceActionsVisible ? HOVER_COLOR : "transparent"}
        opacity={isDisabled ? 0.3 : 1}
        _hover={
          isDisabled
            ? undefined
            : {
                bg: HOVER_COLOR,
              }
        }
        sx={
          isDisabled
            ? undefined
            : {
                // Active background
                "&:has(.active)": {
                  bgColor: ACTIVE_COLOR,
                },
                // Active blue bar
                "&:has(.active)::before": {
                  content: "''",
                  display: "block",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  bgColor: "blue.500",
                  width: "4px",
                  height: "100%",
                  pointerEvents: "none",
                },
                // Show action buttons on hover
                "&:hover > .buttons": {
                  display: "flex",
                },
              }
        }
      >
        <HStack
          as={href !== undefined ? NavLink : undefined}
          end={href !== undefined && !highlightOnSubPath ? true : undefined}
          to={href}
          flex="1 1 auto"
          gap="4"
          overflow="hidden"
          _hover={{textDecoration: "none"}}
          pl={`calc(var(--chakra-space-6) + ${indent} * 1.0rem)`}
        >
          {props.icon !== undefined && <Icon as={props.icon} boxSize={4} />}
          <HStack flex="1 1 auto" overflow="hidden" alignItems="center">
            <chakra.span whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {props.label}
            </chakra.span>
            {props.count !== undefined && props.count > 0 && (
              <chakra.span color="gray" fontWeight="normal">
                {" "}
                ({formatCount(props.count)})
              </chakra.span>
            )}
            {props.notification !== undefined && <NotificationDot level={props.notification} />}
          </HStack>
        </HStack>
        {props.menuActions !== undefined && (
          <HStack
            display={props.forceActionsVisible ? "flex" : "none"}
            spacing="1"
            flex="0 0 auto"
            color="black"
            px={1}
            alignItems="center"
            className="buttons"
          >
            {props.menuActions}
          </HStack>
        )}
      </Box>
    </>
  );
}

export default BasePageSideBar;
