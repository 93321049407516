import {RouteTab} from "../../../../../components/RouterTabs";
import ActivityPage from "./ActivityPage";
import {ChatBubbleLeftIcon} from "@heroicons/react/20/solid";

export default {
  title: "Activity",
  path: "activity",
  icon: ChatBubbleLeftIcon,
  element: <ActivityPage />,
} satisfies RouteTab;
