import {Box, Text} from "@chakra-ui/react";
import {parseISO} from "../../../utils/date";
import * as datefns from "date-fns";
import QuestionStatusComp from "../../../Products/VendorToolkit/components/QuestionStatus/Selector";
import {MentionElement} from "../RichText";
import {ActivityPayloadViewProps} from "..";

const QuestionUpdatedView = ({payload, mentions}: ActivityPayloadViewProps<"QuestionUpdated">) => {
  switch (payload.type) {
    case "Generic":
      return <Text as="span">updated this question</Text>;
    case "DueDate":
      return payload.content ? (
        <Text as="span">changed the due date to {datefns.format(parseISO(payload.content), "PP")}</Text>
      ) : (
        <Text as="span">cleared the due date</Text>
      );
    case "Owner":
      return mentions[0] ? (
        <Text as="span">
          assigned this question to <MentionElement mention={mentions[0]} />
        </Text>
      ) : (
        <Text as="span">unassigned this question</Text>
      );
    case "Parts":
      return <Text as="span">updated the question type</Text>;
    case "Status":
      return (
        <Text as="span">
          changed the status to{" "}
          <Box display="inline-block" verticalAlign="middle">
            <QuestionStatusComp status={payload.content} size="sm" />
          </Box>
        </Text>
      );
    case "Text":
      return <Text as="span">updated the question text</Text>;
    case "Response":
      return <Text as="span">updated the response</Text>;
  }
};

export default QuestionUpdatedView;
