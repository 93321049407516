import jsonApi, {DEFAULT_API_OPTIONS} from "./jsonApi";
import {
  Account,
  LoginRequest,
  WhoAmI,
  GenerateTotpResponse,
  UpdateTwoFa,
  RegisteredUser,
  RequestRegistration,
} from "../Types";
import {analyticsIdentifyAccount, analyticsIdentifyUser} from "../utils/analytics";
import {invalidateQueries, resetQueries} from "../state";
import {router} from "../router";

export async function whoAmI(options = DEFAULT_API_OPTIONS) {
  const res = await jsonApi.post<WhoAmI>("/whoami", null, options);
  if (res.user) {
    analyticsIdentifyUser(res.user);
  }
  if (res.account) {
    analyticsIdentifyAccount(res.account);
  }
  return res;
}

export async function logout() {
  await jsonApi.post<string>("/logout");
  sessionStorage.clear();
  await resetQueries();
  await router!.navigate("/login");
}

export async function login(body: LoginRequest) {
  const res = await jsonApi.post<RegisteredUser>("/login", body);
  await resetQueries();
  return res;
}

export async function register(body: RequestRegistration) {
  return await jsonApi.post<"ok">("/register", body);
}

export async function changeName(name: string) {
  const res = await jsonApi.post<"ok">("/login/name", name);
  await invalidateQueries([{queryKey: ["whoAmI"]}, {queryKey: ["user"]}, {queryKey: ["registeredUsers"]}]);
  return res;
}

export async function listAccounts() {
  return await jsonApi.get<Account[]>("/login/accounts/list");
}

export async function generateTotp() {
  return await jsonApi.get<GenerateTotpResponse>("/login/2fa/totp");
}

export async function update2FA(update: UpdateTwoFa) {
  const res = await jsonApi.post<"ok">("/login/2fa", update);
  await invalidateQueries([{queryKey: ["whoAmI"]}, {queryKey: ["user"]}, {queryKey: ["registeredUsers"]}]);
  return res;
}

export async function updateInternalMode(internalMode: boolean) {
  const res = await jsonApi.post<"ok">("/login/internal_mode", internalMode);
  await resetQueries();
  return res;
}
