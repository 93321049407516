import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom";
import {useCallback} from "react";
import {useQueryData} from "../../state/index.ts";
import {useAccountLocalStorage} from "../../hooks/accountLocalStorage.ts";
import {GiftIcon} from "@heroicons/react/24/outline";
import {subWeeks} from "date-fns";

import IndicatorBadge from "../../components/IndicatorBadge.tsx";
import SlackNotifications from "./SlackNotifications.tsx";
import SyncDocuments from "./SyncDocuments.tsx";
import Facts from "./Facts.tsx";
import NdaFlows from "./NdaFlows.tsx";
import Teams from "./Teams.tsx";
import {AccountFeature} from "../../Types.ts";
import {LockClosedIcon} from "@heroicons/react/20/solid";
import {withSuspense} from "../../state/withSuspense.tsx";
import Certifications from "./Certifications.tsx";
import ShowcaseSearch from "./ShowcaseSearch.tsx";
import AccountPromptModifiers from "./AccountPromptModifiers.tsx";
import ReviewItems from "./ReviewItems.tsx";
import ShowcaseAiPosture from "./ShowcaseAiPosture.tsx";
import {RoundButton} from "../../Header/TopRightNav/RoundButton.tsx";
import ScopeTypes from "./ScopeTypes.tsx";
import ResponseWordLimit from "./ResponseWordLimit.tsx";
import CoreResponses from "./CoreResponses.tsx";
import ShowcaseNotifications from "./ShowcaseNotifications.tsx";
import HomePage from "./HomePage.tsx";

export type Feature = {
  heading: React.ReactNode;
  text: React.ReactNode;
  image: React.ReactNode;
  publishDate: Date;
  actionText: string;
  actionRequiredAccountFeatures?: AccountFeature[];
  url: string;
};

// Add new features to the end
const NEW_FEATURES: (Feature & {id: number})[] = [
  SyncDocuments,
  SlackNotifications,
  Facts,
  NdaFlows,
  Teams,
  Certifications,
  ShowcaseSearch,
  AccountPromptModifiers,
  ReviewItems,
  ShowcaseAiPosture,
  ResponseWordLimit,
  ScopeTypes,
  CoreResponses,
  ShowcaseNotifications,
  HomePage,
]
  .map((f, i) => ({...f, id: i + 1}))
  .reverse();

const ActionButton = withSuspense(
  ({
    url,
    actionText,
    onClick,
    requiredFeatures,
  }: {
    url: string;
    actionText: string;
    onClick: () => void;
    requiredFeatures?: AccountFeature[];
  }) => {
    const whoami = useQueryData({queryKey: ["whoAmI"]});
    const enabledFeatures = whoami.account?.enabled_features ?? [];

    const isDisabled = requiredFeatures !== undefined && !requiredFeatures.every(f => enabledFeatures.includes(f));

    if (isDisabled) {
      return (
        <Tooltip label="This feature is not enabled for your account. For more information, contact support.">
          <Button colorScheme="green" isDisabled={true} leftIcon={<Icon as={LockClosedIcon} />}>
            {actionText}
          </Button>
        </Tooltip>
      );
    } else {
      return (
        <Button as={RouterLink} to={url} colorScheme="green" onClick={onClick}>
          {actionText}
        </Button>
      );
    }
  },
);

const NewFeaturesDrawerFeature = ({
  heading,
  image,
  text,
  url,
  actionText,
  actionRequiredAccountFeatures,
  onClose,
}: Feature & {onClose: () => void}) => (
  <Stack gap={4} p={6}>
    <Box rounded="lg" overflow="hidden">
      {image}
    </Box>
    <Stack>
      <Text as="strong" fontWeight="semibold">
        {heading}
      </Text>
      {text}
    </Stack>
    <HStack mt={4}>
      <ActionButton
        url={url}
        actionText={actionText}
        onClick={onClose}
        requiredFeatures={actionRequiredAccountFeatures}
      />
    </HStack>
  </Stack>
);

export const NewFeatures = () => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [lastOpened, setLastOpened] = useAccountLocalStorage(
    `newFeatures.changelog.lastOpened`,
    subWeeks(Date.now(), 2),
  );

  const newFeatureCount = NEW_FEATURES.filter(f => f.publishDate > lastOpened).length;

  const open = useCallback(() => {
    setLastOpened(new Date(Date.now()));
    onOpen();
  }, [onOpen, setLastOpened]);

  return (
    <>
      <RoundButton onClick={open}>
        <Icon as={GiftIcon} h="6" w="6" />
        {newFeatureCount > 0 && <IndicatorBadge count={newFeatureCount} />}
      </RoundButton>
      <Drawer placement="right" size="sm" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Platformed changelog</DrawerHeader>
          <DrawerBody p={0}>
            <Divider />
            <Stack divider={<Divider />}>
              {NEW_FEATURES.map(f => (
                <NewFeaturesDrawerFeature key={f.id} {...f} onClose={onClose} />
              ))}
            </Stack>
            <Divider />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
