import {HStack, Icon, Link, Text} from "@chakra-ui/react";
import {Feature} from ".";
import {HomeIcon} from "@heroicons/react/24/solid";

export default {
  heading: "Introducing your new homepage",
  text: (
    <>
      <Text>Streamline your workflow with Platformed's new homepage.</Text>
      <Link
        href="https://platformed.com/blog/review-tasks-and-assignments-at-a-glance-with-platformeds-new-user-homepage"
        target="_blank"
        color="blue.500"
        fontWeight="semibold"
      >
        Find out more in our blog post.
      </Link>
    </>
  ),
  image: (
    <HStack
      bg="blue.200"
      bgGradient="linear(165deg, cyan.200, blue.200, blue.300)"
      alignItems="center"
      justifyContent="center"
      p={12}
    >
      <Icon as={HomeIcon} boxSize={20} bg="white" rounded="full" p={4} color="blue.800" />
    </HStack>
  ),
  publishDate: new Date(Date.parse("5 Sep 2024")),
  actionText: "Go to Home",
  url: "/vendor-toolkit/home",
} satisfies Feature;
