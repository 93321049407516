import {Button, Icon, StackProps, HStack, useDisclosure} from "@chakra-ui/react";
import {PlusCircleIcon, ChatBubbleLeftIcon, BookOpenIcon} from "@heroicons/react/20/solid";
import {Card, CardHeading} from "../Card";
import {Link as RouterLink} from "react-router-dom";
import AskQuestionModal from "../../Questions/AskQuestionModal";

function QuickActionsCard({...props}: StackProps) {
  const askQuestionModal = useDisclosure();

  return (
    <Card {...props} flexBasis="0px" minWidth="min-content">
      <CardHeading>Quick actions</CardHeading>
      <HStack p="4" flexWrap="wrap">
        <Button
          as={RouterLink}
          to="/vendor-toolkit/questionnaires/new"
          flex="1 0 auto"
          leftIcon={<Icon as={PlusCircleIcon} />}
          colorScheme="green"
        >
          Create a questionnaire
        </Button>
        <Button
          onClick={() => askQuestionModal.onOpen()}
          flex="1 0 auto"
          leftIcon={<Icon as={ChatBubbleLeftIcon} />}
          colorScheme="blue"
        >
          Ask a question
        </Button>
        {askQuestionModal.isOpen && <AskQuestionModal {...askQuestionModal} />}
        <Button
          as={RouterLink}
          to="/vendor-toolkit/library/facts"
          flex="1 0 auto"
          leftIcon={<Icon as={BookOpenIcon} />}
        >
          View fact library
        </Button>
      </HStack>
    </Card>
  );
}

export default QuickActionsCard;
