import {nominate, Owner, OwnerType} from "../../../Types";

import {
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import {ChevronDownIcon, UsersIcon} from "@heroicons/react/20/solid";
import {Counts} from "../../../hooks/search";
import Only from "./Only";
import {useQueryData} from "../../../state";
import PortalMenuList from "../../../components/PortalMenuList";

const FilterOwners = ({
  relevantOwners,
  owners,
  setOwners,
  counts,
}: {
  relevantOwners: Owner[];
  owners: string[];
  setOwners: (a: string[]) => void;
  counts?: Counts;
}) => {
  const hasMine = owners.includes("ASSIGNED_TO_ME");
  const hasOthers = owners.includes("ASSIGNED_TO_OTHERS");

  const {user, associated_owners} = useQueryData({queryKey: ["whoAmI"]});
  const myAssociatedOwnerIds = associated_owners.map(o => o.owner_id);
  const allOwnerIds = relevantOwners.map(o => o.owner_id);
  const otherOwnerIds = allOwnerIds.filter(id => !myAssociatedOwnerIds.includes(id));
  const myOwnerIds = allOwnerIds.filter(id => myAssociatedOwnerIds.includes(id));

  let valueBase = owners;
  if (hasMine) {
    valueBase = [...valueBase, ...myOwnerIds];
  }
  if (hasOthers) {
    valueBase = [...valueBase, ...otherOwnerIds];
  }

  const value = [...new Set(valueBase)];

  const count = value.length - +hasMine - +hasOthers;

  const userTeams = useQueryData({queryKey: ["userTeams", user.user_id]});
  const userTeamIds = userTeams.map(t => t.team_id);

  const userOwners = relevantOwners.filter(o => o.owner_payload.type === OwnerType.User);
  const teamOwners = relevantOwners.filter(o => o.owner_payload.type === OwnerType.Team);

  const onChangeOwners = (v: string | string[]) => {
    const arr = typeof v === "string" ? [v] : v;
    // We want to figure out what value they unchecked on this change
    const [removedValue] = value.filter(item => !arr.includes(item));

    let newOwners = arr;
    if (removedValue) {
      switch (removedValue) {
        case "ASSIGNED_TO_ME":
          newOwners = arr.filter(item => !myOwnerIds.includes(nominate("ownerId", item)));
          break;
        case "ASSIGNED_TO_OTHERS":
          newOwners = arr.filter(item => !otherOwnerIds.includes(nominate("ownerId", item)));
          break;
        case "UNASSIGNED":
          newOwners = arr.filter(item => item !== "UNASSIGNED");
          break;
        default:
          // If a normal user was unchecked while "ASSIGNED_TO_ME/ASSIGNED_TO_OTHERS" is checked
          if (arr.includes("ASSIGNED_TO_ME") || arr.includes("ASSIGNED_TO_OTHERS")) {
            // Switch to precise filtering
            // Remove the appropriate overarching category
            if (myOwnerIds.includes(nominate("ownerId", removedValue))) {
              newOwners = arr.filter(item => item !== "ASSIGNED_TO_ME");
            } else {
              newOwners = arr.filter(item => item !== "ASSIGNED_TO_OTHERS");
            }
          } // Otherwise keep the exact specified filter
          else {
            newOwners = arr;
          }
          break;
      }
    }

    // If ASSIGNED_TO_ME/ASSIGNED_TO_OTHERS is checked, the individual owners shouldn't be part of the set
    // This needs to be done on each change since we're getting the full set of selected values from the MenuOptions
    // which is expected, but we don't want to have the individual owners in the owner set when they're covered
    // by selected category ASSIGNED_TO_ME/ASSIGNED_TO_OTHERS
    if (arr.includes("ASSIGNED_TO_ME")) {
      newOwners = newOwners.filter(item => !myOwnerIds.includes(nominate("ownerId", item)));
    }
    if (newOwners.includes("ASSIGNED_TO_OTHERS")) {
      newOwners = newOwners.filter(item => !otherOwnerIds.includes(nominate("ownerId", item)));
    }

    if (newOwners.length !== 0) {
      setOwners(newOwners);
    }
  };

  const ownerCount = (counts: Counts, owner: string): number => {
    if (owner === "ASSIGNED_TO_ME") {
      return Object.entries(counts)
        .filter(e => e[0] !== "UNASSIGNED" && myOwnerIds.includes(nominate("ownerId", e[0])))
        .reduce((a, b) => a + b[1], 0);
    } else if (owner === "ASSIGNED_TO_OTHERS") {
      return Object.entries(counts)
        .filter(e => e[0] !== "UNASSIGNED" && !myOwnerIds.includes(nominate("ownerId", e[0])))
        .reduce((a, b) => a + b[1], 0);
    }
    return counts[owner] || 0;
  };

  const only = (user: string) => (e: any) => {
    e.stopPropagation();
    setOwners([user]);
  };

  return (
    <Menu placement="bottom-end" closeOnSelect={false}>
      <MenuButton
        as={Button}
        colorScheme="blue"
        rightIcon={<Icon as={ChevronDownIcon} />}
        leftIcon={<Icon as={UsersIcon} />}
      >
        Owners ({count})
      </MenuButton>
      <PortalMenuList fontSize="md" maxH="50vh" overflowY="auto">
        <MenuOptionGroup type="checkbox" value={value} onChange={onChangeOwners}>
          <MenuItemOption
            value="ASSIGNED_TO_ME"
            _focus={{".only": {visibility: "visible"}, bg: "gray.100"}}
            transition="0"
          >
            <Flex align="center">
              <Text color="orange.600" flex={1} mr={5}>
                Assigned to me
              </Text>
              <Only onClick={only("ASSIGNED_TO_ME")} />
              {counts && <Text color="gray.400">{ownerCount(counts, "ASSIGNED_TO_ME")}</Text>}
            </Flex>
          </MenuItemOption>
          <MenuItemOption
            value="ASSIGNED_TO_OTHERS"
            _focus={{".only": {visibility: "visible"}, bg: "gray.100"}}
            transition="0"
          >
            <Flex align="center">
              <Text color="orange.600" flex={1} mr={5}>
                Assigned to others
              </Text>
              <Only onClick={only("ASSIGNED_TO_OTHERS")} />
              {counts && <Text color="gray.400">{ownerCount(counts, "ASSIGNED_TO_OTHERS")}</Text>}
            </Flex>
          </MenuItemOption>
          <MenuItemOption value="UNASSIGNED" _focus={{".only": {visibility: "visible"}, bg: "gray.100"}} transition="0">
            <Flex align="center">
              <Text color="orange.600" flex={1} mr={5}>
                Unassigned
              </Text>
              <Only onClick={only("UNASSIGNED")} />
              {counts && <Text color="gray.400">{ownerCount(counts, "UNASSIGNED")}</Text>}
            </Flex>
          </MenuItemOption>
          <MenuDivider />
          <Text paddingX={5} paddingY={2} fontWeight={"semibold"}>
            Users
          </Text>
          {userOwners.map(({owner_id, owner_payload}) => (
            <MenuItemOption
              value={owner_id}
              key={owner_id}
              _focus={{".only": {visibility: "visible"}, bg: "gray.100"}}
              transition="0"
            >
              <Flex align="center">
                <Text
                  flex={1}
                  mr={5}
                  color={
                    owner_payload.type === OwnerType.User && owner_payload.content?.user_id === user.user_id
                      ? "green.700"
                      : undefined
                  }
                >
                  {owner_payload.content.name}
                </Text>
                <Only onClick={only(owner_id)} />
                {counts && <Text color="gray.400">{ownerCount(counts, owner_id)}</Text>}
              </Flex>
            </MenuItemOption>
          ))}
          {teamOwners.length && (
            <>
              <MenuDivider />
              <Text paddingX={5} paddingY={2} fontWeight={"semibold"}>
                Teams
              </Text>
            </>
          )}
          {teamOwners.map(({owner_id, owner_payload}) => (
            <MenuItemOption
              value={owner_id}
              key={owner_id}
              _focus={{".only": {visibility: "visible"}, bg: "gray.100"}}
              transition="0"
            >
              <Flex align="center">
                <Text
                  flex={1}
                  mr={5}
                  color={
                    owner_payload.type === OwnerType.Team && userTeamIds.includes(owner_payload.content?.team_id)
                      ? "green.700"
                      : undefined
                  }
                >
                  {owner_payload.content.name}
                </Text>
                <Only onClick={only(owner_id)} />
                {counts && <Text color="gray.400">{ownerCount(counts, owner_id)}</Text>}
              </Flex>
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </PortalMenuList>
    </Menu>
  );
};

export default FilterOwners;
