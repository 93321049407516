import {Flex, Button, ButtonProps, Icon, MenuButton, Box, Menu} from "@chakra-ui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import ModifiersMenuList, {ModifiersMenuProps} from "./ModifiersMenuList";
import * as _ from "lodash-es";

const ModifiersButton = ({
  isLoading,
  buttonProps,
  ...props
}: ModifiersMenuProps & {
  buttonProps?: ButtonProps;
  isLoading: boolean;
}) => {
  const effectiveValue = {...props.defaultValue, ...props.value};
  const changed = props.usedValue && !_.isEqual(effectiveValue, props.usedValue);
  const bubbleSize = buttonProps?.size === "md" ? "5" : "4";
  const width = buttonProps?.size === "md" ? "75px" : "65px";
  return (
    <Menu closeOnSelect={false} placement="bottom-end">
      <MenuButton
        as={Button}
        size="sm"
        w={width}
        rightIcon={<Icon as={ChevronDownIcon} />}
        borderLeft="1px solid"
        borderColor="purple.700"
        isLoading={isLoading}
        {...buttonProps}
      >
        <Flex position="relative">
          <Flex
            fontSize="xs"
            bg="purple.100"
            color="purple.500"
            ml={1}
            w={bubbleSize}
            h={bubbleSize}
            rounded="full"
            align="center"
            justify="center"
            flexShrink={0}
          >
            {Object.values(props.value).filter(Boolean).length}
          </Flex>
          {changed && <Box position="absolute" w="6px" h="6px" bg="red.500" rounded="full" right="-1px" top="-1px" />}
        </Flex>
      </MenuButton>
      <ModifiersMenuList {...props} />
    </Menu>
  );
};

export default ModifiersButton;
