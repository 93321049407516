import {Route} from "react-router-dom";
import AssessmentPage from "./AssessmentPage.tsx";
import {Title} from "../ThirdParty/Assessments/index.tsx";

import controlRoutes from "./Control";
import Crumb from "./Crumb.tsx";
export default (
  <Route path="assessments" handle={{Crumb: Title}}>
    <Route path=":assessmentId" Component={AssessmentPage} handle={{Crumb}} />
    <Route path=":assessmentId" handle={{Crumb}}>
      {controlRoutes}
    </Route>
  </Route>
);
