import {withSuspense} from "../../../../../state/withSuspense.tsx";
import {Button, Icon, Stack, Text} from "@chakra-ui/react";
import {DocumentIcon, PlusIcon} from "@heroicons/react/24/solid";
import {usePromiseState} from "../../../../../hooks/promiseState.ts";
import api from "../../../../../api";
import {useQueryData} from "../../../../../state";
import ShowcaseMessages from "../ShowcaseMessages.tsx";
import {useState} from "react";
import {ShowcaseUpdateMessageId, UpdateNotificationStatus} from "../../../../../Types.ts";
import {ActionBar} from "../../../../../Extension/components/ActionBar.tsx";

const PendingUpdates = withSuspense(() => {
  const [creating, create] = usePromiseState(async () => {
    const messageId = await api.vendorToolkit.trustCenter.createShowcaseMessage();
    setSelectedMessage(messageId);
    return messageId;
  }, []);

  const newMessages = useQueryData({queryKey: ["vendorToolkit", "trustCenterNewShowcaseMessages"]});
  const messages = useQueryData({queryKey: ["vendorToolkit", "trustCenterDraftShowcaseMessages"]});

  const [selectedMessage, setSelectedMessage] = useState<ShowcaseUpdateMessageId | undefined>(
    newMessages.total > 0 ? newMessages.message_ids[0] : messages.message_ids[0],
  );

  return (
    <Stack flex={1} spacing={0}>
      <ActionBar
        padding={3}
        bgColor={"white"}
        justifyContent={"flex-end"}
        actionButtons={
          <Button
            leftIcon={<Icon as={PlusIcon} />}
            colorScheme="green"
            alignSelf="stretch"
            onClick={create}
            isDisabled={creating.inProgress}
            mr={4}
          >
            Create new message
          </Button>
        }
      />
      {messages.total + newMessages.total === 0 ? (
        <Stack flex={1} justifyContent="center" alignItems="center" spacing={4} bg="gray.50">
          <Icon as={DocumentIcon} color="gray.500" boxSize={8} />
          <Text fontSize="md" color="gray.500">
            You don't have any pending messages.
          </Text>
        </Stack>
      ) : (
        <ShowcaseMessages
          messages={messages}
          newMessages={newMessages}
          selectedMessage={selectedMessage}
          setSelectedMessage={setSelectedMessage}
          type_={UpdateNotificationStatus.Draft}
        ></ShowcaseMessages>
      )}
    </Stack>
  );
});

export default PendingUpdates;
