import {Alert, AlertDescription, AlertIcon, AlertTitle, Button, Flex} from "@chakra-ui/react";
import {useState} from "react";
import api from "../../../../../../../api/index.ts";
import {QuestionnaireId} from "../../../../../../../Types.ts";
import ConvertedSectionsPreview from "../Preview/ConvertedSections.tsx";
import SelectAnsweringMode from "../../components/AnsweringMode/Select.tsx";
import {ImportState} from "../importState";
import {SizedStack} from "../../../../../../../hooks/stack";
import {CommonBody, CommonFooter} from "../Common";
import {AnsweringMode} from "../../components/AnsweringMode";
import {usePromiseState} from "../../../../../../../hooks/promiseState";
import {useQueryData} from "../../../../../../../state";
import {formatISO} from "../../../../../../../utils/date";
import {router} from "../../../../../../../router";

const ImportStep = ({
  stack: {value, pop},
  onClose,
  navigateTo,
  questionnaireId,
}: {
  stack: SizedStack<ImportState, 4>;
  onClose: () => void;
  navigateTo?: boolean;
  questionnaireId: QuestionnaireId;
}) => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const [answeringMode, setAnsweringMode] = useState(AnsweringMode.Considered);
  const [advancing, advance] = usePromiseState(async () => {
    await api.vendorToolkit.questionnaires.importQuestions(questionnaireId, {
      sections: value[3],
      questions: [],
      automate: answeringMode === AnsweringMode.Instant,
    });
    await api.vendorToolkit.questionnaires.createOriginalFile(questionnaireId, value[1].file.file_id);
    if (!whoami.internal_mode) {
      await api.vendorToolkit.questionnaires.updateOriginalFileImportedAt(
        questionnaireId,
        value[1].file.file_id,
        formatISO(new Date()),
      );
    }
    onClose();
    if (navigateTo) {
      router!.navigate(`/vendor-toolkit/questionnaires/${questionnaireId}/questions`);
    }
  }, [value, answeringMode, whoami, questionnaireId, navigateTo, onClose]);

  return (
    <>
      <CommonBody step={3}>
        <ConvertedSectionsPreview createSections={value[3]} />
        <SelectAnsweringMode value={answeringMode} onChange={setAnsweringMode} />
        {advancing.lastError ? (
          <Alert status="error" variant="subtle" flexDirection="column" gap={2}>
            <Flex alignItems="center">
              <AlertIcon />
              <AlertTitle fontSize="md" color="red.700">
                Failed to import questions
              </AlertTitle>
            </Flex>
            <AlertDescription fontWeight="thin" fontSize="md" color="red.700" whiteSpace="pre-line" w="full">
              {advancing.lastError.toString()}
            </AlertDescription>
          </Alert>
        ) : null}
      </CommonBody>
      <CommonFooter onClose={onClose}>
        <Button onClick={pop} isDisabled={advancing.inProgress}>
          Back
        </Button>
        <Button
          onClick={advance}
          isDisabled={
            !Object.values(value[2]).some(sheetState => sheetState.sections.length > 0) || advancing.inProgress
          }
          isLoading={advancing.inProgress}
        >
          Import
        </Button>
      </CommonFooter>
    </>
  );
};

export default ImportStep;
