import {windowedFiniteBatchScheduler} from "@yornaath/batshit";
import {HTTPError} from "../api";
import {Nominal} from "../Types";

type ExtractIdPropertyNames<T> = {
  [K in keyof T]: T[K] extends Nominal<string, string> ? K : never;
}[keyof T];

export function resolver<
  T extends Array<any>,
  const K extends ExtractIdPropertyNames<R>,
  R = T extends Array<infer A> ? Exclude<A, null> : never,
>(key: K) {
  return (items: T, query: R[K]): R => {
    const result = items.find(item => item[key] == query);
    if (result == null) {
      throw new HTTPError(new Response(null, {status: 404}));
    }
    return result;
  };
}

export const scheduler = windowedFiniteBatchScheduler({windowMs: 10, maxBatchSize: 100});
