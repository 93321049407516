import {chakra} from "@chakra-ui/react";

export type NotificationLevel = "low" | "medium" | "high";

function notificationColor(level: NotificationLevel): string {
  switch (level) {
    case "low":
      return "gray.400";
    case "medium":
      return "yellow.500";
    case "high":
      return "red.500";
  }
}

export function NotificationDot({
  level = "high",
  ...props
}: {level?: NotificationLevel} & React.ComponentProps<typeof chakra.div>) {
  return <chakra.div flex="0 0 auto" boxSize="2" borderRadius="100%" bgColor={notificationColor(level)} {...props} />;
}
