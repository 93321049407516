import {Box, Divider, Heading, HStack, Icon, Stack, Text} from "@chakra-ui/react";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import {Control, ControlId} from "../../../../Types";
import {useMemo} from "react";

type EvidenceT = {
  file_name: string;
  snippet: string;
  control_id: ControlId;
};

function generateDemoEvidence(controls: Control[]): EvidenceT[] {
  function getControlId(controlNumber: string): ControlId {
    return controls.find(c => c.control_number === controlNumber)!.control_id;
  }

  return [
    {
      file_name: "AccessControlPolicy.docx",
      snippet:
        "1.2 Access Control Policy: This document outlines the purpose, scope, roles, responsibilities, and compliance requirements for access control within our organization.",
      control_id: getControlId("AC-01"),
    },

    {
      file_name: "SecurityProcedures.pdf",
      snippet:
        "Section 3: Detailed procedures for implementing access controls, including user provisioning, access review, and revocation processes.",
      control_id: getControlId("AC-01"),
    },

    {
      file_name: "HR_Onboarding.txt",
      snippet:
        "Step 5: IT creates user accounts based on job role and department, following the principle of least privilege.",
      control_id: getControlId("AC-02"),
    },

    {
      file_name: "IT_SOP_AccountManagement.docx",
      snippet:
        "4.3 Account Deactivation: Accounts must be disabled within 24 hours of employee termination or role change.",
      control_id: getControlId("AC-02"),
    },

    {
      file_name: "AccessMatrix.xlsx",
      snippet: "Sheet 1: Comprehensive matrix mapping user roles to system resources and allowed actions.",
      control_id: getControlId("AC-03"),
    },

    {
      file_name: "FirewallRules.config",
      snippet: "rule 15: allow tcp from 192.168.1.0/24 to 10.0.0.5 port 443",
      control_id: getControlId("AC-04"),
    },

    {
      file_name: "JobDescriptions.pdf",
      snippet:
        "Financial Controller: Responsible for reviewing and approving expenses. Cannot initiate payments to prevent potential fraud.",
      control_id: getControlId("AC-05"),
    },

    {
      file_name: "UserAccessReview_Q2.xlsx",
      snippet:
        "Column E: Indicates whether current access level adheres to the principle of least privilege for each user.",
      control_id: getControlId("AC-06"),
    },
    {
      file_name: "LoginConfig.xml",
      snippet: "<max-attempts>5</max-attempts>\n<lockout-duration>30</lockout-duration>",
      control_id: getControlId("AC-07"),
    },
    {
      file_name: "SystemLoginScreen.png",
      snippet: "Image showing login screen with legal disclaimer and privacy notice",
      control_id: getControlId("AC-08"),
    },
    {
      file_name: "UserGuide.pdf",
      snippet:
        "2.3 Login Process: Upon successful authentication, you will see the date and time of your last login. Please verify this information for security purposes.",
      control_id: getControlId("AC-09"),
    },
    {
      file_name: "SessionSettings.conf",
      snippet: "MAX_CONCURRENT_SESSIONS=3\nSESSION_TIMEOUT=60",
      control_id: getControlId("AC-10"),
    },
    {
      file_name: "ScreenLockPolicy.gpo",
      snippet:
        "Computer Configuration > Policies > Windows Settings > Security Settings > Local Policies > Security Options: Interactive logon: Machine inactivity limit = 900 seconds",
      control_id: getControlId("AC-11"),
    },
    {
      file_name: "NetworkAccessPolicy.docx",
      snippet:
        "5.1 Remote Access: All remote connections must use VPN with multi-factor authentication. Access is limited to predefined IP ranges.",
      control_id: getControlId("AC-17"),
    },
    {
      file_name: "WirelessSecurityStandard.pdf",
      snippet:
        "3.2 Encryption: All wireless networks must use WPA3 encryption. Guest networks must be segregated from the corporate network.",
      control_id: getControlId("AC-18"),
    },
    {
      file_name: "MobileDeviceManagement.yaml",
      snippet: "enforce_encryption: true\nrequire_passcode: true\nminimum_passcode_length: 6\nallow_jailbroken: false",
      control_id: getControlId("AC-19"),
    },
  ];
}

const EvidenceSource = ({evidence}: {evidence: EvidenceT}) => (
  <Stack divider={<Divider />} spacing={0}>
    <HStack py={3} px={4} gap={4}>
      <Stack gap={0}>
        <Text fontWeight="bold" color="gray.900">
          {evidence.file_name}
        </Text>
        <HStack fontSize="sm" color="gray.500" gap={2} divider={<Text fontWeight="bold">·</Text>}>
          <Text>File</Text>
          <Text>Updated 2 days ago</Text>
        </HStack>
      </Stack>
      <Box flex={1} />
      <Icon as={ChevronRightIcon} fontSize="3xl" color="gray.500" />
    </HStack>
    <Stack py={3} px={4} bg="gray.50" color="gray.600">
      <Text>{evidence.snippet}</Text>
    </Stack>
  </Stack>
);

const Evidence = ({controls, control}: {controls: Control[]; control: Control}) => {
  const evidence = useMemo(
    () => generateDemoEvidence(controls).filter(e => e.control_id === control.control_id),
    [control, controls],
  );

  return (
    <Stack p={6} gap={6}>
      <Heading size="md" color="gray.600">
        Evidence
      </Heading>
      <Stack
        border="1px solid"
        borderColor="gray.200"
        rounded="lg"
        overflow="hidden"
        fontSize="md"
        divider={<Divider />}
        spacing={0}
      >
        {evidence.map((e, i) => (
          <EvidenceSource evidence={e} key={i} />
        ))}
      </Stack>
    </Stack>
  );
};

export default Evidence;
