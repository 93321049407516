import {FactClusterId, ResolutionId} from "../../../../Types";
import {useQueryData} from "../../../../state";
import {PAGINATION_NO_LIMIT} from "../../../../utils/pagination";
import {useSubClusterTitle} from "./hooks";

export const ExploreCrumb = ({clusterId}: {clusterId: FactClusterId}) => {
  return useSubClusterTitle(clusterId);
};

export const ResolutionCrumb = ({resolutionId}: {resolutionId: ResolutionId}) => {
  const resolution = useQueryData({queryKey: ["vendorToolkit", "resolutions", {...PAGINATION_NO_LIMIT}]}).items.find(
    resolution => resolution.resolution_id === resolutionId,
  );
  return `${resolution?.resolution_type}`;
};
