import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {CloudArrowUpIcon, UserPlusIcon} from "@heroicons/react/20/solid";
import {memo, useState} from "react";

import templates, {NoTemplate, Template} from "./templates";
import {CreateDocument, DocumentAuthority, DocumentCategory, SharingClassification} from "../../../../Types";
import api from "../../../../api";
import SingleFileUpload from "../../../../components/fileUploads/SingleFileUpload";
import {useQueryData} from "../../../../state";
import {router} from "../../../../router";
import {useAsyncOperation} from "../../../../hooks/asyncOperation";
import {FileUpload} from "../../../../hooks/fileUpload";
import {useResetting} from "../../../../hooks/resetting";
import {useValidation, useValidatedPromiseState} from "../../../../hooks/validationState";
import {SUPPORTED_DOCUMENT_FORMATS} from "../../../../components/fileUploads";
import {useAbortable} from "../../../../hooks/abortable";

async function loadTemplateContent(template: Template) {
  const templateReq = await fetch(template.url);
  if (!templateReq.ok) {
    throw new Error("Failed to load template");
  }
  return await templateReq.text();
}

const NewPolicy = memo(() => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const [name, setName] = useResetting(useState(""), isOpen);
  const [fileUpload, setFileUpload] = useResetting(useAbortable(useState<FileUpload | null>(null)), isOpen);
  const [template, setTemplate] = useResetting(useState(NoTemplate), isOpen);
  const [validationErrors, setValidationErrors] = useValidation(useResetting(useState({}), isOpen), {name});
  const fileUploadState = useAsyncOperation(fileUpload);
  const [authorPolicy, setAuthorPolicy] = useResetting(useState(false), isOpen);

  const [saving, save] = useValidatedPromiseState(
    async () => {
      let creationArgs: CreateDocument;
      if (authorPolicy) {
        creationArgs = {
          review_period: {type: "None"},
          sharing_classification: SharingClassification.Internal,
          name,
          category: DocumentCategory.Policy,
          owner_id: whoami.user_owner!.owner_id,
          authority: DocumentAuthority.Template,
          authoring_template: await loadTemplateContent(template),
          authoring_config: template.config,
        };
      } else {
        if (fileUploadState?.id !== "Uploaded") {
          // Not ready
          return;
        }
        creationArgs = {
          review_period: {type: "None"},
          sharing_classification: SharingClassification.Internal,
          name,
          category: DocumentCategory.Policy,
          owner_id: whoami.user_owner!.owner_id,
          authority: DocumentAuthority.File,
          file_id: fileUploadState!.result.file_id,
        };
      }
      const {document_id} = await api.vendorToolkit.documents.create(creationArgs);

      router!.navigate(`/vendor-toolkit/library/policies/${document_id}`);
      onClose();
    },
    [authorPolicy, onClose, name, template, fileUploadState, whoami.user_owner],
    setValidationErrors,
  );

  const handleSetTemplate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const template = templates.find(t => t.name === e.target.value);
    if (template) {
      setTemplate(template);
    }
  };

  return (
    <>
      <Button leftIcon={<Icon as={UserPlusIcon} />} onClick={onOpen}>
        New policy
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={save}>
          <ModalHeader>Create new policy</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="8" mb="4">
              <FormControl isRequired isInvalid={validationErrors.name !== undefined}>
                <FormLabel>Policy name</FormLabel>
                <Input
                  onChange={e => setName(e.target.value)}
                  value={name}
                  isDisabled={saving.inProgress}
                  placeholder="Password Policy"
                />
                {validationErrors.name && validationErrors.name[0] && (
                  <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl>
                <Flex align="center">
                  <FormLabel htmlFor="author-policy" flex="1" mb="0">
                    Author policy within Platformed
                  </FormLabel>
                  <Switch
                    id="author-policy"
                    onChange={() => setAuthorPolicy(!authorPolicy)}
                    isChecked={authorPolicy}
                    isDisabled={saving.inProgress}
                  />
                </Flex>
                <FormHelperText color="gray.500">
                  You can use Platformed's policy authoring to help you build a policy. Select "no" to import a policy
                  you've built elsewhere.
                </FormHelperText>
              </FormControl>
              {authorPolicy ? (
                <Stack spacing="1">
                  <FormControl>
                    <Flex align="center">
                      <FormLabel htmlFor="use-template" flex="1" mb="0">
                        Use Platformed template
                      </FormLabel>
                      <Switch
                        id="use-template"
                        onChange={() => setTemplate(template === NoTemplate ? templates[0] : NoTemplate)}
                        isChecked={template !== NoTemplate}
                        isDisabled={saving.inProgress}
                      />
                    </Flex>
                    <FormHelperText color="gray.500">
                      You can use a Platformed template to get a ready-made policy which can be tailored to your needs.
                      Select "no" to build a policy from scratch.
                    </FormHelperText>
                  </FormControl>
                  {template !== NoTemplate && (
                    <FormControl isRequired>
                      <FormLabel>Template</FormLabel>
                      <Select onChange={handleSetTemplate} value={template.name} isDisabled={saving.inProgress}>
                        {templates.map(({name}) => (
                          <option key={name} value={name}>
                            {name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Stack>
              ) : (
                <SingleFileUpload
                  Cls={FileUpload}
                  value={fileUpload}
                  accept={SUPPORTED_DOCUMENT_FORMATS}
                  onChange={setFileUpload}
                >
                  {isDragActive => (
                    <HStack align="center" spacing={2}>
                      <Icon as={CloudArrowUpIcon} fontSize="xl" color={isDragActive ? "blue.500" : "gray.500"} />
                      <Text>Drag policy here</Text>
                    </HStack>
                  )}
                </SingleFileUpload>
              )}

              {saving.lastError ? (
                <Alert status="error" mt="12">
                  <AlertIcon boxSize="40px" />
                  <Box>
                    <AlertTitle fontSize="md">Error creating policy</AlertTitle>
                    <AlertDescription fontSize="md">{`${saving.lastError}`}</AlertDescription>
                  </Box>
                </Alert>
              ) : null}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing="3">
              <Button variant="ghost" onClick={onClose} isDisabled={saving.inProgress}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={saving.inProgress}
                isDisabled={
                  validationErrors.name !== undefined || (!authorPolicy && fileUploadState?.id !== "Uploaded")
                }
              >
                Create
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

export default NewPolicy;
