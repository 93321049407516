import {AvatarProps, Flex, FlexProps, Highlight, Text} from "@chakra-ui/react";
import {ThirdPartyMin} from "../../../Types";
import CompanyLogo from "./CompanyLogo";

const ThirdPartyName = ({
  thirdParty,
  queries,
  avatarSize = "xs",
  ...props
}: {thirdParty: ThirdPartyMin; queries?: string[]; avatarSize?: AvatarProps["size"]} & FlexProps) => (
  <Flex align="center" {...props}>
    <CompanyLogo name={thirdParty.name} size={avatarSize} domain={thirdParty.url} mr={2} />
    <Text noOfLines={2}>
      <Highlight query={queries ?? ""} styles={{bg: "yellow.200"}}>
        {thirdParty.name}
      </Highlight>
    </Text>
  </Flex>
);

export default ThirdPartyName;
