import {Question, Section} from "../Types";

export default function filterSections(
  sections: Section[],
  questions: Question[],
): (Omit<Section, "questions"> & {questions: Question[]})[] {
  let questionIdx = 0;
  const result = [];
  for (const section of sections) {
    const filteredQuestions = [];
    for (const question of section.questions) {
      if (questionIdx < questions.length && question.question_id === questions[questionIdx].question_id) {
        filteredQuestions.push(questions[questionIdx]);
        questionIdx += 1;
      }
    }
    result.push({...section, questions: filteredQuestions});
  }
  return result;
}
