import api from "../../../api";
import {param, typedQuery} from "../../typing";
import {ContentId, ShowcaseUpdateMessageId, UpdateNotificationStatus} from "../../../Types.ts";

export default [
  typedQuery(["vendorToolkit", "trustCenterConfig"], async () => {
    return await api.vendorToolkit.trustCenter.getConfig();
  }),
  typedQuery(["vendorToolkit", "trustCenterContents"], async () => {
    return await api.vendorToolkit.trustCenter.listContents();
  }),
  typedQuery(["vendorToolkit", "trustCenterContent", param<ContentId>("contentId")], async context => {
    return await api.vendorToolkit.trustCenter.getContent(context.queryKey[2]);
  }),
  typedQuery(["vendorToolkit", "trustCenterMailingList"], async () => {
    return await api.vendorToolkit.trustCenter.getMailingList();
  }),
  typedQuery(["vendorToolkit", "trustCenterNewShowcaseMessages"], async () => {
    return await api.vendorToolkit.trustCenter.listShowcaseMessages(UpdateNotificationStatus.New);
  }),
  typedQuery(["vendorToolkit", "trustCenterDraftShowcaseMessages"], async () => {
    return await api.vendorToolkit.trustCenter.listShowcaseMessages(UpdateNotificationStatus.Draft);
  }),
  typedQuery(["vendorToolkit", "trustCenterSentShowcaseMessages"], async () => {
    return await api.vendorToolkit.trustCenter.listShowcaseMessages(UpdateNotificationStatus.Sent);
  }),
  typedQuery(
    ["vendorToolkit", "trustCenterShowcaseMessage", param<ShowcaseUpdateMessageId>("messageId")],
    async context => {
      return await api.vendorToolkit.trustCenter.getShowcaseMessage(context.queryKey[2]);
    },
  ),
];
