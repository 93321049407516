import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  BoxProps,
  Flex,
  HStack,
  Icon,
  IconProps,
  Text,
  Tooltip,
  keyframes,
  useDisclosure,
} from "@chakra-ui/react";
import {Fragment, ReactNode, useCallback, useMemo, useRef, useState} from "react";
import SingleFileUpload from "../../../../../../components/fileUploads/SingleFileUpload.tsx";
import {CloudArrowUpIcon, ExclamationCircleIcon, ExclamationTriangleIcon} from "@heroicons/react/20/solid";
import {FileUpload} from "../../../../../../hooks/fileUpload.ts";
import {UploadState} from "./uploadState.ts";
import {ErrorMessage, FileErrors, RowErrors} from "./importCsv.ts";
import {useMotionValueEvent, useScroll} from "framer-motion";
import {useResizeObserver} from "usehooks-ts";
import SelectAnsweringMode from "../components/AnsweringMode/Select.tsx";

function PreTooltip({label, children}: {label?: ReactNode; children: ReactNode}) {
  return <Tooltip label={<Text whiteSpace="pre-line">{label}</Text>}>{children}</Tooltip>;
}

const WarningIcon = (props: IconProps) => (
  <Icon as={ExclamationTriangleIcon} color="yellow.500" w="16px" h="16px" mx={1} {...props} />
);
const ErrorIcon = (props: IconProps) => (
  <Icon as={ExclamationCircleIcon} color="red.500" w="16px" h="16px" mx={1} {...props} />
);
const glowAnimation = keyframes`  
  from {
    box-shadow: 0 0 10px -10px yellow;
  }
  to {
    box-shadow: 0 0 10px 10px yellow;
  }
`;
const Glowing = (props: BoxProps) => (
  <Box
    position="absolute"
    left="50%"
    top="50%"
    w="0px"
    h="0px"
    animation={`${glowAnimation} 1s infinite alternate`}
    {...props}
  />
);

function ErrorMessages({messages}: {messages: ErrorMessage[]}) {
  const hasError = messages.some(msg => msg.severity === "Error");
  const hasWarning = messages.some(msg => msg.severity === "Warning");
  const {isOpen, onClose} = useDisclosure({defaultIsOpen: true});
  return messages.length > 0 ? (
    <Tooltip
      label={
        <Box>
          {messages.map((msg, i) => (
            <Flex key={i} my={1}>
              {msg.severity === "Warning" ? <WarningIcon /> : <ErrorIcon />}
              <Text whiteSpace="pre-line">{msg.message}</Text>
            </Flex>
          ))}
        </Box>
      }
      hasArrow
    >
      <Flex
        h="full"
        direction="column"
        display="flex"
        justifyContent="center"
        gap={1}
        py={2}
        px={1}
        onMouseEnter={onClose}
      >
        {hasWarning && (
          <Flex position="relative">
            {isOpen && <Glowing />}
            <WarningIcon position="relative" />
          </Flex>
        )}
        {hasError && (
          <Flex position="relative">
            {isOpen && <Glowing />}
            <ErrorIcon position="relative" />
          </Flex>
        )}
      </Flex>
    </Tooltip>
  ) : null;
}

function BoxWithScrollIndicators({children, maxH, maxW, ...props}: BoxProps) {
  const container = useRef<HTMLDivElement>(null);
  const [showXScrollIndicator, setShowXScrollIndicator] = useState(false);
  const [showYScrollIndicator, setShowYScrollIndicator] = useState(false);
  const {scrollX, scrollY} = useScroll({container});
  const onChange = useCallback(() => {
    setShowXScrollIndicator(scrollX.get() < container.current!.scrollWidth - container.current!.clientWidth);
    setShowYScrollIndicator(scrollY.get() < container.current!.scrollHeight - container.current!.clientHeight);
  }, [scrollX, scrollY]);
  useMotionValueEvent(scrollX, "change", onChange);
  useMotionValueEvent(scrollY, "change", onChange);
  useResizeObserver({ref: container, onResize: onChange});
  const scrollbarW = container.current ? container.current.offsetWidth - container.current.clientWidth - 0.5 : 0;
  const scrollbarH = container.current ? container.current.offsetHeight - container.current.clientHeight - 0.5 : 0;
  return (
    <Box position="relative" {...props}>
      <Box ref={container} position="relative" overflow="auto" maxH={maxH} maxW={maxW}>
        {children}
      </Box>
      <Box
        position="absolute"
        left="0px"
        top="0px"
        right={`${scrollbarW}px`}
        bottom={`${scrollbarH}px`}
        pointerEvents="none"
        overflow="hidden"
      >
        <Box
          position="absolute"
          h="full"
          w="16px"
          right="0px"
          top="0px"
          bg="radial-gradient(ellipse at right, black, transparent 70%)"
          opacity={showXScrollIndicator ? 0.25 : 0}
          transition="opacity 0.2s"
        />
        <Box
          position="absolute"
          w="full"
          h="16px"
          bottom="0px"
          left="0px"
          bg="radial-gradient(ellipse at bottom, black, transparent 70%)"
          opacity={showYScrollIndicator ? 0.25 : 0}
          pointerEvents="none"
          transition="opacity 0.2s"
        />
      </Box>
    </Box>
  );
}

function FileErrorsTable({errors}: {errors: FileErrors}) {
  const numColumns = errors.columns.length + 1;
  const expandedRows = useMemo(() => {
    const expandedRows: (RowErrors | null)[] = [];
    let prevIdx = -1;
    errors.rows.forEach((row, i) => {
      if (i !== prevIdx + 1) {
        expandedRows.push(null);
      }
      expandedRows.push(row);
      prevIdx = i;
    });
    expandedRows.push(null);
    return expandedRows;
  }, [errors]);

  return (
    <BoxWithScrollIndicators
      maxH="350px"
      w="full"
      border="1px solid"
      borderColor="gray.500"
      bgColor="white"
      color="chakra-body-text"
      fontSize="sm"
    >
      <Box display="grid" gridTemplateColumns={`repeat(${numColumns}, minmax(auto, 300px))`}>
        {expandedRows.map((row, i) =>
          row ? (
            <Fragment key={i}>
              <Box
                bgGradient="linear(to-r, gray.200, gray.100)"
                borderTop={i > 0 ? "1px solid" : undefined}
                borderColor="gray.300"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection="row-reverse"
              >
                <Text p={2}>{row.row + 1}</Text>
                <ErrorMessages messages={row.rowMessages} />
              </Box>
              {row.cells.map((cell, j) => (
                <Box
                  key={j}
                  display="flex"
                  alignItems="center"
                  borderLeft="1px solid"
                  borderTop={i > 0 ? "1px solid" : undefined}
                  borderColor="gray.300"
                  fontWeight={i > 0 ? undefined : "semibold"}
                  justifyContent="space-between"
                >
                  <PreTooltip label={cell.value}>
                    <Text p={2} whiteSpace="pre" textOverflow="ellipsis" overflow="hidden">
                      {cell.value}
                    </Text>
                  </PreTooltip>
                  <ErrorMessages messages={cell.cellMessages} />
                </Box>
              ))}
            </Fragment>
          ) : (
            <Box
              key={i}
              gridColumn={`span ${numColumns}`}
              p={2}
              textAlign="center"
              fontWeight="bold"
              borderTop={i > 0 ? "1px solid" : undefined}
              borderColor="gray.300"
              bgColor="gray.50"
            >
              <Text>...</Text>
            </Box>
          ),
        )}
      </Box>
    </BoxWithScrollIndicators>
  );
}

const UploadStep = ({
  parsingFile,
  submitting,
  setFileQuestions,
  clearSubmit,
  parseFile,
  setFileUpload,
  fileUpload,
  answeringMode,
  setAnsweringMode,
}: UploadState) => {
  const lastError = parsingFile.lastError ?? submitting.lastError;

  const handleFileChange = useCallback(
    (fileUpload: FileUpload | null) => {
      clearSubmit();
      if (fileUpload) {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          parseFile(fileReader, setFileQuestions);
        };
        fileReader.readAsText(fileUpload.file);
      }
      setFileUpload(fileUpload);
    },
    [clearSubmit, parseFile, setFileUpload, setFileQuestions],
  );

  return (
    <>
      <SingleFileUpload Cls={FileUpload} value={fileUpload} onChange={handleFileChange} accept={{"text/csv": [".csv"]}}>
        {isDragActive => (
          <HStack align="center" spacing={2}>
            <Icon as={CloudArrowUpIcon} fontSize="xl" color={isDragActive ? "blue.500" : "gray.500"} />
            <Text>Drag your CSV here</Text>
          </HStack>
        )}
      </SingleFileUpload>
      {lastError ? (
        <Alert status="error" variant="subtle" flexDirection="column" gap={2}>
          <Flex alignItems="center">
            <AlertIcon />
            <Box>
              <AlertTitle fontSize="md" color="red.700">
                There was a problem with the uploaded file
              </AlertTitle>
              <Box fontSize="sm" color="red.700">
                Mouse over error or warning icons for more information.
              </Box>
            </Box>
          </Flex>
          <AlertDescription fontWeight="thin" fontSize="md" color="red.700" whiteSpace="pre-line" w="full">
            {lastError instanceof FileErrors ? <FileErrorsTable errors={lastError} /> : lastError.toString()}
          </AlertDescription>
        </Alert>
      ) : null}
      <SelectAnsweringMode value={answeringMode} onChange={setAnsweringMode} />
    </>
  );
};

export default UploadStep;
