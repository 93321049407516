// This monstrosity allows a component to "own" state which contains
// objects which should be explicitly aborted when they are no longer
// needed.

import {Dispatch, SetStateAction, useCallback, useEffect, useRef} from "react";
import {useEventCallback} from "usehooks-ts";

export interface IAbortable {
  abort(reason?: any): void;
}

function defaultListAbortable(state: any): IAbortable[] {
  return state ? (Array.isArray(state) ? state : [state]) : [];
}

// Special-case for when the state itself is abortable
export function useAbortable<T extends IAbortable | null | undefined>(
  useStateResult: [T, Dispatch<SetStateAction<T>>],
): [T, Dispatch<SetStateAction<T>>];

// Special-case for when the state is a list of abortables
export function useAbortable<T extends IAbortable>(
  useStateResult: [T[], Dispatch<SetStateAction<T[]>>],
): [T[], Dispatch<SetStateAction<T[]>>];

// General case, requires list function
export function useAbortable<T>(
  useStateResult: [T, Dispatch<SetStateAction<T>>],
  // Returns the a list of "abortable" objects in the provided state object
  listAbortables: (state: T) => IAbortable[],
): [T, Dispatch<SetStateAction<T>>];

export function useAbortable<T>(
  [state, setState]: [T, Dispatch<SetStateAction<T>>],
  listAbortables: (state: T) => IAbortable[] = defaultListAbortable,
): [T, Dispatch<SetStateAction<T>>] {
  // Use a ref to keep track of the currently owned "abortable" objects
  // extracted from the state.
  const abortables = useRef(listAbortables(state));

  // Helper function to update the list, and "abort()" any objects that
  // were removed.
  const setAbortables = useCallback((newAbortables: IAbortable[]) => {
    for (const abortable of abortables.current) {
      if (newAbortables.indexOf(abortable) === -1) {
        abortable.abort();
      }
    }
    abortables.current = newAbortables;
  }, []);

  // `listAbortables` is intended to be a pure function, so we don't
  // want to include that in the dependencies.
  const cachedListAbortables = useEventCallback(listAbortables);

  // When the user calls `setState`, we also want to update the list
  // of abortables.
  const setStateWrapper = useCallback(
    (newState: SetStateAction<T>) => {
      if (newState instanceof Function) {
        // User passed a function to generate the new state based on the old
        setState(oldValue => {
          const newValue = newState(oldValue);
          setAbortables(cachedListAbortables(newValue));
          return newValue;
        });
      } else {
        // User passed a simple value
        setAbortables(cachedListAbortables(newState));
        setState(newState);
      }
    },
    [cachedListAbortables, setAbortables, setState],
  );

  // When the component is unmounted, we also want to abort
  // everything owned by the component.
  useEffect(() => () => setAbortables([]), [setAbortables]);

  return [state, setStateWrapper];
}
