import {ButtonGroup, Heading, HStack, Icon, LinkBox, LinkOverlay, Stack, Text} from "@chakra-ui/react";
import VendorToolkitLogo from "../../../assets/vendor-toolkit.png";
import TPRMLogo from "../../../assets/tprm.png";
import {AccountFeature} from "../../Types";
import {useAccountFeatures} from "../../hooks/accountFeatures";
import {useQueryData} from "../../state";
import Page from "../../components/Page";
import {Navigate, Link as RouterLink} from "react-router-dom";
import {ChevronRightIcon} from "@heroicons/react/24/solid";
import {useSessionStorage} from "usehooks-ts";

type Product = {
  label: string;
  path: string;
  description: string;
  feature: AccountFeature;
  logoUrl: string;
};

const PRODUCTS = [
  {
    label: "Vendor toolkit",
    path: "vendor-toolkit",
    description: "Tools to support you selling to enterprises and regulated businesses.",
    feature: AccountFeature.VendorToolkit,
    logoUrl: VendorToolkitLogo,
  },
  {
    label: "Third-party risk management (TPRM)",
    path: "tprm",
    description: "Tools to evaluate and manage the risk in your digital supply chain.",
    feature: AccountFeature.TPRM,
    logoUrl: TPRMLogo,
  },
] as const satisfies Product[];

function ProductCard({product}: {product: Product}) {
  const [_, setLandingPage] = useSessionStorage("landing-page", "product-selector");

  return (
    <LinkBox
      as={HStack}
      role="group"
      border="1px solid"
      borderColor="gray.200"
      rounded="md"
      p={8}
      _hover={{
        borderColor: "blue.300",
        bg: "blue.50",
        color: "blue.500",
      }}
      _focusVisible={{
        boxShadow: "outline",
      }}
      transitionProperty="common"
      transitionDuration="normal"
      cursor="pointer"
      userSelect="none"
      justifyContent="space-between"
      spacing={8}
      onClick={() => setLandingPage(product.path)}
    >
      <Stack spacing={0}>
        <Heading size="md" fontWeight="500">
          <LinkOverlay as={RouterLink} to={`/${product.path}`}>
            {product.label}
          </LinkOverlay>
        </Heading>
        <Text color="gray.500" fontSize="md">
          {product.description}
        </Text>
      </Stack>
      <Icon as={ChevronRightIcon} boxSize={5} color="gray.400" />
    </LinkBox>
  );
}

export default function ProductSelector() {
  const whoami = useQueryData({queryKey: ["whoAmI"]});

  const {enabledFeatures} = useAccountFeatures();
  const enabledProducts = PRODUCTS.filter(p => enabledFeatures[p.feature]);

  if (enabledProducts.length === 1) {
    return <Navigate to={`/${enabledProducts[0].path}`} replace />;
  }

  return (
    <Page
      title={"Select Product"}
      display="flex"
      flexDirection="column"
      borderLeft="none"
      borderRight="none"
      gap={16}
      pb={8}
      overflow="visible"
    >
      <Heading fontWeight="600">{whoami.account!.display_name}</Heading>
      <ButtonGroup orientation="vertical">
        {enabledProducts.map(p => (
          <ProductCard key={p.path} product={p} />
        ))}
      </ButtonGroup>
    </Page>
  );
}
