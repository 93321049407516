import {Stack, Text} from "@chakra-ui/react";
import {withSuspense} from "../../../../state/withSuspense";
import {useQueryData} from "../../../../state";
import {QuestionId} from "../../../../Types";
import {SourcedFactRow} from "../../Library/Facts/FactRow";

const QuestionGeneratedFacts = withSuspense(({questionId}: {questionId: QuestionId}) => {
  const generatedFacts = useQueryData({queryKey: ["vendorToolkit", "questionGeneratedFacts", questionId]});

  return generatedFacts.length > 0 ? (
    <Stack spacing={4}>
      <Stack spacing={0}>
        <Text fontWeight="600">Generated facts</Text>
        <Text fontSize="md" color="gray.500">
          The response to this question is a source of these facts.
        </Text>
      </Stack>
      <Stack
        spacing={0}
        maxH="400"
        rounded="md"
        border="1px solid"
        borderColor="gray.200"
        overflowY="auto"
        overflowX="hidden"
      >
        {generatedFacts.map(f => (
          <SourcedFactRow key={f.type === "Fact" ? f.content.fact_id : f.content.fact_text_id} sourcedFact={f} />
        ))}
      </Stack>
    </Stack>
  ) : null;
});

export default QuestionGeneratedFacts;
