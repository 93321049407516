import {
  Box,
  Button,
  Circle,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  SquareProps,
  Stack,
  Text,
  Tooltip,
  useClipboard,
  useDisclosure,
} from "@chakra-ui/react";
import {memo, useMemo} from "react";
import {Fact, FactTextMin, ResolutionMin, ScopeMin, SourcedFact} from "../../../../Types.ts";
import {FactSources} from "./FactSources.tsx";
import {
  ArrowRightEndOnRectangleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ExclamationTriangleIcon,
  NoSymbolIcon,
  CubeIcon,
} from "@heroicons/react/20/solid";
import {usePromiseState} from "../../../../hooks/promiseState.ts";
import api from "../../../../api/index.ts";
import MoveFactModal from "../FactLibrary/modals/MoveFact.tsx";
import {Hierarchy, SectionParentNode} from "../FactLibrary/utility/hierarchy.ts";
import {DocumentDuplicateIcon} from "@heroicons/react/24/outline";
import {Link as RouterLink} from "react-router-dom";
import {useQueryData} from "../../../../state/index.ts";

export type FactLibraryContext = {
  hierarchy: Hierarchy;
  parent: SectionParentNode;
  recurse: boolean;
};

function RequiresReview({resolution}: {resolution: ResolutionMin}) {
  const url = `/vendor-toolkit/library/facts/review-items/${resolution.resolution_id}`;

  return (
    <>
      <Button
        as={RouterLink}
        to={url}
        // Re-setting chakra button styling
        height="auto"
        lineHeight="auto"
        bg="red.100"
        color="red.800"
        _hover={{
          bg: "red.200",
        }}
        _active={{
          bg: "red.300",
        }}
        // Other props
        leftIcon={<Icon as={ExclamationTriangleIcon} />}
        rightIcon={<Icon as={ChevronRightIcon} />}
        size="sm"
        fontWeight="500"
        px={2}
        py={1}
        rounded="md"
      >
        Fact requires review
      </Button>
    </>
  );
}

function MatchCircle({
  cosineDistance,
  closeThreshold = 0.25,
  nearThreshold = 0.3,
  ...props
}: {cosineDistance: number; closeThreshold?: number; nearThreshold?: number} & SquareProps) {
  const internal = useQueryData({queryKey: ["whoAmI"]}).internal_mode;

  const state = useMemo(() => {
    if (cosineDistance < closeThreshold) {
      return {
        bg: "green.500",
        label: "Close match",
      };
    } else if (cosineDistance < nearThreshold) {
      return {
        bg: "yellow.500",
        label: "Near match",
      };
    } else {
      return {
        bg: "gray.400",
        label: "Distant match",
      };
    }
  }, [closeThreshold, cosineDistance, nearThreshold]);

  const bg = state.bg;
  const label = internal ? `${state.label} (${cosineDistance.toFixed(2)})` : state.label;

  return (
    <Tooltip label={label}>
      <Circle size={2} bg={bg} {...props} />
    </Tooltip>
  );
}

const ACTION_BUTTON_PROPS = {
  fontSize: "lg",
  boxSize: "auto",
  minW: "auto",
  minH: "auto",
  variant: "ghost",
  p: 2,
} as const satisfies Omit<IconButtonProps, "aria-label">;

function CopyToClipboardAction({text}: {text: string}) {
  const {onCopy, hasCopied} = useClipboard(text, 1000);

  return (
    <Tooltip label={hasCopied ? "Copied!" : "Copy to clipboard"}>
      <IconButton
        icon={<Icon as={DocumentDuplicateIcon} />}
        aria-label="Copy to clipboard"
        {...ACTION_BUTTON_PROPS}
        onClick={ev => {
          ev.preventDefault();
          onCopy();
        }}
      />
    </Tooltip>
  );
}

function ViewScopesAction({scope}: {scope: ScopeMin}) {
  // Only take the color scheme portion of the scope axis color since this
  // widget needs to use various shades.
  const color = scope.axis.color.split(".")[0] ?? `blue`;

  const text = `${scope.axis.name}: ${scope.name}`;

  return (
    <Popover openDelay={0} closeDelay={0} trigger="hover">
      <PopoverTrigger>
        <Box>
          <IconButton
            cursor="auto"
            icon={<Icon as={CubeIcon} />}
            aria-label="View scopes"
            {...ACTION_BUTTON_PROPS}
            colorScheme={color}
            bg={`${color}.100`}
            _hover={{
              bg: `${color}.200`,
            }}
            mr="1"
          />
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="auto" overflow="hidden">
          <Stack fontSize="md" spacing={0}>
            <HStack bg="gray.50" borderBottom="1px" borderColor="gray.200" px={2} py={1}>
              <Text fontWeight="500">Scoped to:</Text>
            </HStack>
            <Stack spacing={0} pr={2}>
              <HStack _notLast={{borderBottom: "1px solid", borderColor: "gray.200"}}>
                <Box bg={`${color}.500`} w="4px" alignSelf="stretch"></Box>
                <HStack py={1}>
                  <Text>{text}</Text>
                </HStack>
              </HStack>
            </Stack>
          </Stack>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

function ToggleEnableFactAction({fact}: {fact: Fact}) {
  const [togglingFactEnabled, toggleFactEnabled] = usePromiseState(async () => {
    await api.vendorToolkit.facts.updateDisabled(fact.fact_id, !fact.disabled);
  }, [fact]);

  const label = fact.disabled ? "Enable this fact" : "Disable this fact";
  const icon = fact.disabled ? CheckIcon : NoSymbolIcon;

  return (
    <Tooltip label={label}>
      <IconButton
        icon={<Icon as={icon} />}
        aria-label={label}
        {...ACTION_BUTTON_PROPS}
        onClick={toggleFactEnabled}
        isDisabled={togglingFactEnabled.inProgress}
      />
    </Tooltip>
  );
}

function MoveFactWithinScopeAction({fact, libraryContext}: {fact: Fact; libraryContext: FactLibraryContext}) {
  const moveFactModal = useDisclosure();

  return (
    <>
      <Tooltip label="Move within scope">
        <IconButton
          icon={<Icon as={ArrowRightEndOnRectangleIcon} />}
          aria-label="Move within scope"
          onClick={moveFactModal.onOpen}
          {...ACTION_BUTTON_PROPS}
        />
      </Tooltip>
      {moveFactModal.isOpen && (
        <MoveFactModal
          fact={fact}
          {...moveFactModal}
          libraryContext={libraryContext}
          defaultParent={libraryContext.parent}
        />
      )}
    </>
  );
}

function PendingFactRowActions({factText}: {factText: FactTextMin}) {
  return (
    <>
      <CopyToClipboardAction text={factText.content} />
    </>
  );
}

function FactRowActions({fact, libraryContext}: {fact: Fact; libraryContext?: FactLibraryContext}) {
  return (
    <>
      {libraryContext == null && fact.library_section.scope != null && (
        <ViewScopesAction scope={fact.library_section.scope} />
      )}
      <CopyToClipboardAction text={fact.representative_text.content} />
      {libraryContext != null && <MoveFactWithinScopeAction fact={fact} libraryContext={libraryContext} />}
      <ToggleEnableFactAction fact={fact} />
    </>
  );
}

export const FactRow = memo(
  ({
    fact,
    libraryContext,
    cosineDistance,
  }: {
    fact: Fact;
    libraryContext?: FactLibraryContext;
    cosineDistance?: number;
  }) => {
    const resolutions = fact.resolutions.filter(r => !r.is_internal);
    const numSources = fact.document_sources.length + fact.question_sources.length + fact.subgraph_sources.length;

    return (
      <HStack
        alignItems="flex-start"
        fontSize="md"
        _notLast={{borderBottom: "1px solid", borderColor: "gray.200"}}
        pl={4}
        pr={1}
        spacing={4}
        _hover={{
          bg: "gray.50",
        }}
        bg={fact.disabled ? "gray.50" : undefined}
      >
        <HStack alignItems="flex-start" flex={1} my={3} spacing={4} opacity={fact.disabled ? 0.4 : undefined}>
          {cosineDistance != null && <MatchCircle cosineDistance={cosineDistance} mt="5px" />}
          <Stack>
            <Text flex="1" textDecoration={fact.disabled ? "line-through" : undefined}>
              <Text as="span">{fact.representative_text.content}</Text>
              {"  "}
              <Popover>
                <PopoverTrigger>
                  <HStack as="button" display="inline-flex" color="blue.500" spacing={1}>
                    <Text as="span" textDecoration="underline">
                      {numSources} source{numSources === 1 ? "" : "s"}
                    </Text>
                    <Icon as={ChevronDownIcon} />
                  </HStack>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent width="lg">
                    <FactSources fact={fact} />
                  </PopoverContent>
                </Portal>
              </Popover>
            </Text>
            {resolutions.length > 0 && (
              <HStack>
                <RequiresReview resolution={resolutions[0]} />
              </HStack>
            )}
          </Stack>
        </HStack>
        <HStack spacing={0} mt="1.5">
          <FactRowActions fact={fact} libraryContext={libraryContext} />
        </HStack>
      </HStack>
    );
  },
);

export const PendingFactRow = memo(({factText}: {factText: FactTextMin}) => {
  return (
    <HStack
      alignItems="flex-start"
      fontSize="md"
      _notLast={{borderBottom: "1px solid", borderColor: "gray.200"}}
      pl={4}
      pr={1}
      spacing={4}
      _hover={{
        bg: "gray.50",
      }}
    >
      <HStack alignItems="flex-start" flex={1} my={3} spacing={4}>
        <Tooltip label="This fact is still being processed for incorporation into your fact library.">
          <Spinner size="xs" mt="4px" color="blue.500" />
        </Tooltip>
        <Stack>
          <Text flex="1" color="gray.500">
            {factText.content}
          </Text>
        </Stack>
      </HStack>
      <HStack spacing={0} mt="1.5">
        <PendingFactRowActions factText={factText} />
      </HStack>
    </HStack>
  );
});

export const SourcedFactRow = memo(({sourcedFact}: {sourcedFact: SourcedFact}) => {
  return sourcedFact.type === "Fact" ? (
    <FactRow fact={sourcedFact.content} />
  ) : (
    <PendingFactRow factText={sourcedFact.content} />
  );
});
