import React, {useCallback} from "react";
import OwnerSelector from "../../../../../components/OwnerSelector";
import {getScopeId, ScopeNode, SectionNode} from "../utility/hierarchy";
import api from "../../../../../api";
import {Owner} from "../../../../../Types";
import {useQueryData} from "../../../../../state";

type Props = {
  node: ScopeNode | SectionNode;
};

function GlobalScopeOwner(props: React.ComponentProps<typeof OwnerSelector>) {
  const owner = useQueryData({queryKey: ["vendorToolkit", "scopes", "global", "owner"]}) ?? undefined;
  return <OwnerSelector owner={owner} {...props} />;
}

export function ScopeOrSectionOwner({node}: Props) {
  const reassign = useCallback(
    async (owner?: Owner) => {
      switch (node.type) {
        case "scope":
          {
            const id = getScopeId(node);
            await api.vendorToolkit.scopes.assign(id, owner?.owner_id ?? null);
          }
          break;

        case "section":
          {
            await api.vendorToolkit.librarySections.assign(node.obj.library_section_id, owner?.owner_id ?? null);
          }
          break;
      }
    },
    [node],
  );

  if (node.obj == null) {
    return <GlobalScopeOwner onReassign={reassign} />;
  } else {
    return <OwnerSelector owner={node.obj.owner} onReassign={reassign} />;
  }
}
