import {DocumentCheckIcon} from "@heroicons/react/20/solid";
import {RouteTab} from "../../../../components/RouterTabs/index.tsx";
import {Route} from "react-router-dom";

import ReportPage from "./ReportPage";
import ExecutiveSummaryReport from "./ExecutiveSummaryReport.tsx";
import DetailedReport from "./DetailedReport.tsx";
import AuditReport from "./AuditReport.tsx";

export default {
  title: "Reports",
  path: "reports",
  icon: DocumentCheckIcon,
  routes: (
    <>
      <Route path="" element={<ReportPage />} />
      <Route path="executive-summary" element={<ExecutiveSummaryReport />} handle={{crumb: "Executive Summary"}} />
      <Route path="detailed-report" element={<DetailedReport />} handle={{crumb: "Detailed Report"}} />
      <Route path="audit-report" element={<AuditReport />} handle={{crumb: "Audit Report"}} />
    </>
  ),
} satisfies RouteTab;
