import {Avatar, HStack, Tag, TagLabel} from "@chakra-ui/react";

import {UserMin} from "../Types";
import {memo} from "react";

const User = memo(({user}: {user?: UserMin}) => {
  let colorScheme;
  let tagBody;
  if (user) {
    colorScheme = "orange";
    tagBody = (
      <>
        <Avatar ml={-2} src={user.avatar_url} name={user.name ?? user.primary_email} size="xs" />
        <TagLabel>{user.name ?? user.primary_email}</TagLabel>
      </>
    );
  } else {
    colorScheme = "gray";
    tagBody = <TagLabel>None</TagLabel>;
  }

  return (
    <Tag
      colorScheme={colorScheme}
      size="lg"
      borderRadius="full"
      _hover={{bg: `${colorScheme}.200`}}
      px={1}
      pointerEvents="none"
      position="relative"
      textAlign="left"
      wordBreak="break-all"
      overflow="hidden"
    >
      <HStack px={2} gap={2}>
        {tagBody}
      </HStack>
    </Tag>
  );
});

export default User;
