import {ThirdPartyId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["tprm", "thirdParties", param<ThirdPartyId>("thirdPartyId")], async context => {
    return await api.tprm.thirdParties.get(context.queryKey[2]);
  }),
  typedQuery(["tprm", "thirdParties"], async () => {
    return await api.tprm.thirdParties.list();
  }),
];
