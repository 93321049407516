import {Avatar, Box, Divider, HStack, Heading, Icon, Stack, Text} from "@chakra-ui/react";
import {AssessmentMin, AssessmentStatus as AssessmentStatusT} from "../../../Types";
import {CheckCircleIcon, ChevronRightIcon, EllipsisVerticalIcon, XCircleIcon} from "@heroicons/react/20/solid";

const Assessment = ({assessment}: {assessment: AssessmentMin}) => (
  <Stack
    bg="white"
    rounded="lg"
    border="1px"
    borderColor="gray.200"
    spacing={0}
    divider={<Divider borderColor="gray.200" />}
    minW="300"
    overflow="hidden"
    outline="2px solid"
    outlineOffset="-1px"
    outlineColor={assessment.unmitigated_risks > 0 ? "red.500" : "transparent"}
  >
    <HStack py={4} pl={6} pr={4} bg="gray.50">
      <Avatar name={assessment.framework_version.framework.name} size="xs" />
      <Heading size="sm" fontWeight="semibold">
        {assessment.framework_version.framework.name}
      </Heading>
      <Box flex={1} />
      <Icon fontSize="3xl" color="gray.500" as={EllipsisVerticalIcon} />
      <Icon fontSize="3xl" color="gray.500" as={ChevronRightIcon} />
    </HStack>
    <HStack py={3} px={6} fontSize="md">
      <Text flex={1}>Version</Text>
      <Text fontWeight="semibold">{assessment.framework_version.version}</Text>
    </HStack>
    <HStack py={3} px={6} fontSize="md">
      <Text flex={1}>Controls Assessed</Text>
      <HStack spacing={1} fontWeight="semibold">
        <Icon as={CheckCircleIcon} color="green.500" />
        <Text>122/122</Text>
      </HStack>
    </HStack>
    <HStack py={3} px={6} fontSize="md">
      <Text flex={1}>Unmitigated Risks</Text>
      <HStack spacing={1} fontWeight="semibold">
        {assessment.unmitigated_risks > 0 ? (
          <Icon as={XCircleIcon} color="red.500" />
        ) : (
          <Icon as={CheckCircleIcon} color="green.500" />
        )}
        <Text>{assessment.unmitigated_risks}</Text>
      </HStack>
    </HStack>
    {assessment.status == AssessmentStatusT.NonConformities && (
      <Box bg="red.500" color="white" fontSize="md" textAlign="center" py={1} fontWeight="semibold">
        Non conformities
      </Box>
    )}
  </Stack>
);

export default Assessment;
