import externalApi from "../../../api/external";
import {GetGraphQuery, ContentId, AccountId} from "../../../Types";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(
    ["external", "trustCenterDocuments", param<AccountId>("accountId"), param<string>("password")],
    async context => {
      return await externalApi.trustCenters.listDocuments(context.queryKey[2], context.queryKey[3]);
    },
  ),
  typedQuery(
    ["external", "trustCenterConfig", param<AccountId>("accountId"), param<string>("password")],
    async context => {
      return await externalApi.trustCenters.getConfig(context.queryKey[2], context.queryKey[3]);
    },
  ),
  typedQuery(
    ["external", "trustCenterFaq", param<AccountId>("accountId"), param<string>("password")],
    async context => {
      return await externalApi.trustCenters.getFaq(context.queryKey[2], context.queryKey[3]);
    },
  ),
  typedQuery(
    ["external", "trustCenterAiFaq", param<AccountId>("accountId"), param<string>("password")],
    async context => {
      return await externalApi.trustCenters.getAiFaq(context.queryKey[2], context.queryKey[3]);
    },
  ),
  typedQuery(
    [
      "external",
      "trustCenterContent",
      param<AccountId>("accountId"),
      param<ContentId>("contentId"),
      param<string>("password"),
    ],
    async context => {
      return await externalApi.trustCenters.getContent(context.queryKey[2], context.queryKey[3], context.queryKey[4]);
    },
  ),
  typedQuery(
    [
      "external",
      "trustCenterGraph",
      param<AccountId>("accountId"),
      param<GetGraphQuery>("query"),
      param<string>("password"),
    ],
    async context => {
      return await externalApi.trustCenters.getGraph(context.queryKey[2], context.queryKey[3], context.queryKey[4]);
    },
  ),
  typedQuery(
    ["external", "trustCenterSchema", param<AccountId>("accountId"), param<string>("password")],
    async context => {
      return await externalApi.trustCenters.getSchema(context.queryKey[2], context.queryKey[3]);
    },
  ),
];
