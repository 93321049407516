import {Outlet} from "react-router-dom";
import {useTrustCenterConfig} from "./config.ts";
import {clearAccessToken, useAccessToken} from "./hooks/accessToken";
import {withSuspense} from "../state/withSuspense";
import {useFavicon} from "../hooks/favicon";
import {useCallback, useState} from "react";
import {useClientAccount} from "./hooks/clientAccount";
import {ContextT} from "./hooks/trustCenterContext";
import ExtendTheme from "../theme/ExtendTheme";
import baseTheme from "./baseTheme";
import {AuthButton} from "./TopNav/Auth.tsx";
import {Box, Button, Flex, FormControl, HStack, Input, Text} from "@chakra-ui/react";
import DocumentTitle from "../components/DocumentTitle.tsx";
import {setTrustCenterPassword, useTrustCenterPassword} from "./hooks/accessPassword.ts";
import externalApi from "../api/external";
import {useSuspenseQuery} from "@tanstack/react-query";
import {AccountMin} from "../Types.ts";
import {useValidatedPromiseState, useValidation} from "../hooks/validationState.ts";
import {HTTPError} from "../api";

const LoadTrustCenter = withSuspense(() => {
  const clientAccount = useClientAccount();
  const localPassword = useTrustCenterPassword(clientAccount.account_id);

  const [canAccess, setCanAccess] = useState(false);

  const passwordAuthenticated = useSuspenseQuery({
    queryKey: ["trustCenter", "authenticatePassword", clientAccount.account_id],
    queryFn: async () => {
      try {
        await externalApi.trustCenters.authenticateTrustCenterPassword(clientAccount.account_id, localPassword ?? "");
        setCanAccess(true);
        return true;
      } catch {
        setCanAccess(false);
        return false;
      }
    },
  });

  const [password, setPassword] = useState("");
  const [_validationErrors, setValidationErrors] = useValidation(useState({}), {password});

  const [loggingInWithPassword, loginWithPassword] = useValidatedPromiseState(
    async () => {
      try {
        await externalApi.trustCenters.authenticateTrustCenterPassword(clientAccount.account_id, password);
        setTrustCenterPassword(clientAccount.account_id, password);
        setCanAccess(true);
      } catch (ex) {
        if (ex instanceof HTTPError) {
          switch (ex.response.status) {
            case 401:
              throw new Error("Incorrect password");
          }
        }
      }
    },
    [password, clientAccount.account_id],
    setValidationErrors,
  );

  return (
    <>
      {!passwordAuthenticated.data && !canAccess ? (
        <Flex justifyContent={"center"} alignItems={"center"} width={"100vw"} height={"100vh"}>
          <Box as="form" onSubmit={loginWithPassword} width={"30vw"} display={"flex"} marginBottom={20}>
            <FormControl>
              <HStack>
                <Text>Password:</Text>
                <Input
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  data-testid="tc-password-field"
                  borderColor={loggingInWithPassword.lastError ? "red" : ""}
                />
                <Button type="submit" isDisabled={loggingInWithPassword.inProgress} mx={4}>
                  Submit
                </Button>
              </HStack>
            </FormControl>
          </Box>
        </Flex>
      ) : (
        <LoadClientConfig clientAccount={clientAccount} />
      )}
    </>
  );
});

const LoadClientConfig = withSuspense(({clientAccount}: {clientAccount: AccountMin}) => {
  const config = useTrustCenterConfig();
  const accessToken = useAccessToken(clientAccount.account_id);
  const permissions = ["ISO27001"];
  const logout = useCallback(() => clearAccessToken(clientAccount.account_id), [clientAccount]);

  const title = `${config.name} Trust Center | Powered by Platformed`.trim();

  useFavicon(config.favicon_url);

  const context: ContextT = {loggedIn: !!accessToken, permissions, logout};

  return (
    <>
      <DocumentTitle title={title} platformedPrefix={false} />
      {config.is_public || context.loggedIn ? (
        <ExtendTheme extension={baseTheme}>
          <ExtendTheme extension={config.chakraTheme ?? {}}>
            <Outlet context={context} />
          </ExtendTheme>
        </ExtendTheme>
      ) : (
        <Flex width={"100vw"} height={"80vh"} justifyContent={"center"} alignContent={"center"}>
          <Text>Your trust center is not live. Please log in to view it.</Text>
          <AuthButton
            buttonType="Login"
            mx={4}
            justifyContent="flex-start"
            size="lg"
            fontSize="lg"
            px={4}
            leftIcon={undefined}
          >
            Login
          </AuthButton>
        </Flex>
      )}
    </>
  );
});

export default LoadTrustCenter;
