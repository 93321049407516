import {useCallback, useMemo} from "react";
import {useQueryData} from "../../../../../state";
import {withSuspense} from "../../../../../state/withSuspense";
import {AttributesUi, Attribute, AttributePayload} from "../Attributes";
import api from "../../../../../api";
import {nominate} from "../../../../../Types";

const TiersPage = withSuspense(() => {
  const tiers = useQueryData({queryKey: ["tprm", "thirdPartyTiers"]});

  const attributes: Attribute[] = useMemo(
    () => tiers.map(t => ({id: t.tier_id, title: t.title, color: t.color})),
    [tiers],
  );

  const onCreate = useCallback(async (attribute: AttributePayload) => {
    await api.tprm.thirdPartyTiers.create(attribute);
  }, []);

  const onUpdate = useCallback(async (id: string, attribute: AttributePayload) => {
    const nid = nominate("thirdPartyTierId", id);

    await api.tprm.thirdPartyTiers.updateTitle(nid, attribute.title);
    await api.tprm.thirdPartyTiers.updateColor(nid, attribute.color);
  }, []);

  const onDelete = useCallback(async (id: string) => {
    const nid = nominate("thirdPartyTierId", id);
    await api.tprm.thirdPartyTiers.delete_(nid);
  }, []);

  return (
    <AttributesUi
      label="tier"
      attributes={attributes}
      onCreate={onCreate}
      onUpdate={onUpdate}
      onDelete={onDelete}
      canDeleteLast={false}
    />
  );
});

export default TiersPage;
