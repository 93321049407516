import {
  AdHocQuestion,
  Counterparty,
  LayerType,
  Owner,
  OwnerType,
  Question,
  QuestionnaireMin,
  QuestionStatus,
  ResolutionMin,
  Task,
  ThirdPartyMin,
  UserMin,
  WhoAmI,
} from "../../../Types";

import {Facets} from "../../../hooks/search";
import {getResponseLayer} from "../../../hooks/layerType";
import * as _ from "lodash-es";

function uniqueOwners(owners: (Owner | undefined)[]) {
  return _.sortBy(
    _.uniqBy(
      owners.filter((owner: Owner | undefined): owner is Owner => !!owner),
      owner => owner?.owner_id,
    ),
    owner =>
      owner.owner_payload.content.name ??
      (owner.owner_payload.type === OwnerType.User ? owner.owner_payload.content.primary_email : ""),
  );
}

function uniqueUsers(users: (UserMin | undefined)[]) {
  return _.sortBy(
    _.uniqBy(
      users.filter((user: UserMin | undefined): user is UserMin => !!user),
      user => user?.user_id,
    ),
    user => user.name ?? user.primary_email,
  );
}

export const getQuestionsRelevantOwners = (questions: Question[], owner: Owner, layerType: LayerType) =>
  uniqueOwners([...questions.map(q => getResponseLayer(q.response_layers, layerType).owner), owner]);

export const getQuestionnaireRelevantOwners = (questionnaires: QuestionnaireMin[], owner: Owner) =>
  uniqueOwners([...questionnaires.map(q => q.owner), owner]);

export const getResolutionsRelevantOwners = (resolutions: ResolutionMin[], owner: Owner) =>
  uniqueOwners([...resolutions.map(r => r.owner), owner]);

export const getQuestionnaireRelevantCounterparties = (questionnaires: QuestionnaireMin[]) => {
  const result = [];
  const visitedIds = new Set();
  for (const questionnaire of questionnaires) {
    if (visitedIds.has(questionnaire.counterparty.counterparty_id)) {
      continue;
    }
    result.push(questionnaire.counterparty);
    visitedIds.add(questionnaire.counterparty.counterparty_id);
  }
  return result;
};

export const getAdHocQuestionsRelevantUsers = (questions: AdHocQuestion[], owner: Owner, layerType: LayerType) =>
  uniqueOwners([...questions.map(q => getResponseLayer(q.question.response_layers, layerType).owner), owner]);

export const getAdHocQuestionsRelevantAskers = (questions: AdHocQuestion[], whoami: WhoAmI) =>
  uniqueUsers([...questions.map(q => q.asker), whoami.user]);

export type QuestionFilters = {
  owners: string[];
  statuses: string[];
};

export const facetedSearchQuestion = (
  {owners, statuses}: QuestionFilters,
  layerType: LayerType,
  myOwners: Owner[],
): Facets<Question> => {
  const ownersSet = new Set(owners);
  const myOwnerIds = myOwners.map(o => o.owner_id);
  const showMyOwners = ownersSet.has("ASSIGNED_TO_ME");
  const showOtherOwners = ownersSet.has("ASSIGNED_TO_OTHERS");
  const showUnassigned = ownersSet.has("UNASSIGNED");
  const statusSet = new Set(statuses);

  const ownerFacet = {
    extract: (item: Question) => getResponseLayer(item.response_layers, layerType).owner,
    predicate: (value: Owner | undefined) =>
      value
        ? ownersSet.has(value.owner_id) || (myOwnerIds.includes(value.owner_id) ? showMyOwners : showOtherOwners)
        : showUnassigned,
    name: (value: Owner | undefined) => (value ? value.owner_id : "UNASSIGNED"),
  };

  const statusFacet = {
    extract: (item: Question) => getResponseLayer(item.response_layers, layerType).status,
    predicate: (value: QuestionStatus) => statusSet.has(value),
    name: (value: QuestionStatus) => value.toString(),
  };

  return {
    owner: ownerFacet,
    status: statusFacet,
  };
};

export type QuestionnaireFilters = {
  owners: string[];
  counterparties: string[];
};

export const facetedSearchQuestionnaires = ({owners, counterparties}: QuestionnaireFilters, myOwners: Owner[]) => {
  const ownersSet = new Set(owners);
  const myOwnerIds = myOwners.map(o => o.owner_id);
  const showMyOwners = ownersSet.has("ASSIGNED_TO_ME");
  const showOtherOwners = ownersSet.has("ASSIGNED_TO_OTHERS");
  const showUnassigned = ownersSet.has("UNASSIGNED");
  const counterpartiesSet = new Set(counterparties);
  const showAllCounterparties = counterpartiesSet.has("ALL");

  const ownerFacet = {
    extract: (item: QuestionnaireMin) => item.owner,
    predicate: (value: Owner | undefined) =>
      value
        ? ownersSet.has(value.owner_id) || (myOwnerIds.includes(value.owner_id) ? showMyOwners : showOtherOwners)
        : showUnassigned,
    name: (value: Owner | undefined) => (value ? value.owner_id : "UNASSIGNED"),
  };

  const counterpartyFacet = {
    extract: (item: QuestionnaireMin) => item.counterparty,
    predicate: (value: Counterparty) => showAllCounterparties || counterpartiesSet.has(value.counterparty_id),
    name: (value: Counterparty) => value.counterparty_id,
  };

  return {
    owner: ownerFacet,
    counterparty: counterpartyFacet,
  };
};

export type TaskFilters = {
  statuses: string[];
  counterparties: string[];
};

export const facetedSearchTasks = ({statuses, counterparties}: TaskFilters, layerType: LayerType) => {
  const statusSet = new Set(statuses);
  const counterpartiesSet = new Set(counterparties);
  const showAllCounterparties = counterpartiesSet.has("ALL");

  const statusFacet = {
    extract: (item: Task) => {
      const question =
        item.type === "QuestionnaireQuestion" ? item.content.question : item.content.adhoc_question.question;
      return getResponseLayer(question.response_layers, layerType).status;
    },
    predicate: (value: QuestionStatus) => statusSet.has(value),
    name: (value: QuestionStatus) => value.toString(),
  };

  const counterpartyFacet = {
    extract: (item: Task) => (item.type === "QuestionnaireQuestion" ? item.content.questionnaire.counterparty : null),
    predicate: (value: Counterparty | null) =>
      showAllCounterparties || (!!value && counterpartiesSet.has(value.counterparty_id)),
    name: (value: Counterparty | null) => value?.counterparty_id ?? "None",
  };

  return {
    status: statusFacet,
    counterparty: counterpartyFacet,
  };
};

export type AdHocQuestionFilters = {
  owners: string[];
  statuses: string[];
  askers: string[];
};

export const facetedSearchAdHocQuestion = (
  {owners, statuses, askers}: AdHocQuestionFilters,
  layerType: LayerType,
  myOwners: Owner[],
): Facets<AdHocQuestion> => {
  const ownersSet = new Set(owners);
  const myOwnerIds = myOwners.map(o => o.owner_id);
  const showMyOwners = ownersSet.has("ASSIGNED_TO_ME");
  const showOtherOwners = ownersSet.has("ASSIGNED_TO_OTHERS");
  const showUnassigned = ownersSet.has("UNASSIGNED");
  const statusSet = new Set(statuses);
  const askerSet = new Set(askers);
  const showAllAskers = askerSet.has("ALL");

  const ownerFacet = {
    extract: (item: AdHocQuestion) => getResponseLayer(item.question.response_layers, layerType).owner,
    predicate: (value: Owner | undefined) =>
      value
        ? ownersSet.has(value.owner_id) || (myOwnerIds.includes(value.owner_id) ? showMyOwners : showOtherOwners)
        : showUnassigned,
    name: (value: Owner | undefined) => (value ? value.owner_id : "UNASSIGNED"),
  };

  const statusFacet = {
    extract: (item: AdHocQuestion) => getResponseLayer(item.question.response_layers, layerType).status,
    predicate: (value: QuestionStatus) => statusSet.has(value),
    name: (value: QuestionStatus) => value.toString(),
  };

  const askerFacet = {
    extract: (item: AdHocQuestion) => item.asker,
    predicate: (value: UserMin | undefined) => showAllAskers || askerSet.has(value ? value.user_id : "NONE"),
    name: (value: UserMin | undefined) => (value ? value.user_id : "NONE"),
  };

  return {
    owner: ownerFacet,
    status: statusFacet,
    asker: askerFacet,
  };
};

export type ResolutionFilters = {
  owners: string[];
};

export const facetedSearchResolution = ({owners}: ResolutionFilters, myOwners: Owner[]): Facets<ResolutionMin> => {
  const ownersSet = new Set(owners);
  const myOwnerIds = myOwners.map(o => o.owner_id);
  const showMyOwners = ownersSet.has("ASSIGNED_TO_ME");
  const showOtherOwners = ownersSet.has("ASSIGNED_TO_OTHERS");
  const showUnassigned = ownersSet.has("UNASSIGNED");
  const ownerFacet = {
    extract: (item: ResolutionMin) => item.owner,
    predicate: (value: Owner | undefined) =>
      value
        ? ownersSet.has(value.owner_id) || (myOwnerIds.includes(value.owner_id) ? showMyOwners : showOtherOwners)
        : showUnassigned,
    name: (value: Owner | undefined) => (value ? value.owner_id : "UNASSIGNED"),
  };

  return {
    owner: ownerFacet,
  };
};

export type ThirdPartyFilters = Record<string, never>;

export const facetedSearchThirdParty = (_filters: ThirdPartyFilters): Facets<ThirdPartyMin> => {
  return {};
};
