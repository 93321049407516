import {Alert, AlertDescription, AlertIcon, AlertTitle, Button, Flex} from "@chakra-ui/react";
import {SetStateAction, useCallback, useState} from "react";
import WorkbookPreview from "../Preview/WorkbookPreview.tsx";
import {SizedStack} from "../../../../../../../hooks/stack.ts";
import {ImportState} from "../importState.ts";
import {CommonBody, CommonFooter} from "../Common.tsx";
import {usePromiseState} from "../../../../../../../hooks/promiseState.ts";
import type * as XLSX from "xlsx";
import api from "../../../../../../../api";
import {IdentifiedSheetSection} from "../../../../../../../Types";

const MAX_LENGTH = 64;

type CellValue = string | number | undefined | null;

function truncateCells(aoa: CellValue[][]): (string | undefined)[][] {
  for (const row of aoa) {
    for (let i = 0; i < row.length; ++i) {
      let v = row[i];
      if (v != null) {
        v = v.toString();
        if (v.length > MAX_LENGTH) {
          v = v.slice(0, MAX_LENGTH) + "...";
        }
        row[i] = v;
      } else {
        delete row[i];
      }
    }
  }
  return aoa as (string | undefined)[][];
}

function arrayToObject<T>(a: (T | undefined)[]): {[i: number]: T} {
  return Object.fromEntries(a.map((v, i) => [i, v]).filter(Boolean));
}

function arraysToObjects<T>(aoa: (T | undefined)[][]) {
  return arrayToObject(aoa.map(arrayToObject));
}

type IdentifySheetSectionsResult = {sheetName: string; sections: IdentifiedSheetSection[]};

async function identifySheetSections(
  xlsx: typeof XLSX,
  sheetName: string,
  sheet: XLSX.Sheet,
  totalSheets: number,
  setProgress: React.Dispatch<SetStateAction<number | null>>,
): Promise<IdentifySheetSectionsResult> {
  const aoa: CellValue[][] = xlsx.utils.sheet_to_json(sheet, {header: 1});
  const truncatedAoa = truncateCells(aoa);
  const ooo = arraysToObjects(truncatedAoa);
  const sections = await api.vendorToolkit.ai.identifySheetSections(ooo);
  setProgress(oldProgress => (oldProgress ?? 0) + 1 / totalSheets);
  return {
    sheetName,
    sections,
  };
}

const SelectStep = ({
  stack: {value, replace, push, pop},
  onClose,
}: {
  stack: SizedStack<ImportState, 2>;
  onClose: () => void;
}) => {
  const [progress, setProgress] = useState<number | null>(null);

  const [advancing, advance] = usePromiseState(async () => {
    const promises: Promise<IdentifySheetSectionsResult>[] = [];
    setProgress(0);
    for (const sheetName of value[1].selectedSheetNames) {
      promises.push(
        identifySheetSections(
          value[0].xlsx,
          sheetName,
          value[1].workbook.Sheets[sheetName],
          value[1].selectedSheetNames.length,
          setProgress,
        ),
      );
    }
    const results = await Promise.all(promises);
    setProgress(null);
    push(Object.fromEntries(results.map(result => [result.sheetName, {sections: result.sections}])));
  }, [value, push]);

  const setSelectedSheetNames = useCallback(
    (newValue: string[]) => {
      replace({...value[1], selectedSheetNames: newValue});
    },
    [replace, value],
  );

  return (
    <>
      <CommonBody step={1} progress={progress}>
        <WorkbookPreview
          workbook={value[1].workbook}
          selectedSheetNames={value[1].selectedSheetNames}
          setSelectedSheetNames={setSelectedSheetNames}
          xlsx={value[0].xlsx}
        />
        {advancing.lastError ? (
          <Alert status="error" variant="subtle" flexDirection="column" gap={2}>
            <Flex alignItems="center">
              <AlertIcon />
              <AlertTitle fontSize="md" color="red.700">
                Failed to identify question sections
              </AlertTitle>
            </Flex>
            <AlertDescription fontWeight="thin" fontSize="md" color="red.700" whiteSpace="pre-line" w="full">
              {advancing.lastError.toString()}
            </AlertDescription>
          </Alert>
        ) : null}
      </CommonBody>
      <CommonFooter onClose={onClose}>
        <Button onClick={pop} isDisabled={advancing.inProgress}>
          Back
        </Button>
        <Button
          onClick={advance}
          isDisabled={value[1].selectedSheetNames.length === 0 || advancing.inProgress}
          isLoading={advancing.inProgress}
        >
          Next
        </Button>
      </CommonFooter>
    </>
  );
};

export default SelectStep;
