import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import {MultiSelect} from "chakra-multiselect";
import {useMemo, useState} from "react";
import {useValidatedPromiseState} from "../../../../../hooks/validationState";
import api from "../../../../../api";
import {Fact} from "../../../../../Types";
import {getParentScope, SectionParentNode} from "../utility/hierarchy";
import {flattenScopeToOptions, fromOptionValue, toOptionValue} from "../utility/parentDropdown";
import {FactLibraryContext} from "../../Facts/FactRow";
import {useResetting} from "../../../../../hooks/resetting";

type Props = {
  isOpen: boolean;
  onClose: () => void;

  fact: Fact;
  libraryContext: FactLibraryContext;
  defaultParent: SectionParentNode;
};

function MoveFactModal({fact, isOpen, onClose, libraryContext, ...props}: Props) {
  const parentScope = useMemo(() => getParentScope(libraryContext.parent), [libraryContext.parent]);

  const options = useMemo(
    () => flattenScopeToOptions(parentScope, {scopeLabelSuffix: "(uncategorized)"}),
    [parentScope],
  );

  const [selectedParent, setSelectedParent] = useResetting(
    useState<string>(() => toOptionValue(props.defaultParent)),
    isOpen,
  );

  const [moving, move] = useValidatedPromiseState(async () => {
    const node = fromOptionValue(libraryContext.hierarchy, selectedParent);

    const sectionId = (() => {
      switch (node.type) {
        case "scope":
          return undefined;
        case "section":
          return node.obj.library_section_id;
      }
    })();

    await api.vendorToolkit.facts.updateLibrarySection(fact.fact_id, {
      scope_id: fact.library_section.scope?.scope_id,
      library_section_id: sectionId,
    });

    onClose();
  }, [fact.fact_id, libraryContext.hierarchy, onClose, fact.library_section, selectedParent]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={move}>
        <ModalHeader>Move fact</ModalHeader>
        <ModalBody>
          <VStack spacing="4" alignItems="flex-start">
            <Text fontSize="md">Move this fact to a different section within its scope.</Text>
            <FormControl>
              <FormLabel>Parent</FormLabel>
              <MultiSelect
                options={options}
                value={selectedParent}
                onChange={v => setSelectedParent(v as unknown as string)}
                single
                spellCheck={false}
              />
            </FormControl>
            {moving.lastError ? (
              <Alert status="error" mt="12">
                <AlertIcon boxSize="40px" />
                <Box>
                  <AlertTitle fontSize="md">Error moving fact</AlertTitle>
                  <AlertDescription fontSize="md">{`${moving.lastError}`}</AlertDescription>
                </Box>
              </Alert>
            ) : null}
          </VStack>
        </ModalBody>
        <ModalFooter gap="2">
          <Button variant="outline" onClick={onClose} isDisabled={moving.inProgress}>
            Cancel
          </Button>
          <Button type="submit" colorScheme="blue" isLoading={moving.inProgress} isDisabled={moving.inProgress}>
            Move fact
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default MoveFactModal;
