import {withSuspense} from "../../../../../state/withSuspense.tsx";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  ChakraProps,
  Divider,
  HStack,
  Icon,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import Page from "../../../../../components/Page.tsx";
import {useQueryData} from "../../../../../state/index.ts";
import {ChevronLeftIcon, ChevronRightIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {Link as RouterLink} from "react-router-dom";
import type {
  ApiDateTime,
  Fact,
  FactSourceDocument,
  FactSourceEntity,
  FactSourceQuestion,
  Owner,
} from "../../../../../Types.ts";
import {DateUI} from "../../../../../components/Date.tsx";
import {useCallback} from "react";
import * as _ from "lodash-es";
import ResolutionBadge from "./ResolutionBadge.tsx";
import {RESOLUTION_TYPE_MAP} from "./index.tsx";
import {usePromiseState} from "../../../../../hooks/promiseState.ts";
import api from "../../../../../api/index.ts";
import {router} from "../../../../../router/index.tsx";
import {
  questionResponse,
  sourceDocumentLink,
  sourceQuestionLink,
  sourceSubgraphLink,
  sourceSubgraphName,
} from "../index.tsx";
import FirstLineCenter from "../../../../components/FirstLineCenter.tsx";
import OwnerSelector from "../../../../../components/OwnerSelector.tsx";
import {Type} from "../../../../../typing";
import {useTypedParams} from "../../../../../hooks/typedParams";
import {PAGINATION_NO_LIMIT} from "../../../../../utils/pagination.ts";

// Props to add to intervening elements to prevent them from disrupting the grid layout
const subgridProps = {
  display: "grid",
  gridTemplateColumns: "subgrid",
  gridColumn: "1 / -1",
  padding: 0,
} satisfies ChakraProps;

// Props to add to grid cell elements to ensure consistent padding and alignment
const gridCellProps = {px: 6, py: 4} satisfies ChakraProps;

// Version of peer-hover that doesn't depend on element ordering
const peerHover = "[role=group]:has(>[data-peer]:hover)>&";

const FactSource = ({
  source,
  children,
  created_at,
}: {
  source: "Document" | "Question" | "Entity";
  children: React.ReactNode;
  created_at?: ApiDateTime;
}) => (
  <Box {...subgridProps}>
    <FirstLineCenter {...gridCellProps}>
      <Badge colorScheme={source === "Question" ? "blue" : "green"}>{source}</Badge>
    </FirstLineCenter>
    <Box {...gridCellProps}>{children}</Box>
    <FirstLineCenter color="gray.500" {...gridCellProps}>
      {created_at && <DateUI date={created_at} />}
    </FirstLineCenter>
  </Box>
);

const DocumentFactSource = ({source}: {source: FactSourceDocument}) => (
  <FactSource created_at={source.document_revision} source="Document">
    <Text fontWeight="semibold" as={RouterLink} _hover={{color: "blue.500"}} to={sourceDocumentLink(source)}>
      {source.document_name}
      <Icon as={ChevronRightIcon} verticalAlign="middle" mx={1} />
    </Text>
  </FactSource>
);

const QuestionFactSource = ({source}: {source: FactSourceQuestion}) => (
  <FactSource
    created_at={
      source.parent.type === "Section" ? source.parent.content.questionnaire_date : source.parent.content.question_date
    }
    source="Question"
  >
    <Stack gap={1}>
      <Text as={RouterLink} fontWeight="semibold" _hover={{color: "blue.500"}} to={sourceQuestionLink(source)}>
        {source.question_text}
        <Icon as={ChevronRightIcon} verticalAlign="middle" mx={1} />
      </Text>
      <Text>{questionResponse(source)}</Text>
    </Stack>
  </FactSource>
);

const EntityFactSource = withSuspense(({source}: {source: FactSourceEntity}) => {
  const subgraph = useQueryData({
    queryKey: ["vendorToolkit", "graph", {filter_entity_ids: [source.entity_id], recursion_depth: 2}],
  });
  return (
    <FactSource source="Entity">
      <Stack gap={1}>
        <Text as={RouterLink} fontWeight="semibold" _hover={{color: "blue.500"}} to={sourceSubgraphLink(subgraph)}>
          {sourceSubgraphName(subgraph)}
          <Icon as={ChevronRightIcon} verticalAlign="middle" mx={1} />
        </Text>
      </Stack>
    </FactSource>
  );
});

const FactRow = ({fact}: {fact: Fact}) => {
  const [togglingDisabled, toggleDisabled] = usePromiseState(async () => {
    api.vendorToolkit.facts.updateDisabled(fact.fact_id, !fact.disabled);
  }, [fact]);
  return (
    <AccordionItem borderColor="gray.100" fontSize="md" {...subgridProps}>
      <Box {...subgridProps} role="group">
        <AccordionButton
          {...gridCellProps}
          gridArea="auto / span 2"
          textAlign="left"
          _hover={{}}
          fontWeight="semibold"
          data-peer
          whiteSpace="pre-line"
          textDecoration={fact.disabled ? "line-through" : undefined}
          sx={{[peerHover]: {color: "black"}}}
        >
          {fact.representative_text.content}
        </AccordionButton>
        <Box {...gridCellProps}>
          <DateUI date={fact.representative_text.created_at} />
        </Box>
        <FirstLineCenter {...gridCellProps}>
          <Switch isChecked={!fact.disabled} onChange={toggleDisabled} isDisabled={togglingDisabled.inProgress} />
        </FirstLineCenter>
        <AccordionButton {...gridCellProps} _hover={{}} data-peer sx={{[peerHover]: {color: "black"}}} mb="auto">
          <FirstLineCenter>
            <AccordionIcon />
          </FirstLineCenter>
        </AccordionButton>
      </Box>
      <AccordionPanel
        borderTop="1px solid"
        borderColor="gray.100"
        bg="gray.50"
        color="gray.600"
        p={0}
        {...subgridProps}
        motionProps={{
          style: subgridProps,
          /* needed as display:none breaks the grid layout during animation */ unmountOnExit: true,
        }}
      >
        {fact.document_sources.map((ds, i) => (
          <DocumentFactSource key={i} source={ds} />
        ))}
        {fact.question_sources.map((qs, i) => (
          <QuestionFactSource key={i} source={qs} />
        ))}
        {fact.subgraph_sources.map((qs, i) => (
          <EntityFactSource key={i} source={qs} />
        ))}
      </AccordionPanel>
    </AccordionItem>
  );
};

export const ResolutionParams = Type.Object({resolutionId: Type.ResolutionId()});

const ResolutionPage = withSuspense(() => {
  const {resolutionId} = useTypedParams(ResolutionParams);
  const resolutions = useQueryData({queryKey: ["vendorToolkit", "resolutions", {...PAGINATION_NO_LIMIT}]}).items;
  const resolution = useQueryData({queryKey: ["vendorToolkit", "resolution", resolutionId]});

  const resolutionIdx = resolutions.findIndex(resolution => resolution.resolution_id === resolutionId);
  const prevResolutionId = resolutions[resolutionIdx - 1]?.resolution_id || undefined;
  const nextResolutionId = resolutions[resolutionIdx + 1]?.resolution_id || undefined;
  const info = RESOLUTION_TYPE_MAP[resolution.resolution_type];
  const canResolve = info.canResolve ? info.canResolve(resolution) : true;
  const reassign = useCallback(
    async (owner: Owner | undefined) => {
      api.vendorToolkit.facts.assignResolution(resolution.resolution_id, owner?.owner_id ?? null);
    },
    [resolution.resolution_id],
  );
  const advance = useCallback(async () => {
    await router!.navigate(
      nextResolutionId
        ? `/vendor-toolkit/library/clustered-facts/resolutions/${nextResolutionId}`
        : `/vendor-toolkit/library/clustered-facts/resolutions`,
    );
  }, [nextResolutionId]);
  const [ignoring, ignore] = usePromiseState(async () => {
    await api.vendorToolkit.facts.ignoreResolution(resolutionId);
    await advance();
  }, [advance, resolutionId]);
  const [resolving, resolve] = usePromiseState(async () => {
    await api.vendorToolkit.facts.resolveResolution(resolutionId);
    await advance();
  }, [advance, resolutionId]);

  return (
    <Page title="Facts" display="flex" flexDirection="column" position="relative" pb={20}>
      <Stack flex="1" spacing={0} divider={<Divider borderColor="gray.200" opacity={1} />}>
        <HStack justifyContent="space-between" p="4" bg="gray.50">
          <HStack>
            <Button
              as={RouterLink}
              leftIcon={<Icon as={ChevronLeftIcon} />}
              size="sm"
              to={prevResolutionId && `../${prevResolutionId}`}
              isDisabled={!prevResolutionId}
            >
              Previous
            </Button>
            <Button
              as={RouterLink}
              rightIcon={<Icon as={ChevronRightIcon} />}
              size="sm"
              to={nextResolutionId && `../${nextResolutionId}`}
              isDisabled={!nextResolutionId}
            >
              Next
            </Button>
          </HStack>
          <HStack>
            <Button
              colorScheme="orange"
              leftIcon={<Icon as={XMarkIcon} />}
              size="sm"
              isLoading={ignoring.inProgress}
              isDisabled={ignoring.inProgress}
              onClick={ignore}
            >
              Ignore and continue
            </Button>
          </HStack>
        </HStack>
        <Stack spacing={4} p={6}>
          <Stack align="flex-start">
            <HStack>
              <Text fontSize="sm" fontWeight="semibold">
                {resolution.review_necessity * 100}%
              </Text>
              <ResolutionBadge type={resolution.resolution_type} />
            </HStack>
            <Text>{resolution.reason}</Text>
            <Text fontStyle="italic" fontSize="sm">
              {resolution.review_explanation}
            </Text>
          </Stack>
          <HStack alignItems="flex-start" wrap="wrap">
            <OwnerSelector owner={resolution.owner} onReassign={reassign} />
          </HStack>
        </Stack>
        <Alert
          px={6}
          status={canResolve ? "success" : "error"}
          alignItems="flex-start"
          fontSize="md"
          variant="solid"
          my="-1px"
          borderY="1px solid"
          borderColor={canResolve ? "green.700" : "red.700"}
        >
          <AlertIcon />
          <Box>
            <AlertTitle>{canResolve ? "Ready to resolve" : "Unresolved"}</AlertTitle>
            <AlertDescription>Resolve this contradiction by disabling incorrect facts.</AlertDescription>
          </Box>
          {canResolve && (
            <Button
              ml="auto"
              mt="auto"
              mb="auto"
              rightIcon={<Icon as={ChevronRightIcon} />}
              isLoading={resolving.inProgress}
              isDisabled={resolving.inProgress}
              onClick={resolve}
            >
              Resolve and continue
            </Button>
          )}
        </Alert>
        <Stack gap={4}>
          <Box
            border="1px solid"
            borderColor="gray.100"
            rounded="md"
            display="grid"
            gridTemplateColumns="120px 12fr 3fr 2fr auto"
            gridAutoFlow="row"
          >
            <Box
              borderBottom="1px solid"
              borderColor="gray.100"
              color="gray.500"
              textTransform="uppercase"
              letterSpacing="wider"
              fontSize="sm"
              fontWeight="bold"
              {...subgridProps}
            >
              <Text gridArea="auto / span 2" {...gridCellProps}>
                Fact
              </Text>
              <Text {...gridCellProps}>Age</Text>
              <Text {...gridCellProps}>Enabled</Text>
            </Box>
            <Accordion allowMultiple {...subgridProps}>
              {resolution.involved_facts.map((f, i) => (
                <FactRow fact={f} key={i} />
              ))}
            </Accordion>
          </Box>
        </Stack>
      </Stack>
    </Page>
  );
});

export default ResolutionPage;
