import {Navigate, Route} from "react-router-dom";
import thirdPartyAttributeRoutes from "./ThirdPartyAttributes";
import AdminFrame from "../../components/AdminFrame.tsx";

export default (
  <Route path="configuration" Component={AdminFrame} handle={{crumb: "Configuration"}}>
    {thirdPartyAttributeRoutes}
    <Route index element={<Navigate to="third-party-attributes" replace />} />
  </Route>
);
