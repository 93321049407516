import {Route} from "react-router-dom";
import {withSuspense} from "../../../../state/withSuspense";
import {useQueryData} from "../../../../state";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {useCallback} from "react";
import api from "../../../../api";
import CommitInput from "../../../../components/CommitInput";
import {DeleteModalIconButton} from "../../../../components/DeleteModalButton";
import {usePromiseState} from "../../../../hooks/promiseState";
import {router} from "../../../../router";
import {UploadButton} from "../../../../components/fileUploads/UploadButton";
import {File, AssetId} from "../../../../Types";
import {FileUpload} from "../../../../hooks/fileUpload";
import {SUPPORTED_ASSET_FORMATS, onDropRejected} from ".";
import {PreviewFile} from "../../Library/Documents/PreviewFile";
import externalApi from "../../../../api/external";
import {Type} from "../../../../typing";
import {useTypedParams} from "../../../../hooks/typedParams";
import {useTrustCenterPassword} from "../../../../TrustCenter/hooks/accessPassword.ts";

export const DeleteAssetButton = ({assetId}: {assetId: AssetId}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [deletingAsset, deleteAsset] = usePromiseState(async () => {
    await api.vendorToolkit.assets.deleteAsset(assetId);
    router!.navigate("/vendor-toolkit/trust-center/assets");
  }, [assetId]);

  return (
    <DeleteModalIconButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      delete_={deleteAsset}
      deleting={deletingAsset.inProgress}
      modalHeader="Delete asset"
      modalBody="Are you sure you want to delete this asset?"
    />
  );
};

export const AssetParams = Type.Object({assetId: Type.AssetId()});

const Asset = withSuspense(() => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const {assetId} = useTypedParams(AssetParams);
  const asset = useQueryData({queryKey: ["vendorToolkit", "asset", assetId]});
  const updateTitle = useCallback(
    async (value: string) => {
      await api.vendorToolkit.assets.updateTitle(assetId, value);
    },
    [assetId],
  );
  const updateFile = useCallback(
    async (value: File) => {
      await api.vendorToolkit.assets.updateFile(assetId, value.file_id);
    },
    [assetId],
  );
  const password = useTrustCenterPassword(whoami.account!.account_id) ?? "";
  const assetUrl = externalApi.trustCenters.getAssetUrl(whoami.account!.account_id, assetId, password);
  const toast = useToast();
  const copyUrl = useCallback(() => {
    navigator.clipboard.writeText(assetUrl);
    toast({description: "Asset URL copied to clipboard!", status: "success"});
  }, [assetUrl, toast]);

  return (
    <Flex direction="column" flex={1} p={8} gap={4}>
      <HStack justifyContent="space-between">
        <Heading flex="1" size="lg">
          {asset.title}
        </Heading>
        <DeleteAssetButton assetId={assetId} />
      </HStack>
      <FormControl>
        <FormLabel>Title</FormLabel>
        <CommitInput onCommit={updateTitle} value={asset.title} />
      </FormControl>
      <FormControl>
        <FormLabel>Public URL</FormLabel>
        <InputGroup>
          <Input value={assetUrl} readOnly pr="4.5rem" textOverflow="ellipsis" overflow="hidden" />
          <InputRightElement width="4.5rem">
            <Button onClick={copyUrl} height="1.75rem" size="sm">
              Copy
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>

      <FormControl>
        <FormLabel>Asset</FormLabel>
        <Flex direction="column" gap={4} alignItems="start">
          <UploadButton
            Cls={FileUpload}
            onUpload={updateFile}
            onDropRejected={onDropRejected}
            accept={SUPPORTED_ASSET_FORMATS}
          >
            Upload new version
          </UploadButton>
          {asset.file && <PreviewFile file={asset.file} />}
        </Flex>
      </FormControl>
    </Flex>
  );
});

export default <Route path=":assetId" Component={Asset} />;
