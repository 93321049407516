import {FrameworkId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["tprm", "frameworks", param<FrameworkId>("frameworkId")], async context => {
    return await api.tprm.frameworks.get(context.queryKey[2]);
  }),
  typedQuery(["tprm", "frameworks"], async () => {
    return await api.tprm.frameworks.list();
  }),
];
