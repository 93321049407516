import {Box} from "@chakra-ui/react";

import {useOutletContext} from "react-router-dom";
import {TabOutletContext} from "../Actions/Actions.tsx";
import {ActivityStreamView} from "../../../../../components/ActivityStream";

const MetaPage = () => {
  const {document} = useOutletContext<TabOutletContext>();

  return (
    <Box p={6}>
      <ActivityStreamView activityStream={document.activity_stream} />
    </Box>
  );
};

export default MetaPage;
