import {generateDemoAssessments} from "./assessments";
import {AssessmentId, MitigationStatus, RiskLevel, Risk, ThirdPartyId, ControlId, Assessment} from "../../Types";
import api from "..";

async function generateDemoRisks(assessments: Assessment[]): Promise<Risk[]> {
  const {assessment_id, framework_version} = assessments[0];
  const controls = framework_version.control_groups.flatMap(cg => cg.controls);

  function getControlId(controlNumber: string): ControlId {
    return controls.find(c => c.control_number === controlNumber)!.control_id;
  }

  return <Risk[]>[
    {
      title: "Outdated Access Control Policy",
      description:
        "The current access control policy has not been reviewed or updated in over 18 months, potentially leaving gaps in addressing new technologies or threats.",
      management: {
        title: "Risk Accepted",
        status: MitigationStatus.MITIGATED,
      },
      likelihood: RiskLevel.MEDIUM,
      severity: RiskLevel.HIGH,
      overridden: false,
      source: {assessment_id, control_id: getControlId("AC-01")},
    },
    {
      title: "Incomplete Access Control Policy",
      description:
        "The current access control policy lacks clear guidance on coordination among organizational entities, potentially leading to inconsistent implementation.",
      likelihood: RiskLevel.MEDIUM,
      severity: RiskLevel.HIGH,
      source: {assessment_id, control_id: getControlId("AC-01")},
      overridden: false,
      management: {
        title: "Ignored",
        status: MitigationStatus.IGNORED,
      },
    },

    {
      title: "Delayed Account Termination",
      description:
        "User accounts of terminated employees are not consistently disabled within the required 24-hour timeframe, increasing the risk of unauthorized access.",
      likelihood: RiskLevel.HIGH,
      severity: RiskLevel.HIGH,
      source: {assessment_id, control_id: getControlId("AC-02")},
      overridden: false,
      management: {
        title: "Unmanaged",
        status: MitigationStatus.UNMITIGATED,
      },
    },

    {
      title: "Overly Permissive Access Rights",
      description:
        "Review of the access matrix indicates some users have more permissions than necessary for their roles, violating the principle of least privilege.",
      likelihood: RiskLevel.MEDIUM,
      severity: RiskLevel.MEDIUM,
      source: {assessment_id, control_id: getControlId("AC-06")},
      overridden: false,
      management: {
        title: "Unmanaged",
        status: MitigationStatus.UNMITIGATED,
      },
    },

    {
      title: "Inadequate Separation of Duties in Financial Processes",
      description:
        "Current job descriptions do not fully enforce separation of duties in all critical financial processes, potentially allowing for fraud or errors.",
      likelihood: RiskLevel.LOW,
      severity: RiskLevel.HIGH,
      source: {assessment_id, control_id: getControlId("AC-05")},
      overridden: false,
      management: {
        title: "Unmanaged",
        status: MitigationStatus.UNMITIGATED,
      },
    },
    {
      title: "Weak Account Lockout Policy",
      description:
        "The current account lockout threshold of 5 attempts may not be sufficient to prevent brute force attacks, especially for privileged accounts.",
      likelihood: RiskLevel.MEDIUM,
      severity: RiskLevel.HIGH,
      source: {assessment_id, control_id: getControlId("AC-07")},
      overridden: false,
      management: {
        title: "Unmanaged",
        status: MitigationStatus.UNMITIGATED,
      },
    },

    {
      title: "Outdated System Use Notification",
      description:
        "The current login banner does not reflect recent changes in data protection regulations, potentially exposing the organization to compliance risks.",
      likelihood: RiskLevel.MEDIUM,
      severity: RiskLevel.MEDIUM,
      source: {assessment_id, control_id: getControlId("AC-08")},
      overridden: false,
      management: {
        title: "Unmanaged",
        status: MitigationStatus.UNMITIGATED,
      },
    },

    {
      title: "Excessive Concurrent Sessions",
      description:
        "The limit of 3 concurrent sessions per user may be too high, increasing the risk of session hijacking or unauthorized access.",
      likelihood: RiskLevel.LOW,
      severity: RiskLevel.MEDIUM,
      source: {assessment_id, control_id: getControlId("AC-10")},
      overridden: false,
      management: {
        title: "Unmanaged",
        status: MitigationStatus.UNMITIGATED,
      },
    },

    {
      title: "Insufficient Session Timeout",
      description:
        "The current session timeout of 60 minutes is too long, increasing the risk of unauthorized access to unattended workstations.",
      likelihood: RiskLevel.HIGH,
      severity: RiskLevel.MEDIUM,
      source: {assessment_id, controlId: getControlId("AC-11")},
      overridden: false,
      management: {
        title: "Unmanaged",
        status: MitigationStatus.UNMITIGATED,
      },
    },

    {
      title: "Incomplete Remote Access Monitoring",
      description:
        "While remote access policies are in place, there is a lack of real-time monitoring for unauthorized access attempts, potentially delaying incident response.",
      likelihood: RiskLevel.MEDIUM,
      severity: RiskLevel.HIGH,
      source: {assessment_id, control_id: getControlId("AC-15")},
      overridden: false,
      management: {
        title: "Unmanaged",
        status: MitigationStatus.UNMITIGATED,
      },
    },

    {
      title: "Weak Mobile Device Passcode Policy",
      description:
        "The minimum passcode length of 6 characters for mobile devices may not provide adequate protection against unauthorized access.",
      likelihood: RiskLevel.HIGH,
      severity: RiskLevel.MEDIUM,
      source: {assessment_id, control_id: getControlId("AC-19")},
      overridden: false,
      management: {
        title: "Unmanaged",
        status: MitigationStatus.UNMITIGATED,
      },
    },
  ].map((r, i) => ({...r, risk_id: `risk-${i + 1}`}));
}

export async function listByThirdParty(thirdPartyId: ThirdPartyId): Promise<Risk[]> {
  const thirdParties = await api.tprm.thirdParties.list();
  const frameworkVersions = await api.tprm.frameworkVersions.debugList();

  const assessments = await generateDemoAssessments(thirdParties, frameworkVersions);
  const risks = await generateDemoRisks(assessments);

  return risks.filter(
    r => assessments.find(a => r.source.assessment_id === a.assessment_id)!.third_party.third_party_id === thirdPartyId,
  );
}

export async function listByAssessment(assessmentId: AssessmentId): Promise<Risk[]> {
  const thirdParties = await api.tprm.thirdParties.list();
  const frameworkVersions = await api.tprm.frameworkVersions.debugList();

  const assessments = await generateDemoAssessments(thirdParties, frameworkVersions);
  const risks = await generateDemoRisks(assessments);

  return risks.filter(r => r.source.assessment_id === assessmentId);
}
