export function readBlob(blob: Blob, onProgress?: (progress: number | null) => void): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      onProgress && onProgress(null);
      if (fileReader.result) {
        resolve(fileReader.result as ArrayBuffer);
      } else {
        reject(fileReader.error);
      }
    };
    fileReader.onprogress = e => {
      if (e.lengthComputable) {
        onProgress && onProgress(e.loaded / e.total);
      }
    };
    fileReader.readAsArrayBuffer(blob);
  });
}
