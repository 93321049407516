import {ThirdPartyId, SourceId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["tprm", "sources", param<SourceId>("sourceId")], async context => {
    return await api.tprm.sources.get(context.queryKey[2]);
  }),
  typedQuery(["tprm", "thirdPartySources", param<ThirdPartyId>("thirdPartyId")], async context => {
    return await api.tprm.sources.list(context.queryKey[2]);
  }),
];
