import {withSuspense} from "../../../../../state/withSuspense.tsx";
import {useQueryData} from "../../../../../state";
import {useState} from "react";
import {ShowcaseUpdateMessageId, UpdateNotificationStatus} from "../../../../../Types.ts";
import {Icon, Stack, Text} from "@chakra-ui/react";
import ShowcaseMessages from "../ShowcaseMessages.tsx";
import {DocumentArrowUpIcon} from "@heroicons/react/24/solid";

const SentUpdates = withSuspense(() => {
  const messages = useQueryData({queryKey: ["vendorToolkit", "trustCenterSentShowcaseMessages"]});

  const [selectedMessage, setSelectedMessage] = useState<ShowcaseUpdateMessageId | undefined>(messages.message_ids[0]);

  return (
    <Stack flex={1} mt={16} borderTop={"1px"} borderColor={"gray.100"}>
      {messages.total === 0 ? (
        <Stack flex={1} justifyContent="center" alignItems="center" spacing={4} bg="gray.50">
          <Icon as={DocumentArrowUpIcon} color="gray.500" boxSize={8} />
          <Text fontSize="md" color="gray.500">
            You haven't sent any messages yet.
          </Text>
        </Stack>
      ) : (
        <ShowcaseMessages
          messages={messages}
          selectedMessage={selectedMessage}
          setSelectedMessage={setSelectedMessage}
          type_={UpdateNotificationStatus.Sent}
        ></ShowcaseMessages>
      )}
    </Stack>
  );
});

export default SentUpdates;
