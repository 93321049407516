import {Dispatch, SetStateAction, useState} from "react";

export function useResetting<S>(
  [state, setState]: [S, Dispatch<SetStateAction<S>>],
  shouldReset: boolean,
): [S, Dispatch<SetStateAction<S>>] {
  const [initialState] = useState(state);
  const [wasReset, setWasReset] = useState(shouldReset);
  if (shouldReset !== wasReset) {
    setWasReset(shouldReset);
    if (shouldReset) {
      setState(initialState);
    }
  }
  return [state, setState];
}
