import {Icon, Menu, MenuItemOption, MenuOptionGroup, TagLabel, Tooltip} from "@chakra-ui/react";
import {QuestionStatus} from "../../../../Types";
import {usePromiseState} from "../../../../hooks/promiseState";
import {QUESTION_STATUS_MAP} from ".";
import TagMenuButton from "../../../../components/TagMenuButton";
import PortalMenuList from "../../../../components/PortalMenuList";

const QuestionStatusComp = ({
  status,
  onChangeStatus,
  size,
}: {
  status: QuestionStatus;
  onChangeStatus?: (arg0: QuestionStatus) => Promise<void>;
  size?: "sm" | "lg";
}) => {
  const {color, icon, shortText} = QUESTION_STATUS_MAP[status];

  const [changing, change] = usePromiseState(
    async (val: string | string[]) => {
      if (onChangeStatus) {
        await onChangeStatus(val as QuestionStatus);
      }
    },
    [onChangeStatus],
  );

  let statusLabel = null;
  if (status === QuestionStatus.Automating) {
    statusLabel = "This question is being automatically answered by the Platformed AI.";
  }

  return (
    <Menu isLazy>
      <Tooltip label={statusLabel} placement="right">
        <TagMenuButton
          colorScheme={color}
          isLoading={changing.inProgress}
          isDisabled={!onChangeStatus}
          data-testid={onChangeStatus ? "status-selector" : undefined}
          size={size}
        >
          <Icon as={icon} />
          <TagLabel>{shortText}</TagLabel>
        </TagMenuButton>
      </Tooltip>
      <PortalMenuList fontSize="md" onClick={e => e.stopPropagation()}>
        <MenuOptionGroup type="radio" onChange={change} value={status}>
          {Object.keys(QuestionStatus)
            .filter(s => !QUESTION_STATUS_MAP[s].disableSetting)
            .map(s => (
              <MenuItemOption key={s} value={s}>
                {QUESTION_STATUS_MAP[s].longText}
              </MenuItemOption>
            ))}
        </MenuOptionGroup>
      </PortalMenuList>
    </Menu>
  );
};

export default QuestionStatusComp;
