import {ScopeAxisMin} from "../../../../Types";
import {
  Box,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  HStack,
} from "@chakra-ui/react";
import {memo, useState} from "react";
import api from "../../../../api";
import {useResetting} from "../../../../hooks/resetting";
import {useValidation, useValidatedPromiseState} from "../../../../hooks/validationState";
import {ColorPicker} from "../../../components/ColorPicker";
import {COLOR_PALETTE} from ".";

const EditScopeAxisModal = memo(
  ({
    initialValue,
    onCreate,
    isOpen,
    onClose,
  }: {
    initialValue?: ScopeAxisMin;
    onCreate?: (c: ScopeAxisMin) => void;
    isOpen: boolean;
    onClose: () => void;
  }) => {
    const [name, setName] = useResetting(useState(initialValue?.name ?? ""), isOpen);
    const [color, setColor] = useResetting(useState(initialValue?.color ?? "gray.500"), isOpen);
    const [validationErrors, setValidationErrors] = useValidation(useResetting(useState({}), isOpen), {name});
    const [creating, create] = useValidatedPromiseState(
      async () => {
        if (initialValue) {
          await api.vendorToolkit.scopes.updateAxisName(initialValue.axis_id, name);
          await api.vendorToolkit.scopes.updateAxisColor(initialValue.axis_id, color);
          onClose();
        } else {
          const axis = await api.vendorToolkit.scopes.createAxis({
            name,
            color,
          });
          onClose();
          if (onCreate) {
            onCreate(axis);
          }
        }
      },
      [initialValue, name, color, onClose, onCreate],
      setValidationErrors,
    );

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={create}>
          <ModalHeader>{initialValue ? "Edit scope type" : "Create new scope type"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="8" mb="4">
              <FormControl isInvalid={validationErrors.name !== undefined}>
                <FormLabel>Color</FormLabel>
                <ColorPicker value={color} onChange={setColor} palette={COLOR_PALETTE} />
              </FormControl>
              <FormControl isRequired isInvalid={validationErrors.name !== undefined}>
                <FormLabel>Name</FormLabel>
                <Input onChange={e => setName(e.target.value)} value={name} placeholder="Example Product" autoFocus />
                {validationErrors.name && validationErrors.name[0] && (
                  <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
                )}
              </FormControl>

              {creating.lastError ? (
                <Alert status="error" mt="12">
                  <AlertIcon boxSize="40px" />
                  <Box>
                    <AlertTitle fontSize="md">Error creating scope type</AlertTitle>
                    <AlertDescription fontSize="md">{`${creating.lastError}`}</AlertDescription>
                  </Box>
                </Alert>
              ) : null}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing="3">
              <Button variant="ghost" onClick={onClose} isDisabled={creating.inProgress}>
                Cancel
              </Button>
              <Button colorScheme="blue" type="submit" isLoading={creating.inProgress} isDisabled={creating.inProgress}>
                {initialValue ? "Save" : "Create"}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  },
);

export default EditScopeAxisModal;
