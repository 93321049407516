import {Box, Stack, Icon, Text, Badge, HStack} from "@chakra-ui/react";
import {ApiDateTime, FactSourceDocument, FactSourceQuestion, FactSourceEntity, Fact} from "../../../../Types.ts";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import {Link as RouterLink} from "react-router-dom";
import {
  questionResponse,
  sourceDocumentLink,
  sourceQuestionLink,
  sourceSubgraphLink,
  sourceSubgraphName,
} from "./index.tsx";
import {DateUI} from "../../../../components/Date.tsx";
import {useQueryData} from "../../../../state/index.ts";
import {withSuspense} from "../../../../state/withSuspense.tsx";
import {memo} from "react";
import locateInText from "./locateInText";

const FactSource = ({
  source,
  children,
  created_at,
}: {
  source: "Document" | "Question" | "Entity";
  children: React.ReactNode;
  created_at?: ApiDateTime;
}) => (
  <Stack px={4} py={3} _notLast={{borderBottom: "1px solid", borderColor: "gray.200"}}>
    <HStack justifyContent="space-between">
      <Badge fontSize="sm" colorScheme={source === "Question" ? "blue" : "green"}>
        {source}
      </Badge>
      {created_at && (
        <Box display="contents" color="gray.500">
          <DateUI date={created_at} />
        </Box>
      )}
    </HStack>
    {children}
  </Stack>
);

const ChunkSummary = memo(({chunk, needle}: {chunk: string; needle: string}) => {
  const span = locateInText(chunk, needle);
  return <Text whiteSpace="pre-wrap">{span.content}</Text>;
});

const DocumentFactSource = ({
  source,
  representativeContent,
}: {
  source: FactSourceDocument;
  representativeContent: string;
}) => (
  <FactSource created_at={source.document_revision} source="Document">
    <Text fontWeight="semibold" as={RouterLink} _hover={{color: "blue.500"}} to={sourceDocumentLink(source)}>
      {source.document_name}
      <Icon as={ChevronRightIcon} verticalAlign="middle" mx={1} />
    </Text>
    <ChunkSummary chunk={source.explanation_chunk} needle={representativeContent} />
  </FactSource>
);

const QuestionFactSource = ({source}: {source: FactSourceQuestion}) => (
  <FactSource
    created_at={
      source.parent.type === "Section" ? source.parent.content.questionnaire_date : source.parent.content.question_date
    }
    source="Question"
  >
    <Stack gap={1}>
      <Text
        as={RouterLink}
        fontWeight="semibold"
        _hover={{color: "blue.500"}}
        to={sourceQuestionLink(source)}
        whiteSpace="pre-wrap"
      >
        {source.question_text}
        <Icon as={ChevronRightIcon} verticalAlign="middle" mx={1} />
      </Text>
      <Text whiteSpace="pre-wrap">{questionResponse(source)}</Text>
    </Stack>
  </FactSource>
);

const EntityFactSource = withSuspense(({source}: {source: FactSourceEntity}) => {
  const subgraph = useQueryData({
    queryKey: ["vendorToolkit", "graph", {filter_entity_ids: [source.entity_id], recursion_depth: 2}],
  });
  return (
    <FactSource source="Entity">
      <Stack gap={1}>
        <Text as={RouterLink} fontWeight="semibold" _hover={{color: "blue.500"}} to={sourceSubgraphLink(subgraph)}>
          {sourceSubgraphName(subgraph)}
          <Icon as={ChevronRightIcon} verticalAlign="middle" mx={1} />
        </Text>
      </Stack>
    </FactSource>
  );
});

export function FactSources({fact}: {fact: Fact}) {
  const numSources = fact.document_sources.length + fact.question_sources.length + fact.subgraph_sources.length;

  return (
    <Stack spacing={0}>
      <HStack px={4} py={2} bg="gray.50" borderBottom="1px solid" borderColor="gray.200">
        <Text fontWeight="600" fontSize="md">
          There {numSources === 1 ? "is" : "are"} {numSources} source{numSources === 1 ? "" : "s"} for this fact:
        </Text>
      </HStack>
      <Stack fontSize="md" spacing={0}>
        {fact.document_sources.map((ds, i) => (
          <DocumentFactSource key={i} source={ds} representativeContent={fact.representative_text.content} />
        ))}
        {fact.question_sources.map((qs, i) => (
          <QuestionFactSource key={i} source={qs} />
        ))}
        {fact.subgraph_sources.map((es, i) => (
          <EntityFactSource key={i} source={es} />
        ))}
      </Stack>
    </Stack>
  );
}
