import {
  NotificationChannelConnector,
  NotificationChannelConnectorType,
  UpdateNotificationChannel,
} from "../../../../Types.ts";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Img,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Text,
} from "@chakra-ui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {Link as RouterLink} from "react-router-dom";
import {withSuspense} from "../../../../state/withSuspense.tsx";
import {useQueryData} from "../../../../state/index.ts";
import {useState} from "react";
import {useValidatedPromiseState, useValidation} from "../../../../hooks/validationState.ts";
import {AnyNotificationChannel} from "./AnyNotificationChannel.tsx";
import api from "../../../../api/index.ts";
import {router} from "../../../../router/index.tsx";
import DocumentTitle from "../../../../components/DocumentTitle.tsx";
import PortalMenuList from "../../../../components/PortalMenuList.tsx";
import {Type} from "../../../../typing";
import {useTypedParams} from "../../../../hooks/typedParams";

export const NewNotificationChannelButton = ({connectors}: {connectors: NotificationChannelConnector[]}) => {
  return (
    <Menu placement="bottom-end" closeOnSelect={false}>
      <MenuButton as={Button} colorScheme="green" rightIcon={<Icon as={ChevronDownIcon} />}>
        Add notification channel
      </MenuButton>
      <PortalMenuList fontSize="md" maxH="50vh" overflowY="auto">
        {connectors.map(({connector_type, name, logo_url}) => (
          <MenuItem
            key={name}
            as={RouterLink}
            w="full"
            _hover={{textDecoration: "none"}}
            to={`new/${connector_type}`}
            gap={4}
          >
            <Img w={8} src={logo_url} aspectRatio={1} />
            <Text fontSize="md">{name}</Text>
          </MenuItem>
        ))}
      </PortalMenuList>
    </Menu>
  );
};

export const NotificationChannelConnectorParams = Type.Object({
  connectorType: Type.Enum(NotificationChannelConnectorType),
});

export const NewNotificationChannel = withSuspense(() => {
  const {connectorType} = useTypedParams(NotificationChannelConnectorParams);
  const connector = useQueryData({
    queryKey: ["notificationChannelConnector", connectorType],
  });

  const [payload, setPayload] = useState<UpdateNotificationChannel | null>(null);
  const [name, setName] = useState("");
  const [validationErrors, setValidationErrors] = useValidation(useState({}), {name});

  const [creating, create] = useValidatedPromiseState(
    async () => {
      if (!payload) return;
      await api.notificationChannels.create({
        name: name,
        connector: connector,
        ...payload,
      });

      router!.navigate("/vendor-toolkit/configuration/integrations/notification-channels");
    },
    [payload, name, connector],
    setValidationErrors,
  );

  return (
    <>
      <DocumentTitle title="New notification channel" />
      <Box as="form" onSubmit={create}>
        <Stack p={8} spacing={8} align="flex-start">
          <Heading size="md" p="0" display="flex" alignItems="center" gap={2}>
            New notification channel
          </Heading>
          <Stack spacing={4} align="flex-start" w="500px">
            <FormControl isRequired isInvalid={validationErrors.name !== undefined}>
              <FormLabel>Notification channel name</FormLabel>
              <Input
                onChange={e => setName(e.target.value)}
                value={name}
                isDisabled={false}
                placeholder={connector.name}
              />
              {validationErrors.name && validationErrors.name[0] && (
                <FormErrorMessage>{validationErrors.name[0].message}</FormErrorMessage>
              )}
            </FormControl>

            <AnyNotificationChannel
              connectorType={connector.connector_type}
              payload={payload}
              setPayload={setPayload}
            />

            {creating.lastError ? (
              <Alert status="error" mt="12">
                <AlertIcon boxSize="40px" />
                <Box>
                  <AlertTitle fontSize="md">Error creating notification channel</AlertTitle>
                  <AlertDescription fontSize="md">{`${creating.lastError}`}</AlertDescription>
                </Box>
              </Alert>
            ) : null}
          </Stack>
          <Button
            colorScheme="green"
            type="submit"
            isLoading={creating.inProgress}
            isDisabled={validationErrors.name !== undefined || !payload}
          >
            Create
          </Button>
        </Stack>
      </Box>
    </>
  );
});
