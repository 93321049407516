import {useSuspenseQuery} from "@tanstack/react-query";
import {handleImportError} from "../utils/import";

function useDynamicImport<T>(name: string, imp: () => Promise<T>) {
  const res = useSuspenseQuery({queryKey: ["dynamicImport", name], queryFn: imp, staleTime: Infinity});
  return res.data;
}

export function useFriendlyMimes() {
  return useDynamicImport("friendly-mimes", async () => {
    // Destructure to allow dynamic tree shaking as per
    // https://github.com/rollup/rollup/pull/4952
    const {resolveMime} = await import("friendly-mimes").catch(handleImportError);
    return {resolveMime};
  });
}

export function useXLSX() {
  return useDynamicImport("xlsx", async () => {
    // Destructure to allow dynamic tree shaking as per
    // https://github.com/rollup/rollup/pull/4952
    const XLSX = await import("xlsx").catch(handleImportError);
    return XLSX;
  });
}
