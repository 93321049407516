import {Badge, Box, Divider, HStack, Select, Stack, Text} from "@chakra-ui/react";
import FormSection, {FormQuestion} from "../../../VendorToolkit/Library/Documents/components/FormSection";
import {Owner, ThirdParty, ThirdPartyTierMin} from "../../../../Types";
import {useOutletContext} from "react-router-dom";
import OwnerSelector from "../../../../components/OwnerSelector";
import DueDate from "../../../../components/DueDate";
import TierSelector from "../../components/TierSelector";
import {useCallback} from "react";
import api, {HTTPError} from "../../../../api";
import CommitInput from "../../../../components/CommitInput";

const MetaPage = () => {
  const thirdParty: ThirdParty = useOutletContext();

  const updateName = useCallback(
    async (name: string) => {
      try {
        await api.tprm.thirdParties.updateName(thirdParty.third_party_id, name);
      } catch (ex) {
        if (ex instanceof HTTPError) {
          if (ex.response.status === 422) {
            throw new Error("Name must be between 1 and 256 characters.");
          } else if (ex.response.status === 409) {
            throw new Error(await ex.response.json());
          }
        }
        throw ex;
      }
    },
    [thirdParty.third_party_id],
  );

  const updateUrl = useCallback(
    async (url: string) => {
      await api.tprm.thirdParties.updateUrl(thirdParty.third_party_id, url);
    },
    [thirdParty.third_party_id],
  );

  const updateTier = useCallback(
    async (tier: ThirdPartyTierMin) => {
      await api.tprm.thirdParties.updateTier(thirdParty.third_party_id, tier.tier_id);
    },
    [thirdParty.third_party_id],
  );

  const updateBusinessOwner = useCallback(
    async (owner: Owner | undefined) => {
      await api.tprm.thirdParties.updateBusinessOwner(thirdParty.third_party_id, owner?.owner_id ?? null);
    },
    [thirdParty.third_party_id],
  );

  const updateRiskOwner = useCallback(
    async (owner: Owner | undefined) => {
      await api.tprm.thirdParties.updateRiskOwner(thirdParty.third_party_id, owner?.owner_id ?? null);
    },
    [thirdParty.third_party_id],
  );

  return (
    <Stack spacing={4} p={4} pb={12} divider={<Divider />}>
      <FormSection title="Main" description="Basic information about the third party">
        <FormQuestion title="Name">
          <CommitInput value={thirdParty.name} onCommit={updateName} />
        </FormQuestion>
        <FormQuestion title="URL">
          <CommitInput value={thirdParty.url} onCommit={updateUrl} />
        </FormQuestion>
        <FormQuestion title="Business Owner">
          <Box>
            <OwnerSelector owner={thirdParty.business_owner} onReassign={updateBusinessOwner} />
          </Box>
        </FormQuestion>
        <FormQuestion title="Risk Owner">
          <Box>
            <OwnerSelector owner={thirdParty.risk_owner} onReassign={updateRiskOwner} />
          </Box>
        </FormQuestion>
      </FormSection>
      <FormSection title="Tiering" description="How critical is the third party to your organisation?">
        <FormQuestion title="Tier">
          <TierSelector tier={thirdParty.tier} onChange={updateTier} />
        </FormQuestion>
      </FormSection>
      <FormSection title="Review" description="How often is this supplier reviewed">
        <FormQuestion
          title={
            <HStack>
              <Text>Frequency</Text>
              <Badge colorScheme="blue">Set by Tier</Badge>
            </HStack>
          }
        >
          <Select isDisabled>
            <option>Quarterly</option>
            <option>Annually</option>
            <option>Biannually</option>
            <option>Never</option>
          </Select>
        </FormQuestion>
        <FormQuestion title="Last Reviewed">
          <DueDate complete={true} dueDate={thirdParty.due_date} />
        </FormQuestion>
        <FormQuestion title="Next Review">
          <DueDate complete={true} dueDate={thirdParty.due_date} />
        </FormQuestion>
      </FormSection>
    </Stack>
  );
};

export default MetaPage;
