import {ReactNode, useCallback} from "react";
import {Accept, FileRejection} from "react-dropzone";
import {UploadingFile} from "./FileUploader";
import Dropzone from "./Dropzone";
import {FileUpload, FileUploadBase} from "../../hooks/fileUpload";
import {UploadOptions} from "../../Types";

const DEFAULT_OPTIONS: UploadOptions = {};

export default function SingleFileUpload<TFileUpload extends FileUploadBase = FileUpload>({
  value,
  children,
  onChange,
  accept,
  Cls,
  onDropRejected,
  options = DEFAULT_OPTIONS,
  isDisabled,
}: {
  value: TFileUpload | null;
  onChange: (newValue: TFileUpload | null) => void;
  children: ReactNode | ((isDragActive: boolean) => ReactNode);
  accept?: Accept;
  Cls: {new (file: File, options: UploadOptions): TFileUpload};
  onDropRejected?: (files: FileRejection[]) => ReactNode;
  options?: UploadOptions;
  isDisabled?: boolean;
}) {
  const change = useCallback((file: File | null) => onChange(file && new Cls(file, options)), [Cls, onChange, options]);
  const onDrop = useCallback((files: File[]) => change(files[0]), [change]);
  const onDelete = useCallback(() => change(null), [change]);

  return value ? (
    <UploadingFile fileUpload={value} onDelete={onDelete} isDisabled={isDisabled} />
  ) : (
    <Dropzone
      onDropAccepted={onDrop}
      onDropRejected={onDropRejected}
      borderWidth="2px"
      h="66px"
      maxFiles={1}
      accept={accept}
      isDisabled={isDisabled}
    >
      {children}
    </Dropzone>
  );
}
