import {
  AccountId,
  AssetId,
  ContentId,
  DocumentAccessRequest,
  DocumentExternal,
  DocumentId,
  GetGraphQuery,
  GraphProperty,
  MailSubscriptionId,
  SARRequest,
  SubGraph,
  SubscribeRequest,
  SubscriptionAction,
  TrustCenterConfigMin,
  TrustCenterContent,
  TrustCenterFaq,
} from "../../Types";
import jsonApi, {API_BASE} from "./jsonApi";
import {invalidateQueries} from "../../state";

export async function listDocuments(accountId: AccountId, password: string) {
  const pwd = btoa(`${accountId}:${password}`);
  return await jsonApi.get<DocumentExternal[]>(`/trust_centers/${accountId}/documents`, {
    headers: {
      Authorization: `Basic ${pwd}`,
    },
  });
}

export const downloadDocument = async (accountId: AccountId, documentId: DocumentId, password: string) => {
  const a = document.createElement("a");
  a.href = `${API_BASE}/trust_centers/${accountId}/documents/${documentId}/download?password=${accountId}:${password}`;
  a.click();
};

export async function requestDocumentAccess(
  accountId: AccountId,
  documentId: DocumentId,
  documentRequest: DocumentAccessRequest,
  password: string,
) {
  const pwd = btoa(`${accountId}:${password}`);
  return await jsonApi.post<"ok">(
    `/trust_centers/${accountId}/documents/${documentId}/request_access`,
    documentRequest,
    {
      headers: {
        Authorization: `Basic ${pwd}`,
      },
    },
  );
}

export const getAssetUrl = (accountId: AccountId, assetId: AssetId, password: string) => {
  return `${API_BASE}/trust_centers/${accountId}/assets/${assetId}/download?password=${accountId}:${password}`;
};

export const getContent = async (accountId: AccountId, contentId: ContentId, password: string) => {
  const pwd = btoa(`${accountId}:${password}`);
  return await jsonApi.get<TrustCenterContent>(`/trust_centers/${accountId}/contents/${contentId}`, {
    headers: {
      Authorization: `Basic ${pwd}`,
    },
  });
};

export const getConfig = async (accountId: AccountId, password: string) => {
  const pwd = btoa(`${accountId}:${password}`);
  console.log(pwd);
  return await jsonApi.get<TrustCenterConfigMin>(`/trust_centers/${accountId}/config`, {
    headers: {
      Authorization: `Basic ${pwd}`,
    },
  });
};

export const getFaq = async (accountId: AccountId, password: string) => {
  const pwd = btoa(`${accountId}:${password}`);
  return await jsonApi.get<TrustCenterFaq>(`/trust_centers/${accountId}/faq`, {
    headers: {
      Authorization: `Basic ${pwd}`,
    },
  });
};

export const getAiFaq = async (accountId: AccountId, password: string) => {
  const pwd = btoa(`${accountId}:${password}`);
  return await jsonApi.get<TrustCenterFaq>(`/trust_centers/${accountId}/ai_faq`, {
    headers: {
      Authorization: `Basic ${pwd}`,
    },
  });
};

export const getGraph = async (accountId: AccountId, query: GetGraphQuery, password: string) => {
  const pwd = btoa(`${accountId}:${password}`);
  return await jsonApi.get<SubGraph>(`/trust_centers/${accountId}/graph?${new URLSearchParams(query as any)}`, {
    headers: {
      Authorization: `Basic ${pwd}`,
    },
  });
};

export const getSchema = async (accountId: AccountId, password: string) => {
  const pwd = btoa(`${accountId}:${password}`);
  return await jsonApi.get<{[k: string]: GraphProperty}>(`/trust_centers/${accountId}/schema`, {
    headers: {
      Authorization: `Basic ${pwd}`,
    },
  });
};

export async function subscribe(accountId: AccountId, subscribeRequest: SubscribeRequest, password: string) {
  const pwd = btoa(`${accountId}:${password}`);
  const res = await jsonApi.post<SubscriptionAction>(`/trust_centers/${accountId}/subscribe`, subscribeRequest, {
    headers: {
      Authorization: `Basic ${pwd}`,
    },
  });
  await invalidateQueries([{queryKey: ["vendorToolkit", "trustCenterMailingList"]}]);
  return res;
}

export async function verifySubscriptionEmail(mailSubscriptionId: MailSubscriptionId, token: string) {
  return await jsonApi.post<"ok">(
    `/mail_subscription/${mailSubscriptionId}/verify`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function authenticateTrustCenterPassword(accountId: AccountId, password: string) {
  return await jsonApi.post<"ok">(`/trust_centers/${accountId}/password?${new URLSearchParams({password: password})}`);
}

export async function sarRequest(accountId: AccountId, sarRequest: SARRequest, password: string) {
  const pwd = btoa(`${accountId}:${password}`);
  return await jsonApi.post<"ok">(`/trust_centers/${accountId}/sar`, sarRequest, {
    headers: {
      Authorization: `Basic ${pwd}`,
    },
  });
}
