import {Avatar, Box, Button, Circle, Flex, FlexProps, Heading, Icon, SimpleGrid, Stack, Text} from "@chakra-ui/react";
import {Link as RouterLink, useOutletContext} from "react-router-dom";
import {ApiDateTime, AssessmentMin, CommentPayload, ThirdParty, UserMin, nominate} from "../../../../Types";
import {useQueryData} from "../../../../state";
import {ActionBar} from "../../../../Extension/components/ActionBar";
import {ChevronLeftIcon} from "@heroicons/react/24/solid";
import RelativeTimeText from "../../../../components/RelativeTimeText";
import {RichTextView} from "../../../../components/ActivityStream/RichText";
import Assessment from "../../components/Assessment";
import ReportHeader from "./components/ReportHeader";

const ActivityTitle = ({
  children,
  user,
  occurred_at,
  ...props
}: FlexProps & {user?: UserMin; occurred_at: ApiDateTime}) => {
  return (
    <Flex fontSize="sm" justifyContent="space-between" color="gray.500" {...props}>
      <Text>
        <Text as="span" fontWeight="600">
          {user ? user.name : "Platformed"}
        </Text>{" "}
        {children}
      </Text>
      <RelativeTimeText dateTime={occurred_at} />
    </Flex>
  );
};

type ActivityPayload = {type: "ControlReviewed"; content: object} | {type: "Comment"; content: CommentPayload};

const ActivityView = ({
  activity: {user, payload, occurred_at},
}: {
  activity: {user: UserMin; occurred_at: ApiDateTime; payload: ActivityPayload};
}) => {
  let content;
  switch (payload.type) {
    case "Comment":
      content = (
        <Box border="1px solid" borderColor="gray.200" borderRadius="lg" p={3}>
          <ActivityTitle user={user} occurred_at={occurred_at} mb={2}>
            commented
          </ActivityTitle>
          <Text fontSize="md" color="gray.700">
            <RichTextView message={payload.content.message} mentions={[]} />
          </Text>
        </Box>
      );
      break;
    case "ControlReviewed":
      content = (
        <ActivityTitle user={user} occurred_at={occurred_at}>
          reviewed the control{" "}
          <Text as="strong" fontWeight="semibold">
            AC-1 - Policies and Procedures
          </Text>{" "}
          on assessment{" "}
          <Text as="strong" fontWeight="semibold">
            NIST
          </Text>
        </ActivityTitle>
      );
      break;
  }
  return (
    <>
      <Flex
        backgroundColor="white"
        position="relative"
        alignSelf="start"
        justifyContent="center"
        py={2}
        sx={{":nth-last-of-type(2)": {alignSelf: "stretch"}}}
      >
        {user ? (
          <Avatar name={user.name} size="xs" />
        ) : (
          <Circle size="8px" border="1px solid" borderColor="gray.200" backgroundColor="gray.100" />
        )}
      </Flex>
      <Box display="grid" alignItems="center">
        {content}
      </Box>
    </>
  );
};

const AuditReport = () => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const thirdParty: ThirdParty = useOutletContext();
  const assessments: AssessmentMin[] = useQueryData({
    queryKey: ["tprm", "thirdPartyAssessments", thirdParty.third_party_id],
  });

  const activities = [
    {
      activity_stream_id: nominate("activityStreamId", "activity_1"),
      activity_ordinal: 0,
      occurred_at: nominate("datetime", "2024-08-01T00:00:00Z"),
      payload: {type: "ControlReviewed", content: {}} as ActivityPayload,
      user: whoami.user,
    },
    {
      activity_stream_id: nominate("activityStreamId", "activity_2"),
      activity_ordinal: 1,
      occurred_at: nominate("datetime", "2024-08-01T00:00:00Z"),
      payload: {type: "ControlReviewed", content: {}} as ActivityPayload,
      user: whoami.user,
    },
    {
      activity_stream_id: nominate("activityStreamId", "activity_3"),
      activity_ordinal: 2,
      occurred_at: nominate("datetime", "2024-08-01T00:00:00Z"),
      payload: {
        type: "Comment",
        content: {
          message: {
            elements: [
              {
                type: "Text",
                content: {text: "This evidence is a little sparse, we should reach back out to the client."},
              },
            ],
          },
        },
      } as ActivityPayload,
      user: whoami.user,
    },
  ];

  return (
    <>
      <ActionBar actionButtons={<Button colorScheme="green">Download as PDF</Button>}>
        <Button as={RouterLink} to=".." leftIcon={<Icon as={ChevronLeftIcon} />}>
          Back
        </Button>
      </ActionBar>
      <ReportHeader name="Audit Report" />
      <Stack spacing={12} p={6}>
        <Stack spacing={4}>
          <Heading size="sm" fontWeight="semibold">
            Frameworks Assessed
          </Heading>
          <SimpleGrid spacing={8} columns={4} alignItems="flex-start">
            {assessments.map(assessment => (
              <Assessment assessment={assessment} key={assessment.assessment_id} />
            ))}
          </SimpleGrid>
        </Stack>
        <Stack spacing={4}>
          <Heading size="sm" fontWeight="semibold">
            Activity
          </Heading>
          <Box>
            <Box rowGap={4} columnGap={4} my={4} display="grid" position="relative" gridTemplateColumns="23px auto">
              <Box position="absolute" top={2} bottom={2} left="11px" w="1px" backgroundColor="gray.200" />
              {activities.map(activity => (
                <ActivityView activity={activity} />
              ))}
            </Box>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default AuditReport;
