import {
  Avatar,
  Button,
  HStack,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PortalProps,
  TagLabel,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import {OwnerId, Owner, OwnerType, TeamId} from "../Types";
import {ArrowDownOnSquareIcon, BellAlertIcon, UserMinusIcon} from "@heroicons/react/24/solid";
import {memo} from "react";
import {usePromiseState} from "../hooks/promiseState";
import {useQueriesData, useQueryData} from "../state";
import {withSuspense} from "../state/withSuspense";
import Loading from "./Loading";
import {useAccountLocalStorage} from "../hooks/accountLocalStorage";
import {ReassignUserModal} from "../Products/components/ReassignUserModal.tsx";
import {ReassignTeamModal} from "../Products/components/ReassignTeamModal.tsx";
import TeamAvatar from "./TeamAvatar.tsx";
import TagMenuButton from "./TagMenuButton.tsx";
import PortalMenuList from "./PortalMenuList.tsx";

const NudgeModal = ({owner, onNudge}: {owner: Owner; onNudge: () => Promise<void> | void}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [nudging, nudge] = usePromiseState(async () => {
    await onNudge();
    onClose();
  }, [onNudge, onClose]);

  const name = owner.owner_payload.content.name;
  const message =
    owner.owner_payload.type === OwnerType.User
      ? `This will send a notification to ${name} reminding them to complete this task`
      : `This will send a notification to everyone in ${name} reminding them to complete this task`;

  return (
    <>
      <MenuItem onClick={onOpen}>
        <Icon as={BellAlertIcon} h="6" mr="2" color="gray.500" />
        Nudge
      </MenuItem>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nudge {name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{message}</ModalBody>
          <ModalFooter>
            <HStack spacing="3">
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={nudge} isLoading={nudging.inProgress}>
                Nudge
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const RecentOwners = withSuspense(
  ({ownerIds, myTeamIds, reassign}: {ownerIds: OwnerId[]; myTeamIds: TeamId[]; reassign: (owner: Owner) => void}) => {
    const owners = useQueriesData({queries: ownerIds.map(ownerId => ({queryKey: ["owner", ownerId]}))});

    return (
      <>
        {owners.map(owner => (
          <MenuItem key={owner.owner_id} onClick={() => reassign(owner)}>
            {owner.owner_payload.type === OwnerType.User ? (
              <Avatar
                src={owner.owner_payload.content?.avatar_url}
                name={owner.owner_payload.content?.name ?? owner.owner_payload.content?.primary_email}
                size="xs"
              />
            ) : (
              <TeamAvatar size="xs" isMember={myTeamIds.includes(owner.owner_payload.content?.team_id)} />
            )}
            <Text ml={2}>
              {owner.owner_payload.content?.name ??
                (owner.owner_payload.type === OwnerType.User ? owner.owner_payload.content?.primary_email : "")}
            </Text>
          </MenuItem>
        ))}
        <MenuDivider />
      </>
    );
  },
  <>
    <Loading />
    <MenuDivider />
  </>,
);

const OwnerSelector = memo(
  ({
    owner,
    onReassign,
    onNudge,
    canUnassign = true,
    portalContainerRef,
  }: {
    owner?: Owner;
    onReassign?: (owner?: Owner) => Promise<void> | void;
    onNudge?: () => Promise<void> | void;
    canUnassign?: boolean;
    portalContainerRef?: PortalProps["containerRef"];
  }) => {
    const whoami = useQueryData({queryKey: ["whoAmI"]});
    const userOwner = useQueryData({queryKey: ["resolvedOwner", {type: OwnerType.User, content: whoami.user.user_id}]});

    const userTeams = useQueryData({queryKey: ["userTeams", whoami.user.user_id]});
    const userTeamIds = userTeams.map(t => t.team_id);

    const [recentOwnerIds, setRecentOwnerIds] = useAccountLocalStorage<OwnerId[]>(
      `account/${whoami.account?.account_id}/recent_owners`,
      [],
    );
    const [reassigning, reassign] = usePromiseState(
      async (owner?: Owner) => {
        if (onReassign) {
          if (owner) {
            setRecentOwnerIds(ownerIds =>
              [owner.owner_id, ...ownerIds.filter(ownerId => ownerId !== owner.owner_id)].slice(0, 3),
            );
          }
          await onReassign(owner);
        }
      },
      [onReassign, setRecentOwnerIds],
    );

    let colorScheme;
    let tagBody;
    switch (owner?.owner_payload.type) {
      case undefined:
        colorScheme = "gray";
        tagBody = <TagLabel>Unassigned</TagLabel>;
        break;
      case OwnerType.User:
        colorScheme = "orange";
        tagBody = (
          <>
            <Avatar
              ml={-2}
              src={owner.owner_payload.content?.avatar_url}
              name={owner.owner_payload.content?.name ?? owner.owner_payload.content?.primary_email}
              size="xs"
            />
            <TagLabel>{owner.owner_payload.content?.name ?? owner.owner_payload.content?.primary_email}</TagLabel>
          </>
        );
        break;
      case OwnerType.Team:
        colorScheme = "blue";
        tagBody = (
          <>
            <TeamAvatar size="xs" ml={-2} isMember={userTeamIds.includes(owner.owner_payload.content?.team_id)} />
            <TagLabel>{owner.owner_payload.content?.name ?? ""}</TagLabel>
          </>
        );
        break;
      default:
        throw new Error("Unreachable");
    }

    return (
      // keepMounted because within ReassignModal is a MenuItem that gets
      // unmounted when the menu is closed by default, causing the Modal to
      // disappear as soon as it is shown
      <Menu isLazy lazyBehavior="keepMounted">
        <TagMenuButton
          colorScheme={colorScheme}
          isLoading={reassigning.inProgress}
          isDisabled={!onReassign && !onNudge}
          data-testid="person-selector"
        >
          {tagBody}
        </TagMenuButton>
        <PortalMenuList fontSize="md" onClick={e => e.stopPropagation()} containerRef={portalContainerRef}>
          {onReassign && (
            <>
              {recentOwnerIds.length > 0 && (
                <RecentOwners ownerIds={recentOwnerIds} myTeamIds={userTeamIds} reassign={reassign} />
              )}
              <MenuItem
                onClick={async () => await reassign(userOwner)}
                isDisabled={userOwner.owner_id === owner?.owner_id}
              >
                <Icon as={ArrowDownOnSquareIcon} h="6" mr="2" color="gray.500" />
                Assign to me
              </MenuItem>
              <ReassignUserModal
                onReassign={reassign}
                isReassigning={reassigning.inProgress}
                text={
                  (owner ? "Reassign" : "Assign") +
                  (owner?.owner_payload.type === OwnerType.User ? "" : " to") +
                  " user"
                }
              />
              <ReassignTeamModal
                onReassign={reassign}
                isReassigning={reassigning.inProgress}
                text={
                  (owner ? "Reassign" : "Assign") +
                  (owner?.owner_payload.type === OwnerType.Team ? "" : " to") +
                  " team"
                }
              />
              {owner && canUnassign && (
                <MenuItem onClick={async () => await reassign()}>
                  <Icon as={UserMinusIcon} h="6" mr="2" color="gray.500" />
                  Unassign
                </MenuItem>
              )}
            </>
          )}
          {onNudge && owner && <NudgeModal owner={owner} onNudge={onNudge} />}
        </PortalMenuList>
      </Menu>
    );
  },
);

export default OwnerSelector;
