import {
  DocumentExternal,
  TrustCenterAiCheckItemConfig,
  TrustCenterAiDataUseItemConfig,
  TrustCenterDataProtectionDataUseItemConfig,
  TrustCenterFaq,
  TrustCenterFaqQuestion,
} from "../../../Types";
import {unreachableCase} from "../../../utils/typescript";
import {
  AiModelProvider,
  KybEntry,
  KybT,
  PersonT,
  SubprocessorT,
  TrustCenterAi,
  TrustCenterDataProtection,
  TrustCenterOverview,
  useTrustCenterState,
} from "../../config";
import {unified} from "unified";
import remarkParse from "remark-parse";
import {isAnchorableHeading, parseHeading, toPlainText} from "../Markdown";

export type SearchTrustCenterData = {
  overview?: TrustCenterOverview;
  certifications?: DocumentExternal[];
  documents?: DocumentExternal[];
  legal?: KybT;
  dataProtection?: TrustCenterDataProtection;
  subprocessors?: SubprocessorT[];
  ai?: TrustCenterAi;
  faq?: TrustCenterFaq;
};

/**
 * Collate all the information on the trust center required to perform a search.
 */
export function useSearchTrustCenterData(): SearchTrustCenterData {
  const state = useTrustCenterState();

  return {
    overview: state.config.overview,
    certifications: state.certifications,
    documents: state.documents,
    legal: state.config.kyb,
    dataProtection: state.config.data_protection,
    subprocessors: state.config.subprocessors,
    ai: state.config.ai,
    faq: state.config.faq,
  };
}

export type SearchMarkdownResult = {
  headingId?: string;
  text: string;
};

export type SearchTrustCenterAiResults = {
  overview: SearchMarkdownResult[];
  dataUse: TrustCenterAiDataUseItemConfig[];
  checkItems: TrustCenterAiCheckItemConfig[];
  modelProviders: AiModelProvider[];
  faq: TrustCenterFaqQuestion[];
};

export type SearchTrustCenterDataProtectionResults = {
  overview: SearchMarkdownResult[];
  dataUse: TrustCenterDataProtectionDataUseItemConfig[];
  subprocessors: SubprocessorT[];
};

export type SearchTrustCenterResults = {
  query: string;
  data: {
    overview: SearchMarkdownResult[];
    certifications: DocumentExternal[];
    documents: DocumentExternal[];
    legal: KybEntry[];
    dataProtection: SearchTrustCenterDataProtectionResults;
    ai: SearchTrustCenterAiResults;
    faq: TrustCenterFaqQuestion[];
  };
};

export function countAiSearchResults(results: SearchTrustCenterAiResults): number {
  return (
    results.overview.length +
    results.dataUse.length +
    results.checkItems.length +
    results.modelProviders.length +
    results.faq.length
  );
}

export function countDataProtectionSearchResults(results: SearchTrustCenterDataProtectionResults): number {
  return results.overview.length + results.dataUse.length + results.subprocessors.length;
}

export function countTotalSearchResults(results: SearchTrustCenterResults): number {
  return (
    results.data.overview.length +
    results.data.certifications.length +
    results.data.documents.length +
    results.data.legal.length +
    countAiSearchResults(results.data.ai) +
    countDataProtectionSearchResults(results.data.dataProtection) +
    results.data.faq.length
  );
}

export function generateSearchPlaceholder(data: SearchTrustCenterData): string {
  const options = [
    ...(data.documents?.map(d => d.name) ?? []),
    ...(data.certifications?.map(c => c.name) ?? []),
    ...(data.legal?.map(k => k.key) ?? []),
  ];

  if (options.length === 0) {
    return "Search...";
  }

  const idx = Math.floor(Math.random() * options.length);
  return `e.g. "${options[idx]}"`;
}

/**
 * Given a string `str` and a query `query`, return a substring of `str`
 * centered around the first occurrence of `query`. The resulting substring will
 * have a maximum length of `maxChars` characters (excluding the query), will be
 * trimmed for whitespace, and ellipses ("...") will be added at the beginning
 * and/or end if the substring does not include the start or end of `str`.
 *
 * @param {string} str The string to trim
 * @param {string} query The query to center around
 * @param {number} [maxChars] The maximum number of characters in the resulting string [default: 250]
 *
 * @example
 * trimAroundQuery("The quick brown fox jumps over the lazy dog", "fox", 13) // returns "...brown fox jumps..."
 */
export function trimAroundQuery(str: string, query: string, maxChars: number = 250): string {
  query = query.toLowerCase();

  const idx = str.toLowerCase().indexOf(query);

  let result;

  if (idx === -1) {
    result = str.length > maxChars ? str.slice(0, maxChars) + "..." : str;
  } else {
    const start = Math.max(0, idx - maxChars / 2);
    const end = Math.min(str.length, idx + query.length + maxChars / 2);

    result = str.slice(start, end).trim();

    if (start > 0) {
      result = "..." + result;
    }

    if (end < str.length) {
      result = result + "...";
    }
  }

  return result;
}

function personMatchesQuery(person: PersonT, query: string): boolean {
  return (
    person.name.toLowerCase().includes(query) ||
    (person.role !== undefined && person.role.toLowerCase().includes(query)) ||
    (person.url !== undefined && person.url.toLowerCase().includes(query))
  );
}

function subprocessorMatchesQuery(subprocessor: SubprocessorT, query: string): boolean {
  return subprocessor.name.toLowerCase().includes(query);
}

function aiDataUseItemMatchesQuery(item: TrustCenterAiDataUseItemConfig, query: string): boolean {
  return item.title.toLowerCase().includes(query) || item.description.toLowerCase().includes(query);
}

function aiCheckItemMatchesQuery(item: TrustCenterAiCheckItemConfig, query: string): boolean {
  return item.title.toLowerCase().includes(query) || item.description.toLowerCase().includes(query);
}

function aiModelProviderMatchesQuery(item: AiModelProvider, query: string): boolean {
  return (
    subprocessorMatchesQuery(item.subprocessor, query) ||
    item.models_used.toLowerCase().includes(query) ||
    item.purposes.toLowerCase().includes(query)
  );
}

function dataProtectionDataUseItemMatchesQuery(
  item: TrustCenterDataProtectionDataUseItemConfig,
  query: string,
): boolean {
  return item.title.toLowerCase().includes(query) || item.description.toLowerCase().includes(query);
}

function faqQuestionMatchesQuery(question: TrustCenterFaqQuestion, query: string): boolean {
  return question.text.toLowerCase().includes(query) || question.response.toLowerCase().includes(query);
}

function searchMarkdown(markdown: string, query: string): SearchMarkdownResult[] {
  const processor = unified().use(remarkParse);
  const ast = processor.parse(markdown);

  let currentHeadingId: string | undefined;
  const results: SearchMarkdownResult[] = [];

  for (const node of ast.children) {
    if (isAnchorableHeading(node)) {
      currentHeadingId = parseHeading(node).id;
    }

    const text = toPlainText(node);

    if (text.toLowerCase().includes(query)) {
      results.push({headingId: currentHeadingId, text});
    }
  }

  return results;
}

/**
 * Search the trust center.
 *
 * This currently only performs a client side search (using data passed in via
 * `data`).
 */
export async function searchTrustCenter(query: string, data: SearchTrustCenterData): Promise<SearchTrustCenterResults> {
  // Simulated delay
  await new Promise(r => setTimeout(r, 300));

  query = query.toLowerCase();

  const overview = (() => {
    const results: SearchMarkdownResult[] = [];

    if (data.overview?.welcome !== undefined) {
      results.push(...searchMarkdown(data.overview.welcome, query));
    }

    if (data.overview?.content !== undefined) {
      results.push(...searchMarkdown(data.overview.content, query));
    }

    return results;
  })();

  const documents = data.documents?.filter(d => d.name.toLowerCase().includes(query)) ?? [];
  const certifications = data.certifications?.filter(c => c.name.toLowerCase().includes(query)) ?? [];

  const legal =
    data.legal?.filter(e => {
      if (e.key.toLowerCase().includes(query)) {
        return true;
      }

      switch (e.type) {
        case "STRING":
          return e.value !== undefined && e.value.toLowerCase().includes(query);
        case "URL":
          return e.value !== undefined && e.value.some(v => v.toLowerCase().includes(query));
        case "ENTITIES":
          return e.value.some(p => personMatchesQuery(p, query));
        default:
          unreachableCase(e);
      }
    }) ?? [];

  const ai = (() => {
    const results: SearchTrustCenterAiResults = {
      overview: [],
      dataUse: [],
      checkItems: [],
      modelProviders: [],
      faq: [],
    };

    if (data.ai == null) {
      return results;
    }

    if (data.ai.overview != null) {
      results.overview.push(...searchMarkdown(data.ai.overview, query));
    }

    results.dataUse.push(...data.ai.data_use_items.filter(i => aiDataUseItemMatchesQuery(i, query)));
    results.checkItems.push(...data.ai.check_items.filter(i => aiCheckItemMatchesQuery(i, query)));
    results.modelProviders.push(...data.ai.model_providers.filter(i => aiModelProviderMatchesQuery(i, query)));
    results.faq.push(...data.ai.faq.sections.flatMap(s => s.questions).filter(q => faqQuestionMatchesQuery(q, query)));

    return results;
  })();

  const dataProtection = (() => {
    const results: SearchTrustCenterDataProtectionResults = {
      overview: [],
      dataUse: [],
      subprocessors: [],
    };

    if (data.dataProtection == null) {
      return results;
    }

    if (data.dataProtection.overview != null) {
      results.overview.push(...searchMarkdown(data.dataProtection.overview, query));
    }

    if (data.dataProtection.subprocessors && data.subprocessors?.length !== 0) {
      const subprocessors = data.subprocessors?.filter(sp => subprocessorMatchesQuery(sp, query)) ?? [];
      results.subprocessors.push(...subprocessors);
    }

    results.dataUse.push(
      ...data.dataProtection.data_use_items.filter(i => dataProtectionDataUseItemMatchesQuery(i, query)),
    );

    return results;
  })();

  const faq = data.faq?.sections.flatMap(s => s.questions).filter(q => faqQuestionMatchesQuery(q, query)) ?? [];

  return {
    query,
    data: {
      overview,
      certifications,
      documents,
      legal,
      dataProtection,
      ai,
      faq,
    },
  };
}
