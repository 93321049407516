import {Badge, HStack, Text} from "@chakra-ui/react";
import {useParams} from "react-router-dom";
import {MitigationStatus, ThirdPartyId} from "../../../../Types.ts";
import {useQueryData} from "../../../../state/index.ts";

const Title = () => {
  const thirdPartyId = useParams().thirdPartyId! as ThirdPartyId;
  const count = useQueryData({
    queryKey: ["tprm", "thirdParties", "risks", thirdPartyId],
  }).filter(r => r.management.status != MitigationStatus.MITIGATED).length;
  return (
    <HStack>
      <Text>Risks</Text>
      {count > 0 && <Badge colorScheme="red">{count}</Badge>}
    </HStack>
  );
};

export default Title;
