import {
  HStack,
  Icon,
  Stack,
  Avatar,
  Box,
  Image,
  Text,
  Heading,
  Highlight as ChakraHighlight,
  LinkBox,
  LinkOverlay,
  Table,
  TableContainer,
  Tr,
  Tbody,
  Td,
} from "@chakra-ui/react";
import {ChevronRightIcon, DocumentTextIcon} from "@heroicons/react/24/outline";
import {Link as RouterLink} from "react-router-dom";
import {SearchMarkdownResult, SearchTrustCenterResults, countTotalSearchResults, trimAroundQuery} from "./search";
import {ComponentProps, useMemo} from "react";
import {certificationImageFromType} from "../../utility/certifications";
import {DocumentExternal, TrustCenterFaqQuestion} from "../../../Types";
import {useTime} from "../../../hooks/time";
import {relativeDateView} from "../../../utils/time";
import {DocumentDownloadButton} from "../../Documents";
import {AiModelProvider, KybEntry, SubprocessorT} from "../../config";
import {LegalEntry} from "../Legal";
import {ModelProviderContent} from "../../AiPosture";

export function MaybeHighlight({
  query,
  children,
}: {
  query?: string;
  children: ComponentProps<typeof ChakraHighlight>["children"];
}) {
  const Wrapper = useMemo(
    () =>
      query != null
        ? ({children}: {children: ComponentProps<typeof ChakraHighlight>["children"]}) => (
            <Highlight query={query}>{children}</Highlight>
          )
        : ({children}: {children: ComponentProps<typeof ChakraHighlight>["children"]}) => <>{children}</>,
    [query],
  );

  return <Wrapper>{children}</Wrapper>;
}

export function Highlight({
  query,
  children,
}: {
  query: string;
  children: ComponentProps<typeof ChakraHighlight>["children"];
}) {
  return (
    <ChakraHighlight query={query} styles={{bg: "blue.100", color: "black"}}>
      {children}
    </ChakraHighlight>
  );
}

function ResultSubHeading({count, children}: {count: number; children: React.ReactNode}) {
  return (
    <HStack
      bg="gray.100"
      px={4}
      py={2}
      _notLast={{borderTop: "1px solid", borderBottom: "1px solid", borderColor: "gray.200"}}
    >
      <Text fontSize="md" fontWeight="600" color="gray.500">
        {children} ({count})
      </Text>
    </HStack>
  );
}

function ResultItemSection({title, count, children}: {title: string; count: number; children: React.ReactNode}) {
  return (
    <>
      <ResultSubHeading count={count}>{title}</ResultSubHeading>
      <Stack spacing={0}>{children}</Stack>
    </>
  );
}

function ResultItemBase({children}: {children: React.ReactNode}) {
  return (
    <LinkBox
      as={HStack}
      p={4}
      px={6}
      spacing={6}
      _hover={{bg: "gray.50"}}
      cursor="pointer"
      _notLast={{
        borderBottom: "1px solid",
        borderBottomColor: "gray.200",
      }}
    >
      <HStack flex="1 1 auto" spacing={6}>
        {children}
      </HStack>
      <Icon as={ChevronRightIcon} w={4} h={4} color="gray.500" />
    </LinkBox>
  );
}

function ResultLegalInformationContainer({children}: {children: React.ReactNode}) {
  return (
    <TableContainer>
      <Table variant="simple">
        <Tbody>{children}</Tbody>
      </Table>
    </TableContainer>
  );
}

function ResultLegalInformationItem({entry, onNavigate}: {entry: KybEntry; onNavigate: () => void}) {
  const CELL_STYLES = {
    px: 6,
    py: 4,
    border: "none",
  };

  return (
    <LinkBox as={Tr} _hover={{bg: "gray.50 "}} _notLast={{borderBottom: "1px solid", borderColor: "gray.200"}}>
      <Td w="1%" {...CELL_STYLES} verticalAlign="top" borderRight="1px solid" borderColor="gray.200">
        <LinkOverlay as={RouterLink} to={"./legal"} onClick={onNavigate}>
          <Text fontWeight="500" whiteSpace="nowrap">
            {entry.key}
          </Text>
        </LinkOverlay>
      </Td>
      <Td {...CELL_STYLES} px={entry.type === "ENTITIES" ? 4 : 6}>
        <LegalEntry entry={entry} />
      </Td>
      <Td {...CELL_STYLES} w="1%">
        <Icon as={ChevronRightIcon} w={4} h={4} color="gray.500" />
      </Td>
    </LinkBox>
  );
}

function ResultSubprocessorItem({
  subprocessor,
  query,
  onNavigate,
}: {
  subprocessor: SubprocessorT;
  query: string;
  onNavigate: () => void;
}) {
  const avatarSrc = subprocessor.url != null ? `https://logo.clearbit.com/${subprocessor.url}` : undefined;

  return (
    <ResultItemBase>
      <Avatar name={subprocessor.name} src={avatarSrc} size="sm" />
      <LinkOverlay
        as={RouterLink}
        to={{
          pathname: "./data",
          search: new URLSearchParams({q: subprocessor.name}).toString(),
        }}
        onClick={onNavigate}
      >
        <Text fontWeight={500}>
          <Highlight query={query}>{subprocessor.name}</Highlight>
        </Text>
      </LinkOverlay>
    </ResultItemBase>
  );
}

function ResultDocumentItem({document, onNavigate}: {document: DocumentExternal; onNavigate: () => void}) {
  const readableText = document.is_readable ? "Public" : "Restricted";
  const lastUpdatedText = useTime(relativeDateView(document.last_update), [document.last_update]);

  return (
    <ResultItemBase>
      <Box w={8} h={8} display="grid" placeItems="center">
        <Icon as={DocumentTextIcon} w={6} h={6} />
      </Box>
      <Stack flex="1 1 auto" spacing={0}>
        <LinkOverlay
          as={RouterLink}
          to={{pathname: "./documents", search: new URLSearchParams({q: document.name}).toString()}}
          onClick={onNavigate}
        >
          <Text fontWeight={500}>{document.name}</Text>
        </LinkOverlay>
        <Text fontSize="md" color="gray.500">
          {readableText} • Last updated: {lastUpdatedText}
        </Text>
      </Stack>
      <DocumentDownloadButton document={document} />
    </ResultItemBase>
  );
}

function ResultCertificationItem({
  certification,
  onNavigate,
}: {
  certification: DocumentExternal;
  onNavigate: () => void;
}) {
  const readableText = certification.is_readable ? "Public" : "Restricted";
  const lastUpdatedText = useTime(relativeDateView(certification.last_update), [certification.last_update]);

  const image = useMemo(
    () => certificationImageFromType(certification.certification_type),
    [certification.certification_type],
  );

  return (
    <ResultItemBase>
      {image != null && (
        <Box w={8} h={8} display="grid" placeItems="center">
          <Image src={image} />
        </Box>
      )}
      <Stack flex="1 1 auto" spacing={0}>
        <LinkOverlay
          as={RouterLink}
          to={{pathname: "./certifications", search: new URLSearchParams({q: certification.name}).toString()}}
          onClick={onNavigate}
        >
          <Text fontWeight={500}>{certification.name}</Text>
        </LinkOverlay>
        <Text fontSize="md" color="gray.500">
          {readableText} • Last updated: {lastUpdatedText}
        </Text>
      </Stack>
      <DocumentDownloadButton document={certification} />
    </ResultItemBase>
  );
}

function ResultMarkdownItem({
  item,
  query,
  to,
  onNavigate,
}: {
  item: SearchMarkdownResult;
  query: string;
  to: string;
  onNavigate: () => void;
}) {
  const text = useMemo(() => trimAroundQuery(item.text, query, 500), [query, item.text]);

  return (
    <ResultItemBase>
      <LinkOverlay as={RouterLink} to={{pathname: to, hash: item.headingId}} onClick={onNavigate}>
        <Text whiteSpace="pre-wrap" color="gray.600">
          <Highlight query={query}>{text}</Highlight>
        </Text>
      </LinkOverlay>
    </ResultItemBase>
  );
}

function ResultTextDescriptionItem({
  item,
  query,
  onNavigate,
  to,
}: {
  item: {title: string; description: string};
  query: string;
  onNavigate: () => void;
  to: string;
}) {
  return (
    <ResultItemBase>
      <LinkOverlay as={RouterLink} to={to} onClick={onNavigate}>
        <Text fontWeight="500">
          <Highlight query={query}>{item.title}</Highlight>
        </Text>
        <Text color="gray.600">
          <Highlight query={query}>{item.description}</Highlight>
        </Text>
      </LinkOverlay>
    </ResultItemBase>
  );
}

function ResultAiModelProviderItem({
  item,
  query,
  onNavigate,
}: {
  item: AiModelProvider;
  query: string;
  onNavigate: () => void;
}) {
  const subprocessor = item.subprocessor;

  const avatarSrc = subprocessor.url != null ? `https://logo.clearbit.com/${subprocessor.url}` : undefined;

  return (
    <ResultItemBase>
      <Stack spacing={8}>
        <HStack spacing={6}>
          <Avatar name={subprocessor.name} src={avatarSrc} size="sm" />
          <LinkOverlay
            as={RouterLink}
            to={{
              pathname: "./ai",
              search: new URLSearchParams({q: subprocessor.name}).toString(),
            }}
            onClick={onNavigate}
          >
            <Text fontWeight={500}>
              <Highlight query={query}>{subprocessor.name}</Highlight>
            </Text>
          </LinkOverlay>
        </HStack>
        <ModelProviderContent modelProvider={item} query={query} />
      </Stack>
    </ResultItemBase>
  );
}

function ResultFaqItem({
  question,
  query,
  to,
  onNavigate,
}: {
  question: TrustCenterFaqQuestion;
  query: string;
  to: string;
  onNavigate: () => void;
}) {
  const response = useMemo(() => trimAroundQuery(question.response, query), [query, question.response]);

  return (
    <ResultItemBase>
      <Stack>
        <LinkOverlay
          as={RouterLink}
          to={{
            pathname: to,
            hash: question.question_id,
          }}
          onClick={onNavigate}
        >
          <Text fontWeight="500">
            <Highlight query={query}>{question.text}</Highlight>
          </Text>
        </LinkOverlay>
        <Text color="gray.600" noOfLines={3}>
          <Highlight query={query}>{response}</Highlight>
        </Text>
      </Stack>
    </ResultItemBase>
  );
}

function NoSearchResults() {
  return (
    <Stack spacing={4}>
      <Heading as="h3" fontSize="lg" fontWeight="500">
        No search results.
      </Heading>
      <Box border="1px solid" borderColor="gray.100" rounded="lg" overflow="hidden" p={4}>
        <Text color="gray.500">No results were found for your search query. Please try searching again.</Text>
      </Box>
    </Stack>
  );
}

export type SearchResultsProps = {
  results: SearchTrustCenterResults;
  onNavigate: () => void;
};

export function SearchResults({results, onNavigate}: SearchResultsProps) {
  const total = useMemo(() => countTotalSearchResults(results), [results]);

  if (total === 0) {
    return <NoSearchResults />;
  }

  return (
    <Stack spacing={4}>
      <Heading as="h3" fontSize="lg" fontWeight="500">
        {total} search result{total === 1 ? "" : "s"}:
      </Heading>
      <Stack border="1px solid" borderColor="gray.200" rounded="lg" spacing={0} overflow="hidden">
        {results.data.certifications.length > 0 && (
          <ResultItemSection title="Certifications" count={results.data.certifications.length}>
            {results.data.certifications.map(c => (
              <ResultCertificationItem key={c.document_id} certification={c} onNavigate={onNavigate} />
            ))}
          </ResultItemSection>
        )}

        {results.data.documents.length > 0 && (
          <ResultItemSection title="Documents" count={results.data.documents.length}>
            {results.data.documents.map(d => (
              <ResultDocumentItem key={d.document_id} document={d} onNavigate={onNavigate} />
            ))}
          </ResultItemSection>
        )}

        {results.data.legal.length > 0 && (
          <ResultItemSection title="Legal information" count={results.data.legal.length}>
            <ResultLegalInformationContainer>
              {results.data.legal.map(k => (
                <ResultLegalInformationItem key={k.key} entry={k} onNavigate={onNavigate} />
              ))}
            </ResultLegalInformationContainer>
          </ResultItemSection>
        )}

        {results.data.dataProtection.overview.length > 0 && (
          <ResultItemSection title="Data Protection - Overview" count={results.data.dataProtection.overview.length}>
            {results.data.dataProtection.overview.map((o, idx) => (
              <ResultMarkdownItem key={idx} item={o} query={results.query} onNavigate={onNavigate} to={"./data"} />
            ))}
          </ResultItemSection>
        )}

        {results.data.dataProtection.dataUse.length > 0 && (
          <ResultItemSection title="Data Protection - Data we use" count={results.data.dataProtection.dataUse.length}>
            {results.data.dataProtection.dataUse.map((d, idx) => (
              <ResultTextDescriptionItem
                key={idx}
                item={d}
                query={results.query}
                onNavigate={onNavigate}
                to={"./data"}
              />
            ))}
          </ResultItemSection>
        )}

        {results.data.dataProtection.subprocessors.length > 0 && (
          <ResultItemSection
            title="Data Protection - Subprocessors"
            count={results.data.dataProtection.subprocessors.length}
          >
            {results.data.dataProtection.subprocessors.map(s => (
              <ResultSubprocessorItem key={s.name} subprocessor={s} query={results.query} onNavigate={onNavigate} />
            ))}
          </ResultItemSection>
        )}

        {results.data.overview.length > 0 && (
          <ResultItemSection title="Overview" count={results.data.overview.length}>
            {results.data.overview.map((o, idx) => (
              <ResultMarkdownItem key={idx} item={o} query={results.query} onNavigate={onNavigate} to="./overview" />
            ))}
          </ResultItemSection>
        )}

        {results.data.ai.overview.length > 0 && (
          <ResultItemSection title="AI - Overview" count={results.data.ai.overview.length}>
            {results.data.ai.overview.map((o, idx) => (
              <ResultMarkdownItem key={idx} item={o} query={results.query} onNavigate={onNavigate} to="./ai" />
            ))}
          </ResultItemSection>
        )}

        {results.data.ai.dataUse.length > 0 && (
          <ResultItemSection title="AI - Data we use" count={results.data.ai.dataUse.length}>
            {results.data.ai.dataUse.map((d, idx) => (
              <ResultTextDescriptionItem key={idx} item={d} query={results.query} onNavigate={onNavigate} to="./ai" />
            ))}
          </ResultItemSection>
        )}

        {results.data.ai.checkItems.length > 0 && (
          <ResultItemSection title="AI - Trust and compliance" count={results.data.ai.checkItems.length}>
            {results.data.ai.checkItems.map((d, idx) => (
              <ResultTextDescriptionItem key={idx} item={d} query={results.query} onNavigate={onNavigate} to="./ai" />
            ))}
          </ResultItemSection>
        )}

        {results.data.ai.modelProviders.length > 0 && (
          <ResultItemSection title="AI - Model providers" count={results.data.ai.modelProviders.length}>
            {results.data.ai.modelProviders.map(m => (
              <ResultAiModelProviderItem
                key={m.subprocessor.id}
                item={m}
                query={results.query}
                onNavigate={onNavigate}
              />
            ))}
          </ResultItemSection>
        )}

        {results.data.ai.faq.length > 0 && (
          <ResultItemSection title="AI - FAQs" count={results.data.ai.faq.length}>
            {results.data.ai.faq.map(q => (
              <ResultFaqItem key={q.question_id} question={q} query={results.query} to="./ai" onNavigate={onNavigate} />
            ))}
          </ResultItemSection>
        )}

        {results.data.faq.length > 0 && (
          <ResultItemSection title="FAQs" count={results.data.faq.length}>
            {results.data.faq.map(q => (
              <ResultFaqItem
                key={q.question_id}
                question={q}
                query={results.query}
                to="./faq"
                onNavigate={onNavigate}
              />
            ))}
          </ResultItemSection>
        )}
      </Stack>
    </Stack>
  );
}
