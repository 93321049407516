import {Heading, Stack, Divider, Button, Icon, useDisclosure} from "@chakra-ui/react";
import Page from "../../../components/Page.tsx";
import {ActionBar, ActionBarSearch} from "../../../components/ActionBar.tsx";
import {withSuspense} from "../../../state/withSuspense.tsx";
import {useQueryData} from "../../../state/index.ts";
import ThirdPartiesTable from "../ThirdParty/components/ThirdPartiesTable.tsx";
import {PlusIcon} from "@heroicons/react/20/solid";
import CreateThirdPartyModal from "./CreateThirdPartyModal";
import {useFilterState} from "../../../hooks/filterState";
import {ThirdPartyFilters, facetedSearchThirdParty} from "../../components/Filters";
import {useFacetedSearch} from "../../../hooks/search";

const ThirdPartiesPage = withSuspense(() => {
  const thirdParties = useQueryData({queryKey: ["tprm", "thirdParties"]});
  const createThirdPartyModal = useDisclosure();

  const {filters} = useFilterState<ThirdPartyFilters>("thirdPartyFilters", {});
  const {query, queries, setQuery, filter} = useFacetedSearch(t => t.name, facetedSearchThirdParty(filters), [filters]);
  const {result: filteredThirdParties} = filter(thirdParties);

  return (
    <Page title="My tasks">
      <Stack h="full" spacing={0} divider={<Divider borderColor="gray.200" />}>
        <Heading size="md" p="8">
          Third parties
        </Heading>
        <ActionBar
          actionButtons={
            <>
              <Button colorScheme="green" leftIcon={<Icon as={PlusIcon} />} onClick={createThirdPartyModal.onOpen}>
                Create third party
              </Button>
              {createThirdPartyModal && <CreateThirdPartyModal {...createThirdPartyModal} />}
            </>
          }
        >
          <ActionBarSearch value={query} onChange={e => setQuery(e.target.value)} />
        </ActionBar>
        <ThirdPartiesTable thirdParties={filteredThirdParties} queries={queries} />
      </Stack>
    </Page>
  );
});

export default ThirdPartiesPage;
