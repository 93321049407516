import {Text, Icon, Tooltip, TooltipProps} from "@chakra-ui/react";
import {InformationCircleIcon} from "@heroicons/react/20/solid";

export const TooltipWithIcon = ({children, ...props}: TooltipProps) => {
  return (
    <Tooltip {...props}>
      <Text as="span">
        {children}
        <Icon ml="2" as={InformationCircleIcon} color="gray.400" verticalAlign="middle" />
      </Text>
    </Tooltip>
  );
};

export const AIAssistanceTooltip = (props: Omit<TooltipProps, "children">) => {
  return (
    <TooltipWithIcon
      placement="top-start"
      label="Automatically generate responses to your questions"
      fontSize="sm"
      {...props}
    >
      <Text as="span">Enable AI assistance</Text>
    </TooltipWithIcon>
  );
};
