import {Text} from "@chakra-ui/react";

function NoReviewItemsSnippet({filtered = false, searched = false}: {filtered?: boolean; searched?: boolean}) {
  const suffix = searched ? " matching this search" : filtered ? " matching these filters" : "";

  return (
    <Text p={6} fontSize="md" color="gray.500">
      There are no review items{suffix}.
    </Text>
  );
}

export default NoReviewItemsSnippet;
