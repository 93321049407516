import {CreateSection, File, IdentifiedSheetSection} from "../../../../../../Types";
import {FileUpload} from "../../../../../../hooks/fileUpload";
import {useXLSX} from "../../../../../../hooks/import";
import type * as XLSX from "xlsx";
import {Prefix, useStack} from "../../../../../../hooks/stack";
import {useState} from "react";
import {IAbortable, useAbortable} from "../../../../../../hooks/abortable";

type UploadState = {
  xlsx: typeof XLSX;
  fileUpload: FileUpload | null;
};

type SelectState = {
  file: File;
  workbook: XLSX.WorkBook;
  selectedSheetNames: string[];
};

export type SheetState = {
  sections: IdentifiedSheetSection[];
};

export type AnnotateState = {[sheetName: string]: SheetState};

type ReviewState = CreateSection[];

export type ImportState = Prefix<[UploadState, SelectState, AnnotateState, ReviewState], 1>;

function listAbortables(value: ImportState): IAbortable[] {
  const {fileUpload} = value[0];
  return fileUpload ? [fileUpload] : [];
}

export default function useImportState() {
  const xlsx = useXLSX();
  return useStack(useAbortable(useState<ImportState>([{xlsx, fileUpload: null}]), listAbortables));
}
