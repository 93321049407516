import * as batshit from "@yornaath/batshit";
import {LayerType, QuestionId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";
import {resolver, scheduler} from "../../batch";

const questions = batshit.create({
  fetcher: api.vendorToolkit.questions.load,
  resolver: resolver("question_id"),
  scheduler,
});

export default [
  typedQuery(["vendorToolkit", "question", param<QuestionId>("questionId")], async context => {
    return await questions.fetch(context.queryKey[2]);
  }),
  typedQuery(
    ["vendorToolkit", "questionDocumentation", param<QuestionId>("questionId"), param<LayerType>()],
    async context => {
      return await api.vendorToolkit.questions.listDocumentation(context.queryKey[2], context.queryKey[3]);
    },
  ),
  typedQuery(["vendorToolkit", "questionUsedFacts", param<QuestionId>("questionId")], async context => {
    return await api.vendorToolkit.questions.listUsedFacts(context.queryKey[2]);
  }),
  typedQuery(["vendorToolkit", "questionGeneratedFacts", param<QuestionId>("questionId")], async context => {
    return await api.vendorToolkit.questions.listGeneratedFacts(context.queryKey[2]);
  }),
  typedQuery(["vendorToolkit", "watchingQuestion", param<QuestionId>("questionId")], async context => {
    return await api.vendorToolkit.questions.getWatching(context.queryKey[2]);
  }),
];
