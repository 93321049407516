import {Button, ButtonGroup, Divider, Stack} from "@chakra-ui/react";
import {ActionBar, ActionBarSearch} from "../../../../components/ActionBar";
import ThirdPartiesTable from "../components/ThirdPartiesTable";
import {useOutletContext} from "react-router-dom";
import {ThirdParty} from "../../../../Types";

const SuppliersPage = () => {
  const thirdParty: ThirdParty = useOutletContext();

  return (
    <Stack spacing={0} pb={12} divider={<Divider />}>
      <ActionBar
        actionButtons={
          <ButtonGroup colorScheme="green">
            <Button>Add supplier</Button>
            <Button>Link existing supplier</Button>
          </ButtonGroup>
        }
      >
        <ActionBarSearch />
      </ActionBar>
      <ThirdPartiesTable thirdParties={thirdParty.linked_third_parties} />
    </Stack>
  );
};

export default SuppliersPage;
