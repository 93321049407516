import {
  TableContainer,
  Table,
  Tbody,
  Tr,
  Box,
  Spinner,
  Icon,
  LinkBox,
  LinkOverlay,
  Td,
  Text,
  Badge,
} from "@chakra-ui/react";
import {useQueryData} from "../../../../state";
import {withSuspense} from "../../../../state/withSuspense";
import {ResolutionMin} from "../../../../Types";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import {Link as RouterLink} from "react-router-dom";
import {resolutionRenderInfo} from "../../Library/FactLibrary/utility/review";
import {useTime} from "../../../../hooks/time";
import {relativeDateView} from "../../../../utils/time";

function Row({resolution}: {resolution: ResolutionMin}) {
  const CELL_STYLES = {
    border: "none",
  };

  const badge = resolutionRenderInfo(resolution).badge;
  const time = useTime(relativeDateView(resolution.created_at), [resolution.created_at]);

  return (
    <LinkBox as={Tr} _hover={{bg: "gray.50"}} _notLast={{borderBottom: "1px solid", borderColor: "gray.200"}}>
      <Td {...CELL_STYLES}>
        <Badge colorScheme={badge.color} fontSize="sm">
          {badge.label}
        </Badge>
      </Td>
      <Td {...CELL_STYLES} whiteSpace="wrap" w="100%">
        <LinkOverlay as={RouterLink} to={`/vendor-toolkit/library/facts/review-items/${resolution.resolution_id}`}>
          <Text noOfLines={2}>{resolution.reason}</Text>
        </LinkOverlay>
      </Td>
      <Td color="gray.500">{time}</Td>
      <Td {...CELL_STYLES}>
        <Icon display="block" as={ChevronRightIcon} />
      </Td>
    </LinkBox>
  );
}

export const ReviewItemsTable = withSuspense(
  ({offset, limit}: {offset: number; limit: number}) => {
    const reviewItems = useQueryData({
      queryKey: ["vendorToolkit", "resolutions", {assigned_to_me: true, limit, offset}],
    }).items;

    return (
      <TableContainer>
        <Table variant="simple" fontSize="md">
          <Tbody>
            {reviewItems.map(item => (
              <Row key={item.resolution_id} resolution={item} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    );
  },
  <Box p={8} display="grid" placeItems="center">
    <Spinner size="sm" />
  </Box>,
);
