import {Box, Text} from "@chakra-ui/react";
import {MentionElement} from "../RichText";
import {ActivityPayloadViewProps} from "..";
import DocumentCategoryComp from "../../../Products/VendorToolkit/components/DocumentCategory/Selector";
import SharingClassificationComp from "../../../Products/VendorToolkit/components/SharingClassification/Selector";

const DocumentUpdatedView = ({payload, mentions}: ActivityPayloadViewProps<"DocumentUpdated">) => {
  switch (payload.type) {
    case "Generic":
      return <Text as="span">updated this document</Text>;
    case "Owner":
      return mentions[0] ? (
        <Text as="span">
          assigned this document to <MentionElement mention={mentions[0]} />
        </Text>
      ) : (
        <Text as="span">unassigned this document</Text>
      );
    case "AiText":
      return <Text as="span">updated the AI text</Text>;
    case "Category":
      return (
        <Text as="span">
          changed the category to{" "}
          <Box display="inline-block" verticalAlign="middle">
            <DocumentCategoryComp category={payload.content} size="sm" />
          </Box>
        </Text>
      );
    case "Created":
      return <Text as="span">created this document</Text>;
    case "Deleted":
      return <Text as="span">deleted this document</Text>;
    case "File":
      return <Text as="span">updated the content</Text>;
    case "Name":
      return (
        <Text as="span">
          renamed this document to{" "}
          <Text as="span" fontWeight="semibold">
            {payload.content}
          </Text>
        </Text>
      );
    case "Restored":
      return <Text as="span">restored this document</Text>;
    case "ReviewPeriod":
      return <Text as="span">changed the review period</Text>;
    case "Scopes":
      return <Text as="span">changed the scopes</Text>;
    case "SharingClassification":
      return (
        <Text as="span">
          changed the sharing status to{" "}
          <Box display="inline-block" verticalAlign="middle">
            <SharingClassificationComp sharing={payload.content} size="sm" />
          </Box>
        </Text>
      );
    case "TrustCenterConfig":
      return <Text as="span">changed the Showcase configuration</Text>;
  }
};

export default DocumentUpdatedView;
