import {addDays, formatISO} from "date-fns";
import {SourceMin, SourceId, nominate, ThirdPartyId} from "../../Types";

const days_ago = (days: number) => nominate("datetime", formatISO(addDays(new Date(), -days)));
const date = (years: number, months: number, days: number) =>
  nominate("datetime", formatISO(new Date(years, months, days)));

const TODAY = nominate("datetime", formatISO(new Date()));
const YESTERDAY = days_ago(1);

const SOURCES: SourceMin[] = [
  {
    name: "Supplier Assessment Questionnaire",
    source_id: nominate("sourceId", "Source1"),
    provider: {
      type: "Questionnaire",
      questions: 104,
      completed_questions: 100,
    },
    created_at: YESTERDAY,
    updated_at: YESTERDAY,
    synced_at: YESTERDAY,
    monitored: false,
    confidence: {score: 4, name: "High"},
  },
  {
    name: "ISO27001 Report",
    source_id: nominate("sourceId", "Source2"),
    provider: {
      type: "Audit",
      standard: "ISO27001",
      verified: true,
      verification_url: "https://www.qmsuk.com/verification",
      auditor: {
        name: "Citation ISO Certification",
        url: "https://www.citation.co.uk/",
      },
    },
    created_at: YESTERDAY,
    updated_at: date(2024, 7, 3),
    synced_at: YESTERDAY,
    monitored: false,
    confidence: {score: 5, name: "Auditor"},
  },
  {
    name: "Trust Center",
    source_id: nominate("sourceId", "Source4"),
    provider: {type: "Website", url: "https://trust.salesforce.com", pages: 46},
    created_at: YESTERDAY,
    updated_at: TODAY,
    synced_at: TODAY,
    confidence: {score: 3, name: "Medium"},
    monitored: true,
  },
  {
    name: "Product Documentation",
    source_id: nominate("sourceId", "Source5"),
    provider: {type: "Website", url: "https://security.salesforce.com", pages: 12},
    created_at: YESTERDAY,
    updated_at: TODAY,
    synced_at: TODAY,
    monitored: true,
    confidence: {score: 3, name: "Medium"},
  },
  {
    name: "Security Scorecard Report",
    source_id: nominate("sourceId", "Source6"),
    provider: {
      type: "File",
      file_type: "PDF",
      words: 4192,
    },
    created_at: YESTERDAY,
    updated_at: days_ago(62),
    synced_at: YESTERDAY,
    confidence: {score: 2, name: "Low"},
    monitored: false,
  },
  {
    name: "Adverse News",
    source_id: nominate("sourceId", "Source7"),
    provider: {type: "Google News", articles: 3},
    created_at: YESTERDAY,
    updated_at: TODAY,
    synced_at: TODAY,
    confidence: {score: 4, name: "High"},
    monitored: true,
  },
];

export async function list(_thirdPartyId: ThirdPartyId): Promise<SourceMin[]> {
  return SOURCES;
}

export async function get(sourceId: SourceId): Promise<SourceMin> {
  return SOURCES.find(s => s.source_id === sourceId)!;
}
