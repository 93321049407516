import {
  Text,
  Button,
  Icon,
  MenuDivider,
  MenuOptionGroup,
  MenuItemOption,
  HStack,
  Tooltip,
  MenuListProps,
  Box,
  MenuGroup,
} from "@chakra-ui/react";
import {Fragment, memo, useCallback} from "react";
import {SparklesIcon} from "@heroicons/react/20/solid";
import {PovModifier, PromptModifierType, PromptModifiers, VerbosityModifier} from "../../../../Types";
import PortalMenuList from "../../../../components/PortalMenuList";
import StaticMenuList from "../../../../components/StaticMenuList";
import CommitTextarea from "../../../../components/CommitTextarea";

export type ModifiersMenuProps = {
  value: PromptModifiers;
  usedValue?: PromptModifiers;
  defaultValue?: PromptModifiers;
  onChange: (newValue: PromptModifiers) => Promise<any>;
  isDisabled?: boolean;
  parentLabel?: string;
  staticLayout?: boolean;
};

type ModifierSection = {
  [K in PromptModifierType]: {
    type: K;
    label: string;
    options?: {
      label: string;
      value: Required<PromptModifiers>[K];
    }[];
  };
}[PromptModifierType];

type ModifierSections = ModifierSection[];

const sections: ModifierSections = [
  {
    type: PromptModifierType.Pov,
    label: "Point of view",
    options: [
      {label: "First person (plural)", value: PovModifier.FirstPerson},
      {label: "Third person", value: PovModifier.ThirdPerson},
    ],
  },
  {
    type: PromptModifierType.Verbosity,
    label: "Length",
    options: [
      {label: "Descriptive", value: VerbosityModifier.Descriptive},
      {label: "Balanced", value: VerbosityModifier.Balanced},
      {label: "Concise", value: VerbosityModifier.Concise},
    ],
  },
  {type: PromptModifierType.Custom, label: "Custom"},
];

const ModifierSectionMenuGroup = memo(
  ({
    section,
    sectionIdx,
    sectionValue,
    sectionUsedValue,
    sectionDefaultValue,
    hasDefaultValue,
    onChange,
    isDisabled,
    parentLabel,
  }: {
    section: ModifierSection;
    sectionIdx: number;
    sectionValue: string | undefined;
    sectionUsedValue: string | undefined;
    sectionDefaultValue: string | undefined;
    hasDefaultValue: boolean;
    onChange: (type: PromptModifierType, value: string | undefined) => Promise<void>;
    isDisabled?: boolean;
    parentLabel?: string;
  }) => {
    const sectionEffectiveValue = sectionValue ?? sectionDefaultValue;
    const sectionHeader = (
      <HStack mx="4" my="2">
        <Text fontSize="sm" fontWeight="semibold" flex="1">
          {section.label}
        </Text>
        {hasDefaultValue &&
          (sectionValue != null ? (
            <Button
              size="sm"
              variant="link"
              colorScheme="blue"
              isDisabled={isDisabled}
              onClick={() => onChange(section.type, undefined)}
            >
              Reset
            </Button>
          ) : (
            <Text fontSize="sm" color="gray.500">
              From {parentLabel?.toLowerCase()}
            </Text>
          ))}
      </HStack>
    );
    return (
      <Fragment key={sectionIdx}>
        {sectionIdx !== 0 && <MenuDivider my={4} />}
        {section.options ? (
          <MenuOptionGroup
            value={sectionEffectiveValue}
            type="radio"
            onChange={newValue => onChange(section.type, newValue as string)}
          >
            {sectionHeader}
            {section.options.map((option, optionIdx) => (
              <MenuItemOption
                key={optionIdx}
                fontSize="md"
                value={option.value}
                // Grey out the checkmark itself if this value is inherited
                color={sectionValue ? undefined : "gray.400"}
                isDisabled={isDisabled}
              >
                <HStack align="center" color="chakra-body-text">
                  <Text flex={1}>{option.label}</Text>
                  {sectionValue && option.value === sectionDefaultValue && (
                    <Text fontSize="sm" color="gray.500">
                      Default
                    </Text>
                  )}
                  {option.value === sectionUsedValue && (
                    <Tooltip label="This option was used to generate the most recent answer">
                      <Icon as={SparklesIcon} color="purple.500" />
                    </Tooltip>
                  )}
                </HStack>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        ) : (
          <MenuGroup>
            {sectionHeader}
            <Box px={3} py={1.5}>
              <CommitTextarea
                placeholder={sectionValue == null ? sectionDefaultValue : undefined}
                value={sectionValue ?? ""}
                onCommit={newValue => onChange(section.type, newValue)}
              />
            </Box>
          </MenuGroup>
        )}
      </Fragment>
    );
  },
);

const ModifiersMenuList = ({
  value,
  usedValue,
  defaultValue,
  onChange,
  isDisabled,
  parentLabel,
  staticLayout,
  menuListProps,
}: {menuListProps?: MenuListProps} & ModifiersMenuProps) => {
  const change = useCallback(
    <K extends PromptModifierType>(type: K, option: PromptModifiers[K]) => {
      return onChange({...value, [type]: option});
    },
    [onChange, value],
  );

  const sectionFragment = sections.map((section, sectionIdx) => (
    <ModifierSectionMenuGroup
      key={sectionIdx}
      section={section}
      sectionIdx={sectionIdx}
      sectionUsedValue={usedValue && usedValue[section.type]}
      hasDefaultValue={defaultValue != null}
      sectionDefaultValue={defaultValue && defaultValue[section.type]}
      sectionValue={value[section.type]}
      isDisabled={isDisabled}
      parentLabel={parentLabel}
      onChange={change}
    />
  ));

  return staticLayout ? (
    <StaticMenuList minW="300px" {...menuListProps}>
      {sectionFragment}
    </StaticMenuList>
  ) : (
    <PortalMenuList minW="300px" {...menuListProps}>
      {sectionFragment}
    </PortalMenuList>
  );
};

export default ModifiersMenuList;
