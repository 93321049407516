import {HStack, Heading, Stack, useDisclosure} from "@chakra-ui/react";
import Page from "../../../components/Page.tsx";
import {withSuspense} from "../../../state/withSuspense.tsx";
import {useQueryData} from "../../../state/index.ts";
import {ThirdParty, ThirdPartyId} from "../../../Types.ts";
import {useNavigate, useParams} from "react-router-dom";
import ThirdPartyName from "../components/ThirdPartyName.tsx";
import TabsOutlet from "../../../components/RouterTabs/Outlet.tsx";
import {DeleteModalIconButton} from "../../../components/DeleteModalButton";
import {usePromiseState} from "../../../hooks/promiseState";
import api from "../../../api";

function DeleteThirdPartyButton({thirdParty}: {thirdParty: ThirdParty}) {
  const deleteModal = useDisclosure();
  const navigate = useNavigate();

  const [deleting, delete_] = usePromiseState(async () => {
    await api.tprm.thirdParties.delete_(thirdParty.third_party_id);
    navigate("../../");
  }, [navigate, thirdParty.third_party_id]);

  return (
    <DeleteModalIconButton
      {...deleteModal}
      delete_={delete_}
      deleting={deleting.inProgress}
      modalHeader={`Delete third party ${thirdParty.name}`}
      modalBody="Are you sure you want to delete this third party?"
    />
  );
}

const ThirdPartyPage = withSuspense(() => {
  const {thirdPartyId} = useParams() as {thirdPartyId: ThirdPartyId};
  const thirdParty = useQueryData({queryKey: ["tprm", "thirdParties", thirdPartyId]});

  return (
    <Page title={thirdParty.name} display="flex" flexDirection="column">
      <Stack spacing={0}>
        <HStack justifyContent="space-between" p={8}>
          <Heading size="md">
            <ThirdPartyName thirdParty={thirdParty} />
          </Heading>
          <HStack>
            <DeleteThirdPartyButton thirdParty={thirdParty} />
          </HStack>
        </HStack>
        <TabsOutlet context={thirdParty} />
      </Stack>
    </Page>
  );
});

export default ThirdPartyPage;
