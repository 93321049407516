import {Heading, HStack, Text} from "@chakra-ui/react";
import {FrameworkVersionMin} from "../../../Types";

const FrameworkVersionName = ({frameworkVersion}: {frameworkVersion: FrameworkVersionMin}) => (
  <HStack align="baseline" gap={1}>
    <Heading size="md">{frameworkVersion.framework.name}</Heading>
    <Text fontSize="md" fontWeight="semibold" color="gray.500">
      {frameworkVersion.version}
    </Text>
  </HStack>
);

export default FrameworkVersionName;
