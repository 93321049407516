import {Link as RouterLink, useParams} from "react-router-dom";
import {
  Divider,
  Heading,
  HStack,
  Icon,
  Stack,
  Table,
  Tbody,
  Text,
  Thead,
  Tr,
  Th,
  Td,
  LinkOverlay,
  LinkBox,
  TagLabel,
  Menu,
} from "@chakra-ui/react";
import {AssessmentId, Control, ThirdPartyId} from "../../../Types";
import {useQueryData} from "../../../state";
import Page from "../../../components/Page";
import ThirdPartyName from "../components/ThirdPartyName";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import {ActionBar, ActionBarSearch} from "../../../Extension/components/ActionBar";
import FrameworkVersionName from "../components/FrameworkVersionName";
import TagMenuButton from "../../../components/TagMenuButton";

const ControlRow = ({control}: {control: Control}) => {
  return (
    <LinkBox as={Tr} transform="scale(1)" _hover={{bg: "gray.50", cursor: "pointer"}}>
      <Td color="gray.500" fontWeight="bold" fontSize="sm">
        {control.control_number}
      </Td>
      <Td>
        <LinkOverlay as={RouterLink} to={`controls/${control.control_id}`}>
          <Text fontWeight="semibold">{control.title}</Text>
        </LinkOverlay>
        <Text fontSize="md" color="gray.500">
          {control.description}
        </Text>
      </Td>
      <Td>
        <Menu isLazy>
          <TagMenuButton colorScheme="green">
            <TagLabel>Control met</TagLabel>
          </TagMenuButton>
        </Menu>
      </Td>
      <Td>1 day ago</Td>
      <Td textAlign="right">
        <Icon fontSize="3xl" color="gray.500" as={ChevronRightIcon} />
      </Td>
    </LinkBox>
  );
};

const AssessmentPage = () => {
  const {assessmentId, thirdPartyId} = useParams() as {assessmentId: AssessmentId; thirdPartyId: ThirdPartyId};
  const thirdParty = useQueryData({queryKey: ["tprm", "thirdParties", thirdPartyId]});
  const assessment = useQueryData({queryKey: ["tprm", "assessment", assessmentId]});
  const title = `${thirdParty.name} - ${assessment.framework_version.framework.name}`;
  return (
    <Page title={title} display="flex" flexDirection="column">
      <Stack spacing={0} divider={<Divider />} flex={1}>
        <HStack divider={<Icon as={ChevronRightIcon} border="none" />} p={8} bg="white">
          <Heading size="md">
            <ThirdPartyName thirdParty={thirdParty} />
          </Heading>
          <FrameworkVersionName frameworkVersion={assessment.framework_version} />
        </HStack>
        <ActionBar borderBottom={0} zIndex={0}>
          <ActionBarSearch />
        </ActionBar>
        <Table borderTop="none">
          <Thead>
            <Tr>
              <Th fontSize="sm" color="gray.500" w="7%">
                ID
              </Th>
              <Th fontSize="sm" color="gray.500" w="60%">
                Control
              </Th>
              <Th fontSize="sm" color="gray.500" w="15%">
                Status
              </Th>
              <Th fontSize="sm" color="gray.500">
                Last Updated
              </Th>
              <Th w="5%" />
            </Tr>
          </Thead>
          <Tbody fontSize="md" color="gray.700">
            {assessment.framework_version.control_groups.map(cg => {
              const groupId = cg.title
                .split(" ")
                .map(w => w[0])
                .join("");

              return (
                <>
                  <Tr key={cg.control_group_id}>
                    <Td colSpan={9} bg="blue.500" color="white" fontWeight="semibold">
                      {groupId} - {cg.title}
                    </Td>
                  </Tr>
                  {cg.controls.map(c => (
                    <ControlRow control={c} key={c.control_id} />
                  ))}
                </>
              );
            })}
          </Tbody>
        </Table>
      </Stack>
    </Page>
  );
};

export default AssessmentPage;
