import {Box, Center, Divider, HStack, Heading, Link, Stack, Text} from "@chakra-ui/react";
import {useOutletContext} from "react-router-dom";
import {ThirdParty} from "../../../../../Types";
import CompanyLogo from "../../../components/CompanyLogo";
import {ReactNode} from "react";

const Field = ({name, children}: {name: string; children: ReactNode}) => (
  <HStack>
    <Text w={24} fontWeight="semibold" fontSize="sm" color="gray.300">
      {name}
    </Text>
    {children}
  </HStack>
);

const ReportHeader = ({name}: {name: string}) => {
  const thirdParty: ThirdParty = useOutletContext();

  return (
    <HStack
      spacing={6}
      align="center"
      borderY="1px solid"
      borderColor="black"
      bg="gray.800"
      px={12}
      py={12}
      justify="space-between"
    >
      <HStack spacing={6} color="white" align="flex-start">
        <CompanyLogo name={thirdParty.name} size="lg" domain={thirdParty.url} />
        <Stack gap={8}>
          <Stack>
            <Heading size="sm" color="green.300">
              {name}
            </Heading>
            <Heading size="2xl" fontWeight="normal">
              {thirdParty.name}
            </Heading>
          </Stack>
          <Stack>
            <Field name="Website">
              <Link href="https://salesforce.com" target="_blank" color="blue.300" fontSize="md">
                https://salesforce.com
              </Link>
            </Field>
            <Field name="Tier">
              <HStack align="center">
                <Box w={2} h={2} bg={`${thirdParty.tier.color}.500`} rounded="full" />
                <Heading fontWeight="normal" size="sm">
                  {thirdParty.tier.title}
                </Heading>
              </HStack>
            </Field>
            <Field name="Owner">
              <HStack align="center">
                <Heading fontWeight="normal" size="sm">
                  Henry Irish
                </Heading>
              </HStack>
            </Field>
          </Stack>
        </Stack>
      </HStack>
      <HStack
        bg="white"
        borderRadius="lg"
        p={6}
        gap={6}
        shadow="lg"
        divider={
          <Center height="80px">
            <Divider orientation="vertical" />
          </Center>
        }
      >
        <Stack gap={2} minW="150px" pr={2}>
          <Heading color="orange.500">
            1{" "}
            <Text fontSize="lg" as="span">
              of
            </Text>{" "}
            2
          </Heading>
          <Text fontWeight="semibold" color="gray.600" fontSize="md">
            Passing Assessments
          </Text>
        </Stack>
        <Stack gap={2} minW="150px" pr={2} fontSize="md">
          <Heading color="blue.500">
            {thirdParty.score}
            <Text fontSize="lg" as="span" ml={1}>
              %
            </Text>
          </Heading>
          <Text fontWeight="semibold" color="gray.600">
            Risk Score
          </Text>
        </Stack>
        <Stack gap={2} minW="150px" pr={2} fontSize="md">
          <Heading color="gray.700">12 days ago</Heading>
          <Text fontWeight="semibold" color="gray.600">
            Last modified
          </Text>
        </Stack>
      </HStack>
    </HStack>
  );
};

export default ReportHeader;
