import {InputGroup, InputRightElement, Select, Spinner} from "@chakra-ui/react";
import {usePromiseState} from "../../../hooks/promiseState.ts";
import {memo} from "react";

type LanguageInfo = {
  code: string;
  title: string;
};

const ALL_LANGUAGES = [
  {
    code: "ar",
    title: "Arabic",
  },
  {
    code: "zh-CN",
    title: "Chinese (Simplified)",
  },
  {
    code: "zh-TW",
    title: "Chinese (Traditional)",
  },
  {
    code: "nl",
    title: "Dutch",
  },
  {
    code: "en-GB",
    title: "English (UK)",
  },
  {
    code: "en-US",
    title: "English (US)",
  },
  {
    code: "fr",
    title: "French",
  },
  {
    code: "de",
    title: "German",
  },
  {
    code: "he",
    title: "Hebrew",
  },
  {
    code: "hi",
    title: "Hindi",
  },
  {
    code: "id",
    title: "Indonesian",
  },
  {
    code: "it",
    title: "Italian",
  },
  {
    code: "ja",
    title: "Japanese",
  },
  {
    code: "ko",
    title: "Korean",
  },
  {
    code: "pl",
    title: "Polish",
  },
  {
    code: "pt",
    title: "Portuguese",
  },
  {
    code: "ru",
    title: "Russian",
  },
  {
    code: "es",
    title: "Spanish",
  },
  {
    code: "sw",
    title: "Swahili",
  },
  {
    code: "tr",
    title: "Turkish",
  },
] as const satisfies LanguageInfo[];

type KnownLanguageCode = (typeof ALL_LANGUAGES)[number]["code"];

const LanguageSelector = memo(
  ({
    value,
    onChange,
    options,
  }: {
    value: string;
    onChange?: (newValue: string) => Promise<void> | void;
    options?: readonly KnownLanguageCode[];
  }) => {
    const [changing, change] = usePromiseState(
      async (e: React.ChangeEvent<HTMLSelectElement>) => {
        onChange && (await onChange(e.target.value));
      },
      [onChange],
    );
    return (
      <InputGroup maxW="300px">
        <Select
          onChange={change}
          value={changing.inProgress ? undefined : value}
          isDisabled={!onChange || changing.inProgress}
          bg="white"
        >
          {ALL_LANGUAGES.filter(l => !options || options.includes(l.code)).map(l => (
            <option key={l.code} value={l.code}>
              {l.title}
            </option>
          ))}
        </Select>
        {changing.inProgress && (
          <InputRightElement mr={6}>
            <Spinner color="gray.500" size="sm" />
          </InputRightElement>
        )}
      </InputGroup>
    );
  },
);

export default LanguageSelector;
