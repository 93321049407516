import {
  Box,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  ModalHeader,
  FormControl,
  FormLabel,
  FormErrorMessage,
  ModalFooter,
  HStack,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
} from "@chakra-ui/react";
import {LoginMethodProps} from "./AnyLoginMethod";
import {LoginMethodBox} from "./LoginMethodBox";
import PasswordInput from "../../../components/PasswordInput";
import ComplexityGauge from "../../../components/ComplexityGauge";
import {useResetting} from "../../../hooks/resetting";
import {useCallback, useState} from "react";
import {CreateLoginMethod, LoginMethod} from "../../../Types";
import {useCredentials} from "../../../hooks/credentials";
import {useValidatedField} from "../../../hooks/validatedField";
import {usePromiseState} from "../../../hooks/promiseState";
import {HTTPError} from "../../../api";
import {Form} from "react-router-dom";

const ChangePasswordModal = ({
  close,
  isConfigured,
  onSelect,
  onUpsert,
}: {
  close: () => void;
  isConfigured: boolean;
  onSelect?: (loginMethod: LoginMethod) => Promise<void>;
  onUpsert: (loginMethod: LoginMethod, payload: CreateLoginMethod) => Promise<void>;
}) => {
  const {credentials, credentialFields, handleCredentialsError} = useCredentials();
  const newPasswordField = useValidatedField("");

  const [changing, change] = usePromiseState(async () => {
    try {
      await onUpsert(LoginMethod.UsernamePassword, {
        type: "UsernamePassword",
        content: {
          prev_credentials: credentials,
          new_password: newPasswordField.value,
        },
      });
      if (onSelect) {
        await onSelect(LoginMethod.UsernamePassword);
      }
      close();
    } catch (e) {
      if (handleCredentialsError(e)) {
        return;
      }
      const errorMessage = "An unknown error occurred. Please contact support@platformed.com if this persists";
      if (e instanceof HTTPError && e.response.status === 422) {
        newPasswordField.setError("Password was insufficiently complex");
        return;
      }
      throw new Error(errorMessage);
    }
  }, [credentials, newPasswordField, close, handleCredentialsError, onUpsert, onSelect]);

  return (
    <Form
      onSubmit={e => {
        e.stopPropagation();
        e.preventDefault();
        change();
      }}
    >
      <ModalContent>
        <ModalHeader>{isConfigured ? "Change password" : "Set password"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="8" mb="4">
            {changing.lastError ? (
              <Alert status="error" borderRadius="md">
                <AlertIcon boxSize="40px" />
                <Box>
                  <AlertTitle fontSize="md">Error changing password</AlertTitle>
                  <AlertDescription fontSize="md">{changing.lastError.toString()}</AlertDescription>
                </Box>
              </Alert>
            ) : null}
            {isConfigured ? credentialFields : null}
            <FormControl isRequired isInvalid={!!newPasswordField.error}>
              <FormLabel>New password</FormLabel>
              <Stack spacing={3}>
                <PasswordInput
                  value={newPasswordField.value}
                  onChange={e => newPasswordField.setValue(e.target.value)}
                  autoComplete="new-password"
                  autoFocus={!isConfigured}
                />
                <ComplexityGauge value={newPasswordField.value} />
              </Stack>
              <FormErrorMessage>{newPasswordField.error}</FormErrorMessage>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing="3">
            <Button variant="ghost" onClick={close} isDisabled={changing.inProgress}>
              Cancel
            </Button>
            <Button
              type="submit"
              colorScheme="blue"
              isLoading={changing.inProgress}
              isDisabled={!newPasswordField.value || (isConfigured && !credentials.password)}
            >
              {isConfigured ? "Change" : "Set"}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Form>
  );
};

export function UsernamePasswordLoginMethod(props: LoginMethodProps) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [shouldSelect, setShouldSelect] = useResetting(useState(false), isOpen);
  const configureAndSelect = useCallback(() => {
    setShouldSelect(true);
    onOpen();
  }, [onOpen, setShouldSelect]);
  return (
    <>
      <LoginMethodBox
        {...props}
        title="Password"
        description="Use password authentication"
        onConfigureAndSelect={configureAndSelect}
        rhs={
          <Button colorScheme={props.isSelected ? "blue" : "gray"} size="sm" onClick={onOpen}>
            {props.isConfigured ? "Change password" : "Set password"}
          </Button>
        }
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ChangePasswordModal
          isConfigured={props.isConfigured}
          onSelect={shouldSelect ? props.onSelect : undefined}
          onUpsert={props.onUpsert}
          close={onClose}
        />
      </Modal>
    </>
  );
}
