import {ReactNode, Suspense, useContext, useMemo, useState} from "react";
import {OpenModal, modalContext} from "./context";
import {useEventCallback} from "usehooks-ts";
import {Modal, ModalOverlay} from "@chakra-ui/react";
import {ModalId, ProvidedModal, MODAL_OPTIONS, ModalOptions} from ".";
import Loading from "../Loading";

type ModalProviderProps = {
  modals: ProvidedModal[];
  children?: ReactNode;
};

type ActiveModal<T> = {
  modalId: ModalId<T>;
  modalProps: T;
};

export function ModalProvider({modals, children}: ModalProviderProps) {
  const allModals: ProvidedModal = Object.assign({}, ...modals);
  const parent = useContext(modalContext);
  const [activeModal, setActiveModal] = useState<ActiveModal<any> | null>(null);
  const [modalOptions, setModalOptions] = useState<ModalOptions>({});
  const open: OpenModal = useEventCallback(<T,>(modalId: ModalId<T>, modalProps?: T) => {
    if (Object.hasOwn(allModals, modalId)) {
      setModalOptions(MODAL_OPTIONS[modalId]);
      setActiveModal({modalId, modalProps});
    } else {
      parent.open(modalId, modalProps);
    }
  });
  const close = useEventCallback(() => setActiveModal(null));
  const value = useMemo(() => ({open}), [open]);
  return (
    <>
      <modalContext.Provider value={value}>{children}</modalContext.Provider>

      <Modal isOpen={!!activeModal} onClose={close} {...modalOptions}>
        <ModalOverlay />
        <Suspense fallback={<Loading m={8} />}>
          {activeModal && allModals[activeModal.modalId](close, activeModal.modalProps)}
        </Suspense>
      </Modal>
    </>
  );
}
