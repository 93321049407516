import {Subscriber} from "../../../../Types.ts";
import {
  Heading,
  Icon,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  HStack,
} from "@chakra-ui/react";
import {ListBulletIcon} from "@heroicons/react/24/solid";

const SubscribedTag = ({subscribed}: {subscribed: boolean}) => (
  <Tag colorScheme={subscribed ? "green" : "gray"}>{subscribed ? "Subscribed" : "Not subscribed"}</Tag>
);

const VerifiedTag = ({verified}: {verified: boolean}) => (
  <Tag colorScheme={verified ? "green" : "yellow"}>{verified ? "Verified" : "Unverified"}</Tag>
);

const SubscriberRow = ({subscriber: {subscribed_at, metadata, verified_at, email}}: {subscriber: Subscriber}) => {
  return (
    <Tr fontSize="md">
      <Td>
        <Heading size="sm" noOfLines={1}>
          {email}
        </Heading>
      </Td>
      <Td color="gray.600" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
        {metadata.name}
      </Td>
      <Td color="gray.600" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
        {metadata.company}
      </Td>
      <Td>
        <HStack justifyContent={"flex-end"}>
          <VerifiedTag verified={!!verified_at} />
          <SubscribedTag subscribed={!!verified_at && !!subscribed_at} />
        </HStack>
      </Td>
    </Tr>
  );
};

const SubscriberList = ({subscribers}: {subscribers: Subscriber[]}) => {
  return (
    <Stack as="nav" minH="0" flex="1" spacing={0}>
      {subscribers.length === 0 ? (
        <Stack flex={1} alignItems="center" spacing={4} bg="gray.50" justifyContent={"center"}>
          <Icon as={ListBulletIcon} color="gray.500" boxSize={8} />
          <Text fontSize="md" color="gray.500">
            You don't have any subscribers yet.
          </Text>
        </Stack>
      ) : (
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th w="29%">Email</Th>
                <Th w="28%">Name</Th>
                <Th w="28%">Company</Th>
                <Th w="15%"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {subscribers.map(subscriber => (
                <SubscriberRow key={subscriber.mail_subscription_id} subscriber={subscriber} />
              ))}{" "}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  );
};

export default SubscriberList;
