import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {useResetting} from "../../hooks/resetting.ts";
import externalApi from "../../api/external";
import {useValidation, useValidatedPromiseState} from "../../hooks/validationState.ts";
import {SubscriptionAction} from "../../Types.ts";
import {useClientAccount} from "../hooks/clientAccount.ts";
import {useTrustCenterPassword} from "../hooks/accessPassword.ts";
import {useState} from "react";

type SubscribeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  privacyPolicy?: string;
  displayName?: string;
};

const SubscriptionConfirmationModal = ({
  isOpen,
  onClose,
  action,
}: {
  isOpen: boolean;
  onClose: () => void;
  action: SubscriptionAction;
}) => {
  function confirmationHeading(action: SubscriptionAction): string {
    switch (action) {
      case SubscriptionAction.Verification:
        return "Please verify your email";
      case SubscriptionAction.AlreadySubscribed:
        return "You're already subscribed";
      case SubscriptionAction.Resubscribe:
        return "You've been resubscribed";
      case SubscriptionAction.RateLimited:
        return "You've already subscribed";
    }
  }

  function confirmationText(action: SubscriptionAction): string {
    switch (action) {
      case SubscriptionAction.Verification:
        return "We've sent you an email to verify your subscription.";
      case SubscriptionAction.AlreadySubscribed:
        return "We can confirm you're already subscribed to this mailing list.";
      case SubscriptionAction.Resubscribe:
        return "Thank you for returning! You've been resubscribed to this mailing list.";
      case SubscriptionAction.RateLimited:
        return "Please check your email for the verification link.";
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{confirmationHeading(action)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Text fontSize="md">{confirmationText(action)}</Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing="3">
            <Button colorScheme="blue" onClick={onClose}>
              Ok
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const SubscribeModal = ({isOpen, onClose, privacyPolicy, displayName}: SubscribeModalProps) => {
  const [name, setName] = useResetting(useState(""), isOpen);
  const [company, setCompany] = useResetting(useState(""), isOpen);
  const [email, setEmail] = useResetting(useState(""), isOpen);
  const [agreePrivacy, setAgreePrivacy] = useResetting(useState(false), isOpen);
  const [validationErrors, setValidationErrors] = useValidation(useResetting(useState({}), isOpen), {
    email,
    agreePrivacy,
  });
  const {isOpen: isConfirmationOpen, onClose: closeConfirmation, onOpen: openConfirmation} = useDisclosure();
  const [subscriptionAction, setSubscriptionAction] = useResetting(useState(SubscriptionAction.Verification), isOpen);
  const account = useClientAccount();
  const password = useTrustCenterPassword(account.account_id) ?? "";
  const [subscribing, subscribe] = useValidatedPromiseState(
    async () => {
      const action = await externalApi.trustCenters.subscribe(
        account.account_id,
        {
          email,
          metadata: {
            name: name === "" ? undefined : name,
            company: company === "" ? undefined : company,
          },
        },
        password,
      );
      setSubscriptionAction(action);
      openConfirmation();
      onClose();
    },
    [onClose, email, name, company, openConfirmation, setSubscriptionAction, account.account_id, password],
    setValidationErrors,
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={subscribe}>
          <ModalHeader>Subscribe to trust center messages </ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize="md">
            <Stack spacing={4}>
              <Text fontSize="md">Subscribe to be notified of changes to our trust center.</Text>
              <FormControl isRequired>
                <FormLabel>Business email</FormLabel>
                <Input type="email" onChange={e => setEmail(e.target.value)} />
                {validationErrors.email && validationErrors.email[0] && (
                  <FormErrorMessage>{validationErrors.email[0].message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input type="text" onChange={e => setName(e.target.value)} />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Company</FormLabel>
                <Input type="text" onChange={e => setCompany(e.target.value)} />
              </FormControl>
              <FormControl isRequired>
                <Checkbox
                  isChecked={agreePrivacy}
                  onChange={e => setAgreePrivacy(e.target.checked)}
                  fontWeight={500}
                  alignItems={"top"}
                >
                  <Stack mt={-0.5} ml={1}>
                    <Text>{"I agree to the processing of personal data"}</Text>
                    {privacyPolicy && (
                      <Link href={privacyPolicy} target="_blank" color="blue.500" fontWeight="semibold">
                        {" "}
                        {displayName ?? "The company's"}'s privacy policy
                      </Link>
                    )}
                    <Link
                      href={"https://platformed.com/privacy"}
                      target="_blank"
                      color="blue.500"
                      fontWeight="semibold"
                    >
                      Platformed's privacy policy
                    </Link>
                  </Stack>
                </Checkbox>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing="3">
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="green"
                isDisabled={subscribing.inProgress || !email || !name || !company}
                type={"submit"}
                isLoading={subscribing.inProgress}
              >
                Subscribe
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <SubscriptionConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={closeConfirmation}
        action={subscriptionAction}
      />
    </>
  );
};

const SubscribeButton = ({privacyPolicy, displayName}: {privacyPolicy?: string; displayName?: string}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <Button aria-label="Subscribe" onClick={onOpen} colorScheme={"green"}>
        Subscribe
      </Button>
      <SubscribeModal isOpen={isOpen} onClose={onClose} privacyPolicy={privacyPolicy} displayName={displayName} />
    </>
  );
};

export default SubscribeButton;
