import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Checkbox,
  ModalFooter,
  Button,
  CheckboxGroup,
  FormErrorMessage,
  Icon,
  Text,
} from "@chakra-ui/react";
import {useResetting} from "../../../../../hooks/resetting";
import api from "../../../../../api";
import {EntityId, SubGraph} from "../../../../../Types";
import {
  formatOrganizationRelationship,
  generateCreateOperationsForPerson,
  generateUpdateOperationsForPerson,
  ORGANIZATION_RELATIONSHIPS,
  OrganizationRelationship,
  Person,
  PersonDescription,
} from "../../../graph/types/person";
import SingleFileUpload from "../../../../../components/fileUploads/SingleFileUpload";
import {FileUpload} from "../../../../../hooks/fileUpload";
import {CameraIcon} from "@heroicons/react/20/solid";
import {useAsyncOperation} from "../../../../../hooks/asyncOperation";
import {useValidatedPromiseState} from "../../../../../hooks/validationState";
import {useState} from "react";
import {useAbortable} from "../../../../../hooks/abortable";

type AddOrEditPersonModalProps = {
  /**
   * Entire entity graph. This is needed to look up relationships before
   * performing operations.
   */
  graph: SubGraph;

  parentEntityId: EntityId;

  isOpen: boolean;
  onClose: () => void;
} & ({kind: "add"} | {kind: "edit"; person: Person});

export function AddOrEditPersonModal(props: AddOrEditPersonModalProps) {
  const kind = props.kind;

  const [photoUpload, setPhotoUpload] = useResetting(useAbortable(useState<FileUpload | null>(null)), props.isOpen);
  const photoUploadState = useAsyncOperation(photoUpload);

  const [state, setState] = useResetting(
    useState<PersonDescription>(
      kind === "add"
        ? {
            name: "",
            jobTitle: "",
            websiteUrl: "",
            organizationRelationships: [],
          }
        : {
            name: props.person.name,
            jobTitle: props.person.jobTitle ?? "",
            websiteUrl: props.person.websiteUrl ?? "",
            organizationRelationships: [...props.person.organizationRelationships],
            photoAssetId: props.person.photoAssetId,
          },
    ),
    props.isOpen,
  );

  const [organizationRelationshipsError, setOrganizationRelationshipsError] = useResetting(
    useState(false),
    props.isOpen,
  );

  const [submitting, submit] = useValidatedPromiseState(async () => {
    if (state.organizationRelationships.length === 0) {
      setOrganizationRelationshipsError(true);
      return;
    }

    const finalState = {...state};

    if (photoUploadState !== null) {
      if (photoUploadState.id !== "Uploaded") {
        // Not ready
        return;
      }

      const {asset_id} = await api.vendorToolkit.assets.create({
        title: "person-photo",
        file_id: photoUploadState.result.file_id,
      });

      finalState.photoAssetId = asset_id;
    }

    const operations =
      kind === "add"
        ? generateCreateOperationsForPerson(props.parentEntityId, finalState)
        : generateUpdateOperationsForPerson(props.graph, props.parentEntityId, props.person, finalState);

    if (operations.length > 0) {
      await api.vendorToolkit.graph.update(operations);
    }

    // Delete the old photo asset
    if (kind === "edit" && props.person.photoAssetId !== undefined) {
      // This shouldn't show an error in the UI if it fails, since the user
      // will still have an updated photo.
      try {
        await api.vendorToolkit.assets.deleteAsset(props.person.photoAssetId);
      } catch (err: unknown) {
        // Do nothing
      }
    }

    props.onClose();
  }, [kind, props, state, photoUploadState, setOrganizationRelationshipsError]);

  // Render

  const title = kind === "add" ? "Add new person" : `Edit ${props.person.name}`;

  const submitButtonText = kind === "add" ? "Create" : "Save changes";

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={submit}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="8">
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                value={state.name}
                onChange={e => setState(s => ({...s, name: e.target.value}))}
                placeholder="Jane Smith"
                autoFocus={kind === "add"}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Job title</FormLabel>
              <Input
                placeholder="CEO"
                value={state.jobTitle}
                onChange={e => setState(s => ({...s, jobTitle: e.target.value}))}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Website / social link</FormLabel>
              <Input
                type="url"
                placeholder="https://www.example.com/jane.smith"
                value={state.websiteUrl}
                onChange={e => setState(s => ({...s, websiteUrl: e.target.value}))}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Photo</FormLabel>
              <SingleFileUpload Cls={FileUpload} value={photoUpload} onChange={setPhotoUpload}>
                {isDragActive => (
                  <HStack align="center" spacing={2}>
                    <Icon as={CameraIcon} fontSize="xl" color={isDragActive ? "blue.500" : "gray.500"} />
                    <Text>
                      {props.kind === "edit" && props.person.photoAssetId !== undefined ? "Replace" : "Add"} photo
                    </Text>
                  </HStack>
                )}
              </SingleFileUpload>
            </FormControl>
            <FormControl isInvalid={organizationRelationshipsError}>
              <FormLabel>Legal roles</FormLabel>
              <Stack spacing={4}>
                <CheckboxGroup
                  value={state.organizationRelationships}
                  onChange={v => {
                    setState(s => ({...s, organizationRelationships: v as OrganizationRelationship[]}));
                    setOrganizationRelationshipsError(false);
                  }}
                >
                  {ORGANIZATION_RELATIONSHIPS.map(v => (
                    <Checkbox key={v} value={v}>
                      {formatOrganizationRelationship(v)}
                    </Checkbox>
                  ))}
                </CheckboxGroup>
              </Stack>
              <FormErrorMessage>Please select at least one legal role.</FormErrorMessage>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <HStack spacing="3">
            <Button variant="ghost" onClick={props.onClose} isDisabled={submitting.inProgress}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              isLoading={submitting.inProgress}
              isDisabled={photoUploadState !== null && photoUploadState.id !== "Uploaded"}
            >
              {submitButtonText}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
