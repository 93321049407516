import {SharingClassification as SharingClassificationT} from "../../../../Types";

import {Menu, MenuItemOption, MenuOptionGroup, TagLabel} from "@chakra-ui/react";
import {usePromiseState} from "../../../../hooks/promiseState";
import {SHARING_CLASSIFICATION_MAP} from ".";
import TagMenuButton from "../../../../components/TagMenuButton";
import PortalMenuList from "../../../../components/PortalMenuList";

const SharingClassificationComp = ({
  sharing,
  onChangeSharing,
  size,
}: {
  sharing: SharingClassificationT;
  onChangeSharing?: (sharing: SharingClassificationT) => Promise<void>;
  size?: "sm" | "lg";
}) => {
  const {color, text} = SHARING_CLASSIFICATION_MAP[sharing];

  const [changing, change] = usePromiseState(
    async (val: string | string[]) => {
      if (onChangeSharing) {
        await onChangeSharing(val as SharingClassificationT);
      }
    },
    [onChangeSharing],
  );

  return (
    <Menu isLazy>
      <TagMenuButton colorScheme={color} isLoading={changing.inProgress} isDisabled={!onChangeSharing} size={size}>
        <TagLabel>{text}</TagLabel>
      </TagMenuButton>
      <PortalMenuList fontSize="md" onClick={e => e.stopPropagation()}>
        <MenuOptionGroup type="radio" onChange={change} value={sharing}>
          {Object.keys(SharingClassificationT).map(c => (
            <MenuItemOption key={c} value={c}>
              {SHARING_CLASSIFICATION_MAP[c].text}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </PortalMenuList>
    </Menu>
  );
};

export default SharingClassificationComp;
