import {Box} from "@chakra-ui/react";

import Page from "../../../../components/Page.tsx";
import {useQueriesData} from "../../../../state/index.ts";
import {withSuspense} from "../../../../state/withSuspense.tsx";
import SingleQuestionView from "../QuestionnaireViewer/Questions/SingleQuestionView.tsx";
import {ArchivedQuestionnaireBanner} from "../QuestionnaireViewer/QuestionnaireBanners.tsx";
import {facetedSearchQuestion, QuestionFilters} from "../../../components/Filters/index.tsx";
import {QuestionId, QuestionMin, QuestionStatus as QuestionStatusT, Section} from "../../../../Types.ts";
import {useFilterState} from "../../../../hooks/filterState.ts";
import {useMemo} from "react";
import {useFacetedSearch} from "../../../../hooks/search.ts";
import {useLayerType} from "../../../../hooks/layerType.ts";
import QuestionNav from "./QuestionNav.tsx";
import SidePane from "../../../../components/SidePane.tsx";
import {useTypedParams} from "../../../../hooks/typedParams";
import {QuestionParams} from "./Crumb";
import questionKeys from "../../../../utils/questionKeys";
import filterSections from "../../../../utils/filterSections";

function nextQuestion(
  reverse: boolean,
  baseQuestionId: QuestionId,
  filteredSections: Section[],
  unfilteredSections: Section[],
): QuestionMin | undefined {
  const visibleQuestionIds = new Set(
    filteredSections.flatMap(section => section.questions).map(question => question.question_id),
  );

  // Flatten in section order
  const flattenedQuestions = unfilteredSections.flatMap(section => section.questions);

  const currentIdx = flattenedQuestions.findIndex(question => question.question_id === baseQuestionId);
  const candidateQuestions = reverse
    ? flattenedQuestions.slice(0, currentIdx).reverse()
    : flattenedQuestions.slice(currentIdx + 1);
  return candidateQuestions.find(question => visibleQuestionIds.has(question.question_id));
}

const DEFAULT_FILTERS = {
  owners: ["ASSIGNED_TO_ME", "ASSIGNED_TO_OTHERS", "UNASSIGNED"],
  statuses: Object.keys(QuestionStatusT),
};

const QuestionPage = withSuspense(() => {
  const [layerType] = useLayerType();
  const {questionnaireId, questionId} = useTypedParams(QuestionParams);
  const [question, questionnaire, whoami] = useQueriesData({
    queries: [
      {queryKey: ["vendorToolkit", "question", questionId]},
      {queryKey: ["vendorToolkit", "questionnaire", questionnaireId]},
      {queryKey: ["whoAmI"]},
    ],
  });
  const questions = useQueriesData({
    queries: questionKeys(questionnaire),
  });
  const account = whoami.account!;
  const {question_number, text} = question;

  const {filters, filterCount, clearFilters} = useFilterState<QuestionFilters>("questionFilters", DEFAULT_FILTERS);

  // Using the facetedSearch hook here as it ensures the logic is the same as the main questions page
  const {filter} = useFacetedSearch(q => q.text, facetedSearchQuestion(filters, layerType, whoami.associated_owners), [
    filters,
    whoami.associated_owners,
  ]);

  const {result} = useMemo(() => filter(questions), [questions, filter]);
  const filteredSections = useMemo(
    () => filterSections(questionnaire.sections, result).filter(s => s.questions.length > 0),
    [questionnaire.sections, result],
  );

  const nextQuestionId = nextQuestion(false, questionId, filteredSections, questionnaire.sections)?.question_id;
  const prevQuestionId = nextQuestion(true, questionId, filteredSections, questionnaire.sections)?.question_id;

  const title = `${question_number ?? ""} ${text}`.trim();
  const parent = useMemo(() => {
    return {
      type: "Questionnaire",
      promptModifiers: {
        ...account.prompt_modifiers,
        ...questionnaire.prompt_modifiers,
      },
    } as const;
  }, [questionnaire.prompt_modifiers, account.prompt_modifiers]);

  return (
    <Page title={title}>
      {questionnaire.archived_at && <ArchivedQuestionnaireBanner />}
      <Box display="flex" flexDir="row">
        <SidePane>
          <QuestionNav
            questionnaire={questionnaire}
            questionId={questionId}
            clearFilters={clearFilters}
            filterCount={filterCount}
            sections={filteredSections}
          />
        </SidePane>
        <SingleQuestionView
          key={questionId}
          question={question}
          nextQuestionId={nextQuestionId}
          prevQuestionId={prevQuestionId}
          parent={parent}
        />
      </Box>
    </Page>
  );
});

export default QuestionPage;
