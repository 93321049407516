// Map with WeakRef-wrapped values and a FinalizationRegistry
export default class WeakValueMap<K, V extends WeakKey> {
  #inner = new Map<K, WeakRef<V>>();
  #registry = new FinalizationRegistry<K>(key => this.#inner.delete(key));

  #unregister(ref: WeakRef<V> | undefined) {
    const value = ref && ref.deref();
    if (value) this.#registry.unregister(value);
  }

  constructor() {}

  clear() {
    // unregister finalizer for existing values
    for (const ref of this.#inner.values()) this.#unregister(ref);

    return this.#inner.clear();
  }
  delete(key: K) {
    // unregister finalizer if an existing value
    this.#unregister(this.#inner.get(key));

    return this.#inner.delete(key);
  }

  get(key: K) {
    const ref = this.#inner.get(key);
    const value = ref && ref.deref();

    // remove entry if reference is no longer valid
    if (ref && !value) this.#inner.delete(key);

    return value;
  }

  set(key: K, value: V): this {
    // unregister finalizer if an existing value
    this.#unregister(this.#inner.get(key));

    // register finalizer for the new value
    this.#registry.register(value, key, value);

    // store a weak reference to the value
    this.#inner.set(key, new WeakRef(value));
    return this;
  }
}
