import {Button, Icon, Stack} from "@chakra-ui/react";
import {useState} from "react";
import {useOutletContext} from "react-router-dom";
import {useQueryData} from "../../../../state";
import {MitigationStatus, Risk, ThirdParty} from "../../../../Types";
import RisksTable from "../components/RisksTable";
import {ActionBar, ActionBarSearch} from "../../../../components/ActionBar";
import {ChevronDownIcon, FunnelIcon} from "@heroicons/react/20/solid";

const RisksPage = () => {
  const [showManaged, setShowManaged] = useState(true);
  const toggleShowManaged = () => setShowManaged(!showManaged);

  const thirdParty: ThirdParty = useOutletContext();
  const risks: Risk[] = useQueryData({
    queryKey: ["tprm", "thirdParties", "risks", thirdParty.third_party_id],
  }).filter(r => showManaged || r.management.status != MitigationStatus.MITIGATED);

  return (
    <Stack spacing={0}>
      <ActionBar
        actionButtons={
          <Button
            onClick={toggleShowManaged}
            colorScheme="blue"
            leftIcon={<Icon as={FunnelIcon} />}
            rightIcon={<Icon as={ChevronDownIcon} />}
          >
            Management ({risks.length})
          </Button>
        }
      >
        <ActionBarSearch />
      </ActionBar>
      <RisksTable risks={risks} />
    </Stack>
  );
};

export default RisksPage;
