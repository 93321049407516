import {ThirdPartyTierMin, nominate} from "../../../Types.ts";
import {Box, Menu, MenuItemOption, MenuOptionGroup, PortalProps, TagLabel} from "@chakra-ui/react";
import TagMenuButton from "../../../components/TagMenuButton.tsx";

import {useQueryData} from "../../../state";
import {usePromiseState} from "../../../hooks/promiseState";
import PortalMenuList from "../../../components/PortalMenuList";
import {withSuspense} from "../../../state/withSuspense";

type Props = {
  tier: ThirdPartyTierMin | null;
  onChange?: (tier: ThirdPartyTierMin) => Promise<void> | void;
  portalContainerRef?: PortalProps["containerRef"];
};

const TierSelector = withSuspense(
  ({tier, onChange, portalContainerRef: containerRef}: Props) => {
    const tiers = useQueryData({queryKey: ["tprm", "thirdPartyTiers"]});

    const [changing, change] = usePromiseState(
      async (value: string | string[]) => {
        const id = nominate("thirdPartyTierId", value as string);
        const tier = tiers.find(t => t.tier_id === id)!;

        await onChange?.(tier);
      },
      [onChange, tiers],
    );

    return (
      <Box>
        <Menu isLazy>
          <TagMenuButton
            colorScheme={changing.inProgress ? "gray" : tier?.color ?? "gray"}
            isLoading={changing.inProgress}
            isDisabled={onChange == null}
          >
            <TagLabel>{tier?.title ?? "Select a tier..."}</TagLabel>
          </TagMenuButton>
          <PortalMenuList fontSize="md" onClick={e => e.stopPropagation()} containerRef={containerRef}>
            <MenuOptionGroup type="radio" onChange={change} value={tier?.tier_id}>
              {tiers.map(t => (
                <MenuItemOption key={t.tier_id} value={t.tier_id}>
                  {t.title}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </PortalMenuList>
        </Menu>
      </Box>
    );
  },
  <Menu isLazy>
    <TagMenuButton colorScheme="gray" isDisabled={true}>
      <TagLabel>Loading...</TagLabel>
      <PortalMenuList>
        <MenuOptionGroup type="radio" value={""}>
          <MenuItemOption value={""}>Loading...</MenuItemOption>
        </MenuOptionGroup>
      </PortalMenuList>
    </TagMenuButton>
  </Menu>,
);

export default TierSelector;
