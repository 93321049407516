import {AssessmentId, ThirdPartyId} from "../../../Types";
import api from "../../../api";
import {param, typedQuery} from "../../typing";

export default [
  typedQuery(["tprm", "assessment", param<AssessmentId>("assessmentId")], async context => {
    return await api.tprm.assessments.get(context.queryKey[2]);
  }),

  typedQuery(["tprm", "thirdPartyAssessments", param<ThirdPartyId>("thirdPartyId")], async context => {
    return await api.tprm.assessments.list(context.queryKey[2]);
  }),
];
