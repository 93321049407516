import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import {useValidatedPromiseState, useValidation} from "../../../hooks/validationState";
import {useResetting} from "../../../hooks/resetting.ts";
import {useQueryData} from "../../../state";
import api from "../../../api";
import {QuestionStatus} from "../../../Types";
import {router} from "../../../router/index.tsx";
import {useState} from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function AskQuestionModal({isOpen, onClose}: Props) {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const [questionText, setQuestionText] = useResetting(useState(""), isOpen);
  const [validationErrors, setValidationErrors] = useValidation(useState({}), {questionText});

  const [creating, create] = useValidatedPromiseState(
    async () => {
      const {
        question: {question_id},
      } = await api.vendorToolkit.adhocQuestions.create({
        question: {
          text: questionText,
          response_layer: {
            status: QuestionStatus.Automating,
          },
        },
        asker_id: whoami.user.user_id,
      });

      onClose();
      router!.navigate(`/vendor-toolkit/questions/${question_id}`);
    },
    [questionText, whoami.user.user_id, onClose],
    setValidationErrors,
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={create}>
        <ModalHeader>Ask a question</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Text fontSize="md">
              Ask a new one-off question. Platformed will attempt to automatically answer your question using
              information from your fact library.
            </Text>
            <FormControl isInvalid={validationErrors.questionText !== undefined}>
              <FormLabel>Question text</FormLabel>
              <Textarea
                data-testid="new-question-text"
                value={questionText}
                onChange={e => setQuestionText(e.target.value)}
                autoFocus
              />
              {validationErrors.questionText && validationErrors.questionText[0] && (
                <FormErrorMessage>{validationErrors.questionText[0].message}</FormErrorMessage>
              )}
            </FormControl>
            {creating.lastError ? (
              <Alert status="error" mt="12">
                <AlertIcon boxSize="40px" />
                <Box>
                  <AlertTitle fontSize="md">Error creating question</AlertTitle>
                  <AlertDescription fontSize="md">{`${creating.lastError}`}</AlertDescription>
                </Box>
              </Alert>
            ) : null}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing="3">
            <Button variant="outline" onClick={onClose} isDisabled={creating.inProgress}>
              Cancel
            </Button>
            <Button colorScheme="blue" type="submit" isDisabled={creating.inProgress} isLoading={creating.inProgress}>
              Ask question
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AskQuestionModal;
