import {DependencyList, Dispatch, SetStateAction, useState} from "react";
import {usePromiseState} from "./promiseState";
import {HTTPError} from "../api";
import * as _ from "lodash-es";

type ValidationState<S extends object> = {
  [Key in keyof S]?: {message: string}[];
};

// Returns the current validation state (map of field names to list of
// validation errors) and a setter function to set the validation errors.
// The validation error(s) for a field will be automatically cleared
// when the value for that field (in the `values` parameter) changes.
export function useValidation<const S extends object>(
  [state, setState]: [ValidationState<S>, Dispatch<SetStateAction<ValidationState<S>>>],
  values: S,
) {
  const [prevValues, setPrevValues] = useState(values);
  let valuesChanged = false;
  const newValidationState = {...state};
  const allKeys = _.union(Object.keys(prevValues), Object.keys(values)) as (keyof S)[];
  for (const k of allKeys) {
    if (values[k] !== prevValues[k]) {
      valuesChanged = true;
      delete newValidationState[k];
    }
  }

  if (valuesChanged) {
    setState(newValidationState);
    setPrevValues(values);
  }

  return [state, setState] as const;
}

// Wrapper around `usePromiseState` which automatically catches validation
// errors from the backend and stores them using the provided `setValidationErrors`
// state setter. Returns a callback to be used as `onSubmit` handlers.
export function useValidatedPromiseState<R, P extends unknown[]>(
  cb: (...args: P) => Promise<R>,
  inputs: DependencyList,
  setValidationErrors?: (validationErrors: any) => void,
) {
  return usePromiseState<R | undefined, [React.FormEvent<HTMLFormElement>, ...P]>(
    async (e, ...args) => {
      e.stopPropagation();
      e.preventDefault();
      try {
        return await cb(...args);
      } catch (ex) {
        if (ex instanceof HTTPError) {
          switch (ex.response.status) {
            case 409:
              throw new Error(await ex.response.json());
            case 422:
              if (setValidationErrors) {
                setValidationErrors(await ex.response.json());
                return;
              } else {
                throw ex;
              }
          }
        }
        throw ex;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValidationErrors, ...inputs],
  );
}
