// eslint-disable-next-line no-restricted-imports
import {MenuList, MenuListProps, Portal, PortalProps} from "@chakra-ui/react";

type Props = Omit<MenuListProps, "zIndex"> & {
  /**
   * The `ref` to the component where the portal will be attached to.
   */
  containerRef?: PortalProps["containerRef"];
};

export default function PortalMenuList(allProps: Props) {
  const {containerRef, ...props} = allProps;

  return (
    <Portal containerRef={containerRef}>
      <MenuList {...props} />
    </Portal>
  );
}
