import {withSuspense} from "../../../../../state/withSuspense.tsx";
import {useQueryData} from "../../../../../state";
import SubscriberList from "../SubscriberList.tsx";
import {Divider, HStack, Stack, Tag} from "@chakra-ui/react";

const MailingList = withSuspense(() => {
  const mailingList = useQueryData({
    queryKey: ["vendorToolkit", "trustCenterMailingList"],
  });
  return (
    <Stack flex={1} spacing={0}>
      <HStack padding={5} justifyContent={"flex-end"}>
        <Tag colorScheme={"blue"}>{"Subscribed (" + mailingList.active_count + ")"}</Tag>

        <Tag colorScheme={"blue"}>{"Not subscribed (" + mailingList.inactive_count + ")"}</Tag>
      </HStack>
      <Divider display={"flex"} />
      <SubscriberList subscribers={mailingList.subscribers}></SubscriberList>
    </Stack>
  );
});

export default MailingList;
