import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Checkbox,
  ModalFooter,
  Button,
  Textarea,
  Select,
} from "@chakra-ui/react";
import * as _ from "lodash-es";
import {SubGraph, EntityId} from "../../../../Types";
import {useResetting} from "../../../../hooks/resetting";
import {usePromiseState} from "../../../../hooks/promiseState";
import api from "../../../../api";
import {
  generateCreateOperationsForSubprocessor,
  generateUpdateOperationsForSubprocessor,
  Subprocessor,
  SubprocessorDescription,
} from "../../graph/types/subprocessor";
import {COUNTRIES} from "../../../../utils/country";
import {useState} from "react";

type AddOrEditSubprocessorModalProps = {
  graph: SubGraph;
  parentEntityId: EntityId;

  isOpen: boolean;
  onClose: () => void;
} & ({kind: "add"} | {kind: "edit"; subprocessor: Subprocessor});

const COUNTRY_OPTIONS = Object.entries(COUNTRIES)
  .sort((a, b) => a[1].localeCompare(b[1]))
  .map(([k, v]) => (
    <option key={k} value={k}>
      {v}
    </option>
  ));

export function AddOrEditSubprocessorModal(props: AddOrEditSubprocessorModalProps) {
  const kind = props.kind;

  const [state, setState] = useResetting(
    useState<SubprocessorDescription>(
      kind === "add"
        ? {
            name: "",
            description: "",
            sharedData: "",
            isoCountryCode: "",
            trustCenterUrl: "",
            websiteUrl: "",
            gdprBasedInEurope: false,
            gdprSccs: false,
            gdprEquivalence: false,
          }
        : {
            name: props.subprocessor.name,
            description: props.subprocessor.description,
            sharedData: props.subprocessor.sharedData,
            isoCountryCode: props.subprocessor.isoCountryCode,
            trustCenterUrl: props.subprocessor.trustCenterUrl,
            websiteUrl: props.subprocessor.websiteUrl,
            gdprBasedInEurope: props.subprocessor.gdprBasedInEurope,
            gdprSccs: props.subprocessor.gdprSccs,
            gdprEquivalence: props.subprocessor.gdprEquivalence,
          },
    ),
    props.isOpen,
  );

  const [submitting, submit] = usePromiseState(
    async (ev: React.FormEvent) => {
      ev.preventDefault();

      const operations =
        kind === "add"
          ? generateCreateOperationsForSubprocessor(props.parentEntityId, state)
          : generateUpdateOperationsForSubprocessor(props.subprocessor, state);

      if (operations.length > 0) {
        await api.vendorToolkit.graph.update(operations);
      }

      props.onClose();
    },
    [kind, props, state],
  );

  // Render

  const title = kind === "add" ? "Add new subprocessor" : `Edit subprocessor ${props.subprocessor.name}`;

  const submitButtonText = kind === "add" ? "Create" : "Save changes";

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="xl">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={submit}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="8">
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                value={state.name}
                onChange={e => setState(s => ({...s, name: e.target.value}))}
                placeholder="Example Ltd"
                autoFocus={kind === "add"}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Website</FormLabel>
              <Input
                type="url"
                value={state.websiteUrl}
                onChange={e => setState(s => ({...s, websiteUrl: e.target.value}))}
                placeholder="https://example.com"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Trust center URL</FormLabel>
              <Input
                type="url"
                value={state.trustCenterUrl}
                onChange={e => setState(s => ({...s, trustCenterUrl: e.target.value}))}
                placeholder="https://trust.example.com"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={state.description}
                onChange={e => setState(s => ({...s, description: e.target.value}))}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Shared data</FormLabel>
              <Textarea value={state.sharedData} onChange={e => setState(s => ({...s, sharedData: e.target.value}))} />
            </FormControl>
            <FormControl>
              <FormLabel>Location</FormLabel>
              <Select
                placeholder="-- Select country or region --"
                value={state.isoCountryCode}
                onChange={e => setState(s => ({...s, isoCountryCode: e.target.value}))}
              >
                {COUNTRY_OPTIONS}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>GDPR</FormLabel>
              <Stack spacing={4}>
                <Checkbox
                  isChecked={state.gdprSccs}
                  onChange={e => setState(s => ({...s, gdprSccs: e.target.checked}))}
                >
                  SCCs
                </Checkbox>
                <Checkbox
                  isChecked={state.gdprBasedInEurope}
                  onChange={e => setState(s => ({...s, gdprBasedInEurope: e.target.checked}))}
                >
                  Based in Europe
                </Checkbox>
                <Checkbox
                  isChecked={state.gdprEquivalence}
                  onChange={e => setState(s => ({...s, gdprEquivalence: e.target.checked}))}
                >
                  Equivalency ruling
                </Checkbox>
              </Stack>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <HStack spacing="3">
            <Button variant="ghost" onClick={props.onClose} isDisabled={submitting.inProgress}>
              Cancel
            </Button>
            <Button colorScheme="blue" type="submit" isLoading={submitting.inProgress}>
              {submitButtonText}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
