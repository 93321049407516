import {Box, HStack, LinkBox, LinkOverlay, Spinner, StackProps, Text} from "@chakra-ui/react";
import {Card} from "../Card";
import {useQueryData} from "../../../../state";
import {withSuspense} from "../../../../state/withSuspense";
import {Link as RouterLink} from "react-router-dom";

const NumReviewItems = withSuspense(
  () => {
    const numReviewItems = useQueryData({queryKey: ["vendorToolkit", "resolutionsCount", {}]});

    return (
      <HStack spacing={2}>
        <Text fontWeight="600" fontSize="x-large">
          {numReviewItems}
        </Text>
      </HStack>
    );
  },
  <Spinner size="sm" />,
);

function TaskCountsCard({...props}: StackProps) {
  return (
    <LinkBox
      as={Card}
      fontSize="md"
      justifyContent="center"
      alignItems="center"
      _hover={{bg: "gray.50"}}
      p={8}
      position="relative"
      {...props}
    >
      <Box display="grid" placeItems="center" boxSize={12}>
        <NumReviewItems />
      </Box>
      <Text textAlign="center">
        <LinkOverlay as={RouterLink} to={"/vendor-toolkit/library/facts/review-items"}>
          review items
        </LinkOverlay>
      </Text>
    </LinkBox>
  );
}

export default TaskCountsCard;
