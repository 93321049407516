import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import Search from "../../../components/Search";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useResetting} from "../../../hooks/resetting";
import {SearchResults} from "./resultsComponents";
import {
  generateSearchPlaceholder,
  searchTrustCenter,
  SearchTrustCenterResults,
  useSearchTrustCenterData as useSearchTrustCenterData,
} from "./search";
import {useTrustCenterConfig} from "../../config";
import {withSuspense} from "../../../state/withSuspense";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function SearchModal({isOpen, onClose}: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent maxW="1080px" pb={4}>
          <SearchModalInner isOpen={isOpen} onClose={onClose} />
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

const SearchModalInner = withSuspense(({isOpen, onClose}: Props) => {
  const config = useTrustCenterConfig();
  const data = useSearchTrustCenterData();

  const [query, setQuery] = useResetting(useState(""), isOpen);
  const [pending, setPending] = useResetting(useState(false), isOpen);
  const [results, setResults] = useResetting(useState<SearchTrustCenterResults | undefined>(undefined), isOpen);
  const [searchPlaceholder] = useResetting(
    useState(() => generateSearchPlaceholder(data)),
    isOpen,
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const onSearch = useCallback(
    async (ev: React.FormEvent) => {
      ev.preventDefault();

      setPending(true);
      setResults(await searchTrustCenter(query, data));
      setPending(false);
    },
    [data, query, setPending, setResults],
  );

  // Re-focus the input when search has completed.
  useEffect(() => {
    if (!pending && inputRef.current != null) {
      inputRef.current.focus();
    }
  }, [pending]);

  return (
    <>
      <ModalHeader>
        <Text>Ask {config.name}</Text>
        <Text fontSize="md" fontWeight="400" color="gray.500">
          Search for information on our trust center.
        </Text>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack gap={8}>
          <HStack as="form" onSubmit={onSearch}>
            <Search
              query={query}
              onChange={setQuery}
              placeholder={searchPlaceholder}
              disabled={pending}
              inputProps={{fontSize: "1rem"}}
              ref={inputRef}
            />
            <Button variant="primary" type="submit" isDisabled={query.length === 0} isLoading={pending}>
              Search
            </Button>
          </HStack>
          {!pending && results !== undefined && <SearchResults results={results} onNavigate={onClose} />}
        </Stack>
      </ModalBody>
    </>
  );
});

export default SearchModal;
