import {Box, Button, Divider, Heading, HStack, Icon, IconButton, Stack, Img} from "@chakra-ui/react";
import {useQueryData} from "../../../../../state";
import {useParams} from "react-router-dom";
import {SourceId, SourceMin, ThirdPartyId} from "../../../../../Types";
import Page from "../../../../../components/Page";
import {ActionBar} from "../../../../../components/ActionBar";
import ThirdPartyName from "../../../components/ThirdPartyName";
import {ChevronRightIcon, TrashIcon} from "@heroicons/react/24/solid";
import Pdf from "../../../../VendorToolkit/Library/Documents/Pdf";

import pdfUrl from "./soc2.pdf";
import isoUrl from "./iso.pdf";
import securityScorecardUrl from "./scorecard.pdf";
import securityWebsite from "./security.salesforce.com.png";
import trustWebsite from "./trust.salesforce.com.png";

const URLS: {[_id: string]: string} = {
  Source2: pdfUrl,
  Source3: isoUrl,
  Source6: securityScorecardUrl,
};

const PDFFile = ({source}: {source: SourceMin}) => <Pdf downloadUrl={URLS[source.source_id]!} />;

const FileActions = () => (
  <ActionBar
    actionButtons={
      <>
        <Button colorScheme="blue">Replace this file</Button>
        <IconButton icon={<Icon as={TrashIcon} />} colorScheme="red" aria-label="Delete" />
      </>
    }
  >
    <Button colorScheme="green">Download</Button>
  </ActionBar>
);

const File = ({source}: {source: SourceMin}) => {
  if (source.provider.type !== "File") {
    return null;
  }

  switch (source.provider.file_type) {
    case "PDF":
      return (
        <>
          <FileActions />
          <PDFFile source={source} />
        </>
      );
    default:
      return null;
  }
};

const Website = ({source}: {source: SourceMin}) => {
  if (source.provider.type != "Website") {
    return null;
  }

  return (
    <>
      <ActionBar>
        <Button colorScheme="green">Refresh</Button>
      </ActionBar>
      <Box p={8}>
        <Img src={source.provider.url.includes("security") ? securityWebsite : trustWebsite} rounded="md" />
      </Box>
    </>
  );
};

const SourcePage = () => {
  const {sourceId, thirdPartyId} = useParams() as {sourceId: SourceId; thirdPartyId: ThirdPartyId};
  const thirdParty = useQueryData({queryKey: ["tprm", "thirdParties", thirdPartyId]});
  const source = useQueryData({queryKey: ["tprm", "sources", sourceId]});
  // TODO
  const title = "Test";
  return (
    <Page title={title} display="flex" flexDirection="column">
      <Stack spacing={0} divider={<Divider />} bg="gray.200" flex={1}>
        <HStack divider={<Icon as={ChevronRightIcon} />} p={8} bg="white">
          <Heading size="md">
            <ThirdPartyName thirdParty={thirdParty} />
          </Heading>
          <Heading size="md">{source.name}</Heading>
        </HStack>
        {source.provider.type === "File" ? (
          <File source={source} />
        ) : source.provider.type === "Website" ? (
          <Website source={source} />
        ) : null}
      </Stack>
    </Page>
  );
};

export default SourcePage;
