import {
  Accordion,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import {useCallback, useMemo, useState} from "react";
import {withSuspense} from "../../../../../state/withSuspense";
import {FactLibraryContext, FactRow} from "../../Facts/FactRow";
import NoFactsSnippet from "./NoFactsSnippet";
import {PageNav} from "../../../../components/PageNav";
import {getSearchPlaceholderText} from "../utility/search";
import Search from "../../../../../components/Search";
import {useQueryData} from "../../../../../state";
import {FactSearchArgs} from "../../../../../Types";
import {ScopeOrSectionOwner} from "./ScopeOrSectionOwner";

type Props = {
  title: string;
  libraryContext?: FactLibraryContext;
};

const PAGE_SIZE = 25;

const BaseFactsPage = withSuspense((props: Props) => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const [pageNo, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const fromSearch = searchQuery.length > 0;
  const isClustering = whoami.account!.facts_modified_at > whoami.account!.facts_clustered_at;

  const [expandedFacts, setExpandedFacts] = useState<number | number[]>([]);

  const facts = useQueryData({
    queryKey: [
      "vendorToolkit",
      "facts",
      {
        section_filter: props.libraryContext && {
          scoped_id:
            props.libraryContext.parent.type === "scope"
              ? {
                  scope_id: props.libraryContext.parent.obj?.scope_id,
                }
              : {
                  scope_id: props.libraryContext.parent.obj.parent_scope?.scope_id,
                  library_section_id: props.libraryContext.parent.obj.library_section_id,
                },
          recursive: props.libraryContext.recurse,
        },
        pagination: {
          offset: (pageNo - 1) * PAGE_SIZE,
          limit: PAGE_SIZE,
        },
        search: searchQuery.length > 0 ? searchQuery : undefined,
      } satisfies FactSearchArgs,
    ],
  });

  const searchPlaceholderText = useMemo(() => getSearchPlaceholderText(props.libraryContext), [props.libraryContext]);

  const resultsText = useMemo(() => {
    const p = facts.total !== 1 ? "s" : "";
    return fromSearch ? `${facts.total} result${p} found:` : `Showing all ${facts.total} fact${p}:`;
  }, [facts.total, fromSearch]);

  const from = (pageNo - 1) * PAGE_SIZE + 1;
  const to = Math.min(pageNo * PAGE_SIZE, facts.total);

  const isUncategorized = props.libraryContext?.recurse === false;

  const updateSearchQuery = useCallback((search: string) => {
    setSearchQuery(search);
    setPage(1);
    setExpandedFacts([]);
  }, []);

  const updatePage = useCallback((page: number) => {
    setPage(page);
    setExpandedFacts([]);
  }, []);

  return (
    <Stack flexGrow={1}>
      {isClustering && (
        <Alert status="info">
          <AlertIcon />
          <AlertTitle fontSize="md">Organizing facts</AlertTitle>
          <AlertDescription fontSize="md">Some facts may not appear until this process completes.</AlertDescription>
        </Alert>
      )}
      <HStack justifyContent="space-between" p={8} pb={0}>
        <Heading size="md">
          <Text as="span">{props.title}</Text>
          {isUncategorized && (
            <Text as="span" color="gray.500" fontWeight={500}>
              {" "}
              (uncategorized)
            </Text>
          )}
        </Heading>
        {props.libraryContext != null && !isUncategorized && <ScopeOrSectionOwner node={props.libraryContext.parent} />}
      </HStack>
      <Box px={8} py={4}>
        <Search
          query={searchQuery}
          onChange={updateSearchQuery}
          placeholder={searchPlaceholderText}
          debounceTime={1_000}
        />
      </Box>
      <Box display="flex" flexFlow="column" flexGrow={1}>
        {facts.total > 0 ? (
          <>
            {fromSearch && (
              <Text p={0} px={8} fontSize="md" color="gray.500">
                {resultsText}
              </Text>
            )}
            <Stack spacing={0} flexGrow={1}>
              <Accordion allowMultiple key={pageNo} index={expandedFacts} onChange={setExpandedFacts} flexGrow={1}>
                {facts.items.map(fact => (
                  <FactRow key={fact.fact_id} fact={fact} libraryContext={props.libraryContext} />
                ))}
              </Accordion>
              <Flex
                py={2}
                px={2}
                pl={8}
                align="center"
                justify="space-between"
                bg="gray.50"
                borderColor="gray.200"
                bottom="0"
              >
                <Text fontSize="md">
                  Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{facts.total}</strong> results
                </Text>
                <PageNav
                  page={pageNo}
                  pageCount={Math.max(1, Math.ceil(facts.total / PAGE_SIZE))}
                  setPage={updatePage}
                  buttonProps={{bg: "white"}}
                />
              </Flex>
            </Stack>
          </>
        ) : (
          <NoFactsSnippet searchQuery={searchQuery} withinSection={props.libraryContext?.parent.type === "section"} />
        )}
      </Box>
    </Stack>
  );
});

export default BaseFactsPage;
